import { useNavigate } from 'react-router-dom';

import { Box, Grid, Stack, Typography } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ResumeEditorState } from '../../../interface';
import { isNilOrEmpty, isNotNilOrEmpty } from '../../../utils';
import Icon from '../../common/Icon';
import MobileTooltip from '../../common/MobileTooltip';
import Tooltip from '../../common/Tooltip';
import EmptySection from './EmptySection';
import ResumeEditorButton from './ResumeEditorButton';
import ResumeSectionContent from './ResumeSectionContent';

interface ResumeSectionProps {
  title: string;
  content: string | JSX.Element | JSX.Element[] | null;
  noContentText?: string;
  tooltipContent?: string | string[];
  editable?: boolean;
  enableSectionReorder?: boolean;
  hideEmptySection?: boolean;
  editorState?: ResumeEditorState;
}

const ResumeSection = ({
  title,
  content,
  noContentText = 'No item(s) added.',
  enableSectionReorder = false,
  tooltipContent,
  editable = false,
  editorState,
  hideEmptySection,
}: ResumeSectionProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveDevice();
  const shouldhideEmptySection = hideEmptySection && isNilOrEmpty(content);

  const resumeEditorState: ResumeEditorState = {
    action: editorState?.action || '',
    resumeId: editorState?.resumeId || '',
    elementId: editorState?.elementId || '',
    elementType: editorState?.elementType || '',
  };

  // TODO: Do we need edit functionality here?
  const handleAddEditClick = () => {
    resumeEditorState.action = editorState?.action || 'edit';
    navigate(`/resumes/edit/${editorState?.elementType}`, { state: { resumeEditorState } });
  };

  const handleReorderClick = () => {
    resumeEditorState.action = 'reorder';
    navigate(`/resumes/reorder/${editorState?.elementType}`, {
      state: { id: editorState?.resumeId },
    });
  };

  return shouldhideEmptySection ? null : (
    <Stack className="resume-section" gap="16px">
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid xs={7} item>
          <Typography variant={isMobile ? 'h3' : 'h2'}>
            <span style={{ paddingRight: '8px' }}>{title}</span>
            {tooltipContent &&
              (isMobile || isTablet ? (
                <MobileTooltip content={tooltipContent} />
              ) : (
                <Tooltip content={tooltipContent} />
              ))}
          </Typography>
        </Grid>
        {editable && (
          <Grid item>
            {enableSectionReorder && (
              <ResumeEditorButton onClick={handleReorderClick} startIcon="fi fi-rr-apps-sort">
                Reorder
              </ResumeEditorButton>
            )}
            {editorState?.action === 'edit' ? (
              <ResumeEditorButton onClick={handleAddEditClick} />
            ) : (
              <ResumeEditorButton
                sx={{ color: 'primary' }}
                onClick={handleAddEditClick}
                startIcon={
                  <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
                }
              >
                Add
              </ResumeEditorButton>
            )}
          </Grid>
        )}
      </Grid>
      <Box className="resume-section-body">
        {isNotNilOrEmpty(content) && content !== null ? (
          <ResumeSectionContent content={content} />
        ) : (
          <EmptySection text={noContentText} />
        )}
      </Box>
    </Stack>
  );
};

export default ResumeSection;
