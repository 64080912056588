import { useLayoutEffect } from 'react';

import { Stack } from '@mui/material';

import useIsMobile from '../../../../hooks/useIsMobile';
import type { Draft as DraftDataType } from '../../../../stores/SuperEditorDrafts';
import useDraftsStore from '../../../../stores/SuperEditorDrafts';
import Draft from './components/Draft';

interface DraftsManagerProps {
  sectionTitle?: string;
  onRemoveDraft?: (id: string) => void;
  onAppendDraft?: (draft: DraftDataType) => void;
  onReplaceDraft?: (draft: DraftDataType) => void;
}

const DraftsManager = ({
  sectionTitle = '',
  onRemoveDraft,
  onAppendDraft,
  onReplaceDraft,
}: DraftsManagerProps) => {
  const isMobile = useIsMobile();

  const { drafts, removeDraft, resetDrafts } = useDraftsStore();

  const onDelete = (id: string) => {
    removeDraft(id);
    if (onRemoveDraft) {
      onRemoveDraft(id);
    }
  };

  // cleanup code to reset drafts once component mounts for the first time and  before it unmounts
  useLayoutEffect(() => {
    resetDrafts();

    return () => {
      resetDrafts();
    };
  }, [resetDrafts]);

  return (
    <>
      {drafts?.length > 0 && (
        // Drafts UI
        <Stack
          sx={{
            flexDirection: isMobile ? 'column' : 'row',
            gap: 1.5,
          }}
        >
          {drafts.map((draft, index) => (
            // Draft component
            <Draft
              key={draft.id}
              draft={draft}
              index={index}
              sectionTitle={sectionTitle}
              onAppendDraft={onAppendDraft}
              onReplaceDraft={onReplaceDraft}
              onDelete={onDelete}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default DraftsManager;
