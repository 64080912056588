import { useMemo } from 'react';

import { type ResumeData, ResumeTemplateKey } from '../../interface';
import { isNilOrEmpty } from '../../utils';
import Modern from './Modern';
import StandardA from './StandardA';
import StandardB from './StandardB';
import DocumentBaseStyles from './styles/DocumentBaseStyles';

interface TemplateDocumentProps {
  resume: ResumeData;
  selectedTemplateKey?: ResumeTemplateKey;
}

const TemplateDocument = ({
  resume,
  selectedTemplateKey = ResumeTemplateKey.StandardLayoutA,
}: TemplateDocumentProps) => {
  const document = useMemo(() => {
    if (isNilOrEmpty(resume)) {
      return null;
    }
    switch (selectedTemplateKey) {
      case 'standard_layout_a':
        return <StandardA resume={resume} />;

      case 'standard_layout_b':
        return <StandardB resume={resume} />;

      case 'modern_layout_a':
        return <Modern resume={resume} />;

      case 'modern_layout_b':
        return <Modern resume={resume} templateKey={selectedTemplateKey} />;

      default:
        return <StandardA resume={resume} />;
    }
  }, [resume, selectedTemplateKey]);

  return (
    <div>
      <DocumentBaseStyles />
      {document}
    </div>
  );
};

export default TemplateDocument;
