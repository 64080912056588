import parse from 'html-react-parser';
import { useNavigate } from 'react-router';

import { Box, Grid, Stack, Typography } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ResumeEditorState, SummaryInfo } from '../../../interface';
import { isNilOrEmpty, isNotNilOrEmpty } from '../../../utils';
import EmptySection from './EmptySection';
import ResumeEditorButton from './ResumeEditorButton';

interface SummaryProps {
  summary?: SummaryInfo;
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
}

const Summary = ({ summary, editable, resumeId, hideEmptySection }: SummaryProps) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const shouldhideEmptySection = hideEmptySection && isNilOrEmpty(summary?.professional_summary);

  const handleEditSummary = () => {
    const resumeEditorState: ResumeEditorState = {
      action: 'edit',
      resumeId: resumeId || '',
      elementId: summary?._id || '',
      elementType: 'summary',
    };
    navigate('/resumes/edit/summary', { state: { resumeEditorState } });
  };

  return shouldhideEmptySection ? null : (
    <Stack gap="16px">
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid item>
          <Typography variant={isMobile ? 'h3' : 'h2'}> Professional Summary</Typography>
        </Grid>
        {editable && (
          <Grid item>
            <ResumeEditorButton onClick={handleEditSummary} />
          </Grid>
        )}
      </Grid>
      {isNotNilOrEmpty(summary?.professional_summary) ? (
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '12px',
            borderColor: 'border.light',
            padding: 2,
          }}
        >
          <Typography variant="body2">{parse(summary?.professional_summary || '')}</Typography>
        </Box>
      ) : (
        <EmptySection text="No Professional Summary Added" />
      )}
    </Stack>
  );
};

export default Summary;
