import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../components/DragnDrop/SortableItem';
import FormActions from '../../components/Form/FormActions';
import type { SortedResumeSectionKey, VolunteerExperienceInfo } from '../../interface';
import { isNotNilOrEmpty } from '../../utils';
import { formatDate } from '../../utils/dateUtils';

interface VolunteerExperienceReorderProps {
  volunteerExperiences: VolunteerExperienceInfo[] | undefined;
  isLoading?: boolean;
  onSubmit?: (sortedKeys: SortedResumeSectionKey[]) => void;
}

const VolunteerExperienceReorder = ({
  volunteerExperiences = [],
  isLoading,
  onSubmit,
}: VolunteerExperienceReorderProps) => {
  const [volunteerExperiencesOrder, setVolunteerExperiencesOrder] = useState(volunteerExperiences);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = volunteerExperiencesOrder.findIndex(
        (experience) => experience._id === active.id,
      );
      const newIndex = volunteerExperiencesOrder.findIndex(
        (experience) => experience._id === over.id,
      );
      const movedArray = arrayMove(volunteerExperiencesOrder, oldIndex, newIndex);

      setVolunteerExperiencesOrder(movedArray);
    }
  };

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedVolunteerExperienceKeys = volunteerExperiencesOrder.map((experience, index) => ({
        id: experience._id,
        index,
      }));

      onSubmit(sortedVolunteerExperienceKeys);
    }
  };

  return (
    // TODO: add to common components
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={volunteerExperiencesOrder.map((experience) => experience._id)}
          strategy={verticalListSortingStrategy}
        >
          {volunteerExperiencesOrder.map((experience) => (
            <SortableItem
              key={experience._id}
              id={experience._id}
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: '12px',
                borderColor: 'border.light',
                backgroundColor: 'background.light',
                '&:hover': {
                  backgroundColor: 'background.mediumLight',
                },
              }}
            >
              <Stack gap={0.5}>
                {isNotNilOrEmpty(experience.organization) && (
                  <Typography variant="label1">{experience.organization}</Typography>
                )}
                {isNotNilOrEmpty(experience.organization) && (
                  <Typography variant="body2">{experience.location}</Typography>
                )}
                <Typography variant="body2">
                  {formatDate(
                    experience.start_month,
                    experience.start_year,
                    experience.end_month,
                    experience.end_year,
                    experience.present,
                  )}
                </Typography>
              </Stack>
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <FormActions onSubmit={onSaveClick} submitButtonProps={{ loading: isLoading }} />
    </Stack>
  );
};

export default VolunteerExperienceReorder;
