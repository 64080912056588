import { create } from 'zustand';

import Routepaths from '../routes/RoutePaths';

type LastVisitedTopPageState = {
  lastVisitedTopPage: Routepaths;
  setLastVisitedTopPage: (page: Routepaths) => void;
};

const lastVisitedPageKey = 'lastVisitedTopPage';

export const useLastVisitedTopPageStore = create<LastVisitedTopPageState>((set) => {
  // Try to get the state from localStorage on initialization
  const savedState = localStorage.getItem(lastVisitedPageKey);
  const initialState = savedState
    ? { lastVisitedTopPage: savedState as Routepaths }
    : { lastVisitedTopPage: Routepaths.HOME }; // default to home page

  return {
    ...initialState,
    setLastVisitedTopPage: (page) => set(() => ({ lastVisitedTopPage: page })),
  };
});

// Subscribe to state changes and save to localStorage
useLastVisitedTopPageStore.subscribe((state) => {
  if (state.lastVisitedTopPage === Routepaths.HOME) {
    localStorage.removeItem(lastVisitedPageKey);
    return;
  }
  localStorage.setItem(lastVisitedPageKey, state.lastVisitedTopPage);
});
