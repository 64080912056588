import type { ComponentsOverrides, Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

const MuiDataGridOverride: ComponentsOverrides<Theme>['MuiDataGrid'] = {
  root: {
    border: 'none',
  },
  withBorderColor: {
    border: 'none',
  },
};

export const scoreColors = {
  dark: {
    excellent: '#00B050',
    good: '#99D16D',
    fair: '#408ACF',
    marginal: '#FFA824',
    poor: '#D41A24',
  },
  light: {
    excellent: '#EDFFF5',
    good: '#F5FFED',
    fair: '#EDF6FF',
    marginal: '#FFF8ED',
    poor: '#FFEDEE',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#E6F7EE',
      mediumLight: '#BBF0D3',
      main: '#00B050',
      mediumDark: '#00883E',
      dark: '#007435',
    },
    error: {
      light: '#FBE9EA',
      mediumLight: '#F4C5C8',
      main: '#D41A24',
      mediumDark: '#AA151D',
      dark: '#881117',
    },
    warning: {
      main: '#F8DA3F',
    },
    success: {
      main: '#09B457',
    },
    assent: {
      light: '#D3E8FC',
      main: '#228EF2',
    },
    score: scoreColors,
    text: {
      primary: '#212121',
      primaryGray: '#515151',
      secondary: '#737373',
      tertiary: '#B9B9B9',
    },
    border: {
      light: '#E9E9E9',
      main: '#D9D9D9',
    },
    background: {
      light: '#FFF',
      mediumLight: '#F9F9F9',
      main: '#EBEBEB',
      mediumDark: '#CCC',
    },
  },

  typography: {
    fontFamily: `'Inter', 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif`,
    fontSize: 14,
    display1: {
      fontSize: '4rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '3rem',
    },

    h1: {
      fontSize: '2.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.8125rem',
    },

    h2: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.25rem',
    },

    h3: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.5625rem',
    },

    body1: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.875rem',
    },

    body2: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },

    body3: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },

    label1: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },

    label2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },

    assistiveBold: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.125rem',
      letterSpacing: '-0.00375rem',
    },

    assistive: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.125rem',
      letterSpacing: '-0.00375rem',
    },
  },

  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '0px',
          padding: '20px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px',
          borderRadius: '8px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px' /* 142.857% */,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        ...MuiDataGridOverride, // Assign the overrides directly here
      },
    },
  },
});

export default theme;
