import { useState } from 'react';

import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import BreadCrumbs from '../../components/common/BreadCrumbs';
import Modal from '../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../components/common/Modal/ModalDetail';
import { useAuth } from '../../contexts/auth';
import { useSnackbar } from '../../contexts/snackbar';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { deleteUserProfile } from '../../services/user';
import DeleteAccount from './components/DeleteAccount';

const ProfileSettings = () => {
  const [showModal, setShowModal] = useState(false);
  const { logout } = useAuth();

  const { showSnackbar } = useSnackbar();

  const BreadCrumbLinks = [
    {
      label: 'Settings',
      href: RoutePaths.SETTINGS,
    },
  ];

  const { mutate: deleteProfile } = useMutation({
    mutationFn: deleteUserProfile,
    onSuccess: () => {
      setShowModal(false);
      logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Delete Account started');
      logout();
      showSnackbar('success', 'Account Deleted');
    },
  });

  return (
    <Stack>
      <Modal open={showModal}>
        <>
          <ModalDetail
            title="Delete Account?"
            description="Are you sure you want to permanently delete your account and all the associated data? This action cannot be undone."
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={deleteProfile}
            onClose={() => setShowModal(false)}
            disableConfirmButtonDuration={5}
          />
        </>
      </Modal>
      <Stack
        gap="32px"
        sx={{
          width: '60vw',
          margin: '32px 128px',
        }}
      >
        <BreadCrumbs links={BreadCrumbLinks} />
        <DeleteAccount onDelete={() => setShowModal(true)} />
      </Stack>
    </Stack>
  );
};
export default ProfileSettings;
