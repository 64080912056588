import AiCustomizeImage from '../../assets/ai_customize_illustration.svg';
import AiResumeScanImage from '../../assets/ai_resume_scan_illustration.svg';
import JobScoreImage from '../../assets/job_match_score_illustration.svg';
import JobXrayImage from '../../assets/job_xray_illustration.svg';
import SampleResumeImage from '../../assets/sample_resume_illustration.svg';
import RoutePaths from '../../routes/RoutePaths';
import { JobDescriptionTypes } from '../../services/jobDescriptions';
import { JobMatchScoreKeys } from '../../services/jobMatches';

export enum ProgressScreenType {
  AiResumeScan = 'ai-resume-scan',
  JDXray = 'jd-xray',
  JobMatchScore = 'job-match-score',
  AiCustomize = 'ai-customize',
  SampleResume = 'sample-resume',
}

export const ScreenTypeEventSuccessLabel = {
  [ProgressScreenType.AiResumeScan]: 'AI Resume Upload Successful',
  [ProgressScreenType.JDXray]: 'JD Xray Generation Successful',
  [ProgressScreenType.JobMatchScore]: 'Job Match Score Generation Successful',
  [ProgressScreenType.AiCustomize]: 'AI Customize Resume Successful',
  [ProgressScreenType.SampleResume]: 'Sample Resume Generation Successful',
};

export interface ProgressContent {
  title: string;
  estTime: string;
  info: string;
  image: string;
  descriptionPoints: string[];
  progressText: string;
  queryKey: string;
  endpoint: string;
  redirectPath: string;
}

export interface MissingFields {
  name: string;
  label: string;
  placeholder: string;
}

export const missingFieldMap: MissingFields[] = [
  {
    name: 'job_company',
    label: 'Company name in job description',
    placeholder: 'JobQuest',
  },
  {
    name: 'job_title.Job_Title',
    label: 'Job title in job description',
    placeholder: 'Project manager, Computer security',
  },
];

export const progressContent: Record<ProgressScreenType, ProgressContent> = {
  [ProgressScreenType.JobMatchScore]: {
    title: 'Job Match Score',
    estTime: '1-2 min est.',
    info: 'No guesswork. Your score for the job.',
    image: JobScoreImage,
    descriptionPoints: [
      'Are you applying to the right job?',
      'Job Match Score indicates how well your resume matches the job description.',
      'We provide detailed gap analysis and recommendation',
    ],
    progressText: 'Generating a Job Match Score report',
    queryKey: JobMatchScoreKeys.JOBMATCHES,
    endpoint: '/match-score',
    redirectPath: RoutePaths.JOBMATCHES,
  },
  [ProgressScreenType.AiResumeScan]: {
    title: 'AI Resume Scan',
    estTime: '1 min est.',
    info: 'Less editing. Proven ATS Resume Templates.',
    image: AiResumeScanImage,
    descriptionPoints: [
      'JobQuest scans and extracts your resume content.',
      'Download your resume in any of JobQuest’s resume templates.',
      'JobQuest resume templates are optimized for both ATS and human reviewers.',
    ],
    progressText: 'Scanning the uploaded resume',
    // TODO: need to rework on these arguments after keys structure are unified
    queryKey: RoutePaths.RESUMES.replace('/', ''),
    endpoint: RoutePaths.RESUMES,
    redirectPath: RoutePaths.RESUMES,
  },
  [ProgressScreenType.SampleResume]: {
    title: 'Sample Resume',
    estTime: '1 min est.',
    info: 'Get inspired. Avoid common mistakes.',
    image: SampleResumeImage,
    descriptionPoints: [
      'JobQuest generates a targeted sample resume.',
      'Use the sample resume to avoid common mistakes.',
      'Spend more time personalizing the content.',
    ],
    progressText: 'Generating a sample resume',
    queryKey: RoutePaths.RESUMES.replace('/', ''),
    endpoint: '/resumes/ai_generation',
    redirectPath: RoutePaths.RESUMES,
  },
  [ProgressScreenType.JDXray]: {
    title: 'X-Ray Job Description',
    estTime: '1 min est.',
    info: 'Less reading. No-frills job highlights.',
    image: JobXrayImage,
    descriptionPoints: [
      'JobQuest scans and analyzes the job description.',
      'We generate a Job X-ray, highlighting key points of the job description.',
      'Job X-ray saves you time on reading.',
    ],
    progressText: 'Generating a Job X-ray',
    queryKey: JobDescriptionTypes.JOBXRAYS,
    endpoint: '/job-description',
    redirectPath: RoutePaths.JOBS,
  },
  [ProgressScreenType.AiCustomize]: {
    title: 'AI Customize',
    estTime: '1 min est.',
    info: 'Less writing. More interviews.',
    image: AiCustomizeImage,
    descriptionPoints: [
      'JobQuest tailors your resume to the job description.',
      'We tailor the Professional Summary and the most recent Work Experience.',
      'Tailoring your resume for each application boosts the chances of landing an interview.',
    ],
    progressText: 'Tailoring the resume to the job description',
    queryKey: RoutePaths.RESUMES.replace('/', ''),
    endpoint: '/resumes/ai_customization',
    redirectPath: RoutePaths.RESUMES,
  },
};
