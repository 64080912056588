import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../components/DragnDrop/SortableItem';
import FormActions from '../../components/Form/FormActions';
import type { CertificatesLicensesInfo, SortedResumeSectionKey } from '../../interface';
import { isNotNilOrEmpty } from '../../utils';
import { formatDate } from '../../utils/dateUtils';

interface CertificateAndLicenseReorderProps {
  certificateAndLicenses: CertificatesLicensesInfo[] | undefined;
  isLoading?: boolean;
  onSubmit?: (sortedExperinceKeys: SortedResumeSectionKey[]) => void;
}

const CertificateAndLicenseReorder = ({
  certificateAndLicenses = [],
  isLoading,
  onSubmit,
}: CertificateAndLicenseReorderProps) => {
  const [certificateAndLicensesOrder, setCertificateAndLicensesOrder] =
    useState(certificateAndLicenses);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = certificateAndLicensesOrder.findIndex(
        (certification) => certification._id === active.id,
      );
      const newIndex = certificateAndLicensesOrder.findIndex(
        (certification) => certification._id === over.id,
      );
      const movedArray = arrayMove(certificateAndLicensesOrder, oldIndex, newIndex);

      setCertificateAndLicensesOrder(movedArray);
    }
  };

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedCertificateAndLicenseKeys = certificateAndLicensesOrder.map(
        (certification, index) => ({
          id: certification._id,
          index,
        }),
      );

      onSubmit(sortedCertificateAndLicenseKeys);
    }
  };

  return (
    // TODO: add to common components
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={certificateAndLicensesOrder.map((certification) => certification._id)}
          strategy={verticalListSortingStrategy}
        >
          {certificateAndLicensesOrder.map((certification) => (
            <SortableItem
              key={certification._id}
              id={certification._id}
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: '12px',
                borderColor: 'border.light',
                backgroundColor: 'background.light',
                '&:hover': {
                  backgroundColor: 'background.mediumLight',
                },
              }}
            >
              <Stack gap={0.5}>
                {isNotNilOrEmpty(certification.name) && (
                  <Typography variant="label1">{certification.name}</Typography>
                )}
                {isNotNilOrEmpty(certification.organization) && (
                  <Typography variant="body2">{certification.organization}</Typography>
                )}
                <Typography variant="body2">
                  {formatDate(
                    null,
                    null,
                    certification.issued_month,
                    certification.issued_year,
                    false,
                  )}
                </Typography>
              </Stack>
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <FormActions onSubmit={onSaveClick} submitButtonProps={{ loading: isLoading }} />
    </Stack>
  );
};

export default CertificateAndLicenseReorder;
