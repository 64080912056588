import type { BoxProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';

// TODO: add CircularProgressProps
const LoadingSpinner = ({ sx, ...resProps }: BoxProps) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 1000,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      ...sx,
    }}
    {...resProps}
  >
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;
