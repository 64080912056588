import { useState } from 'react';

import type { SxProps } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { CertificatesLicensesInfo } from '../../../interface';
import { isNotNilOrEmpty } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Modal from '../../common/Modal/Modal';
import ModalActionsDeprecated from '../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../common/Modal/ModalDetail';

interface CertificateLicenseDetailProps {
  key?: string;
  certificate: CertificatesLicensesInfo;
  isEditable?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  sx?: SxProps;
}

const CertificateLicenseDetail = ({
  key,
  certificate,
  isEditable,
  onEdit,
  onDelete,
  sx,
}: CertificateLicenseDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditCertificate = async () => {
    if (onEdit) {
      onEdit(certificate._id);
    }
  };

  const handleDeleteCertificate = async () => {
    setOpen(false);
    if (onDelete) {
      onDelete(certificate._id);
    }
  };

  return (
    <Stack
      key={key}
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Certificate/License?"
            description={`Are you sure you want to delete the certificate/license from ${certificate.organization}? This action cannot be undone.`}
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeleteCertificate}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Stack gap={1}>
            {isNotNilOrEmpty(certificate.name) && (
              <Typography variant="label1">{certificate.name}</Typography>
            )}
            {isNotNilOrEmpty(certificate.organization) && (
              <Typography variant="body2">{certificate.organization}</Typography>
            )}
            <Typography variant="body2">
              {formatDate(null, null, certificate.issued_month, certificate.issued_year, false)}
            </Typography>
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditCertificate}>
              <Icon className="fi fi-rr-edit" />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <Icon className="fi fi-rr-trash" />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default CertificateLicenseDetail;
