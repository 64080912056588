import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import FileDropzone from '../../../components/FileDropzone';
import FormActions from '../../../components/Form/FormActions';
import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import Button from '../../../components/common/Button';
import InfoPopup from '../../../components/common/InfoPopup';
import TextWrappedLinkButton from '../../../components/common/TextWrappedLinkButton';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import { ResumesKeys, uploadResumeFile } from '../../../services/resumes';
import { useAppBarStore } from '../../../stores/AppBarStore';

const ResumeImport = () => {
  const [resumeFile, setResumeFile] = useState<File>();
  const [resumeId, setResumeId] = useState<string>('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const { showSnackbar } = useSnackbar();
  const { setAppBar, setInitialState } = useAppBarStore();
  const [isShowingProgress, setIsShowingProgress] = useState(false);

  const queryClient = useQueryClient();

  const PopupMessageContent = {
    title: 'What is a Base Resume?',
    description:
      'Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice.',
  };

  const BreadCrumbLinks = isMobile
    ? [
        {
          label: 'Back',
          href: RoutePaths.RESUMES,
        },
      ]
    : [
        {
          label: 'Resumes',
          href: RoutePaths.RESUMES,
        },
        {
          label: 'Import',
          href: RoutePaths.RESUME_IMPORT,
        },
      ];

  useEffect(() => {
    if (isMobile) {
      setAppBar('Resumes', null);
    }

    return () => {
      setInitialState(isMobile);
    };
  }, [isMobile, setAppBar, setInitialState]);

  const { isLoading: isResumeUploading, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setResumeId(data?._id);
      setIsShowingProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  const handleSelectFile = (file: File | undefined) => {
    setResumeFile(file);
  };

  const onImportFile = () => {
    if (!resumeFile) {
      throw new Error('Resume file is not selected');
    }

    uploadResume(resumeFile as File);
  };

  return (
    // create resume page
    <Stack
      sx={{
        height: { xs: '90%', sm: '100%' },
        width: '100%',
        maxWidth: '120rem',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          sm: `${theme.spacing(3)}`,
        },
        boxSizing: 'border-box',
        gap: { xs: 1.5, sm: 4 },
      }}
    >
      {!isShowingProgress ? (
        <>
          <Stack sx={{ gap: 0.5 }}>
            <BreadCrumbs links={BreadCrumbLinks} />
            {!isMobile && (
              <Typography variant="body2" sx={{ paddingLeft: 3.75 }}>
                Start from an existing resume.
              </Typography>
            )}
          </Stack>

          <Stack
            sx={{
              paddingX: { sm: 3.75 },
              gap: 4,
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <FileDropzone
              label="Import a Resume"
              helperText={
                <TextWrappedLinkButton
                  variant="body3"
                  text={{
                    startingText: 'Importing a new resume creates a base resume for Jobquest. ',
                    linkText: 'Base resume',
                    endingText: '',
                  }}
                  onClick={() => setShowPopup(true)}
                />
              }
              onFileChange={handleSelectFile}
            />
            <InfoPopup
              content={PopupMessageContent}
              open={showPopup}
              onClose={() => setShowPopup(false)}
              onOpen={() => setShowPopup(true)}
            />
            <FormActions
              sx={{ width: '100%' }}
              actions={
                <Stack sx={{ gap: 1.5, flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    sx={{
                      width: { xs: '30%', sm: 'fit-content' },
                      padding: {
                        xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                        sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onImportFile}
                    sx={{
                      width: { xs: '70%', sm: 'fit-content' },
                      padding: {
                        xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                        sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                      },
                    }}
                    disabled={!resumeFile}
                    loading={isResumeUploading}
                  >
                    Import
                  </Button>
                </Stack>
              }
            />
          </Stack>
        </>
      ) : (
        <ProgressManager id={resumeId || ''} progressScreenKey={ProgressScreenType.AiResumeScan} />
      )}
    </Stack>
  );
};

export default ResumeImport;
