import { isNotNil } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { useSnackbar } from '../../contexts/snackbar';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import type { MissingInfoPayload } from '../../services/progress';
import { addMissingInfo, continueCreateTask, getProgressStatus } from '../../services/progress';
import { useSideBarStore } from '../../stores/SideBarStore';
import type { MissingInfoContent } from '../../utils';
import {
  compareProgressStatus,
  getProgressScreenSnackbarMessage,
  processMissingFields,
} from '../../utils';
import Modal from '../common/Modal/Modal';
import ModalDetail from '../common/Modal/ModalDetail';
import MissingInfoForm from './components/MissingInfoForm';
import ProgressRenderer from './components/ProgressRenderer';
import { ProgressScreenType, ScreenTypeEventSuccessLabel, progressContent } from './constants';

interface ProgressManagerProps {
  progressScreenKey: ProgressScreenType;
  id: string;
  successEventLabel?: string;
}

const ProgressManager = ({ progressScreenKey, id, successEventLabel }: ProgressManagerProps) => {
  const { hideAppBar, hideSideBar } = useSideBarStore();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isProgressStatusError, setIsProgressStatusError] = useState<boolean>(false);
  const snackbarMessage = getProgressScreenSnackbarMessage(progressScreenKey);
  const [showModal, setShowModal] = useState(false);
  const [missingInfoContent, setMissingInfoContent] = useState<MissingInfoContent>({
    missingFields: undefined,
    missingFieldsEndpoint: '',
    jobDescriptionId: '',
    hasMissingFields: false,
  });

  useEffect(() => {
    hideAppBar();
    hideSideBar();
  }, [hideAppBar, hideSideBar]);

  const fetchProgressStatus = useCallback(
    async (_id: string, endpoint: string) => {
      const res = await getProgressStatus({ id: _id, endpoint });

      // TODO: error handling will be updated once the API is updated
      const hasError = [
        res?.data?.status,
        res?.data?.resume?.status,
        res?.data?.job_description?.status,
        res?.data?.match_score?.status,
        res?.data?.ai_customization?.status,
        res?.data?.ai_generation?.status,
      ].some((value) => value === 'error');

      setIsProgressStatusError(hasError);
      const missingInfo = processMissingFields(res?.data, progressScreenKey);
      setMissingInfoContent(missingInfo);
      // Enable modal when there is a pending status or the flow is create job xray
      if (
        missingInfo?.hasMissingFields &&
        (compareProgressStatus(res?.data, progressScreenKey, 'pending') ||
          compareProgressStatus(res?.data, progressScreenKey, 'missing'))
      ) {
        // Enable modal form
        setShowModal(true);
      } else if (res?.data?.progress === 100) {
        setTimeout(() => {
          showSnackbar('success', snackbarMessage.success);

          logEvent(
            EventCategory.FORM_SUBMISSION,
            EventAction.SUBMIT,
            successEventLabel || ScreenTypeEventSuccessLabel[progressScreenKey],
          );

          navigate(`${progressContent[progressScreenKey].redirectPath}/${id}`);
        }, 1000);
      }

      //  return progress data
      return res?.data;
    },
    [progressScreenKey, showSnackbar, snackbarMessage.success, successEventLabel, navigate, id],
  );

  const { data: progressStatus, isError: isProgressError } = useQuery({
    enabled: !!id,
    queryKey: [progressContent[progressScreenKey].queryKey, id, 'status'],
    queryFn: () => fetchProgressStatus(id, progressContent[progressScreenKey].endpoint),
    refetchInterval: (data) => {
      // if the task is in progress, refetch every 5 seconds
      if (
        !isProgressStatusError &&
        !showModal &&
        isNotNil(data) &&
        !compareProgressStatus(data, progressScreenKey, 'done')
      ) {
        return 5000;
      }
      return false;
    },
    retry: 2,
  });

  // useQuery will set isProgressError to true after 3 consecutive failed calls.
  // If isProgressStatusError is true, then the error is returned by the status API call.
  // Otherwise, the error is due to a network issue.
  useEffect(() => {
    if (isProgressError) {
      showSnackbar('error', isProgressStatusError ? snackbarMessage.error : 'Something went wrong');
    }
  }, [isProgressError, isProgressStatusError, showSnackbar, snackbarMessage.error]);

  const { mutate: continueCreateTaskProgress, isLoading: isContinueProgressLoading } = useMutation({
    mutationFn: continueCreateTask,
    onSuccess: () => {
      setShowModal(false);
    },
    onError: () => {
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  const { mutate: submitMissingInfo, isLoading: isSubmitMissingInfoLoading } = useMutation({
    mutationFn: addMissingInfo,
    onSuccess: () => {
      setShowModal(false);
    },
    onError: () => {
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  const onSubmitMissingInfo = (data: MissingInfoPayload) => {
    const requestData = {
      endpoint: missingInfoContent.missingFieldsEndpoint,
      payload: data,
    };
    if (progressScreenKey === ProgressScreenType.JDXray) {
      submitMissingInfo(requestData);
    } else {
      let param = null;
      if (progressScreenKey === ProgressScreenType.AiCustomize) {
        param = 'ai_customize';
      } else if (progressScreenKey === ProgressScreenType.SampleResume) {
        param = 'ai_generate';
      }
      requestData.endpoint = `${requestData.endpoint}/${id}/continue${
        param ? `?task_type=${param}` : ''
      }`;
      requestData.payload = {
        ...requestData.payload,
        job_description_id: missingInfoContent.jobDescriptionId,
      };
      continueCreateTaskProgress(requestData);
    }
  };

  return (
    <>
      <ProgressRenderer
        {...progressContent[progressScreenKey]}
        isError={isProgressStatusError || isProgressError}
        progress={progressStatus?.progress || 0}
      />
      <Modal open={showModal} contentSx={{ width: { xs: '70vw', md: '650px' } }}>
        <>
          <ModalDetail
            title="Add Missing Job Information"
            description="Some required information could not be found in the job description you provided. Add the missing information to proceed."
          />
          <MissingInfoForm
            missingFields={missingInfoContent.missingFields}
            onSubmitMissingInfo={onSubmitMissingInfo}
            isLoading={isContinueProgressLoading || isSubmitMissingInfoLoading}
          />
        </>
      </Modal>
    </>
  );
};
export default ProgressManager;
