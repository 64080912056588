import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import { useAuth } from '../../contexts/auth';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { createNewJobMatchScore } from '../../services/jobMatches';
import { uploadJdResume } from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import UploadOnboardingProfile from './components/UploadOnboardingProfile';
import Welcome from './components/Welcome';

enum SelectedOnboardingSection {
  WELCOME = 'welcome',
  PROFILE_UPLOAD = 'profile-upload',
  ONBOARDING_PROGRESS = 'onboarding-progress',
}

const OnboardingPage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();
  const { isOnboardingCompleted } = useAuth();
  const [selectedSection, setSelectedSection] = useState<SelectedOnboardingSection>(
    SelectedOnboardingSection.WELCOME,
  );
  const { showSnackbar } = useSnackbar();
  const [matchScoreId, setMatchScoreId] = useState<string>();

  // if onboarding true, then redirect to job matches page
  useLayoutEffect(() => {
    if (isOnboardingCompleted) {
      navigate(RoutePaths.JOBMATCHES);
    }
  }, [isOnboardingCompleted, navigate]);

  const { mutate: createJobMatch } = useMutation({
    retry: 3,
    mutationFn: createNewJobMatchScore,
    onSuccess: (res) => {
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Job Match Score Creation started',
      );
      setMatchScoreId(res?.data.match_score_id);
      setSelectedSection(SelectedOnboardingSection.ONBOARDING_PROGRESS);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to job match score. Please try again.',
      );
    },
  });

  const uploadJdResumeQueryFuntion = async ({
    description,
    file,
  }: {
    description: string;
    file: File;
  }) => {
    const res = await uploadJdResume(description, file);
    return res;
  };

  const { mutate: uploadJdResumeData, isLoading: isJdResumeUploading } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    onMutate: () => {
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Upload JD Resume Data in onboarding',
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (jdResumeData: any) => {
      createJobMatch({
        job_description_id: jdResumeData.job_description_id,
        resume_id: jdResumeData.resume_id,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  const onBackClick = () => {
    setSelectedSection(SelectedOnboardingSection.WELCOME);
  };

  const onClickNext = () => {
    logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Onboarding Welcome Next Click');
    setSelectedSection(SelectedOnboardingSection.PROFILE_UPLOAD);
  };

  return (
    <>
      {selectedSection === SelectedOnboardingSection.WELCOME && (
        <Welcome onNextClick={onClickNext} />
      )}
      {selectedSection === SelectedOnboardingSection.PROFILE_UPLOAD && (
        <UploadOnboardingProfile
          onFinishSignUp={uploadJdResumeData}
          onBackClick={onBackClick}
          isLoading={isJdResumeUploading}
        />
      )}
      {selectedSection === SelectedOnboardingSection.ONBOARDING_PROGRESS && (
        // quickfix: removing left margin added by layout component
        <Stack
          sx={{
            marginLeft: !isOpen && !isMobile ? '20px' : '0px',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              width: `calc(100% - ${isMobile ? '24px' : '48px'})`,
              maxWidth: '1920px',
              margin: '32px auto',
            }}
          >
            <ProgressManager
              id={matchScoreId || ''}
              progressScreenKey={ProgressScreenType.JobMatchScore}
              successEventLabel="Onboarding Job Match Score Creation Successful"
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default OnboardingPage;
