import type { CardProps } from '../../common/Card';
import type { InlineChipsListProps } from '../../common/InlineChipsList';
import InlineChipsList from '../../common/InlineChipsList';
import StyledCard from '../../common/StyledCard';

interface SkillsRendererProps extends InlineChipsListProps {
  cardProps?: CardProps;
}

const SkillsRenderer = ({ dataList, title, cardProps }: SkillsRendererProps) => (
  <StyledCard
    {...cardProps}
    cardContentProps={{
      ...cardProps?.cardContentProps,
      sx: {
        padding: 3,
        gap: 2,
        '&:last-child': {
          paddingBottom: 3,
        },
        ...cardProps?.cardContentProps?.sx,
      },
    }}
  >
    <InlineChipsList dataList={dataList} title={title} />
  </StyledCard>
);

export default SkillsRenderer;
