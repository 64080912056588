// context to score snackbar messages and show snackbar using MUI Snackbar and alert component
import { isNotNil } from 'ramda';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { type AlertColor, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Icon from '../components/common/Icon';
import { Storage, StorageKeys } from '../services/storage';

export interface SnackbarContextProps {
  isShowingSnackbar: boolean;
  showSnackbar: (severity: AlertColor, message: string, control?: React.ReactNode) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps>({
  isShowingSnackbar: false,
  showSnackbar: (severity: AlertColor, message: string) => {
    // eslint-disable-next-line no-console
    console.log({ severity, message });
  },
  hideSnackbar: () => {
    // eslint-disable-next-line no-console
    console.log('hideSnackbar');
  },
});

const HIDE_SNACKBAR_DURATION = 5000;

export const SnackbarProvider = ({ children }: { children: React.ReactElement }) => {
  const [isShowingSnackbar, setIsShowingSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [actionControl, setActionControl] = useState<React.ReactNode | undefined>();
  const iconProps = severity === 'info' ? { icon: false } : {};
  const showSnackbar = useCallback(
    (severityLevel: AlertColor, messageText: string, control?: React.ReactNode) => {
      setIsShowingSnackbar(true);
      setMessage(messageText);
      setSeverity(severityLevel);
      setActionControl(control);
    },
    [],
  );

  useEffect(() => {
    const globalErrorText = Storage.get(StorageKeys.GLOBAL_ERROR);

    if (globalErrorText) {
      showSnackbar('error', globalErrorText);
      Storage.remove(StorageKeys.GLOBAL_ERROR);
    }
  }, [showSnackbar]);

  // to hide snackbar
  const hideSnackbar = useCallback(() => {
    setIsShowingSnackbar(false);
    // Update snackbar content after snackbar is hidden for a smooth transition.
    setTimeout(() => {
      if (!isShowingSnackbar) {
        setMessage('');
        setSeverity('success');
        setActionControl(undefined);
      }
    }, 4000);
  }, [isShowingSnackbar]);

  const contextValue = useMemo(
    () => ({ isShowingSnackbar, showSnackbar, hideSnackbar }),
    [isShowingSnackbar, showSnackbar, hideSnackbar],
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      <>
        <Snackbar
          open={isShowingSnackbar}
          autoHideDuration={HIDE_SNACKBAR_DURATION}
          onClose={hideSnackbar}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MuiAlert
            iconMapping={{
              success: <Icon className="fi fi-br-check" style={{ fontSize: '16px' }} />,
              error: <Icon className="fi fi-br-exclamation" style={{ fontSize: '16px' }} />,
            }}
            variant="filled"
            severity={severity} // This prop will determine the color (green or red)
            action={isNotNil(actionControl) && actionControl}
            sx={{
              display: 'flex',
              alignItems: 'center', // Align icon and text
              width: '100%',
              borderRadius: '8px',
              padding: '4px 16px',
              color: 'common.white',
              '&.MuiAlert-filledInfo': { backgroundColor: 'text.primary' },
            }}
            {...iconProps}
          >
            <Typography variant="label1">{message}</Typography>
          </MuiAlert>
        </Snackbar>
        {children}
      </>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
