import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

interface SectionBorderedProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const SectionBordered = ({ sx, children }: SectionBorderedProps) => (
  <Box
    sx={{
      padding: 2,
      borderColor: 'border.light',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 3,
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default SectionBordered;
