import { useForm } from 'react-hook-form';

import { InputLabel, Stack, TextField, Typography } from '@mui/material';

import Button from '../../common/Button';
import ModalActions from '../../common/Modal/ModalActions';
import type { MissingFields } from '../constants';

// TODO: update styles and data types
interface MissingInfoFormProps {
  missingFields?: MissingFields[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitMissingInfo: (formData: any) => void;
  isLoading?: boolean;
}

const MissingInfoForm = ({
  missingFields,
  onSubmitMissingInfo,
  isLoading,
}: MissingInfoFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack sx={{ gap: 2.5, paddingBottom: 5 }}>
        {missingFields?.map((field) => (
          <Stack gap={1} key={field.name}>
            <InputLabel
              htmlFor={field.name}
              sx={{
                color: 'error.main',
                '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
              }}
              required
            >
              <Typography variant="label2" color="text.primary">
                {field.label}
              </Typography>
            </InputLabel>
            <TextField
              id={field.name}
              placeholder={field.placeholder}
              {...register(field.name, {
                required: 'This field is required',
                validate: (value) => value.trim() !== '' || 'Whitespace-only input is not allowed',
              })}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid',
                    borderColor: 'border.light',
                    borderRadius: '8px',
                    padding: '10px',
                  },
                },
                paddingBottom: 0.5,
              }}
              inputProps={{ maxLength: 64 }}
              variant="outlined"
              error={!!errors[field.name]}
              fullWidth
            />
          </Stack>
        ))}
      </Stack>
      <ModalActions
        actions={
          <Button
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={handleSubmit(onSubmitMissingInfo as any)}
            sx={{
              width: { xs: '100%', sm: 'fit-content' },
              padding: { xs: '16px 12px', sm: '8px 12px' },
            }}
            disabled={!isValid}
            loading={isLoading}
          >
            Add
          </Button>
        }
      />
    </form>
  );
};

export default MissingInfoForm;
