import { create } from 'zustand';

type SideBarState = {
  isOpen: boolean;
  shouldHideAppBar: boolean;
  shouldHideSideBar: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
  hideAppBar: () => void;
  showAppBar: () => void;
  hideSideBar: () => void;
  showSideBar: () => void;
  reset: () => void;
};

const sideBarKey = 'sidebarState';

export const useSideBarStore = create<SideBarState>((set) => {
  // Try to get the state from localStorage on initialization
  const savedState = localStorage.getItem(sideBarKey);
  const initialState = savedState
    ? JSON.parse(savedState)
    : {
        isOpen: window.innerWidth > 600,
        shouldHideAppBar: false,
        shouldHideSideBar: false,
      };
  set(initialState);

  return {
    ...initialState,
    toggle: () => set((state) => ({ isOpen: !state.isOpen })),
    open: () => set(() => ({ isOpen: true })),
    close: () => set(() => ({ isOpen: false })),
    hideAppBar: () => set(() => ({ shouldHideAppBar: true })),
    showAppBar: () => set(() => ({ shouldHideAppBar: false })),
    hideSideBar: () => set(() => ({ shouldHideSideBar: true })),
    showSideBar: () => set(() => ({ shouldHideSideBar: false })),
    reset: () => set(() => ({ isOpen: window.innerWidth > 600 })),
  };
});

// Subscribe to state changes and save to localStorage
useSideBarStore.subscribe((state) => {
  // For Sign in sign up page, both sidebar and appbar should be hidden
  if (state.shouldHideAppBar && state.shouldHideSideBar) {
    localStorage.removeItem(sideBarKey);
    return;
  }
  localStorage.setItem(sideBarKey, JSON.stringify(state));
});
