import { useSortable } from '@dnd-kit/sortable';
import type { SxProps } from '@mui/material';
import { Box, Stack } from '@mui/material';

import { ReactComponent as DragIcon } from '../../assets/icons/fi-br-drag.svg';

interface SortableItemProps {
  id: string;
  key?: string;
  children: React.ReactNode;
  disabled?: boolean;
  showBorders?: boolean;
  sx?: SxProps;
}

const SortableItem = ({
  id,
  key,
  children,
  disabled = false,
  showBorders,
  sx,
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <Stack
      key={key}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: showBorders ? 1 : 3,
        border: showBorders ? '1px solid #D9D9D9' : 'none',
        borderRadius: showBorders ? 2 : 0,
        padding: showBorders ? '8px 16px' : 0,
        touchAction: 'none',
        ...sx,
      }}
      style={style}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      {!disabled && (
        <Box
          sx={{
            cursor: 'grab',
          }}
        >
          <DragIcon style={{ marginTop: '3px', touchAction: 'none' }} />
        </Box>
      )}
      {children}
    </Stack>
  );
};

export default SortableItem;
