import { isNil, isNotNil } from 'ramda';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import { ResumeKey, type VolunteerExperienceInfo } from '../../../../interface';
import RoutePaths from '../../../../routes/RoutePaths';
import { ResumesKeys, deleteResumeSection } from '../../../../services/resumes';
import Icon from '../../../common/Icon';
import MobileTooltip from '../../../common/MobileTooltip';
import Tooltip from '../../../common/Tooltip';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';
import VolunteerExperienceDetail from './components/VolunteerExperienceDetail';

interface VolunteerExperienceSectionProps {
  experiences?: VolunteerExperienceInfo[];
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
  tooltipContent?: string | string[];
  enableSectionReorder?: boolean;
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

const VolunteerExperienceSection = ({
  experiences,
  editable,
  resumeId,
  hideEmptySection,
  tooltipContent,
  enableSectionReorder = false,
}: VolunteerExperienceSectionProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveDevice();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const shouldhideEmptySection =
    hideEmptySection && (isNil(experiences) || (isNotNil(experiences) && experiences.length === 0));
  const volunteerExperienceEditPageUrl = RoutePaths.RESUME_EDIT.replace(
    ':key',
    'volunteer-experience',
  );

  const resumeEditorState: ResumeEditorState = {
    action: '',
    resumeId: resumeId || '',
    elementId: '',
    elementType: 'volunteer-experience',
  };

  const { mutate: deleteResumeVolunteerExperience } = useMutation({
    mutationFn: deleteResumeSection,
    onSuccess: () => {
      showSnackbar('success', 'Volunteer Experience deleted');
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries([ResumesKeys.RESUMES, resumeEditorState.resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const handleAddVolunteerExperience = () => {
    resumeEditorState.action = 'add';
    navigate(volunteerExperienceEditPageUrl, {
      state: { resumeEditorState },
    });
  };

  const handleEditVolunteerExperience = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    navigate(volunteerExperienceEditPageUrl, {
      state: { resumeEditorState },
    });
  };

  const handleDeleteExperience = useCallback(
    (id: string) => {
      deleteResumeVolunteerExperience({
        elementId: id,
        sectionKey: ResumeKey.VOLUNTEER_EXPERIENCE,
      });
    },
    [deleteResumeVolunteerExperience],
  );

  const handleReorderClick = () => {
    resumeEditorState.action = 'reorder';
    navigate(`/resumes/reorder/${resumeEditorState?.elementType}`, {
      state: { id: resumeEditorState?.resumeId },
    });
  };

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid xs={7} item>
          <Typography variant={isMobile ? 'h3' : 'h2'}>
            <span style={{ paddingRight: '8px' }}>Volunteer Experience</span>
            {tooltipContent &&
              (isMobile || isTablet ? (
                <MobileTooltip content={tooltipContent} />
              ) : (
                <Tooltip content={tooltipContent} />
              ))}
          </Typography>
        </Grid>
        {editable && (
          <Grid item>
            {enableSectionReorder && (
              <ResumeEditorButton onClick={handleReorderClick} startIcon="fi fi-rr-apps-sort">
                Reorder
              </ResumeEditorButton>
            )}
            <ResumeEditorButton
              sx={{ color: 'primary' }}
              onClick={handleAddVolunteerExperience}
              startIcon={
                <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
              }
            >
              Add
            </ResumeEditorButton>
          </Grid>
        )}
      </Grid>
      {experiences && experiences.length !== 0 ? (
        experiences.map((experience) => (
          <VolunteerExperienceDetail
            key={experience._id}
            experience={experience}
            isEditable={editable}
            onEdit={handleEditVolunteerExperience}
            onDelete={handleDeleteExperience}
          />
        ))
      ) : (
        <EmptySection text="No Volunteer Experience Added" />
      )}
    </Stack>
  );
};

export default VolunteerExperienceSection;
