// @ts-nocheck
import { useEffect } from 'react';

import { registerCodeHighlighting } from '@lexical/code';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const CodeHighlightPlugin = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => registerCodeHighlighting(editor), [editor]);
  return null;
};

export default CodeHighlightPlugin;
