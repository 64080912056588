import parse from 'html-react-parser';

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import type { Draft as DraftDataType } from '../../../../../stores/SuperEditorDrafts';
import { getDraftTitleText, getDraftsIcon } from '../../../../../utils';
import Button from '../../../../common/Button';
import Icon from '../../../../common/Icon';

interface DraftProps {
  draft: DraftDataType;
  index: number;
  sectionTitle: string;
  onAppendDraft?: (draft: DraftDataType) => void;
  onReplaceDraft?: (draft: DraftDataType) => void;
  onDelete: (id: string) => void;
}

const Draft = ({
  draft,
  index,
  sectionTitle,
  onAppendDraft,
  onReplaceDraft,
  onDelete,
}: DraftProps) => {
  const theme = useTheme();
  return (
    <Stack
      key={draft.id}
      sx={{
        width: '100%',
        padding: 1.5,
        gap: 1.5,
        border: '1px solid',
        borderColor: 'border.light',
        borderRadius: '8px',
      }}
    >
      <Stack flexDirection="row" gap={1}>
        {draft.status === 'loading' && (
          <CircularProgress size={16} value={50} variant="indeterminate" color="success" />
        )}
        {draft.status !== 'loading' && (
          <Icon
            className={getDraftsIcon(draft.type)}
            style={{ fontSize: '16px', color: theme.palette.text.secondary }}
          />
        )}
        <Typography variant="assistiveBold">
          {getDraftTitleText(draft.type, draft.status, sectionTitle, index)}
        </Typography>
      </Stack>

      <Typography variant="body3">{parse(draft.text)}</Typography>

      {draft.status !== 'loading' && (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 2.5,
            gap: 1.5,
          }}
        >
          <Button
            variant="outlined"
            // TODO: make the common in Button, these are used in many places with the 'outlined' variant
            sx={{
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onAppendDraft && onAppendDraft(draft)}
          >
            Append
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onReplaceDraft && onReplaceDraft(draft)}
          >
            Replace
          </Button>
          <Button
            variant="outlined"
            sx={{
              minWidth: 'unset',
              color: 'text.primary',
              borderColor: 'border.main',
            }}
            onClick={() => onDelete(draft.id)}
          >
            <Icon
              className="fi fi-rr-trash"
              // TODO: add these as default props in Icon. used often.
              fontSize="16px"
              color={theme.palette.text.secondary}
            />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Draft;
