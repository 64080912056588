import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Modal from '../../common/Modal/Modal';
import ModalActions from '../../common/Modal/ModalActions';
import ModalDetail from '../../common/Modal/ModalDetail';
import SwipeableDrawer from '../../common/SwipeableDrawer';
import ImportJobDescriptionContent from './ImportJobDescriptionContent';
import JobDescriptionExample from './JobDescriptionExample';

export interface ImportJobDescriptionProps {
  onImport: (data: { jDText?: string }) => void;
  onClose?: () => void;
  defaultValue?: string;
  isSectionEnabled?: boolean;
}

/**
 * A component that allows users to import a job description text for a job x-ray.
 * It adapts its layout based on the device type, displaying a modal on desktop view
 * and a swipeable drawer on mobile. The user can input or import a job description
 * and view an example job description in a modal.
 *
 * @component
 *
 * @param {Object} props - The properties for the ImportJobDescription component.
 * @param {(data: { jDText?: string }) => void} props.onImport - Callback function invoked when a job description is imported, receiving the job description text.
 * @param {() => void} [props.onClose] - Optional callback triggered when the swipeable drawer or modal is closed.
 * @param {string} [props.defaultValue=""] - Default job description text to be displayed in the input field.
 * @param {boolean} [props.isSectionEnabled=false] - Flag that controls whether the section is enabled and visible. Defaults to `false`.
 *
 * @returns {JSX.Element | null} The rendered JSX element, or `null` if `isSectionEnabled` is `false`.
 *
 * @example
 * const handleImport = (data: { jDText?: string }) => {
 *   console.log('Imported Job Description:', data.jDText);
 * };
 *
 * <ImportJobDescription
 *   onImport={handleImport}
 *   defaultValue="Sample job description text"
 *   isSectionEnabled={true}
 * />;
 */

const ImportJobDescription = ({
  onImport,
  onClose,
  defaultValue = '',
  isSectionEnabled = false,
}: ImportJobDescriptionProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();
  const [open, setOpen] = useState(isSectionEnabled);
  const [showPopup, setShowPopup] = useState(false);
  const [importJDText, setImportJDText] = useState(defaultValue);

  useEffect(() => {
    if (isMobile) {
      setOpen(isSectionEnabled);
    }
  }, [isMobile, isSectionEnabled]);

  const onCloseDrawer = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onSelect = () => {
    onImport({ jDText: importJDText });
    setOpen(false);
  };

  const handleJobDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isMobile) {
        onImport({ jDText: event.target.value });
      }
      setImportJDText(event.target.value);
    },
    [isMobile, onImport],
  );

  return isSectionEnabled ? (
    <>
      {!isMobile ? (
        <Stack
          sx={{
            border: `${theme.spacing(0.125)} solid`,
            borderColor: 'border.light',
            borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                               ${theme.spacing(2)} ${theme.spacing(2)}`,
            padding: `${theme.spacing(3)} ${theme.spacing(4)} 
                          ${theme.spacing(4)} ${theme.spacing(6)}`,
            minHeight: '45vh',
            boxSizing: 'border-box',
          }}
        >
          <ImportJobDescriptionContent
            jobDescriptionText={importJDText}
            onShowExample={() => setShowPopup(true)}
            onJobDescriptionChange={handleJobDescriptionChange}
          />
          <Modal open={showPopup} contentSx={{ width: '80vw', maxWidth: '46.5rem' }}>
            <>
              <ModalDetail
                title="Example Job Posting from LinkedIn"
                description={<JobDescriptionExample />}
              />
              <ModalActions
                actions={
                  <Button color="primary" onClick={() => setShowPopup(false)}>
                    Close
                  </Button>
                }
              />
            </>
          </Modal>
        </Stack>
      ) : (
        <SwipeableDrawer
          open={open}
          onClose={onCloseDrawer}
          onOpen={() => setOpen(true)}
          contentProps={{
            sx: {
              height: '85vh',
              alignItems: 'flex-start',
              textAlign: 'left',
              paddingBottom: 3,
              boxSizing: 'border-box',
            },
          }}
        >
          <Stack sx={{ width: '100%', alignItems: 'flex-end', paddingBottom: 0.5 }}>
            <IconButton
              aria-label="Close icon"
              size="small"
              component="button"
              onClick={showPopup ? () => setShowPopup(false) : onCloseDrawer}
              sx={{ mrginLeft: 'auto', padding: 1.25 }}
            >
              <Icon
                className="fi fi-br-cross"
                style={{ color: theme.palette.text.secondary, fontSize: theme.spacing(2) }}
              />
            </IconButton>
          </Stack>
          <Typography variant="h3" sx={{ paddingBottom: 2 }}>
            {showPopup ? 'Example Job Posting from LinkedIn' : 'Import Job X-Ray'}
          </Typography>
          {showPopup ? (
            // Remove margin/padding from maxHeight
            <Box
              sx={{
                height: `calc(85vh - ${theme.spacing(23.75)})`,
                overflowY: 'scroll',
              }}
            >
              <JobDescriptionExample />
            </Box>
          ) : (
            <ImportJobDescriptionContent
              jobDescriptionText={importJDText}
              onShowExample={() => setShowPopup(true)}
              onJobDescriptionChange={handleJobDescriptionChange}
            />
          )}
          <Button
            sx={{ width: '100%', padding: 2, marginTop: 2 }}
            onClick={showPopup ? () => setShowPopup(false) : onSelect}
          >
            {showPopup ? 'Got it' : 'Import'}
          </Button>
        </SwipeableDrawer>
      )}
    </>
  ) : null;
};

export default ImportJobDescription;
