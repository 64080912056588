/* eslint-disable @typescript-eslint/naming-convention */
import { BASE_URL } from '../configs';
import type { ExperienceInfo } from '../interface';
import { Storage } from './storage';

// quickfix: debug why BASE_URL is undefined in production, along with http.ts file issue
const baseURL = `${BASE_URL ?? window.location.origin}`;

export enum ComposeFieldTypes {
  JOB_POSITION = 'jobPosition',
  SKILLS_AND_TOOLS = 'skillsAndTools',
  RESPONSIBILITIES = 'responsibilities',
  ACHIEVEMENTS = 'achievements',
  DAILY_ROUTINES = 'dailyRoutines',
  STORY = 'story',
}

export interface ComposeFields {
  jobPosition: string;
  skillsAndTools: string;
  responsibilities: string;
  achievements?: string;
  dailyRoutines?: string;
  story?: string;
}

/**
 * Handles the response from a stream by reading it as text and invoking the provided callbacks.
 *
 * @param res - The response object.
 * @param onProgress - A callback function to be called with the progress data.
 * @param onEnd - A callback function to be called when the stream ends.
 * @param resErrorText - Optional error message to be used if the response is not ok. If http response json has errors then that would be used.
 * @returns A promise that resolves when the stream handling is complete.
 */
const handleStreamResponse = async (
  res: Response,
  onProgress: (data: string) => void,
  onEnd: (data: string) => void,
  resErrorText?: string,
): Promise<void> => {
  if (!res.status || res.status !== 200) {
    const responseData = await res.json();
    if (responseData?.errors) {
      throw new Error(responseData?.errors);
    }

    Promise.reject(resErrorText ?? 'Failed to generate response');
    return;
  }

  const stream = res.body;

  // piping stream to text decoder to read stream as text
  const reader = stream?.pipeThrough(new TextDecoderStream())?.getReader();

  let streamText = '';

  // handle stream response
  while (true && reader) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();
    if (done) {
      onEnd(streamText);
      Promise.resolve();
      break;
    }

    const processedChunk = value
      .split('data: ')
      .filter((str) => str !== '' && str !== ' \n\n')
      .join('')
      .replace(/ \n\n/g, '');

    streamText += processedChunk;
    onProgress(streamText);
  }
};

export const generateHeroMessage = async (
  requestData: {
    professional_summary?: string;
    professional_experience?: {
      job_title: string;
      description: string;
    };
    previously_generated_hero_message?: string;
    onProgress?: (data: string) => void;
    onEnd?: (data: string) => void;
    signal?: AbortSignal;
  },
  // eslint-disable-next-line consistent-return
) => {
  const {
    professional_summary,
    professional_experience,
    previously_generated_hero_message,
    onProgress = () => {},
    onEnd = () => {},
    signal,
  } = requestData;

  if (!professional_summary && !professional_experience) {
    return Promise.reject(
      new Error('Either professional_summary or professional_experience is required.'),
    );
  }

  try {
    const token = Storage.getJwtToken();

    // using fetch because axios doesn't directly support stream respons
    const res = await fetch(`${baseURL}/api/backend/resumes/hero_message/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        professional_summary,
        professional_experience,
        previously_generated_hero_message,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to generate hero message');
  } catch (error) {
    // TODO: handle 401 and 403 errors
    return Promise.reject(error);
  }
};

export const autofixObjective = async (requestData: {
  text: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { text, onProgress, onEnd, signal } = requestData;

  if (!text) {
    return Promise.reject(new Error('text is required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/objective/autofix/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subtext: text,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to autofix objective');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateProfessionalSummary = async (requestData: {
  professionalExperience: ExperienceInfo;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { professionalExperience, onProgress, onEnd, signal } = requestData;

  if (!professionalExperience) {
    return Promise.reject(new Error('professionalExperience is required.'));
  }

  const experienceInfo = {
    job_title: professionalExperience.job_title,
    description: professionalExperience.description,
  };

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/professional_summary/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        professional_experience: experienceInfo,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const autofixProfessionalSummary = async (requestData: {
  professionalSummary: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { professionalSummary, onProgress, onEnd, signal } = requestData;

  if (!professionalSummary) {
    return Promise.reject(new Error('professionalSummary is required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/professional_summary/autofix/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        professional_summary: professionalSummary,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to autofix professional summary');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const autofixWorkExperience = async (requestData: {
  description: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { description, onProgress, onEnd, signal } = requestData;

  if (!description) {
    return Promise.reject(new Error('description is required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/work_experience/autofix/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        description,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to autofix work experience');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const superChargeWorkExperience = async (requestData: {
  bulletPoints: string[];
  title: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { bulletPoints, title, onProgress, onEnd, signal } = requestData;

  if (!bulletPoints || !title) {
    return Promise.reject(new Error('bulletPoints and title are required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/work_experience/supercharge/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        bullet_points: bulletPoints,
        title,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to supercharge work experience');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const composeWorkExperience = async (requestData: {
  fields: ComposeFields;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { fields, onProgress, onEnd, signal } = requestData;

  if (!fields.jobPosition || !fields.responsibilities || !fields.skillsAndTools) {
    return Promise.reject(
      new Error('jobPosition, responsibilities, and skillsAndTools are required.'),
    );
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/work_experience/compose/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: fields.jobPosition,
        skills_and_tools: fields.skillsAndTools,
        responsibilities: fields.responsibilities,
        achievements: fields.achievements,
        daily_routines: fields.dailyRoutines,
        story: fields.story,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to compose work experience');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateskillsAndTools = async (requestData: {
  jobPosition: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { jobPosition, onProgress, onEnd, signal } = requestData;

  if (!jobPosition) {
    return Promise.reject(new Error('Job Position is required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/skills/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: jobPosition,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to generate skills and tools');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateResponsibilities = async (requestData: {
  jobPosition: string;
  skillsAndTools: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { jobPosition, skillsAndTools, onProgress, onEnd, signal } = requestData;

  if (!jobPosition || !skillsAndTools) {
    return Promise.reject(new Error('Job Position and Skills & Tools are required.'));
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/responsibilities/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: jobPosition,
        skills_and_tools: skillsAndTools,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to generate responsibilities');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateAchievements = async (requestData: {
  jobPosition: string;
  skillsAndTools: string;
  responsibilities: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { jobPosition, responsibilities, skillsAndTools, onProgress, onEnd, signal } = requestData;

  if (!jobPosition || !responsibilities || !skillsAndTools) {
    return Promise.reject(
      new Error('Job Position, Skills & Tools and Responsibilities are required.'),
    );
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/achievements/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: jobPosition,
        skills_and_tools: skillsAndTools,
        responsibilities,
      }),
    });
    await handleStreamResponse(res, onProgress, onEnd, 'Failed to generate achievements');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateDailyRoutines = async (requestData: {
  jobPosition: string;
  skillsAndTools: string;
  responsibilities: string;
  achievements: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const { jobPosition, responsibilities, skillsAndTools, achievements, onProgress, onEnd, signal } =
    requestData;

  if (!jobPosition || !responsibilities || !skillsAndTools) {
    return Promise.reject(
      new Error('Job Position, Skills & Tools and Responsibilities are is required.'),
    );
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/daily_routines/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: jobPosition,
        skills_and_tools: skillsAndTools,
        responsibilities,
        achievements,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd, 'Failed to generate daily routines');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateStory = async (requestData: {
  jobPosition: string;
  skillsAndTools: string;
  responsibilities: string;
  achievements: string;
  dailyRoutines: string;
  onProgress: (data: string) => void;
  onEnd: (data: string) => void;
  signal?: AbortSignal;
  // eslint-disable-next-line consistent-return
}) => {
  const {
    jobPosition,
    responsibilities,
    skillsAndTools,
    achievements,
    dailyRoutines,
    onProgress,
    onEnd,
    signal,
  } = requestData;

  if (!jobPosition || !responsibilities || !skillsAndTools) {
    return Promise.reject(
      new Error('Job Position, Skills & Tools and Responsibilities are required.'),
    );
  }

  try {
    const token = Storage.getJwtToken();

    const res = await fetch(`${baseURL}/api/backend/resumes/your_story/generate/`, {
      method: 'POST',
      signal,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        job_position: jobPosition,
        skills_and_tools: skillsAndTools,
        responsibilities,
        achievements,
        daily_routines: dailyRoutines,
      }),
    });

    await handleStreamResponse(res, onProgress, onEnd);
  } catch (error) {
    return Promise.reject(error);
  }
};
