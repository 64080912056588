import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import FormActions from '../../../components/Form/FormActions';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import Button from '../../../components/common/Button';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { ResumesKeys, createResume } from '../../../services/resumes';
import { useAppBarStore } from '../../../stores/AppBarStore';
import { isNilOrEmpty } from '../../../utils';
import type { CareerStageKey } from '../constants';
import ChooseCareerStage from './ChooseCareerStage';

const BlankResumeBuilder = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const theme = useTheme();
  const { setAppBar, setInitialState } = useAppBarStore();
  const [selectedCareerStage, setSelectedCareerStage] = useState<CareerStageKey>();

  const queryClient = useQueryClient();

  const BreadCrumbLinks = isMobile
    ? [
        {
          label: 'Back',
          href: RoutePaths.RESUMES,
        },
      ]
    : [
        {
          label: 'Resumes',
          href: RoutePaths.RESUMES,
        },
        {
          label: 'Create Blank',
          href: RoutePaths.RESUME_BLANK,
        },
      ];

  useEffect(() => {
    if (isMobile) {
      setAppBar('Resumes', null);
    }

    return () => {
      setInitialState(isMobile);
    };
  }, [isMobile, setAppBar, setInitialState]);

  const { isLoading: isCreatingResume, mutate: createBlankResume } = useMutation({
    retry: 3,
    mutationFn: createResume,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Blank Resume Creation Successful',
      );
      showSnackbar('success', 'Blank resume created successfully.');
      navigate(RoutePaths.RESUME_HANDLER.replace(':id', data?.id));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create a blank Resume. Please try again.',
      );
    },
  });

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '120rem',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          sm: `${theme.spacing(3)}`,
        },
        boxSizing: 'border-box',
        gap: { xs: 1.5, sm: 4 },
      }}
    >
      <Stack sx={{ gap: 0.5 }}>
        <BreadCrumbs links={BreadCrumbLinks} />
        {!isMobile && (
          <Typography variant="body2" sx={{ paddingLeft: 3.75 }}>
            Build a resume from scratch.
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{
          paddingX: { xs: 0, sm: 2.5 },
          gap: 4,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <ChooseCareerStage onChange={setSelectedCareerStage} />
        <FormActions
          sx={{ width: '100%' }}
          actions={
            <Stack sx={{ gap: 1.5, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{
                  width: { xs: '30%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => createBlankResume({ careerStage: selectedCareerStage })}
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                loading={isCreatingResume}
                disabled={isNilOrEmpty(selectedCareerStage)}
              >
                Create
              </Button>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

export default BlankResumeBuilder;
