import { useLocation } from 'react-router-dom';

import RoutePaths from '../routes/RoutePaths';

/**
 * Determines if the current page is a tablet responsive page.
 * @returns {boolean} - Returns true if the current page is a tablet responsive page, otherwise returns false.
 */
const useIsTabletResponsivePage = () => {
  const location = useLocation();
  const tabletPaths = [RoutePaths.JOBMATCHES, RoutePaths.JOBS, RoutePaths.RESUMES, RoutePaths.HOME];
  return tabletPaths.includes(location.pathname as RoutePaths);
};

export default useIsTabletResponsivePage;
