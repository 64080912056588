/* eslint-disable react/no-array-index-key */
import { isNotNil } from 'ramda';
import { useMemo } from 'react';

import type { ExperienceInfo } from '../../../interface';
import { getCombinedCompanyExperiences } from '../../../utils/apiUtils';
import { formatDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';
import type { StringConcatenatorProps } from './StringConcatenator';
import StringConcatenator from './StringConcatenator';

interface TemplateWorkExperienceProps {
  workExperience: ExperienceInfo[];
  sectionContentProps?: SectionContentProps;
  sectionHeadingProps?: StringConcatenatorProps;
}

const TemplateWorkExperience = ({
  workExperience,
  sectionContentProps,
  sectionHeadingProps,
}: TemplateWorkExperienceProps) => {
  const getCombinedWorkExperienceDate = (companyExperience: ExperienceInfo) => {
    if (isNotNil(companyExperience)) {
      return formatDate(
        companyExperience?.start_month,
        companyExperience?.start_year,
        companyExperience?.end_month,
        companyExperience?.end_year,
        companyExperience?.present,
        true,
      );
    }
    return '';
  };

  const combinedCompanyExperiences = useMemo(() => {
    if (isNotNil(workExperience)) {
      return getCombinedCompanyExperiences(workExperience);
    }
    return [];
  }, [workExperience]);

  return (
    <>
      {combinedCompanyExperiences.map((companyExp) =>
        companyExp?.combined_experiences?.map((experience, index) => (
          <SectionContent
            key={index}
            heading={
              index === 0 ? (
                <StringConcatenator
                  value1={experience?.company}
                  value2={experience?.location}
                  value1Style={{ ...styles.h3, ...sectionHeadingProps?.value1Style }}
                  value2Style={{ ...styles.body1, ...sectionHeadingProps?.value2Style }}
                  separator=" • "
                  {...sectionHeadingProps}
                />
              ) : undefined
            }
            subHeading={experience?.job_title}
            content={experience?.description}
            date={getCombinedWorkExperienceDate(companyExp)}
            subHeadingStyle={{
              ...styles.label1,
              ...sectionContentProps?.subHeadingStyle,
            }}
            {...sectionContentProps}
          />
        )),
      )}
    </>
  );
};

export default TemplateWorkExperience;
