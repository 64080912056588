import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../../components/DragnDrop/SortableItem';
import FormActions from '../../../components/Form/FormActions';
import type { EducationInfo, SortedResumeSectionKey } from '../../../interface';
import { addSeparator, isNotNilOrEmpty } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';

interface EducationReorderProps {
  educations: EducationInfo[] | undefined;
  isLoading?: boolean;
  onSubmit?: (sectionsOrder: SortedResumeSectionKey[]) => void;
}

const EducationReorder = ({ educations = [], isLoading, onSubmit }: EducationReorderProps) => {
  const [educationsOrder, setEducationsOrder] = useState(educations);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = educationsOrder.findIndex((education) => education._id === active.id);
      const newIndex = educationsOrder.findIndex((education) => education._id === over.id);
      const movedArray = arrayMove(educationsOrder, oldIndex, newIndex);

      setEducationsOrder(movedArray);
    }
  };

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedEducationKeys = educationsOrder.map((education, index) => ({
        id: education._id,
        index,
      }));

      onSubmit(sortedEducationKeys);
    }
  };

  return (
    // TODO: add to common components
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={educationsOrder.map((education) => education._id)}
          strategy={verticalListSortingStrategy}
        >
          {educationsOrder.map((education) => (
            <SortableItem
              key={education._id}
              id={education._id}
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: '12px',
                borderColor: 'border.light',
                backgroundColor: 'background.light',
                '&:hover': {
                  backgroundColor: 'background.mediumLight',
                },
              }}
            >
              <Stack gap={0.5}>
                {(isNotNilOrEmpty(education.degree) || isNotNilOrEmpty(education.major)) && (
                  <Typography variant="label1">
                    {addSeparator(education.degree, education.major, ', ')}
                  </Typography>
                )}
                {isNotNilOrEmpty(education.school) && (
                  <Typography variant="body2">{education.school}</Typography>
                )}
                <Typography variant="body2" color="text.primaryGray">
                  {formatDate(
                    education.start_month,
                    education.start_year,
                    education.end_month,
                    education.end_year,
                    education.present,
                    true, // Disable duration caluculation
                  )}
                </Typography>
              </Stack>
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <FormActions onSubmit={onSaveClick} submitButtonProps={{ loading: isLoading }} />
    </Stack>
  );
};

export default EducationReorder;
