import { isNotNil } from 'ramda';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useMatch } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../contexts/snackbar';
import {
  type AchievementInfo,
  type CertificatesLicensesInfo,
  type ContactInfo,
  type CourseworkInfo,
  type EducationInfo,
  type ExperienceInfo,
  type HeroInfo,
  type InterestsInfo,
  type ObjectiveInfo,
  type PatentInfo,
  type ProjectInfo,
  type PublicationInfo,
  type ResumeData,
  ResumeKey,
  type SummaryInfo,
  type VolunteerExperienceInfo,
} from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import {
  ResumesKeys,
  addResumeCoursework,
  addResumeEducation,
  addResumeInterests,
  addResumePatent,
  addResumeProject,
  addResumeSection,
  addResumeSummary,
  addResumeWorkExperience,
  editResumeContactInfo,
  editResumeCoursework,
  editResumeEducation,
  editResumeInterests,
  editResumePatent,
  editResumeProject,
  editResumeSkills,
  editResumeSummary,
  editResumeWorkExperience,
  getResumeXRay,
  updateResumeSection,
} from '../../services/resumes';
import { useResumeEditorStore } from '../../stores/ResumeEditorStore';
import { capitalize, isNilOrEmpty } from '../../utils/index';
import BreadCrumbs from '../common/BreadCrumbs';
import Button from '../common/Button';
import AchievementEditor from './components/AchievementEditor';
import { CertificateLicenseEditor } from './components/CertificateLicenseEditor';
import ContactInfoEditor from './components/ContactInfoEditor';
import EducationEditor from './components/EducationEditor';
import HeroMessageEditor from './components/HeroMessageEditor';
import ObjectiveEditor from './components/ObjectiveEditor';
import PatentEditor from './components/PatentEditor';
import ProjectEditor from './components/ProjectEditor';
import PublicationEditor from './components/PublicationEditor';
import SortableListEditor from './components/SortableListEditor';
import SummaryEditor from './components/SummaryEditor';
import VolunteerExperienceEditor from './components/VolunteerExperienceEditor';
import WorkExperienceEditor from './components/WorkExperienceEditor/WorkExperienceEditor';
import { ResumeEditorMap } from './constants';

interface SkillsInfo {
  id: string;
  skills: string[];
}

interface ResumeEditorValue {
  breadCrumb: string;
  sectionKey: keyof ResumeData;
}

// TODO: move to pages
const ResumeEditor = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const ref = useRef<HTMLDivElement | null>(null);
  const { showSnackbar } = useSnackbar();
  const { setIsMutating } = useResumeEditorStore();

  const match = useMatch(RoutePaths.RESUME_EDIT);
  const key: string = match?.params.key || '';

  const location = useLocation();
  const { resumeEditorState } = location.state; // TODO: get state from store

  const resumeEditorMap: Record<string, ResumeEditorValue> = useMemo(() => ResumeEditorMap, []);

  const { sectionKey } = resumeEditorMap[key];
  const { resumeId } = resumeEditorState;

  const BreadCrumbLinks = [
    {
      label: 'Resumes',
      href: RoutePaths.RESUMES,
    },
    {
      label: 'View',
      href: `${RoutePaths.RESUMES}/${resumeEditorState.resumeId}`,
    },
    {
      label: resumeEditorMap[resumeEditorState?.metadata?.subType || key].breadCrumb,
      href: '',
    },
  ];

  const { data: resume } = useQuery<ResumeData>({
    queryKey: [ResumesKeys.RESUMES, resumeEditorState.resumeId],
    queryFn: () => getResumeXRay(resumeEditorState.resumeId),
    enabled: !!resumeEditorState.resumeId,
  });

  const extractedData = resume?.[sectionKey as keyof ResumeData];

  const [sectionItem, setSectionItem] = useState<string[]>(
    extractedData?.[sectionKey as keyof typeof extractedData] || [],
  );

  const getExperienceValue = () => {
    const emptyExperienceInfo: ExperienceInfo = {
      _id: '',
      job_title: '',
      company: '',
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      present: false,
      location: '',
      description: '',
      is_career_break: resumeEditorState.metadata?.subType === 'career-break' || false,
    };

    return resumeEditorState.action === 'add'
      ? emptyExperienceInfo
      : resume?.[ResumeKey.EXPERIENCE].filter((exp) => exp._id === resumeEditorState.elementId)[0];
  };

  const getDefaultProjectValue = () => {
    const emptyProjectInfo: ProjectInfo = {
      _id: '',
      project_name: '',
      organization: '',
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      present: false,
      description: '',
      index: 0,
    };
    return resumeEditorState.action === 'add'
      ? emptyProjectInfo
      : resume?.[ResumeKey.PROJECT].filter(
          (project) => project._id === resumeEditorState.elementId,
        )[0];
  };

  const getEducationValue = () => {
    const emptyEducationInfo: EducationInfo = {
      _id: '',
      degree: '',
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      school: '',
      gpa: null,
      total_gpa: null,
      major: '',
      present: false,
      index: 0,
    };

    return resumeEditorState.action === 'add'
      ? emptyEducationInfo
      : resume?.[ResumeKey.EDUCATION].filter((edu) => edu._id === resumeEditorState.elementId)[0];
  };

  const getPatentValue = () => {
    const emptyPatentInfo: PatentInfo = {
      _id: '',
      title: '',
      number: '',
    };

    return resumeEditorState.action === 'add'
      ? emptyPatentInfo
      : resume?.[ResumeKey.PATENTS].filter(
          (patent) => patent._id === resumeEditorState.elementId,
        )[0];
  };

  const getPublicationValue = () => {
    const emptyPublicationInfo: PublicationInfo = {
      _id: '',
      title: '',
      publisher: '',
      issued_month: null,
      issued_year: null,
    };

    return resumeEditorState.action === 'add'
      ? emptyPublicationInfo
      : resume?.[ResumeKey.PUBLICATIONS].filter(
          (publication) => publication._id === resumeEditorState.elementId,
        )[0];
  };

  const getVolunteerExperienceValue = () => {
    const emptyExperienceInfo: VolunteerExperienceInfo = {
      _id: '',
      organization: '',
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      present: false,
      location: '',
      description: '',
    };
    return resumeEditorState.action === 'add'
      ? emptyExperienceInfo
      : resume?.[ResumeKey.VOLUNTEER_EXPERIENCE].filter(
          (exp) => exp._id === resumeEditorState.elementId,
        )[0];
  };

  const getAchievementValue = () => {
    const emptyAchievementInfo: AchievementInfo = {
      _id: '',
      name: '',
      organization: '',
      issued_month: null,
      issued_year: null,
      description: '',
    };
    return resumeEditorState.action === 'add'
      ? emptyAchievementInfo
      : resume?.[ResumeKey.ACHIEVEMENTS].filter(
          (achievement) => achievement._id === resumeEditorState.elementId,
        )[0];
  };

  const getDefaultCertificateLicenseValue = () => {
    const emptyCertificateLicense: CertificatesLicensesInfo = {
      _id: '',
      name: '',
      organization: '',
      issued_month: null,
      issued_year: null,
    };
    return resumeEditorState.action === 'add'
      ? emptyCertificateLicense
      : resume?.[ResumeKey.CERTIFICATES].filter(
          (certificate) => certificate._id === resumeEditorState.elementId,
        )[0];
  };

  const { mutate: addProfessionalSummary } = useMutation({
    mutationFn: addResumeSummary,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Professional summary added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Professional summary added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editSummary } = useMutation({
    mutationFn: editResumeSummary,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Professional summary updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Professional summary updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editContactInfo } = useMutation({
    mutationFn: editResumeContactInfo,
    onSuccess: (response) => {
      setIsMutating(false);
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Resume Contact information updated',
      );
      showSnackbar('success', 'Contact information updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutateAsync: editWorkExperience } = useMutation({
    mutationFn: editResumeWorkExperience,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Work experience updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Work experience updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutateAsync: addWorkExperience } = useMutation({
    mutationFn: addResumeWorkExperience,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Work experience added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Work experience added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editProject } = useMutation({
    mutationFn: editResumeProject,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Project updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Project updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addProject } = useMutation({
    mutationFn: addResumeProject,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Projects added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Projects added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editEducation } = useMutation({
    mutationFn: editResumeEducation,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Education updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Education updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addEducation } = useMutation({
    mutationFn: addResumeEducation,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Education added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Education added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editSkills } = useMutation({
    mutationFn: editResumeSkills,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Skills updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Skills updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editCoursework } = useMutation({
    mutationFn: editResumeCoursework,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Coursework updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Coursework updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addCoursework } = useMutation({
    mutationFn: addResumeCoursework,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Coursework Added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Coursework Added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editInterests } = useMutation({
    mutationFn: editResumeInterests,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Interests updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Interests updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addInterests } = useMutation({
    mutationFn: addResumeInterests,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Interests Added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Interests Added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: editPatent } = useMutation({
    mutationFn: editResumePatent,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Patent updated');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Patent updated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addPatent } = useMutation({
    mutationFn: addResumePatent,
    onSuccess: (response) => {
      setIsMutating(false);
      showSnackbar('success', 'Patent Added');
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Patent Added');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: updateSection } = useMutation({
    mutationFn: updateResumeSection,
    onSuccess: (response, payload) => {
      setIsMutating(false);
      const message = `${capitalize(payload.sectionKey.replaceAll('_', ' '))}`;
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, message);
      showSnackbar('success', message);
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: addSection } = useMutation({
    mutationFn: addResumeSection,
    onSuccess: (response, payload) => {
      setIsMutating(false);
      const message = `${capitalize(payload.sectionKey.replaceAll('_', ' '))}`;
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, message);
      showSnackbar('success', message);
      queryClient.invalidateQueries([ResumesKeys.RESUMES, response.resume_id]);
      navigate(`/resumes/${response.resume_id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsMutating(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  // Editor function for professional summary
  const handleEditSummary = useCallback(
    (summary: SummaryInfo) => {
      setIsMutating(true);

      if (isNilOrEmpty(summary) || summary._id === '') {
        addProfessionalSummary({
          resumeId,
          summary: {
            professional_summary: summary.professional_summary,
          },
        });
      } else {
        editSummary(summary);
      }
    },
    [addProfessionalSummary, editSummary, resumeId, setIsMutating],
  );

  // TODO: add generic method for all editors submit
  const handleHeroSubmit = useCallback(
    (hero: HeroInfo) => {
      setIsMutating(true);
      if (hero._id === '') {
        addSection({
          resumeId,
          sectionKey,
          data: hero,
        });
      } else {
        updateSection({
          elementId: hero._id,
          sectionKey,
          data: hero,
        });
      }
    },
    [addSection, resumeId, sectionKey, setIsMutating, updateSection],
  );

  const handleObjectiveSubmit = useCallback(
    (objective: ObjectiveInfo) => {
      setIsMutating(true);
      if (objective._id === '') {
        addSection({
          resumeId,
          sectionKey,
          data: objective,
        });
      } else {
        updateSection({
          elementId: objective._id,
          sectionKey,
          data: objective,
        });
      }
    },
    [addSection, resumeId, sectionKey, setIsMutating, updateSection],
  );

  // Editor function for contact info
  const handleEditContactInfo = useCallback(
    (contact: ContactInfo) => {
      setIsMutating(true);
      editContactInfo(contact);
    },
    [editContactInfo, setIsMutating],
  );

  // Editor functions for work experience
  const handleUpdateExperience = useCallback(
    (experience: ExperienceInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        return editWorkExperience(experience);
      }
      // Using id in  experience info as resume id for add operation
      return addWorkExperience({ ...experience, _id: resumeEditorState.resumeId });
    },
    [setIsMutating, editWorkExperience, addWorkExperience, resumeEditorState.resumeId],
  );

  // Editor functions for project
  const handleUpdateProject = useCallback(
    (project: ProjectInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        editProject(project);
      } else if (method === 'add') {
        // Using id in  experience info as resume id for add operation
        addProject({ ...project, _id: resumeEditorState.resumeId });
      }
    },
    [setIsMutating, editProject, addProject, resumeEditorState.resumeId],
  );

  // Editor functions for education
  const handleUpdateEducation = useCallback(
    (education: EducationInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        editEducation(education);
      } else if (method === 'add') {
        // Using id in  education info as resume id for add operation
        addEducation({ ...education, _id: resumeEditorState.resumeId });
      }
    },
    [setIsMutating, editEducation, addEducation, resumeEditorState.resumeId],
  );

  // Editor functions for skills
  const handleUpdateSkills = useCallback(
    (updatedSkills: SkillsInfo) => {
      setIsMutating(true);
      editSkills(updatedSkills);
    },
    [editSkills, setIsMutating],
  );

  // Editor functions for coursework
  const handleUpdateCoursework = useCallback(
    (updatedCoursework: CourseworkInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        editCoursework(updatedCoursework);
      } else if (method === 'add') {
        // Using id in coursework info _id as resume id for add operation
        addCoursework(updatedCoursework);
      }
    },
    [setIsMutating, editCoursework, addCoursework],
  );

  // Editor functions for interests
  const handleUpdateInterests = useCallback(
    (updatedInterests: InterestsInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        editInterests(updatedInterests);
      } else if (method === 'add') {
        // Using id in coursework info _id as resume id for add operation
        addInterests(updatedInterests);
      }
    },
    [setIsMutating, editInterests, addInterests],
  );

  // Editor functions for patent
  const handleUpdatePatent = useCallback(
    (patent: PatentInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        editPatent(patent);
      } else if (method === 'add') {
        // Using id in  education info as resume id for add operation
        addPatent({ ...patent, _id: resumeEditorState.resumeId });
      }
    },
    [setIsMutating, editPatent, addPatent, resumeEditorState.resumeId],
  );

  // Editor functions for publications
  const handleUpdatePublication = useCallback(
    (publication: PublicationInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        updateSection({
          elementId: publication._id,
          sectionKey: resumeEditorMap.publications.sectionKey,
          data: publication,
        });
      } else if (method === 'add') {
        addSection({
          resumeId: resume?.[ResumeKey.RESUME_ID] || '',
          sectionKey: resumeEditorMap.publications.sectionKey,
          data: publication,
        });
      }
    },
    [setIsMutating, updateSection, resumeEditorMap.publications.sectionKey, addSection, resume],
  );

  // Editor functions for volunteer experience
  const handleUpdateVolunteerExperience = useCallback(
    (experience: VolunteerExperienceInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        updateSection({
          elementId: experience._id,
          sectionKey: resumeEditorMap['volunteer-experience'].sectionKey,
          data: experience,
        });
      } else if (method === 'add') {
        addSection({
          resumeId: resume?.[ResumeKey.RESUME_ID] || '',
          sectionKey: resumeEditorMap['volunteer-experience'].sectionKey,
          data: experience,
        });
      }
    },
    [setIsMutating, updateSection, resumeEditorMap, addSection, resume],
  );

  // Editor functions for achievements
  const handleUpdateAchievement = useCallback(
    (achievement: AchievementInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        updateSection({
          elementId: achievement._id,
          sectionKey: resumeEditorMap[ResumeKey.ACHIEVEMENTS].sectionKey,
          data: achievement,
        });
      } else if (method === 'add') {
        addSection({
          resumeId: resume?.[ResumeKey.RESUME_ID] || '',
          sectionKey: resumeEditorMap[ResumeKey.ACHIEVEMENTS].sectionKey,
          data: achievement,
        });
      }
    },
    [setIsMutating, updateSection, resumeEditorMap, addSection, resume],
  );

  // Editor functions for certificates and licenses
  const handleUpdateCertificateLicense = useCallback(
    (certificateLicense: CertificatesLicensesInfo, method: string) => {
      setIsMutating(true);
      if (method === 'edit') {
        updateSection({
          elementId: certificateLicense._id,
          sectionKey: resumeEditorMap[ResumeKey.CERTIFICATES].sectionKey,
          data: certificateLicense,
        });
      } else if (method === 'add') {
        addSection({
          resumeId: resume?.[ResumeKey.RESUME_ID] || '',
          sectionKey: resumeEditorMap[ResumeKey.CERTIFICATES].sectionKey,
          data: certificateLicense,
        });
      }
    },
    [setIsMutating, updateSection, resumeEditorMap, addSection, resume],
  );

  const handleAddNewSectionItem = () => {
    if (!sectionItem.includes('')) {
      setSectionItem([...sectionItem, '']);
    }
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  };

  const handleDeleteSectionItem = (value: string) => {
    const updatedSkills = sectionItem.filter((skill) => skill !== value);
    setSectionItem(updatedSkills);
  };

  const handleChangeSectionItem = (index: number, newValue: string) => {
    const updatedSkills = [...sectionItem];
    updatedSkills[index] = newValue;
    setSectionItem(updatedSkills);
  };

  const handleSumbitSkills = () => {
    handleUpdateSkills({ id: resume?.[ResumeKey.SKILLS]._id || '', skills: sectionItem });
  };

  // TODO: we can directly use update function as submit function
  const handleSumbitCoursework = () => {
    const isCourseIdPresent = isNotNil(resume?.[ResumeKey.COURSEWORK]?._id);
    handleUpdateCoursework(
      {
        _id: isCourseIdPresent
          ? resume?.[ResumeKey.COURSEWORK]._id || ''
          : resume?.[ResumeKey.RESUME_ID] || '',
        coursework: sectionItem,
      },
      isCourseIdPresent ? 'edit' : 'add',
    );
  };

  const handleSumbitInterests = () => {
    const isInterestIdPresent = isNotNil(resume?.[ResumeKey.INTERESTS]?._id);
    handleUpdateInterests(
      {
        _id: isInterestIdPresent
          ? resume?.[ResumeKey.INTERESTS]._id || ''
          : resume?.[ResumeKey.RESUME_ID] || '',
        interests: sectionItem,
      },
      isInterestIdPresent ? 'edit' : 'add',
    );
  };

  const shouldDisableSubmitButton = useMemo(
    () => (itemsList: string[]) => itemsList.some((element) => element.trim() === ''),
    [],
  );

  return (
    //  Resume page
    <Grid2 container sx={{ justifyContent: 'center', gap: 4 }}>
      {/* top grid */}
      <Grid2 xs={10}>
        <Grid2 justifyContent="space-between" container>
          <Grid2>
            <Stack gap="4px">
              <BreadCrumbs links={BreadCrumbLinks} />
              <Typography variant="body2" paddingLeft="30px">
                {resume?.[ResumeKey.FILENAME]}
              </Typography>
            </Stack>
          </Grid2>
          {/* Skill Add button */}
          {(resumeEditorState.elementType === 'skills' ||
            resumeEditorState.elementType === 'coursework' ||
            resumeEditorState.elementType === 'interests') && (
            <Grid2>
              <Button onClick={handleAddNewSectionItem}>
                <i
                  className="fi fi-rr-add"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                  }}
                />
                <Typography variant="label2">Add</Typography>
              </Button>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
      <Grid2 xs={10}>
        {/* TODO: Use enum for key */}
        {resumeEditorState.elementType === 'summary' && (
          <SummaryEditor
            defaultSummary={resume?.[ResumeKey.SUMMARY]}
            latestWorkExperiences={resume?.work_experience.find((exp) => !exp.is_career_break)}
            onEdit={handleEditSummary}
          />
        )}
        {resumeEditorState.elementType === ResumeKey.CONTACT && (
          <ContactInfoEditor
            defaultContactInfo={resume?.[ResumeKey.CONTACT]}
            onEdit={handleEditContactInfo}
          />
        )}
        {resumeEditorState.elementType === 'hero_message' && (
          <HeroMessageEditor
            defaultHeroMessage={resume?.[ResumeKey.HERO]}
            resume={resume}
            onEdit={handleHeroSubmit}
          />
        )}
        {resumeEditorState.elementType === 'objective' && (
          <ObjectiveEditor
            defaultObjective={resume?.[ResumeKey.OBJECTIVE]}
            onEdit={handleObjectiveSubmit}
          />
        )}
        {resumeEditorState.elementType === 'work-experience' && (
          <WorkExperienceEditor
            defaultWorkExperience={getExperienceValue()}
            onUpdate={handleUpdateExperience}
            resumeId={resumeEditorState.resumeId}
          />
        )}
        {resumeEditorState.elementType === 'project' && (
          <ProjectEditor defaultProject={getDefaultProjectValue()} onUpdate={handleUpdateProject} />
        )}
        {resumeEditorState.elementType === 'education' && (
          <EducationEditor
            defaultEducation={getEducationValue()}
            onUpdate={handleUpdateEducation}
          />
        )}
        {resumeEditorState.elementType === 'skills' && (
          <SortableListEditor
            ref={ref}
            items={sectionItem}
            onDelete={handleDeleteSectionItem}
            onChange={handleChangeSectionItem}
            onSubmit={handleSumbitSkills}
            onUpdateOrder={setSectionItem}
            disableSubmitButton={shouldDisableSubmitButton(sectionItem)}
            emptySectionText="No Skills Added"
          />
        )}
        {resumeEditorState.elementType === 'coursework' && (
          <SortableListEditor
            ref={ref}
            items={sectionItem}
            onDelete={handleDeleteSectionItem}
            onChange={handleChangeSectionItem}
            onSubmit={handleSumbitCoursework}
            onUpdateOrder={setSectionItem}
            disableSubmitButton={shouldDisableSubmitButton(sectionItem)}
            emptySectionText="No Relevant Coursework Added"
          />
        )}
        {resumeEditorState.elementType === 'interests' && (
          <SortableListEditor
            ref={ref}
            items={sectionItem}
            onDelete={handleDeleteSectionItem}
            onChange={handleChangeSectionItem}
            onSubmit={handleSumbitInterests}
            onUpdateOrder={setSectionItem}
            disableSubmitButton={shouldDisableSubmitButton(sectionItem)}
            emptySectionText="No Interests Added"
          />
        )}
        {resumeEditorState.elementType === 'patents' && (
          <PatentEditor defaultPatent={getPatentValue()} onUpdate={handleUpdatePatent} />
        )}
        {resumeEditorState.elementType === resumeEditorMap.publications.sectionKey && (
          <PublicationEditor
            defaultPublication={getPublicationValue()}
            onUpdate={handleUpdatePublication}
          />
        )}
        {resumeEditorState.elementType === 'volunteer-experience' && (
          <VolunteerExperienceEditor
            defaultVolunteerExperience={getVolunteerExperienceValue()}
            onUpdate={handleUpdateVolunteerExperience}
          />
        )}
        {resumeEditorState.elementType === ResumeKey.ACHIEVEMENTS && (
          <AchievementEditor
            defaultAchievement={getAchievementValue()}
            onUpdate={handleUpdateAchievement}
          />
        )}
        {resumeEditorState.elementType === ResumeKey.CERTIFICATES && (
          <CertificateLicenseEditor
            defaultCertificateLicense={getDefaultCertificateLicenseValue()}
            onUpdate={handleUpdateCertificateLicense}
          />
        )}
      </Grid2>
    </Grid2>
  );
};

export default ResumeEditor;
