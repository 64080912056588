import { type ReactNode, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { ReactComponent as DragIcon } from '../../../assets/icons/fi-br-drag.svg';

interface SortableItemProps {
  id: string;
  children: ReactNode;
  onDelete: (skill: string) => void;
}

// deprating this because it is nor generic, should be replaced with SortableItem
export const SortableItemsDeprecated = ({ id, children, onDelete }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Grid2
      spacing="8px"
      sx={{
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'border.light',
        borderRadius: '8px',
        padding: '12px 16px',
        backgroundColor: 'common.white',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      container
    >
      <Grid2>
        <Button
          variant="text"
          sx={{ color: 'text.tertiary', padding: '0px', minWidth: '20px' }}
          {...attributes}
          {...listeners}
          ref={setNodeRef}
        >
          {/* TODO: Check why fi-br-drag class is not working*/}
          {/* <i
            className="fi fi-br-drag"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
            }}
          /> */}
          <DragIcon />
        </Button>
      </Grid2>
      <Grid2 flexGrow={1}>
        {children}
      </Grid2>
      {isHovered && (
        <Grid2>
          <Button
            variant="text"
            sx={{ color: 'error.main', padding: '0px', minWidth: '20px' }}
            onClick={() => onDelete(id)}
          >
            <i
              className="fi fi-rr-trash"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
              }}
            />
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};
