import { Stack } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import { DataGrid as MUiDataGrid } from '@mui/x-data-grid';

interface DataGridProps extends MuiDataGridProps {
  mainBoxSx?: SxProps;
  sx?: SxProps;
  isLoading?: boolean;
}

// Props interface for DataGrid with emptyGridState
interface DataGridWithEmptyStateProps extends DataGridProps {
  emptyGridState?: JSX.Element;
}

const WithEmptyState =
  (DataGridComponent: React.ComponentType<DataGridProps>) =>
  ({ rows, emptyGridState, mainBoxSx, isLoading, ...restProps }: DataGridWithEmptyStateProps) =>
    (
      <>
        {rows.length > 0 || isLoading ? (
          <DataGridComponent
            rows={rows}
            mainBoxSx={mainBoxSx}
            isLoading={isLoading}
            {...restProps}
          />
        ) : (
          <Stack sx={{ ...mainBoxSx }}>
            {/* Render empty table state component when rows are empty */}
            {emptyGridState}
          </Stack>
        )}
      </>
    );

export const DataGrid = ({
  columns,
  rows,
  isLoading,
  mainBoxSx,
  sx,
  onRowClick,
  getRowHeight = () => 64,
  pageSizeOptions = [10, 20, 50],
  ...restProps
}: DataGridProps) => (
  <Stack
    sx={{
      '& .MuiDataGrid-root': {
        border: '1px solid',
        borderBottom: 'none',
        borderColor: 'border.light',
        backgroundColor: 'common.white',
        borderRadius: 2,
      },
      ...mainBoxSx,
    }}
  >
    <MUiDataGrid
      columns={columns}
      rows={rows}
      sx={{
        height: '40rem',
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
          paddingLeft: 0,
          fontWeight: 600,
        },
        '& .MuiDataGrid-columnHeader': {
          borderBottom: '1px solid',
          borderColor: 'border.light',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'break-spaces',
          lineHeight: 1,
          padding: 3,
        },
        '& .MuiDataGrid-columnSeparator--sideRight': {
          display: 'none',
        },
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
          ':hover': {
            borderRadius: 2,
            '& .column-controls': {
              opacity: 1,
            },
          },
        },
        '& .MuiDataGrid-cell': {
          border: 'none',
          padding: 3,
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
          minHeight: 0,
        },
        ...sx,
      }}
      loading={isLoading}
      getRowHeight={getRowHeight}
      rowSpacingType="margin"
      pageSizeOptions={pageSizeOptions}
      onRowClick={onRowClick}
      {...restProps}
    />
  </Stack>
);

export const DataGridWithEmptyState = WithEmptyState(DataGrid);
