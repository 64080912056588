import { isNil, isNotNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import type { ExperienceInfo } from '../../../../interface';
import { ResumesKeys, deleteResumeExperience } from '../../../../services/resumes';
import { useSwipeableDrawerStore } from '../../../../stores/SwipeableDrawerStore';
import { getCombinedCompanyExperiences } from '../../../../utils/apiUtils';
import Button from '../../../common/Button';
import DropdownMenu from '../../../common/DropdownMenu';
import Icon from '../../../common/Icon';
import SwipeableDrawerDeprecated, { Puller } from '../../../common/SwipeableDrawerDeprecated';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';
import WorkExperienceDetail from './components/WorkExperienceDetail';

interface DisplayWorkExperienceProps {
  experiences?: ExperienceInfo[];
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
  metadata?: Record<string, unknown>;
}

const DisplayWorkExperience = ({
  experiences,
  editable,
  resumeId,
  hideEmptySection,
}: DisplayWorkExperienceProps) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const { toggle: toggleMenuDrawer } = useSwipeableDrawerStore();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const shouldhideEmptySection =
    hideEmptySection && (isNil(experiences) || (isNotNil(experiences) && experiences.length === 0));

  const resumeEditorState: ResumeEditorState = {
    action: '',
    resumeId: resumeId || '',
    elementId: '',
    elementType: 'work-experience',
  };

  const { mutate: deleteResumeWorkExperience } = useMutation({
    mutationFn: deleteResumeExperience,
    onSuccess: () => {
      showSnackbar('success', 'Work Experience deleted');
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries([ResumesKeys.RESUMES, resumeEditorState.resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  // TODO: Combine add work experience and career break
  const handleAddWorkExperience = () => {
    if (isMobile) {
      toggleMenuDrawer();
    }
    resumeEditorState.action = 'add';
    navigate('/resumes/edit/work-experience', {
      state: { resumeEditorState },
    });
  };

  const handleAddCareerBreak = () => {
    if (isMobile) {
      toggleMenuDrawer();
    }
    resumeEditorState.action = 'add';
    resumeEditorState.metadata = { subType: 'career-break' };
    navigate('/resumes/edit/work-experience', {
      state: { resumeEditorState },
    });
  };

  const handlerReorderWorkExperience = () => {
    resumeEditorState.action = 'reorder';
    // TODO: use nested routes, use RoutePaths
    navigate('/resumes/reorder/work-experience', {
      state: { id: resumeId },
    });
  };

  const handleEditWorkExperience = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    navigate('/resumes/edit/work-experience', {
      state: { resumeEditorState },
    });
  };

  const handleEditCareerBreak = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    resumeEditorState.metadata = { subType: 'career-break' };
    navigate('/resumes/edit/work-experience', {
      state: { resumeEditorState },
    });
  };

  const handleDeleteExperience = useCallback(
    (id: string) => {
      deleteResumeWorkExperience(id);
    },
    [deleteResumeWorkExperience],
  );

  const shouldShowReorderButton = useMemo(() => {
    if (experiences) {
      // count experiences with present = true
      const presentExperiences = experiences.filter((exp) => exp.present);
      // show reorder button if there are more than 1 present experiences
      return presentExperiences.length > 1;
    }
    return false;
  }, [experiences]);

  const combinedCompanyExperiences = useMemo(() => {
    if (experiences) {
      return getCombinedCompanyExperiences(experiences);
    }
    return [];
  }, [experiences]);

  const menuItems = [
    {
      label: 'Work Experience',
      icon: 'fi fi-rr-briefcase',
      action: handleAddWorkExperience,
    },
    {
      label: 'Career Break',
      icon: 'fi fi-rr-mug-hot-alt',
      action: handleAddCareerBreak,
    },
  ];

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid xs={7} item>
          <Typography variant={isMobile ? 'h3' : 'h2'}>Work Experience</Typography>
        </Grid>
        {editable && (
          <Grid item>
            {shouldShowReorderButton && (
              <ResumeEditorButton
                onClick={handlerReorderWorkExperience}
                startIcon="fi fi-rr-apps-sort"
              >
                Reorder
              </ResumeEditorButton>
            )}

            {isMobile ? (
              <>
                <ResumeEditorButton
                  sx={{ color: 'primary' }}
                  onClick={toggleMenuDrawer}
                  startIcon={
                    <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
                  }
                >
                  Add
                </ResumeEditorButton>
                <SwipeableDrawerDeprecated>
                  <Puller sx={{ marginBottom: 3 }} />
                  {menuItems.map((item) => (
                    <Button
                      variant="text"
                      key={item.label}
                      sx={{
                        gap: 1,
                        width: '100%',
                        padding: '16px 12px',
                        justifyContent: 'flex-start',
                      }}
                      onClick={item.action}
                    >
                      <Icon
                        className={item.icon}
                        style={{ color: theme.palette.text.secondary, fontSize: '16px' }}
                      />
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {item.label}
                      </Typography>
                    </Button>
                  ))}
                </SwipeableDrawerDeprecated>
              </>
            ) : (
              <DropdownMenu menuItems={menuItems} label="Add" icon="fi fi-rr-add" />
            )}
          </Grid>
        )}
      </Grid>
      {combinedCompanyExperiences && combinedCompanyExperiences.length !== 0 ? (
        combinedCompanyExperiences.map((companyExp) => (
          <Stack
            key={companyExp._id}
            gap="16px"
            sx={
              companyExp.combined_experiences.length > 1
                ? {
                    border: '1px solid',
                    borderRadius: '12px',
                    borderColor: 'border.light',
                    padding: '16px',
                  }
                : {}
            }
          >
            {companyExp.combined_experiences.length > 1 && (
              <Stack>
                <Typography variant="label1">{companyExp.company}</Typography>
              </Stack>
            )}
            {companyExp.combined_experiences.map((experience: ExperienceInfo) => (
              <WorkExperienceDetail
                key={experience.job_title + experience.company}
                experience={experience}
                isEditable={editable}
                hideCompanyName={companyExp.combined_experiences.length > 1}
                onEdit={
                  experience.is_career_break ? handleEditCareerBreak : handleEditWorkExperience
                }
                onDelete={handleDeleteExperience}
              />
            ))}
          </Stack>
        ))
      ) : (
        <EmptySection text="No Work Experience Added" />
      )}
    </Stack>
  );
};

export default DisplayWorkExperience;
