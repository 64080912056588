import type { AlertProps as MUIAlertProps } from '@mui/material';
import { Alert as MuiAlert } from '@mui/material';

import Icon from './Icon';

enum AlertColor {
  success = '',
  info = 'background.mediumLight',
  warning = '',
  error = 'error.light',
}

interface AlertProps extends MUIAlertProps {
  muiAlertIconStyles?: React.CSSProperties;
}

const Alert = ({ severity, children, sx, muiAlertIconStyles, ...restProps }: AlertProps) => (
  <MuiAlert
    severity={severity}
    sx={{
      padding: 2,
      borderRadius: 1.5,
      color: 'text.primary',
      backgroundColor: AlertColor[severity || 'info'],
      '& .MuiAlert-icon': {
        padding: 0,
        color: severity === 'info' ? 'text.secondary' : '',
        ...muiAlertIconStyles,
      },
      '& .MuiAlert-message': { padding: 0, paddingTop: 0.2 },
      ...sx,
    }}
    iconMapping={{
      error: <Icon className="fi fi-rr-shield-exclamation" fontSize="20px" />,
    }}
    {...restProps}
  >
    {children}
  </MuiAlert>
);

export default Alert;
