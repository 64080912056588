import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { ResponseStatus } from '../../interface';
import { JobDescriptionTypes, getAllJobXRays } from '../../services/jobDescriptions';
import { isNilOrEmpty } from '../../utils';
import JobDescriptionDesktopSelector from './components/JobDescriptionDesktopSelector';
import JobDescriptionMobileSelector from './components/JobDescriptionMobileSelector';
import { SelectJDSection } from './constants';

interface JobDescriptionSelectorProps {
  onUpdate: (data: { jdId?: string; jDText?: string }) => void;
  value: {
    activeSection: SelectJDSection;
    jobDescriptionId: string;
    jobDescriptionText: string;
  };
}
const JobDescriptionSelector = ({ onUpdate, value }: JobDescriptionSelectorProps) => {
  const { isMobile } = useResponsiveDevice();
  const [selectedSection, setSelectedSection] = useState(value.activeSection);

  const { data: jDQueryResponse, isLoading } = useQuery({
    queryKey: [JobDescriptionTypes.JOBXRAYS],
    queryFn: () => getAllJobXRays(ResponseStatus.DONE),
  });

  const jobDescriptions = jDQueryResponse?.job_description_list;

  const jobDescriptionProps = useMemo(
    () => ({
      selectJobXrayProps: {
        jobDescriptionList: jobDescriptions,
        onSelectJD: onUpdate,
        isLoading: isLoading || isNilOrEmpty(jobDescriptions),
        defaultValue: value?.jobDescriptionId,
        isSectionEnabled: selectedSection === SelectJDSection.SELECT_JD,
      },
      importJobDescriptionProps: {
        onImport: onUpdate,
        defaultValue: value?.jobDescriptionText,
        isSectionEnabled: selectedSection === SelectJDSection.IMPORT_JD,
      },
    }),
    [isLoading, jobDescriptions, onUpdate, selectedSection, value],
  );

  return isMobile ? (
    <JobDescriptionMobileSelector
      {...jobDescriptionProps}
      value={value}
      setSelectedSection={setSelectedSection}
    />
  ) : (
    <JobDescriptionDesktopSelector
      {...jobDescriptionProps}
      selectedSection={selectedSection}
      setSelectedSection={setSelectedSection}
    />
  );
};

export default JobDescriptionSelector;
