import { Switch as MUISwitch, styled } from '@mui/material';

const Switch = styled(MUISwitch)(({ theme, checked }) => ({
  padding: 9,
  '& .MuiSwitch-switchBase': {
    top: '3px',
  },
  '& .MuiSwitch-track': {
    borderRadius: 30,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: checked ? theme.palette.primary.main : theme.palette.text.secondary,
    backgroundColor: '#fff !important',
    opacity: '1 !important',
    transition:
      'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&::before, &::after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 14,
      height: 14,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    width: checked ? 14 : 10,
    height: checked ? 14 : 10,
    margin: 2,
    marginLeft: checked ? 1 : 5,
    backgroundColor: checked ? theme.palette.primary.main : theme.palette.common.white,
    border: checked ? 'none' : `2px solid ${theme.palette.text.secondary}`,
    boxShadow: 'none',
  },
}));

export default Switch;
