import Card, { type CardProps } from './Card';

const StyledCard = ({ children, sx, cardContentProps, ...restProps }: CardProps) => (
  <Card
    sx={{
      borderRadius: '12px',
      ...sx,
    }}
    cardContentProps={{
      ...cardContentProps,
      sx: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2.5,
        wordBreak: 'break-word',
        padding: { xs: '20px 16px', sm: '24px' },
        '&:last-child': {
          paddingBottom: { xs: 3, sm: 4 },
        },
        ...cardContentProps?.sx,
      },
    }}
    {...restProps}
  >
    {children}
  </Card>
);

export default StyledCard;
