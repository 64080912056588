import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../../components/DragnDrop/SortableItem';
import FormActions from '../../../components/Form/FormActions';
import WorkExperienceDetail from '../../../components/ResumeView/components/DisplayWorkExperience/components/WorkExperienceDetail';
import type { ExperienceInfo, SortedResumeSectionKey } from '../../../interface';
import { getCombinedCompanyExperiences, resetIndexesInCompanies } from '../../../utils/apiUtils';

interface WorkExperiencesReorderProps {
  experiences: ExperienceInfo[];
  isLoading?: boolean;
  onSubmit?: (sortedExperinceKeys: SortedResumeSectionKey[]) => void;
}

const WorkExperiencesReorder = ({
  experiences = [],
  isLoading,
  onSubmit,
}: WorkExperiencesReorderProps) => {
  const [combinedCompanyExperiences, setCombinedCompanyExperiences] = useState(
    getCombinedCompanyExperiences(experiences),
  );

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedExperinceKeys = combinedCompanyExperiences
        .map((companyExp) =>
          companyExp.combined_experiences.map((exp) => ({
            id: exp._id,
            index: exp.index,
          })),
        )
        .flatMap((exp) => exp);

      onSubmit(sortedExperinceKeys);
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = combinedCompanyExperiences.findIndex(
        (companyExp) => companyExp._id === active.id,
      );
      const newIndex = combinedCompanyExperiences.findIndex(
        (companyExp) => companyExp._id === over.id,
      );
      const movedArray = arrayMove(combinedCompanyExperiences, oldIndex, newIndex);

      // reset indexes
      const updatedCombinedCompanyExperiences = resetIndexesInCompanies(movedArray);

      setCombinedCompanyExperiences(updatedCombinedCompanyExperiences);
    }
  };

  // handle DragEnd for nested dndContext
  const handledNestedExperiencesDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // find the company experience
      const companyExp = combinedCompanyExperiences.find((exp) =>
        exp.combined_experiences.some((_exp) => _exp._id === active.id),
      );

      if (companyExp) {
        const oldIndex = companyExp.combined_experiences.findIndex((exp) => exp._id === active.id);
        const newIndex = companyExp.combined_experiences.findIndex((exp) => exp._id === over.id);

        // swap nested elements
        const movedArray = arrayMove(companyExp.combined_experiences, oldIndex, newIndex);

        // // update index property in the experiences and start from 0
        let lastSetIndex = 0;
        movedArray.forEach((exp) => {
          exp.index = lastSetIndex;
          lastSetIndex += 1;
        });

        // update the combinedCompanyExperiences with reordered nested experiences
        const updatedCombinedCompanyExperiences = combinedCompanyExperiences.map((exp) => {
          if (exp._id === companyExp._id) {
            return {
              ...exp,
              combined_experiences: movedArray,
            };
          }
          return exp;
        });

        // reset all indexes in the combinedCompanyExperiences
        const companyExperiencesWithResetIndexes = resetIndexesInCompanies(
          updatedCombinedCompanyExperiences,
        );

        setCombinedCompanyExperiences(companyExperiencesWithResetIndexes);
      }
    }
  };

  return (
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        {combinedCompanyExperiences.length > 0 && (
          <SortableContext
            items={combinedCompanyExperiences.map((companyExp) => companyExp._id)}
            strategy={verticalListSortingStrategy}
          >
            {combinedCompanyExperiences.map((companyExp) => (
              <SortableItem
                key={companyExp._id}
                id={companyExp._id}
                disabled={!companyExp.present}
                sx={{
                  display: !companyExp.present ? 'none' : 'flex',
                }}
              >
                <Stack
                  gap="16px"
                  width="100%"
                  sx={
                    companyExp.combined_experiences.length > 1
                      ? {
                          border: '1px solid',
                          borderRadius: '12px',
                          borderColor: 'border.light',
                          padding: '16px',
                        }
                      : {}
                  }
                >
                  {companyExp.combined_experiences.length > 1 && (
                    <Stack>
                      <Typography variant="label1">{companyExp.company}</Typography>
                    </Stack>
                  )}
                  {/* nested dndContext, for nested drag & drop */}
                  <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={handledNestedExperiencesDragEnd}
                  >
                    <SortableContext
                      items={companyExp.combined_experiences.map((exp) => exp._id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {companyExp.combined_experiences.map((experience: ExperienceInfo) => (
                        <SortableItem
                          key={`${experience._id}_${experience.company}`}
                          id={experience._id}
                          disabled={
                            !experience.present || companyExp.combined_experiences.length === 1
                          }
                          sx={{
                            display: !experience.present ? 'none' : 'flex',
                          }}
                        >
                          <WorkExperienceDetail
                            experience={experience}
                            hideCompanyName={companyExp.combined_experiences.length > 1}
                            hideDescription
                            sx={{
                              width: '100%',
                            }}
                          />
                        </SortableItem>
                      ))}
                    </SortableContext>
                  </DndContext>
                </Stack>
              </SortableItem>
            ))}
          </SortableContext>
        )}
      </DndContext>

      <FormActions
        sx={{ marginTop: 3 }}
        onSubmit={onSaveClick}
        submitButtonProps={{ loading: isLoading }}
      />
    </Stack>
  );
};

export default WorkExperiencesReorder;
