import parse from 'html-react-parser';
import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { ProjectInfo } from '../../../../../interface';
import { hasTextInsideHtml, isNotNilOrEmpty } from '../../../../../utils';
import { formatDate } from '../../../../../utils/dateUtils';
import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal/Modal';
import ModalActionsDeprecated from '../../../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../../common/Modal/ModalDetail';

interface ProjectDetailProps {
  key: string;
  project: ProjectInfo;
  isEditable?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const ProjectDetail = ({ key, project, isEditable, onEdit, onDelete }: ProjectDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditProject = async () => {
    onEdit(project._id);
  };

  const handleDeleteProject = async () => {
    setOpen(false);
    onDelete(project._id);
  };

  return (
    <Stack
      key={key}
      gap="8px"
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* TODO: Explore modal manager */}
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Project?"
            description={`Are you sure you want to delete project ${project.project_name} at ${project.organization}? This action cannot be undone.`}
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeleteProject}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Stack gap="4px">
            {isNotNilOrEmpty(project.project_name) && (
              <Typography variant="label1">{project.project_name}</Typography>
            )}
            {isNotNilOrEmpty(project.organization) && (
              <Typography variant="body2">{project.organization}</Typography>
            )}
            <Typography variant="body2">
              {formatDate(
                project.start_month,
                project.start_year,
                project.end_month,
                project.end_year as number,
                project.present,
              )}
            </Typography>
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditProject}>
              <i
                className="fi fi-rr-edit"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                }}
              />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <i
                className="fi fi-rr-trash"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                }}
              />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      {project?.description && hasTextInsideHtml(project.description) && (
        <Typography variant="body2" sx={{ color: 'text.primaryGray' }}>
          {parse(project.description)}
        </Typography>
      )}
    </Stack>
  );
};

export default ProjectDetail;
