import { useTheme } from '@mui/material/styles';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ButtonProps } from '../../common/Button';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

const ResumeEditorButton = ({
  children = 'Edit',
  startIcon = 'fi fi-rr-edit',
  sx,
  ...restProps
}: ButtonProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();

  return (
    <Button
      variant="text"
      sx={{
        color: 'text.primary',
        minWidth: { xs: 'max-content', md: '80px' },
        padding: { xs: 2, md: '8px 12px' },
        ...sx,
      }}
      {...restProps}
    >
      {typeof startIcon === 'string' ? (
        <Icon
          className={startIcon}
          style={{ fontSize: '16px', color: theme.palette.text.secondary }}
        />
      ) : (
        startIcon
      )}
      {/* Display text for tablets and desktop screen */}
      {!isMobile && children}
    </Button>
  );
};

export default ResumeEditorButton;
