import { useCallback, useLayoutEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { CircularProgress, Stack, useTheme } from '@mui/material';

import { useSnackbar } from '../../../contexts/snackbar';
import type { ComposeFields } from '../../../services/superEditor';
import {
  ComposeFieldTypes,
  generateAchievements,
  generateDailyRoutines,
  generateResponsibilities,
  generateStory,
  generateskillsAndTools,
} from '../../../services/superEditor';
import { isNotNilOrEmpty } from '../../../utils';
import BootstrapTooltip from '../../common/BootstrapTooltip';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Modal from '../../common/Modal/Modal';
import ModalActions from '../../common/Modal/ModalActions';
import ModalDetail from '../../common/Modal/ModalDetail';
import TextFieldWithLabel from '../../common/TextFieldWithLabel';

interface ComposeWorkExpModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: ComposeFields) => void;
}

export const ComposeWorkExpModal = ({ open, onClose, onSubmit }: ComposeWorkExpModalProps) => {
  const [step, setStep] = useState(1);

  const [loadingComposeField, setLoadingComposeField] = useState<ComposeFieldTypes>();

  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
    trigger,
    reset,
  } = useForm();

  useLayoutEffect(() => {
    // When the modal is opened, reset the form and set the step to 1
    if (open) {
      reset();
      setStep(1);
    }
  }, [open, reset]);

  const generateComposeField = useCallback(
    (field: ComposeFieldTypes) => async () => {
      setLoadingComposeField(field);

      switch (field) {
        case ComposeFieldTypes.SKILLS_AND_TOOLS:
          setLoadingComposeField(ComposeFieldTypes.SKILLS_AND_TOOLS);
          generateskillsAndTools({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.SKILLS_AND_TOOLS, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.SKILLS_AND_TOOLS, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.RESPONSIBILITIES:
          setLoadingComposeField(ComposeFieldTypes.RESPONSIBILITIES);
          generateResponsibilities({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.RESPONSIBILITIES, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.RESPONSIBILITIES, data);
              setLoadingComposeField(undefined);
              trigger();
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.ACHIEVEMENTS:
          setLoadingComposeField(ComposeFieldTypes.ACHIEVEMENTS);
          generateAchievements({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.ACHIEVEMENTS, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.ACHIEVEMENTS, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.DAILY_ROUTINES:
          setLoadingComposeField(ComposeFieldTypes.DAILY_ROUTINES);
          generateDailyRoutines({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            achievements: getValues(ComposeFieldTypes.ACHIEVEMENTS) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.DAILY_ROUTINES, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.DAILY_ROUTINES, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        case ComposeFieldTypes.STORY:
          setLoadingComposeField(ComposeFieldTypes.STORY);
          generateStory({
            jobPosition: getValues(ComposeFieldTypes.JOB_POSITION) as string,
            skillsAndTools: getValues(ComposeFieldTypes.SKILLS_AND_TOOLS) as string,
            responsibilities: getValues(ComposeFieldTypes.RESPONSIBILITIES) as string,
            achievements: getValues(ComposeFieldTypes.ACHIEVEMENTS) as string,
            dailyRoutines: getValues(ComposeFieldTypes.DAILY_ROUTINES) as string,
            onProgress: (data) => {
              setValue(ComposeFieldTypes.STORY, data);
            },
            onEnd: (data) => {
              setValue(ComposeFieldTypes.STORY, data);
              setLoadingComposeField(undefined);
            },
          }).catch((e) => {
            if (e.name !== 'AbortError') {
              showSnackbar('error', 'An error occurred while composing the Work Experience');
            }
            setLoadingComposeField(undefined);
          });
          break;

        default:
          break;
      }
    },
    [getValues, setValue, showSnackbar, trigger],
  );

  const getEndAdornment = useCallback(
    (fieldType: ComposeFieldTypes, disabled = false) =>
      loadingComposeField === fieldType ? (
        <CircularProgress
          size={16}
          value={50}
          variant="indeterminate"
          sx={{
            color: theme.palette.text.secondary,
          }}
        />
      ) : (
        <BootstrapTooltip
          title="Generate Skills & Tools"
          placement="bottom"
          PopperProps={{
            sx: {
              marginTop: '-0.5rem',
            },
          }}
        >
          <Icon
            className="fi fi-rr-magic-wand"
            fontSize="16px"
            color={theme.palette.text.tertiary}
            style={{
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            onClick={generateComposeField(fieldType)}
          />
        </BootstrapTooltip>
      ),
    [
      generateComposeField,
      loadingComposeField,
      theme.palette.text.secondary,
      theme.palette.text.tertiary,
    ],
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      contentSx={{ margin: 2, width: '80vw' }}
      disableRestoreFocus
    >
      <>
        <ModalDetail
          title="Compose"
          description="AI will write 6 resume bullet points based on your answers. Answer freely in any format. Phrases, fragments, or even scribbles are perfectly fine."
          onClose={onClose}
        />
        <form
          style={{
            // animate height change
            transition: 'height 0.5s ease',
          }}
        >
          <Stack gap={2}>
            {step === 1 && (
              <>
                <TextFieldWithLabel
                  id={ComposeFieldTypes.JOB_POSITION}
                  label="Your detailed job position"
                  placeholder="UX designer | E-Commerce | SaaS"
                  required
                  autoFocus
                  errors={errors}
                  {...register('jobPosition', {
                    required: 'Job Position is required',
                    value: '',
                  })}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.SKILLS_AND_TOOLS}
                  label="Skills & tools"
                  placeholder="User research, information architecture, workflow design, wireframing, user onboarding, figma, adobe illustrator"
                  required
                  errors={errors}
                  {...register(ComposeFieldTypes.SKILLS_AND_TOOLS, {
                    required: 'Skills and Tools are required',
                    value: '',
                  })}
                  InputProps={{
                    endAdornment: getEndAdornment(
                      ComposeFieldTypes.SKILLS_AND_TOOLS,
                      !watch(ComposeFieldTypes.JOB_POSITION),
                    ),
                  }}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.RESPONSIBILITIES}
                  label="Responsibilities"
                  errors={errors}
                  required
                  placeholder="Creating user journeys and task flows, rapid prototyping, maintaining design consistency, working closely with product manager and engineering team for execution"
                  {...register('responsibilities', {
                    required: 'Responsibilites are required',
                    value: '',
                  })}
                  InputProps={{
                    endAdornment: getEndAdornment(
                      ComposeFieldTypes.RESPONSIBILITIES,
                      !watch(ComposeFieldTypes.JOB_POSITION) ||
                        !watch(ComposeFieldTypes.SKILLS_AND_TOOLS),
                    ),
                  }}
                  multiline
                  minRows={3}
                  maxRows={5}
                />
              </>
            )}
            {step === 2 && (
              <>
                <TextFieldWithLabel
                  id={ComposeFieldTypes.ACHIEVEMENTS}
                  label="Achievements or recognitions"
                  placeholder="Redesigned E-Commerce navigation and checkout process, boosting conversions by 30%"
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.ACHIEVEMENTS, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(
                      ComposeFieldTypes.ACHIEVEMENTS,
                      !watch(ComposeFieldTypes.JOB_POSITION) ||
                        !watch(ComposeFieldTypes.SKILLS_AND_TOOLS) ||
                        !watch(ComposeFieldTypes.RESPONSIBILITIES),
                    ),
                  }}
                  fullWidth
                  autoFocus
                  multiline
                  maxRows={5}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.DAILY_ROUTINES}
                  label="Daily routines"
                  placeholder="User research, creating wireframes and prototypes with figma, conducting usability tests, collaborating with the product manager and dev team, iterating on designs, reviewing user feedback, and ensuring design consistency across the platform."
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.DAILY_ROUTINES, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(
                      ComposeFieldTypes.DAILY_ROUTINES,
                      !watch(ComposeFieldTypes.JOB_POSITION) ||
                        !watch(ComposeFieldTypes.SKILLS_AND_TOOLS) ||
                        !watch(ComposeFieldTypes.RESPONSIBILITIES),
                    ),
                  }}
                  multiline
                  minRows={4}
                  maxRows={5}
                />

                <TextFieldWithLabel
                  id={ComposeFieldTypes.STORY}
                  label="Your story to share"
                  placeholder="Faced with a complex e-commerce redesign, I tackled navigation issues and a confusing checkout process. I led a user-centered approach, gathering feedback through testing. Iterating rapidly with Figma, I collaborated with engineers to streamline navigation and simplify checkout, boosting conversions by 30%."
                  showOptionalLabel
                  errors={errors}
                  {...register(ComposeFieldTypes.STORY, { value: '' })}
                  InputProps={{
                    endAdornment: getEndAdornment(
                      ComposeFieldTypes.STORY,
                      !watch(ComposeFieldTypes.JOB_POSITION) ||
                        !watch(ComposeFieldTypes.SKILLS_AND_TOOLS) ||
                        !watch(ComposeFieldTypes.RESPONSIBILITIES),
                    ),
                  }}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={5}
                />
              </>
            )}
            <ModalActions
              sx={{
                justifyContent: 'center',
                '& .actions-grid': {
                  flexGrow: 1,
                },
              }}
              actions={
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    onClick={() => {
                      if (step === 1) {
                        onClose();
                      } else {
                        setStep(1);
                      }
                    }}
                    variant="outlined"
                    sx={{ height: 'height: 36px', padding: '8px 12px', color: 'primary.main' }}
                  >
                    {step === 1 ? 'Cancel' : 'Back'}
                  </Button>
                  <Button
                    type="button"
                    disabled={isNotNilOrEmpty(errors) || !isValid}
                    sx={{ height: 'height: 36px', padding: '8px 12px' }}
                    onClick={(e) => {
                      e?.stopPropagation();
                      handleSubmit((fields: FieldValues) => {
                        if (step === 1) {
                          setStep(2);
                        } else {
                          onSubmit(fields as ComposeFields);
                        }
                      })();
                    }}
                  >
                    {step === 1 ? 'Next' : 'Compose '}
                  </Button>
                </Stack>
              }
            />
          </Stack>
        </form>
      </>
    </Modal>
  );
};
