import type { ReactElement } from 'react';

import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';

export const renderText = (text?: ReactElement | string, typographyProps?: TypographyProps) => {
  if (typeof text === 'string') {
    return (
      <Typography
        variant="body2"
        {...typographyProps}
        sx={{ wordBreak: 'break-word', ...typographyProps?.sx }}
      >
        {text}
      </Typography>
    );
  }
  return text;
};
