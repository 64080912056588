import { useEffect, useState } from 'react';

import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import { hasTextInsideHtml } from '../../../utils';
import BootstrapTooltip from '../../common/BootstrapTooltip';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

const ComposeButtonPlugin = ({
  onCompose,
  isComposing,
}: {
  onCompose: (() => void) | undefined;
  isComposing?: boolean;
}) => {
  const [editor] = useLexicalComposerContext();
  const [isEmpty, setIsEmpty] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        editorState.read(() => {
          const text = $generateHtmlFromNodes(editor);

          if (!hasTextInsideHtml(text)) {
            setIsEmpty(true);
          } else {
            setIsEmpty(false);
          }
        });
      });
    });

    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  if ((!onCompose || !isEmpty) && !isComposing) {
    return null;
  }

  return (
    <Stack
      sx={{
        maxWidth: 'max-content',
        padding: 2.5,
        gap: 0.5,
      }}
    >
      {!isComposing && (
        <Typography variant="assistive" color="text.secondary">
          Or, start with AI
        </Typography>
      )}

      <BootstrapTooltip
        title={isComposing ? '' : 'Assist with creating a write-up from scratch'}
        placement="bottom"
        PopperProps={{
          sx: {
            marginTop: '-0.5rem',
          },
        }}
      >
        <div>
          <Button
            variant="outlined"
            sx={{
              borderColor: 'border.main',
              fontWeight: '600',
              color: 'text.primary',
              pointerEvents: isComposing ? 'none' : 'auto',
            }}
            onClick={onCompose}
          >
            {isComposing ? (
              <CircularProgress
                size={16}
                value={50}
                variant="indeterminate"
                sx={{
                  color: theme.palette.text.secondary,
                }}
              />
            ) : (
              <Icon className="fi fi-rr-hat-wizard" color={theme.palette.text.secondary} />
            )}
            {isComposing ? 'Composing' : 'Compose'}
          </Button>
        </div>
      </BootstrapTooltip>
    </Stack>
  );
};

export default ComposeButtonPlugin;
