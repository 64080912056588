import { scoreColors } from '../theme/theme';

const hRange = [0, 360];
const sRange = [0, 100];
const lRange = [0, 100];

const normalizeHash = (hash: number, min: number, max: number) =>
  Math.floor((hash % (max - min)) + min);

const getHashOfString = (str: string) => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

export const generateHSL = (name: string) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

export const HSLtoString = (hsl: unknown[]) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

export const generateColorFromName = (name: string) => HSLtoString(generateHSL(name));

// TODO: rework, get palette from theme & update return type check
export const getScoreColor = (
  score: number,
  type = 'color',
  variant: 'dark' | 'light' = 'dark',
) => {
  if (score >= 90) {
    return type === 'color' ? scoreColors[variant].excellent : `score.${variant}.excellent`;
  }
  if (score >= 75) {
    return type === 'color' ? scoreColors[variant].good : `score.${variant}.good`;
  }
  if (score >= 60) {
    return type === 'color' ? scoreColors[variant].fair : `score.${variant}.fair`;
  }
  if (score >= 51) {
    return type === 'color' ? scoreColors[variant].marginal : `score.${variant}.marginal`;
  }
  return type === 'color' ? scoreColors[variant].poor : `score.${variant}.poor`;
};
