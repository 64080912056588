import type { TypographyProps } from '@mui/material';
import { Typography, useTheme } from '@mui/material';

import Button, { type ButtonProps } from './Button';
import Icon from './Icon';

interface AddMissingInfoButtonProps extends ButtonProps {
  hideIcon?: boolean;
  typographyVariant?: TypographyProps['variant'];
}

const AddMissingInfoButton = ({
  variant = 'text',
  typographyVariant = 'label1',
  hideIcon,
  onClick,
  sx,
  ...restProps
}: AddMissingInfoButtonProps) => {
  const theme = useTheme();
  const startIcon = hideIcon ? null : (
    <Icon
      className="fi fi-br-shield-exclamation"
      style={{ color: theme.palette.error.main, fontSize: '20px' }}
    />
  );
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{ color: 'error.main', marginLeft: -1.5, ...sx }}
      startIcon={startIcon}
      {...restProps}
    >
      <Typography variant={typographyVariant} sx={{ display: { xs: 'none', sm: 'block' } }}>
        Add Missing Info
      </Typography>
    </Button>
  );
};
export default AddMissingInfoButton;
