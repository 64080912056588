import { create } from 'zustand';

type ResumeEditorState = {
  isMutating: boolean;
  setIsMutating: (isMutating: boolean) => void;
  editorState: ResumeEditorState;
  setEditorState: (editorState: ResumeEditorState) => void;
};

const ResumeEditorKey = 'resumeEditor';

export const useResumeEditorStore = create<ResumeEditorState>((set) => {
  // Try to get the state from localStorage on initialization
  const savedState = localStorage.getItem(ResumeEditorKey);
  const initialState = savedState
    ? JSON.parse(savedState)
    : {
        isMutating: false,
        editorState: {
          action: '',
          resumeId: '',
          elementId: '',
          elementType: '',
        },
      };
  set(initialState);

  return {
    ...initialState,
    setIsMutating: (isMutating) => set(() => ({ isMutating })),
    setEditorState: (editorState) => set(() => ({ editorState })),
  };
});

// Subscribe to state changes and save to localStorage
useResumeEditorStore.subscribe((state) => {
  localStorage.setItem(ResumeEditorKey, JSON.stringify(state));
});
