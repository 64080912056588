import { $createParagraphNode, $createTextNode, $getRoot, type LexicalEditor } from 'lexical';
import { useCallback, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import { useSnackbar } from '../../../contexts/snackbar';
import { useAbortSignal } from '../../../hooks/useAbortSignal';
import type { HeroInfo, ResumeData } from '../../../interface';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { generateHeroMessage } from '../../../services/superEditor';
import type { Draft } from '../../../stores/SuperEditorDrafts';
import useDraftsStore, { DraftType } from '../../../stores/SuperEditorDrafts';
import { getDraftTooltipText, getDraftsIcon, getTextFromHtml } from '../../../utils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import type { ToolbarExternalControl } from '../../RichTextEditor/plugins/ToolbarPlugin';
import HelperTextWithModal from '../../common/HelperTextWithModal';
import DraftsManager from './Drafts/DraftsManager';

interface HeroMessageEditorProps {
  onEdit: (summary: HeroInfo) => void;
  defaultHeroMessage?: HeroInfo;
  resume?: ResumeData;
}

// TODO: simplify props and only get neccessary data from resume
const HeroMessageEditor = ({ onEdit, defaultHeroMessage, resume }: HeroMessageEditorProps) => {
  const [message, setMessage] = useState(defaultHeroMessage?.message || '');

  const richTextEditorRef = useRef<LexicalEditor>();
  const { drafts, maxDrafts, isLoading, addDraft, updateDraft, getDraft, removeDraft } =
    useDraftsStore();
  const { showSnackbar } = useSnackbar();

  const { abortSignal } = useAbortSignal();

  const latestWorkExprience = resume?.work_experience?.find((exp) => !exp.is_career_break);

  // TODO: replace with react-hook-form validations
  // Define a function to handle the form submission
  const onSubmit = () => {
    // Call the callback function to update the hero message
    onEdit({
      _id: defaultHeroMessage?._id || '',
      message,
    });
  };

  const onEditorChange = (html: string) => {
    setMessage(html);
  };

  const onRichTextEditorRef = (ref: LexicalEditor) => {
    richTextEditorRef.current = ref;
  };

  const onAppendDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      // append the draft text to the editor content
      richTextEditorRef.current.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(draft.text);
        paragraph.append(element);

        root.append(paragraph);
        root.selectEnd();
      });
    }
  };

  const onReplaceDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      // replace the editor content with the draft text
      richTextEditorRef.current.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(draft.text);
        paragraph.append(element);

        root.clear();
        root.append(paragraph);
        root.selectEnd();
      });
    }
  };

  const onGenerateClick = useCallback(() => {
    const id = uuid();

    const previousHeroMessageDraftText = drafts.find(
      (draft) => draft.type === DraftType.GENERATE,
    )?.text;

    // add empty draft
    addDraft({
      id,
      text: '',
      type: DraftType.GENERATE,
      status: 'loading',
    });

    generateHeroMessage({
      professional_summary: getTextFromHtml(resume?.professional_summary?.professional_summary),
      professional_experience: {
        job_title: latestWorkExprience?.job_title || '',
        description: getTextFromHtml(latestWorkExprience?.description),
      },
      previously_generated_hero_message: previousHeroMessageDraftText,
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: data,
            type: DraftType.GENERATE,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Hero Message Generated');
          updateDraft(id, {
            id,
            text: data,
            type: DraftType.GENERATE,
            status: 'success',
          });
        }, 1000);
      },
      signal: abortSignal,
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message ||
            'An error occurred while generating the Hero Message. Please try again later.',
        );
        const draft = getDraft(id);
        if (draft?.text === '') {
          removeDraft(id);
        } else {
          updateDraft(id, {
            type: DraftType.AUTOFIX,
            status: 'error',
          });
        }
      }
    });
  }, [
    drafts,
    addDraft,
    resume?.professional_summary?.professional_summary,
    latestWorkExprience?.job_title,
    latestWorkExprience?.description,
    abortSignal,
    updateDraft,
    showSnackbar,
    getDraft,
    removeDraft,
  ]);

  const toolbarExternalControls = useMemo<ToolbarExternalControl[]>(() => {
    // enable autofix and supercharge buttons if description has more than 10 words
    const meetsProgessionalSummaryLimit =
      (resume?.professional_summary?.professional_summary || '').split(' ').length >= 10;
    const meetsWorkExpLimit =
      getTextFromHtml(latestWorkExprience?.description).split(' ').length >= 10;

    const shouldDisable =
      isLoading ||
      drafts.length === maxDrafts ||
      !resume ||
      (!meetsProgessionalSummaryLimit && !meetsWorkExpLimit);

    return [
      {
        title: 'Generate',
        icon: getDraftsIcon(DraftType.GENERATE),
        disabled: shouldDisable,
        tooltipText: getDraftTooltipText(
          DraftType.GENERATE,
          'Hero Message',
          shouldDisable,
          drafts.length === maxDrafts,
          maxDrafts,
          'Your most recent Work Experience or Professional Summary must have at least 10 words to generate a Hero Message',
        ),
        onClick: onGenerateClick,
      },
    ];
  }, [
    drafts.length,
    isLoading,
    latestWorkExprience?.description,
    maxDrafts,
    onGenerateClick,
    resume,
  ]);

  return (
    // TODO: refactor to make this a generic component for use-cases like hero, objective, professional summary, etc.
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={2}>
        <Stack gap="8px">
          <Stack gap="4px">
            <InputLabel
              htmlFor="hero_message"
              sx={{
                maxHeight: '24px',
                '& .MuiFormLabel-asterisk': {
                  color: 'error.main',
                  paddingLeft: 0.5,
                  fontSize: '12px',
                },
              }}
              required
            >
              <Typography variant="label2" color="text.primary">
                Hero Message
              </Typography>
            </InputLabel>
            <FormHelperText>
              <Typography variant="body3">
                Describe your professional role, core competency as a tagline.
                <HelperTextWithModal
                  modalTitle="Example Hero Messages"
                  buttonText="Example"
                  modalContent={[
                    'Full-stack developer with ML experience',
                    'Seasoned Product Management leader with 10+ years | New Product Launch | Gen AI',
                    'Creative Digital Marketing Professional with SEO Experience',
                    'Passionate UI/UX Designer with 5+ years | Unicorn Product Designer',
                    'Outbound sales rep with 8+ years | Enterprise SaaS | Cyber Security',
                  ]}
                />
              </Typography>
            </FormHelperText>
          </Stack>
          <RichTextEditor
            html={defaultHeroMessage?.message || ''}
            toolbarExternalControls={toolbarExternalControls}
            onChange={onEditorChange}
            onEditorRefChange={onRichTextEditorRef}
            autoFocus
            disabled={isLoading}
          />
        </Stack>

        <DraftsManager
          sectionTitle="Hero Message"
          onAppendDraft={onAppendDraft}
          onReplaceDraft={onReplaceDraft}
        />

        {/* TODO: pass isLoading, Verify if still needed */}
        <FormActions onSubmit={onSubmit} />
      </Stack>
    </form>
  );
};

export default HeroMessageEditor;
