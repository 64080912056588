import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { NavigateNextRounded } from '@mui/icons-material';
import { Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material';

export interface BreadCrumbLink {
  label: string;
  href: string;
}

export interface BreadCrumbsProps {
  links: BreadCrumbLink[];
  onBack?: () => void;
}

const BreadCrumbs = ({ links = [], onBack }: BreadCrumbsProps) => {
  const navigate = useNavigate();

  if (!links.length) {
    return null;
  }

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        variant="text"
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: 'unset',
          width: '30px',
          i: {
            flexWrap: 'wrap !important',
          },
        }}
        onClick={onBack || (() => navigate(-1))}
      >
        <i
          style={{
            display: 'flex',
            alignContent: 'center',
            color: '#737373',
            width: '24px',
            height: '24px',
          }}
          className="fi fi-br-arrow-left"
        />
      </Button>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextRounded />}>
        {links.map((link, index, arr) => (
          <Link
            component={RouterLink}
            underline="hover"
            to={link.href}
            key={link.label}
            aria-current={index === arr.length - 1 ? 'page' : undefined}
            sx={{
              color: index === arr.length - 1 ? 'text.primary' : 'inherit',
              pointerEvents: index === arr.length - 1 ? 'none' : 'auto',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: index === arr.length - 1 ? 'text.primary' : 'text.secondary',
              }}
            >
              {link.label}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadCrumbs;
