/* eslint-disable react/no-array-index-key */
import type { VolunteerExperienceInfo } from '../../../interface';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';
import type { StringConcatenatorProps } from './StringConcatenator';
import StringConcatenator from './StringConcatenator';

interface TemplateVolunteerExperienceProps {
  volunteerExperience: VolunteerExperienceInfo[];
  sectionContentProps?: SectionContentProps;
  sectionHeadingProps?: StringConcatenatorProps;
}

const TemplateVolunteerExperience = ({
  volunteerExperience,
  sectionContentProps,
  sectionHeadingProps,
}: TemplateVolunteerExperienceProps) => (
  <>
    {volunteerExperience.map((experience, index) => (
      <SectionContent
        key={index}
        heading={
          <StringConcatenator
            value1={experience?.organization}
            value2={experience?.location}
            separator=" • "
            {...sectionHeadingProps}
          />
        }
        content={experience?.description}
        date={getFormatedDate(experience)}
        subHeadingStyle={{ ...styles.label2, ...sectionContentProps?.subHeadingStyle }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplateVolunteerExperience;
