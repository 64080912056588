// TODO: refactor to include svg support and move to separate component
// TODO: set default value for fontSize to 20px
const Icon = ({
  color,
  className,
  fontSize,
  style,
  onClick,
}: {
  color?: string;
  className?: string;
  fontSize?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <i
    className={className}
    style={{
      color,
      fontSize,
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      cursor: onClick ? 'pointer' : 'default',
      ...style,
    }}
    onClick={onClick}
  />
);

export default Icon;
