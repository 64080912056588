import {
  Box,
  LinearProgress,
  AccordionSummary as MUIAccordionSummary,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import type { AccordionSummaryProps as MUIAccordionSummaryProps } from '@mui/material';

import Icon from '../../../../../components/common/Icon';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { getScoreColor } from '../../../../../utils/colorUtils';

interface AccordionSummaryProps extends MUIAccordionSummaryProps {
  label: number;
  expanded?: boolean;
}

const AccordionSummary = ({ label, expanded, children, ...restProps }: AccordionSummaryProps) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <MUIAccordionSummary
      {...restProps}
      sx={{
        padding: 0,
        minHeight: 'fit-content',
        '& .MuiAccordionSummary-content': { padding: 0 },
        '& .Mui-expanded': {
          paddingBottom: { xs: 4, sm: 5 },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexGrow: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: { xs: 1, md: 2 },
          }}
        >
          {expanded ? (
            <Icon
              className="fi fi-rr-angle-small-down"
              style={{ color: theme.palette.text.secondary }}
            />
          ) : (
            <Icon
              className="fi fi-rr-angle-small-right"
              style={{ color: theme.palette.text.secondary }}
            />
          )}
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {children}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
            gap: { xs: 1, sm: 2, md: 3 },
            minWidth: { xs: '100%', sm: '40%' },
          }}
        >
          <LinearProgress
            variant="determinate"
            value={label}
            sx={{
              width: '50%',
              maxWidth: '15.625rem',
              height: '0.75rem',
              borderRadius: '5px',
              backgroundColor: 'background.mediumLight',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getScoreColor(label, 'key'),
                borderRadius: '5px',
              },
            }}
          />
          <Typography sx={{ width: { xs: '3.5rem' } }} variant={isMobile ? 'label1' : 'h3'}>
            {Math.round(label)}%
          </Typography>
        </Stack>
      </Box>
    </MUIAccordionSummary>
  );
};

export default AccordionSummary;
