import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Checkbox, InputLabel, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import type { ContactInfo } from '../../../interface';
import FormActions from '../../Form/FormActions';

interface ContactInfoEditorProps {
  onEdit: (contact: ContactInfo) => void;
  defaultContactInfo?: ContactInfo;
}

const ContactInfoEditor = ({ onEdit, defaultContactInfo }: ContactInfoEditorProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showCountry, setShowCountry] = useState(defaultContactInfo?.display_country || false);

  // Define a function to handle the form submission
  const onSubmit = (data: ContactInfo) => {
    onEdit({ ...defaultContactInfo, ...data });
  };

  // TODO: Refactor code
  return (
    // Add form element with onSubmit
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <form onSubmit={handleSubmit(onSubmit as any)}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel
            htmlFor="first_name"
            sx={{
              color: 'error.main',
              '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
            }}
            required
          >
            <Typography variant="label2" color="text.primary">
              Name
            </Typography>
          </InputLabel>
          <Grid2 justifyContent="space-between" spacing={5} container>
            <Grid2 xs={6}>
              {/* TODO: Use common TextField component */}
              <TextField
                id="first_name"
                placeholder="First Name"
                {...register('first_name', {
                  required: 'Enter a first name',
                })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                  paddingBottom: 0.5,
                }}
                defaultValue={defaultContactInfo?.first_name}
                variant="outlined"
                error={!!errors.first_name}
                fullWidth
              />
              {!!errors?.first_name && (
                <Typography variant="assistive" color="error">
                  {errors?.first_name?.message?.toString()}
                </Typography>
              )}
            </Grid2>
            <Grid2 xs={6}>
              <TextField
                id="last_name"
                placeholder="Last Name"
                {...register('last_name')}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                }}
                defaultValue={defaultContactInfo?.last_name}
                variant="outlined"
                fullWidth
              />
            </Grid2>
          </Grid2>
        </Stack>
        <Grid2 justifyContent="space-between" spacing={5} container>
          <Grid2 xs={6}>
            <InputLabel htmlFor="city" sx={{ paddingBottom: 1, maxHeight: '28px' }}>
              <Typography variant="label2" color="text.primary">
                City
              </Typography>
            </InputLabel>
            <TextField
              id="city"
              placeholder="City"
              {...register('city')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid',
                    borderColor: 'border.light',
                    borderRadius: '8px',
                    padding: '10px',
                  },
                },
              }}
              defaultValue={defaultContactInfo?.city}
              variant="outlined"
              fullWidth
            />
          </Grid2>
          <Grid2 xs={6}>
            <InputLabel htmlFor="state" sx={{ paddingBottom: 1, maxHeight: '28px' }}>
              <Typography variant="label2" color="text.primary">
                State
              </Typography>
            </InputLabel>
            <TextField
              id="state"
              placeholder="State"
              {...register('state')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid',
                    borderColor: 'border.light',
                    borderRadius: '8px',
                    padding: '10px',
                  },
                },
                paddingBottom: 0.5,
              }}
              defaultValue={defaultContactInfo?.state}
              variant="outlined"
              fullWidth
            />
          </Grid2>
        </Grid2>
        <Grid2 alignItems="center" container>
          <Grid2>
            <Checkbox
              checked={showCountry}
              {...register('display_country')}
              onChange={() => setShowCountry(!showCountry)}
              color="primary"
              sx={{ padding: 0, paddingRight: 1 }}
            />
          </Grid2>
          <Grid2>
            <Typography variant="body3">Show Country</Typography>
          </Grid2>
        </Grid2>
        {showCountry && (
          <Stack gap={1}>
            <InputLabel>
              <Typography variant="label2" color="text.primary">
                Country
              </Typography>
            </InputLabel>
            <TextField
              id="country"
              placeholder="United States"
              {...register('country')}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid',
                    borderColor: 'border.light',
                    borderRadius: '8px',
                    padding: '10px',
                  },
                },
              }}
              inputProps={{
                maxLength: 32,
              }}
              defaultValue={defaultContactInfo?.country}
              variant="outlined"
              fullWidth
            />
          </Stack>
        )}
        <Stack gap={1}>
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              Email Address
            </Typography>
          </InputLabel>
          <TextField
            id="email"
            placeholder="example@email.com"
            {...register('email')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            defaultValue={defaultContactInfo?.email}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Stack gap={1}>
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              Phone
            </Typography>
          </InputLabel>
          <TextField
            id="phone"
            placeholder="+123-456-789"
            {...register('phone')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            defaultValue={defaultContactInfo?.phone}
            variant="outlined"
            fullWidth
          />
        </Stack>
        {/* TODO: Create a generic input field component that handles input field style to avoid redundancy. i-e label and input styles */}
        <Stack gap={1}>
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              LinkedIn
            </Typography>
          </InputLabel>
          <TextField
            id="linkedin"
            placeholder="https://linkedin.com/user"
            {...register('linkedin')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            defaultValue={defaultContactInfo?.linkedin}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} />
      </Stack>
    </form>
  );
};

export default ContactInfoEditor;
