import { isNotNil } from 'ramda';
import { type ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import uuid from 'react-uuid';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, TextField } from '@mui/material';

import FormActions from '../../Form/FormActions';
import EmptySection from '../../ResumeView/components/EmptySection';
import { SortableItemsDeprecated } from './SortableItems_deprecated';

interface SortableListEditorProps {
  onDelete: (item: string) => void;
  onChange: (index: number, item: string) => void;
  onUpdateOrder: (item: string[]) => void;
  onSubmit: () => void;
  disableSubmitButton?: boolean;
  emptySectionText?: string;
  items?: string[];
}

const SortableListEditor = forwardRef(
  (
    {
      items,
      onDelete,
      onChange,
      onUpdateOrder,
      onSubmit,
      disableSubmitButton = false,
      emptySectionText = 'No Item(s) Added',
    }: SortableListEditorProps,
    ref,
  ) => {
    const { register, handleSubmit } = useForm();
    const buttonRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollIntoView() {
          if (buttonRef.current) {
            buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        },
      }),
      [],
    );

    const itemsObject = items?.map((item) => ({
      id: uuid(),
      label: item,
    }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDragEnd = ({ active, over }: any) => {
      if (active.id !== over.id && isNotNil(items) && isNotNil(itemsObject)) {
        const activeIndex = itemsObject.findIndex((obj) => obj.id === active.id);
        const overIndex = itemsObject.findIndex((obj) => obj.id === over.id);
        onUpdateOrder(arrayMove(items, activeIndex, overIndex));
      }
    };

    const handleChangeItem = (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (isNotNil(itemsObject)) {
        const index = itemsObject.findIndex((obj) => obj.id === name);
        if (index !== -1) {
          itemsObject[index].label = value;
        }
      }
    };

    const handleDeleteItem = (id: string) => {
      const deleteElement = itemsObject?.filter((item) => item.id === id) || [];
      onDelete(deleteElement[0]?.label);
    };

    return (
      // Add form element with onSubmit
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <form onSubmit={handleSubmit(onSubmit as any)}>
        <Stack gap="48px">
          {items?.length === 0 ? (
            <EmptySection text={emptySectionText} sx={{ paddingY: 8 }} />
          ) : (
            <Stack gap="12px">
              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                {isNotNil(itemsObject) && (
                  <SortableContext
                    items={itemsObject.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {itemsObject.map((item, index) => (
                      // TODO: Replace SortableItemsDeprecated with SortableItems
                      <SortableItemsDeprecated
                        key={item.id}
                        id={item.id}
                        onDelete={handleDeleteItem}
                      >
                        <TextField
                          {...register(`${item.id}`)}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                            },
                          }}
                          onChange={handleChangeItem}
                          onBlur={() => onChange(index, item.label)}
                          defaultValue={item.label}
                          variant="outlined"
                          fullWidth
                        />
                      </SortableItemsDeprecated>
                    ))}
                  </SortableContext>
                )}
              </DndContext>
            </Stack>
          )}
          <FormActions
            ref={buttonRef}
            sx={{ overflowY: 'auto', maxHeight: '400px' }}
            submitButtonProps={{ disabled: disableSubmitButton }}
          />
        </Stack>
      </form>
    );
  },
);

export default SortableListEditor;
