import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { EducationInfo } from '../../../../../interface';
import { addSeparator, isNotNilOrEmpty } from '../../../../../utils';
import { formatDate } from '../../../../../utils/dateUtils';
import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal/Modal';
import ModalActionsDeprecated from '../../../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../../common/Modal/ModalDetail';

interface EducationDetailProps {
  key: string;
  education: EducationInfo;
  isEditable?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const EducationDetail = ({
  key,
  education,
  isEditable,
  onEdit,
  onDelete,
}: EducationDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditEducation = () => {
    onEdit(education._id);
  };

  const handleDeleteEducation = async () => {
    setOpen(false);
    onDelete(education._id);
  };

  return (
    <Box
      key={key}
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* TODO: Explore modal manager */}
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Education?"
            description={`Are you sure you want to delete education for ${education.degree}? This action cannot be undone.`}
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeleteEducation}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Stack gap="8px">
            {(isNotNilOrEmpty(education.degree) || isNotNilOrEmpty(education.major)) && (
              <Typography variant="label1">
                {addSeparator(education.degree, education.major, ', ')}
              </Typography>
            )}
            {isNotNilOrEmpty(education.school) && (
              <Typography variant="body2">{education.school}</Typography>
            )}
            <Typography variant="body2" color="text.primaryGray">
              {formatDate(
                education.start_month,
                education.start_year,
                education.end_month,
                education.end_year,
                education.present,
                true, // Disable duration caluculation
              )}
            </Typography>
            {isNotNilOrEmpty(education.gpa) && (
              <Typography variant="body2" color="text.primaryGray">
                GPA: {addSeparator(education.gpa?.toFixed(2), education.total_gpa?.toFixed(2), '/')}
              </Typography>
            )}
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditEducation}>
              <i
                className="fi fi-rr-edit"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                }}
              />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <i
                className="fi fi-rr-trash"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                }}
              />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EducationDetail;
