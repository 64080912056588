const JobDescriptionTemplate = () => (
  <>
    <p>Product Policy Manager (WhatsApp) </p>
    <p>Meta · London, England, United Kingdom Reposted 1 week ago · 346 applicants </p>
    <p>Full-time </p>
    <p>10,001+ employees · Software Development </p>
    <p>6,376 company alumni work here </p>
    <p>Skills: Social Media Content Creation, Policy Development, +8 more </p>
    <p>View verifications related to this job post.View verifications related to this job post. </p>
    <p>Show all </p>
    <p>See how you compare to 29 applicants. Try Premium for $0 </p>
    <p>Apply </p>
    <p>Save </p>
    <p>Save Product Policy Manager (WhatsApp) at Meta </p>
    <p>Share </p>
    <p>Show more options </p>
    <p>Product Policy Manager (WhatsApp) </p>
    <p>Meta London, England, United Kingdom </p>
    <p>Apply </p>
    <p>Save </p>
    <p>Save Product Policy Manager (WhatsApp) at Meta </p>
    <p>Show more options </p>
    <p>About the job </p>
    <p>
      Messaging Product Policy is seeking a product policy manager to support WhatsApp’s product
      development and integrity efforts as an encrypted private messaging service, including our
      approach to safety and compliance, scaled enforcement, and product launch readiness. In this
      role, you will collaborate closely with WhatsApp Product, Trust and Safety, Engineering,
      Legal, Public Policy, Communications, and other teams to identify risks and mitigations, drive
      consensus, and advance the interests of WA’s global user base.
    </p>
    <p>Product Policy Manager (WhatsApp) Responsibilities: </p>
    <p>
      Review product launches and work with subject matter experts to identify and address policy
      risks and possible mitigations
    </p>
    <p>
      Articulate policy principles, risks, and developments to engineering teams, product
      management, and other internal stakeholders
    </p>
    <p>
      Build cross-functional relationships across product, communications, operations and legal
      teams
    </p>
    <p>
      Identify new policy development needs, write policies, and work with Product and Operations
      teams to build and refine enforcement flows as needed for those features and products
    </p>
    <p>
      Act as an escalation point of contact and manage internal communications for policy issues
    </p>
    <p>Minimum Qualifications: </p>
    <p>
      5+ years of work experience in policy, enforcement, or operations, with an emphasis on
      technology
    </p>
    <p>Experience with product development lifecycle and working with product or technical teams</p>
    <p>
      Experience working cross-functionally and consensus-building, including managing
      multi-stakeholder projects
    </p>
    <p>Experience in internet/social media content issues </p>
    <p>Demonstrated analytical thinking and problem-solving </p>
    <p>Preferred Qualifications: </p>
    <p>Advanced Degree, Law Degree, or Project Management Degree </p>
    <p>Experience working on or with online products including messaging platforms </p>
    <p>Experience working at a Tech Company </p>
    <p>Experience managing incident response or escalations </p>
    <p>About Meta: </p>
    <p>
      Meta builds technologies that help people connect, find communities, and grow businesses. When
      Facebook launched in 2004, it changed the way people connect. Apps like Messenger, Instagram
      and WhatsApp further empowered billions around the world. Now, Meta is moving beyond 2D
      screens toward immersive experiences like augmented and virtual reality to help build the next
      evolution in social technology. People who choose to build their careers by building with us
      at Meta help shape a future that will take us beyond what digital connection makes possible
      today—beyond the constraints of screens, the limits of distance, and even the rules of
      physics.
    </p>
  </>
);

export default JobDescriptionTemplate;
