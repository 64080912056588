// TODO Usama: Handle description and file states
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import FileDropzone from '../../../components/FileDropzone';
import JobDescriptionInput from '../../../components/JobDescriptionInput';
import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Logo from '../../../components/common/Logo';

interface UploadOnboardingProfileProps {
  onFinishSignUp: ({ description, file }: { description: string; file: File }) => void;
  onBackClick: () => void;
  isLoading?: boolean;
}

const UploadOnboardingProfile = ({
  onFinishSignUp,
  onBackClick,
  isLoading,
}: UploadOnboardingProfileProps) => {
  const [description, setDescription] = useState<string>();
  const [file, setFile] = useState<File>();

  const handleFinishSignUp = async () => {
    if (file && description) {
      onFinishSignUp({ description, file });
    }
  };

  const handleUpdateJobDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  return (
    <Box>
      {/* TODO: move logo to main page */}
      <Logo height="50px" style={{ marginLeft: '2rem', marginTop: '2rem' }} />
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '40vw',
          marginTop: '2rem',
        }}
      >
        <Card
          sx={{ borderRadius: '12px', padding: 5 }}
          cardContentProps={{
            sx: {
              padding: 0,
              '&:last-child': {
                paddingBottom: 0,
              },
            },
          }}
        >
          <Stack gap={6}>
            <Stack gap={4}>
              <Typography variant="h1" sx={{ textAlign: 'center' }}>
                Create your first Job Match Score
              </Typography>
              <Stack gap={2.5}>
                <JobDescriptionInput
                  onUpdateDescription={handleUpdateJobDescription}
                  sx={{ maxWidth: '770px' }}
                />
                <FileDropzone onFileChange={(inputFile: File | undefined) => setFile(inputFile)} />
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="outlined"
                onClick={onBackClick}
                sx={{ minWidth: '80px', color: 'primary.main' }}
              >
                Back
              </Button>
              <Button loading={isLoading} onClick={handleFinishSignUp} sx={{ minWidth: '80px' }}>
                Let&apos;s Go!
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default UploadOnboardingProfile;
