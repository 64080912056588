/* eslint-disable react/no-array-index-key */
import type { ProjectInfo } from '../../../interface';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';

interface TemplateProjectProps {
  projects: ProjectInfo[];
  sectionContentProps?: SectionContentProps;
}

const TemplateProject = ({ projects, sectionContentProps }: TemplateProjectProps) => (
  <>
    {projects?.map((project, index) => (
      <SectionContent
        key={index}
        heading={project?.project_name}
        subHeading={project?.organization}
        content={project?.description}
        date={getFormatedDate(project)}
        subHeadingStyle={{ ...styles.label2, ...sectionContentProps?.subHeadingStyle }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplateProject;
