import type { InputLabelProps as MuiInputLabelProps, TypographyProps } from '@mui/material';
import { InputLabel as MuiInputLabel, Typography } from '@mui/material';

interface InputLabelProps extends MuiInputLabelProps {
  label?: string;
  typographyProps?: TypographyProps;
}

const InputLabel = ({
  htmlFor,
  required,
  sx,
  label,
  typographyProps,
  ...restProps
}: InputLabelProps) => (
  <MuiInputLabel
    htmlFor={htmlFor}
    sx={{
      color: required ? 'error.main' : 'text.primary',
      '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
      ...sx,
    }}
    required={required}
    {...restProps}
  >
    <Typography variant="label2" color="text.primary" {...typographyProps}>
      {label}
    </Typography>
  </MuiInputLabel>
);

export default InputLabel;
