import { isNotNil } from 'ramda';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import type { SxProps } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { useResumeEditorStore } from '../../stores/ResumeEditorStore';
import type { ButtonProps } from '../common/Button';
import Button from '../common/Button';

interface FormActionsProps {
  actions?: React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  sx?: SxProps;
  submitButtonProps?: ButtonProps;
  rootRef?: React.Ref<HTMLDivElement> | null;
}

const FormActions = forwardRef<HTMLDivElement, FormActionsProps>(
  ({ actions, onCancel, onSubmit, sx, submitButtonProps }, ref) => {
    const navigate = useNavigate();
    const { isMutating } = useResumeEditorStore();
    const onCancelHandler = onCancel || (() => navigate(-1));

    return (
      <Grid2
        sx={{
          justifyContent: 'flex-end',
          gap: '12px',
          ...sx,
        }}
        ref={ref}
        container
      >
        {isNotNil(actions) ? (
          <Grid2 sx={{ width: 'inherit' }}>{actions}</Grid2>
        ) : (
          <>
            <Grid2>
              <Button
                variant="outlined"
                onClick={onCancelHandler}
                sx={{ minWidth: '80px', border: '1.5px solid', color: 'primary.main' }}
              >
                Cancel
              </Button>
            </Grid2>
            <Grid2>
              <Button
                sx={{ minWidth: '80px' }}
                type="submit"
                onClick={onSubmit}
                loading={isMutating || submitButtonProps?.loading}
                {...submitButtonProps}
              >
                Save
              </Button>
            </Grid2>
          </>
        )}
      </Grid2>
    );
  },
);

export default FormActions;
