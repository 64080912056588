import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import FormActions from '../../../components/Form/FormActions';
import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import SelectJobDescription from '../../../components/SelectJobDescription';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import Button from '../../../components/common/Button';
import Stepper from '../../../components/common/Stepper';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import { JobDescriptionTypes, uploadJobDescription } from '../../../services/jobDescriptions';
import { ResumesKeys, createResume } from '../../../services/resumes';
import { useAppBarStore } from '../../../stores/AppBarStore';
import { isNilOrEmpty, isNotNilOrEmpty } from '../../../utils';
import { SelectJDSection } from '../../Create/components/SelectJobDescriptionDeprecated';
import type { CareerStageKey } from '../constants';
import ChooseCareerStage from './ChooseCareerStage';

const SampleResumeBuilder = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { isMobile } = useResponsiveDevice();
  const { setAppBar, setInitialState } = useAppBarStore();
  const [selectedCareerStage, setSelectedCareerStage] = useState<CareerStageKey>();
  const [jobDescriptionDetail, setJobDescriptionDetail] = useState({
    activeSection: SelectJDSection.SELECT_JD,
    jobDescriptionId: '',
    jobDescriptionText: '',
  });
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(1);
  const [resumeId, setResumeId] = useState<string>(location?.state?.resumeId || '');
  const [isShowingProgress, setIsShowingProgress] = useState(
    isNotNilOrEmpty(location?.state?.resumeId),
  );

  const queryClient = useQueryClient();

  const BreadCrumbLinks = isMobile
    ? [
        {
          label: 'Back',
          href: RoutePaths.RESUMES,
        },
      ]
    : [
        {
          label: 'Resumes',
          href: RoutePaths.RESUMES,
        },
        {
          label: 'Create Sample',
          href: RoutePaths.RESUME_SAMPLE,
        },
      ];

  useEffect(() => {
    if (isMobile) {
      setAppBar('Resumes', null);
    }

    return () => {
      setInitialState(isMobile);
    };
  }, [isMobile, setAppBar, setInitialState]);

  const handleOnUpdate = (data: { jdId?: string; jDText?: string }) => {
    if (data.jdId) {
      setJobDescriptionDetail((prevJobDescription) => ({
        ...prevJobDescription,
        activeSection: SelectJDSection.SELECT_JD,
        jobDescriptionId: data.jdId || '',
      }));
    }

    if (data.jDText) {
      setJobDescriptionDetail((prevJobDescription) => ({
        ...prevJobDescription,
        activeSection: SelectJDSection.IMPORT_JD,
        jobDescriptionText: data.jDText || '',
      }));
    }
  };

  const { isLoading: isCreatingResume, mutate: createSampleResume } = useMutation({
    retry: 3,
    mutationFn: createResume,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setResumeId(data._id);
      setIsShowingProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create a blank Resume. Please try again.',
      );
    },
  });

  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jobDescriptionData) => {
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated job xrays are fetched
      createSampleResume({
        careerStage: selectedCareerStage,
        jobDescriptionId: jobDescriptionData._id,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  const onClickNext = () => {
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
    } else if (jobDescriptionDetail.activeSection === SelectJDSection.SELECT_JD) {
      createSampleResume({
        careerStage: selectedCareerStage,
        jobDescriptionId: jobDescriptionDetail.jobDescriptionId,
      });
    }
    if (jobDescriptionDetail.activeSection === SelectJDSection.IMPORT_JD) {
      uploadJD(jobDescriptionDetail.jobDescriptionText);
    }
  };

  const onBackClick = () => {
    setActiveStep(activeStep - 1);
  };

  const shouldDisableNextButton = useMemo(() => {
    if (activeStep === 1) {
      return isNilOrEmpty(selectedCareerStage);
    }
    return (
      (jobDescriptionDetail.activeSection === SelectJDSection.SELECT_JD &&
        isNilOrEmpty(jobDescriptionDetail.jobDescriptionId)) ||
      (jobDescriptionDetail.activeSection === SelectJDSection.IMPORT_JD &&
        isNilOrEmpty(jobDescriptionDetail.jobDescriptionText)) ||
      isJdUploading ||
      isCreatingResume
    );
  }, [activeStep, isCreatingResume, isJdUploading, selectedCareerStage, jobDescriptionDetail]);

  return (
    // create resume page
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '120rem',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          sm: `${theme.spacing(3)}`,
        },
        boxSizing: 'border-box',
        gap: { xs: 1.5, sm: 4 },
      }}
    >
      {!isShowingProgress ? (
        <>
          <Stack sx={{ gap: 0.5 }}>
            <BreadCrumbs links={BreadCrumbLinks} />
            {!isMobile && (
              <Typography variant="body2" sx={{ paddingLeft: 3.75 }}>
                Create a sample resume for the job.
              </Typography>
            )}
          </Stack>
          <Stack sx={{ paddingX: { xs: 0, sm: 2.5 }, gap: 5, height: '100%' }}>
            <Stepper totalSteps={2} activeStep={activeStep} />
            <Stack sx={{ gap: 4, height: '100%', justifyContent: 'space-between' }}>
              {activeStep === 1 ? (
                <ChooseCareerStage
                  onChange={setSelectedCareerStage}
                  defaultValue={selectedCareerStage}
                />
              ) : (
                <SelectJobDescription onUpdate={handleOnUpdate} value={jobDescriptionDetail} />
              )}
              <FormActions
                sx={{ width: '100%' }}
                actions={
                  <Stack
                    sx={{
                      gap: 1.5,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={activeStep === 1 ? () => navigate(-1) : onBackClick}
                      sx={{
                        width: { xs: '30%', sm: 'fit-content' },
                        padding: {
                          xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                          sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                        },
                      }}
                    >
                      {activeStep === 1 ? 'Cancel' : 'Back'}
                    </Button>
                    <Button
                      onClick={onClickNext}
                      sx={{
                        width: { xs: '70%', sm: 'fit-content' },
                        padding: {
                          xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                          sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                        },
                      }}
                      disabled={shouldDisableNextButton}
                    >
                      Add
                    </Button>
                  </Stack>
                }
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <ProgressManager id={resumeId || ''} progressScreenKey={ProgressScreenType.SampleResume} />
      )}
    </Stack>
  );
};

export default SampleResumeBuilder;
