import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { PublicationInfo } from '../../../../../interface';
import { isNotNilOrEmpty } from '../../../../../utils';
import { formatDate } from '../../../../../utils/dateUtils';
import Button from '../../../../common/Button';
import Icon from '../../../../common/Icon';
import Modal from '../../../../common/Modal/Modal';
import ModalActionsDeprecated from '../../../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../../common/Modal/ModalDetail';

interface PublicationDetailProps {
  key: string;
  publication: PublicationInfo;
  isEditable?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const PublicationDetail = ({
  key,
  publication,
  isEditable,
  onEdit,
  onDelete,
}: PublicationDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditPublication = () => {
    onEdit(publication._id);
  };

  const handleDeletePublication = async () => {
    setOpen(false);
    onDelete(publication._id);
  };

  return (
    <Box
      key={key}
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* TODO: Explore modal manager */}
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Publication?"
            description={`Are you sure you want to delete ${publication.title} Publication? This action cannot be undone.`}
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeletePublication}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Stack gap={1}>
            {isNotNilOrEmpty(publication.title) && (
              <Typography variant="label1">{publication.title}</Typography>
            )}
            {isNotNilOrEmpty(publication.publisher) && (
              <Typography variant="body2">{publication.publisher}</Typography>
            )}
            <Typography variant="body2" color="text.primaryGray">
              {formatDate(
                publication.issued_month,
                publication.issued_year,
                null, // endMonth
                null, // endYear
                false, // isPresenr
                true, // Disable duration caluculation
              )}
            </Typography>
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditPublication}>
              <Icon className="fi fi-rr-edit" color="text.secondary" />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <Icon className="fi fi-rr-trash" color="error.main" />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PublicationDetail;
