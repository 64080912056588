import { useNavigate } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import RoutePaths from '../../../routes/RoutePaths';
import { Storage, StorageKeys } from '../../../services/storage';
import Button from '../../common/Button';

interface ProgressActionsProps {
  message?: string; // Making message optional with a default value
  hideInfoMessage?: boolean;
  continueTo?: string;
}

const ProgressActions = ({
  message = 'We will notify you once the Job Match Score is ready to view',
  hideInfoMessage,
  continueTo = RoutePaths.JOBMATCHES,
}: ProgressActionsProps) => {
  const navigate = useNavigate();

  const onContinueToHome = () => {
    Storage.set(StorageKeys.CONTINUE_TO_JOQBQUEST_URL, continueTo);
    navigate(continueTo);
  };
  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Button
        onClick={onContinueToHome}
        sx={{
          width: { xs: '100%', sm: 'fit-content' },
          padding: { xs: '16px 12px', sm: '8px 12px' },
        }}
      >
        Continue to JobQuest
      </Button>
      {!hideInfoMessage && (
        <Typography variant="body3" color="text.primaryGray">
          {message}
        </Typography>
      )}
    </Stack>
  );
};

export default ProgressActions;
