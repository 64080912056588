import { LinearProgress, Stack, Typography } from '@mui/material';

const ScanProgress = ({ progress }: { progress: number }) => (
  // progress in text and Linear progress bar below it
  <Stack sx={{ width: '70%' }} spacing={1}>
    <Typography variant="body3" fontWeight={600}>
      {progress.toFixed(0)}%
    </Typography>
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        borderRadius: '5px',
      }}
    />
  </Stack>
);

export default ScanProgress;
