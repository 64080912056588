import type { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Storage } from '../services/storage';
import RoutePaths from './RoutePaths';

interface RouteGuardProps {
  redirectPath?: string;
  children?: ReactElement;
}

const RouteGuard = ({
  redirectPath = RoutePaths.SIGN_IN,
  children,
}: RouteGuardProps): React.ReactElement => {
  // TODO: debug why isn't isLoggedIn available here on page refresh
  // one possible solution is to move auth context to top of app.tsx render tree and remove navigate logic from auth context
  // const { isLoggedIn } = useAuth();
  const isLoggedIn = Storage.getCurrentUser();

  const location = useLocation();

  const authPaths: string[] = [RoutePaths.SIGN_IN, RoutePaths.SIGN_UP, RoutePaths.VERIFICATION];

  if (!isLoggedIn && !authPaths.includes(location.pathname)) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isLoggedIn && authPaths.includes(location.pathname)) {
    return <Navigate to={RoutePaths.HOME} replace />;
  }

  return children || <Outlet />;
};

export default RouteGuard;
