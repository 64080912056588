import { Box, Chip, Divider, LinearProgress, Stack, useTheme } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import Icon from './Icon';

interface StepperProps {
  totalSteps: number;
  activeStep: number;
}

// TODO: should we add completed steps props?

const Stepper = ({ totalSteps, activeStep }: StepperProps) => {
  const theme = useTheme();
  const activeIndex = activeStep - 1;
  const { isMobile } = useResponsiveDevice();

  return (
    <Box>
      <Stack
        sx={{
          marginBottom: 1.5,
          flexDirection: 'row',
          gap: 1,
          ...(isMobile && { marginY: 1 }),
        }}
      >
        {Array.from({ length: totalSteps }, (_, index) =>
          isMobile ? (
            <LinearProgress
              key={index}
              color="primary"
              variant="determinate"
              value={index < activeIndex ? 100 : 0}
              sx={{
                width: '100%',
                height: '0.5rem',
                borderRadius: 1,
                backgroundColor: index > activeIndex ? 'border.light' : 'primary',
              }}
            />
          ) : (
            <Chip
              key={index}
              label={`Step ${index + 1}`}
              icon={
                <Icon
                  className={activeIndex > index ? 'fi fi-br-check' : ''}
                  style={{ fontSize: theme.spacing(2), color: theme.palette.primary.main }}
                />
              }
              sx={{
                padding: 1,
                border: 'none',
                ...(activeIndex === index
                  ? {
                      backgroundColor: 'primary.light',
                      color: 'primary.main',
                      fontWeight: 600,
                    }
                  : {
                      backgroundColor: 'transparent',
                      color: 'text.primary',
                      fontWeight: 400,
                    }),
              }}
              variant={activeIndex === index ? 'filled' : 'outlined'}
            />
          ),
        )}
      </Stack>
      {!isMobile && <Divider />}
    </Box>
  );
};

export default Stepper;
