import { isNotNil } from 'ramda';
import { useState } from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import CheckIcon from '../../../../assets/icons/fi-br-check.svg';
import CrossIcon from '../../../../assets/icons/fi-br-cross.svg';
import { type ResumeTemplateInfo, TemplateDescriptionType } from '../../../../interface';
import Card from '../../../common/Card';
import Icon from '../../../common/Icon';
import Rating from '../../../common/Rating';

interface SelectLayoutProps {
  templates?: ResumeTemplateInfo[];
  selectedTemplateKey?: string;
  onChange: (template: ResumeTemplateInfo) => void;
}

const SelectLayout = ({
  templates,
  selectedTemplateKey = templates?.[0]._id || '',
  onChange,
}: SelectLayoutProps) => {
  const selectedTemplateData =
    templates?.find((template) => template._id === selectedTemplateKey) || templates?.[0];

  const theme = useTheme();
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const getThumbnailfromUrl = (url: string) => require(`../../../../assets${url}`);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return isNotNil(templates) ? (
    <Card
      sx={{
        borderRadius: '8px',
        padding: 2.5,
        boxSizing: 'border-box',
      }}
      cardContentProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5,
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      }}
    >
      <Typography variant="h3">Select Layout</Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1,
          overflowX: { xs: 'auto', md: 'hidden' },
          flexWrap: { md: 'wrap' },
        }}
      >
        {/* Box container to avoid brightness filter on borders */}
        {templates?.map((template) => (
          <Box
            key={template.key}
            onClick={() => onChange(template)}
            sx={{
              border: selectedTemplateKey === template._id ? '3px solid' : '1px solid',
              borderColor:
                selectedTemplateKey === template._id ? theme.palette.success.main : '#E9E9E9', // : theme.palette.border.light,
              borderRadius: '8px',
              height: '100%',
              cursor: 'pointer',
              position: 'relative',
              minWidth: '100px',
              width: { xs: '50%', md: `calc(25% - 9px)` },
            }}
          >
            <img
              src={getThumbnailfromUrl(template.thumbnail_url)}
              alt={template.name}
              style={{
                filter: selectedTemplateKey === template._id ? 'brightness(0.7)' : 'none', // Apply filter to dim the image when selected
                width: '100%',
                height: 'inherit',
                display: isImageLoaded ? 'block' : 'none',
              }}
              onLoad={() => setIsImageLoaded(true)}
            />
            {!isImageLoaded && (
              <Skeleton variant="rectangular" sx={{ height: '180px', width: '100%' }} />
            )}
            {template._id === selectedTemplateKey && (
              <Icon
                className="fi fi-br-check"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: theme.palette.common.white,
                }}
              />
            )}
          </Box>
        ))}
      </Stack>
      <Stack>
        <Typography variant="label2">{selectedTemplateData?.name}</Typography>
        <Typography
          variant="body3"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: 1,
            paddingTop: 0.5,
          }}
        >
          ATS Score <Rating value={selectedTemplateData?.ats_score || 0} />
        </Typography>
        <List sx={{ paddingBottom: 0 }}>
          {selectedTemplateData?.descriptions.map((description) => (
            <ListItem key={description.text} sx={{ padding: '8px 0px', alignItems: 'flex-start' }}>
              <ListItemIcon sx={{ minWidth: '24px', marginTop: '1px' }}>
                <img
                  src={description.type === TemplateDescriptionType.PRO ? CheckIcon : CrossIcon}
                  alt={description.type}
                  style={{ width: '16px', height: '16px' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={description.text}
                sx={{ margin: 0 }}
                primaryTypographyProps={{ variant: 'body3', sx: { wordBreak: 'break-word' } }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Card>
  ) : null;
};

export default SelectLayout;
