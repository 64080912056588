import { useEffect } from 'react';
import type { FieldValues, Validate } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';

import Icon from '../../../../components/common/Icon';
import MobileTooltip from '../../../../components/common/MobileTooltip';
import Tooltip from '../../../../components/common/Tooltip';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';

interface CareerStageSelectProps {
  careerStages: string[];
  defaultCareerStage?: string;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationsFn?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>>;
  onChange: ((careerStage: string | number | null) => void) | undefined;
}

const CareerStageSelect = ({
  careerStages,
  defaultCareerStage,
  loading,
  validationsFn,
  onChange,
}: CareerStageSelectProps) => {
  const { register, setValue } = useForm();
  const { isMobile, isTablet } = useResponsiveDevice();
  const theme = useTheme();

  useEffect(() => {
    if (defaultCareerStage) {
      setValue('career_stage', defaultCareerStage);
    }
  }, [defaultCareerStage, setValue]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const IconComponent = () => (
    <Icon
      className="fi fi-rr-angle-small-down"
      fontSize="16px"
      color={theme.palette.text.tertiary}
    />
  );

  return (
    <Stack gap={1}>
      <Typography variant="h3">
        <span style={{ paddingRight: '8px' }}>Choose Career Stage</span>
        {isMobile || isTablet ? (
          <MobileTooltip content="Choosing your career stage automatically customizes resume section" />
        ) : (
          <Tooltip content="Choosing your career stage automatically customizes resume section" />
        )}
      </Typography>
      {/* TODO: extract this to a component */}
      <Select
        {...register('career_stage', {
          validate: validationsFn,
        })}
        placeholder="Career Stage"
        defaultValue={defaultCareerStage}
        onChange={(e) => onChange && onChange(e.target.value)}
        disabled={loading}
        fullWidth
        IconComponent={IconComponent}
        sx={{
          padding: '4px 10px',
          '> .MuiOutlinedInput-notchedOutline ': { borderColor: '#E9E9E9', borderRadius: '8px' },
        }}
      >
        {careerStages.map((careerStage) => (
          <MenuItem
            key={careerStage}
            value={careerStage}
            sx={{
              padding: 1.1,
            }}
          >
            <Typography variant="body3">{careerStage}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default CareerStageSelect;
