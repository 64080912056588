import type React from 'react';
import uuid from 'react-uuid';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import type {
  ListItemIconProps,
  ListItemProps,
  ListItemTextProps,
  ListProps as MuiListProps,
} from '@mui/material';
import { ListItem, ListItemIcon, ListItemText, List as MuiList } from '@mui/material';

import Icon from '../common/Icon';

interface ListProps extends MuiListProps {
  dataList: string[];
  bulletIcon?: string | React.ReactNode; // Define the icon prop as a React node
  listItemProps?: ListItemProps;
  listItemIconProps?: ListItemIconProps;
  listItemTextProps?: ListItemTextProps;
}

const List: React.FC<ListProps> = ({
  dataList,
  children,
  bulletIcon = <FiberManualRecordIcon sx={{ fontSize: '8px' }} />,
  listItemProps,
  listItemIconProps,
  listItemTextProps,
  ...restProps
}) => (
  <MuiList {...restProps}>
    {dataList?.map((item) => (
      <ListItem key={uuid()} alignItems="flex-start" {...listItemProps}>
        <ListItemIcon
          {...listItemIconProps}
          sx={{
            minWidth: '16px',
            color: 'text.primary',
            ...listItemIconProps?.sx,
          }}
        >
          {typeof bulletIcon === 'string' ? (
            <Icon className={bulletIcon} fontSize="12px" />
          ) : (
            bulletIcon
          )}
        </ListItemIcon>
        <ListItemText {...listItemTextProps} sx={{ margin: 0, ...listItemTextProps?.sx }}>
          {item}
        </ListItemText>
      </ListItem>
    ))}
    {children && (
      <ListItem alignItems="flex-start" {...listItemProps}>
        <ListItemIcon
          {...listItemIconProps}
          sx={{
            minWidth: '20px',
            color: 'text.primary',
            ...listItemIconProps?.sx,
          }}
        >
          {typeof bulletIcon === 'string' ? (
            <Icon className={bulletIcon} fontSize="12px" />
          ) : (
            bulletIcon
          )}
        </ListItemIcon>
        <ListItemText {...listItemTextProps} sx={{ margin: 0, ...listItemTextProps?.sx }}>
          {children}
        </ListItemText>
      </ListItem>
    )}
  </MuiList>
);

export default List;
