import { Box, Typography } from '@mui/material';

import SectionBordered from '../../common/Section/SectionBordered';

interface ResumeSectionContentProps {
  content: string | JSX.Element | JSX.Element[];
}

const ResumeSectionContent = ({ content }: ResumeSectionContentProps) => {
  const isStringContent = typeof content === 'string';

  return (
    <Box className="resume-section-content">
      {isStringContent ? (
        <SectionBordered>
          <Typography variant="body2">{content}</Typography>
        </SectionBordered>
      ) : (
        <>{content}</>
      )}
    </Box>
  );
};

export default ResumeSectionContent;
