import { useLayoutEffect } from 'react';

import { IconButton, AppBar as MuiAppBar, Stack, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as MenuOpenIcon } from '../../../assets/icons/fi-rr-menu-open.svg';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { useAppBarStore } from '../../../stores/AppBarStore';
import { useSideBarStore } from '../../../stores/SideBarStore';
import Icon from '../../common/Icon';
import { drawerWidth } from '../../common/constants';

const AppBarCustom = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    isMobile && {
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const AppBar = () => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();
  const { content: appBarContent, controls: appBarControls, setInitialState } = useAppBarStore();
  const { isOpen, toggle: toggleSideBarDrawer, shouldHideSideBar } = useSideBarStore();

  // run only when both content and controls are not provided to set initial state
  useLayoutEffect(() => {
    if (!appBarContent && !appBarControls) {
      setInitialState(isMobile);
    }
  }, [appBarContent, appBarControls, isMobile, setInitialState]);

  return (
    <AppBarCustom
      position="fixed"
      variant="outlined"
      sx={{
        backgroundColor: '#fff',
      }}
      open={isOpen}
      isMobile={isMobile}
    >
      <Toolbar>
        {((isMobile && !isOpen) || !isMobile) && (
          <Stack
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleSideBarDrawer}
              edge="start"
              sx={{
                padding: 1,
                ...(shouldHideSideBar && { display: 'none' }),
              }}
            >
              {isOpen ? (
                <MenuOpenIcon />
              ) : (
                <Icon
                  className="fi fi-rr-menu-burger"
                  fontSize="20px"
                  color={theme.palette.text.secondary}
                />
              )}
            </IconButton>
            {typeof appBarContent === 'string' ? (
              <Typography
                sx={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: '25px' }}
              >
                {appBarContent}
              </Typography>
            ) : (
              appBarContent
            )}
          </Stack>
        )}
        <Stack sx={{ flexGrow: 1, alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {appBarControls}
        </Stack>
      </Toolbar>
    </AppBarCustom>
  );
};

export default AppBar;
