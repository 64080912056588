import { Grid, Stack, Typography } from '@mui/material';

import BreadCrumbs from '../../../components/common/BreadCrumbs';
import Button from '../../../components/common/Button';
import Chip from '../../../components/common/Chip';
import Icon from '../../../components/common/Icon';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import type { ResumeData } from '../../../interface';

interface ResumePageHeaderProps {
  resume: ResumeData | undefined;
  BreadCrumbLinks: Array<{ label: string; href: string }>;
  shouldShowAiCustomize: boolean;
  onAiCustomizeClick: () => void;
  onDownloadFile: () => void;
}

const ResumePageHeader = ({
  resume,
  BreadCrumbLinks,
  shouldShowAiCustomize,
  onAiCustomizeClick,
  onDownloadFile,
}: ResumePageHeaderProps) => {
  const { isMobile } = useResponsiveDevice();

  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        paddingRight: { xs: 0, sm: 3.5 },
      }}
    >
      <Grid xs={12} sx={{ marginBottom: { xs: 1.5, sm: 4 } }}>
        <Stack gap={0.5}>
          <BreadCrumbs links={BreadCrumbLinks} />
          {/* Hide for mobile devices */}
          {/* Resume File details */}
          {!isMobile && (
            <Stack
              sx={{
                paddingLeft: 3.75,
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {resume?.is_ai_customized && <Chip label="AI Customized" />}
              {resume?.is_ai_generated && <Chip label="AI Sample" />}
              <Typography variant="body2">
                {resume?.name}
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ paddingLeft: 1, color: 'text.secondary' }}
                >
                  {resume?.filename}
                </Typography>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
      {!isMobile && (
        <Grid>
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'flex-end', md: 'center' },
              gap: 1,
            }}
          >
            {shouldShowAiCustomize && (
              <Button
                variant="outlined"
                sx={{ minWidth: 'max-content' }}
                onClick={onAiCustomizeClick}
              >
                <Icon className="fi fi-rr-magic-wand" fontSize="20px" />
                AI Customize
              </Button>
            )}
            <Button onClick={onDownloadFile}>
              <Icon className="fi fi-rr-file-download" fontSize="20px" />
              Download
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default ResumePageHeader;
