import http from './http';

export const deleteUserProfile = async () => {
  try {
    const res = await http.delete('/users/delete-account/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
