import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';

import type { SxProps } from '@mui/material';
import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import JobDescriptionExample from '../SelectJobDescription/components/JobDescriptionExample';
import Button from '../common/Button';
import Modal from '../common/Modal/Modal';
import ModalActionsDeprecated from '../common/Modal/ModalActions_deprecated';
import ModalDetail from '../common/Modal/ModalDetail';
import TextField from '../common/TextField';
import TextWrappedLinkButton from '../common/TextWrappedLinkButton';

interface JobDescriptionInputProps {
  // Define a prop to accept the callback function
  onUpdateDescription: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}

const JobDescriptionInput = ({ onUpdateDescription, sx }: JobDescriptionInputProps) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Stack sx={sx}>
      <InputLabel
        htmlFor="description"
        sx={{
          color: 'error.main',
          '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
        }}
        required
      >
        <Typography variant="label2" color="common.black">
          Job Description
        </Typography>
      </InputLabel>
      <FormHelperText sx={{ marginTop: '0px', marginBottom: '8px' }}>
        <TextWrappedLinkButton
          variant="body3"
          text={{
            startingText:
              'Copy and paste the entire job description from the job posting, including the company name and job title. You don’t have to clean up the text.',
            linkText: 'Example',
            endingText: '',
          }}
          onClick={() => setShowModal(true)}
        />
      </FormHelperText>
      <Modal open={showModal} contentSx={{ width: '50vw' }}>
        <>
          <ModalDetail
            title="Example Job Posting from LinkedIn"
            description={<JobDescriptionExample />}
          />
          <ModalActionsDeprecated
            actions={
              <Button color="primary" onClick={() => setShowModal(false)}>
                <Typography variant="label2">Close</Typography>
              </Button>
            }
          />
        </>
      </Modal>
      <TextField
        id="description"
        type="textarea"
        {...register('description', {
          required: 'Description is required',
        })}
        multiline
        minRows={8}
        maxRows={20}
        error={!!errors.description}
        helperText={errors.description?.message?.toString()}
        onChange={onUpdateDescription}
        fullWidth
      />
    </Stack>
  );
};

export default JobDescriptionInput;
