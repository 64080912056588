import { useEffect } from 'react';

const useWindowResize = (cb = () => {}) => {
  // TODO: need to throttle this
  useEffect(() => {
    cb();

    window.addEventListener('resize', cb);

    return () => {
      window.removeEventListener('resize', cb);
    };
  }, [cb]);
};

export default useWindowResize;
