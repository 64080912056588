import { Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';

interface DeleteAccountProps {
  onDelete: () => void;
}

const DeleteAccount = ({ onDelete }: DeleteAccountProps) => (
  <Stack gap="24px">
    <Stack>
      <Typography variant="label1">Delete Account</Typography>
      <Typography variant="body2">
        Permanently delete your account and all data, including resumes.
      </Typography>
    </Stack>
    <Button color="error" sx={{ maxWidth: 'fit-content' }} size="small" onClick={onDelete}>
      Delete Account
    </Button>
  </Stack>
);

export default DeleteAccount;
