import styles from '../styles';

interface LinkedInProps {
  profileURL: string;
}

const LinkedIn = ({ profileURL }: LinkedInProps) => {
  const linkedIn = profileURL.split('/');
  const username = profileURL.endsWith('/')
    ? linkedIn[linkedIn.length - 2]
    : linkedIn[linkedIn.length - 1];
  return (
    <a
      className="linkedin"
      href={profileURL}
      style={styles.colorPrimary}
      target="_blank"
      rel="noreferrer"
    >
      {username}
    </a>
  );
};

export default LinkedIn;
