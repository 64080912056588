import { Fragment } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Divider, Stack, Typography } from '@mui/material';

import { MouseSensor, TouchSensor } from '../../../../components/DragnDrop/Sensors';
import SortableItem from '../../../../components/DragnDrop/SortableItem';
import Switch from '../../../../components/common/Switch';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import { ResumeKey, type SectionOrder } from '../../../../interface';

interface ReorderSectionsProps {
  sectionsOrder: SectionOrder[] | undefined;
  DisabledSectionsReorderKeys: string[];
  onSectionsReorder?: (sectionsOrder: SectionOrder[]) => void;
}

const ReorderSections = ({
  sectionsOrder = [],
  DisabledSectionsReorderKeys = [],
  onSectionsReorder,
}: ReorderSectionsProps) => {
  const { isMobile, isTablet } = useResponsiveDevice();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && !DisabledSectionsReorderKeys.includes(String(over.id)) && active.id !== over.id) {
      const oldIndex = sectionsOrder.findIndex((section) => section.section_name === active.id);
      const newIndex = sectionsOrder.findIndex((section) => section.section_name === over.id);

      const newSectionsOrder = arrayMove(sectionsOrder, oldIndex, newIndex);
      if (onSectionsReorder) {
        onSectionsReorder(newSectionsOrder);
      }
    }
  };

  const handleSwitchMouseDown = (section: SectionOrder) => {
    // change enabled property in localSectionsOrder for the given section
    const newSectionsOrder = sectionsOrder.map((s) => {
      if (s.section_name === section.section_name) {
        return {
          ...s,
          enabled: !s.enabled,
        };
      }
      return s;
    });
    if (onSectionsReorder) {
      onSectionsReorder(newSectionsOrder);
    }
  };

  const dndSensor = useSensors(useSensor(isMobile || isTablet ? TouchSensor : MouseSensor));

  return (
    <>
      <Typography variant="label1">Sections</Typography>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={dndSensor}
      >
        <SortableContext
          items={sectionsOrder.map((section) => section.section_name)}
          strategy={verticalListSortingStrategy}
        >
          {sectionsOrder.map((section) => (
            <Fragment key={section.section_name}>
              <SortableItem
                key={section.section_name}
                id={section.section_name}
                disabled={DisabledSectionsReorderKeys.includes(section.section_name)}
                showBorders
                sx={{
                  justifyContent: 'center',
                  backgroundColor: 'common.white',
                }}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2">{section.name}</Typography>
                  <Switch
                    checked={section.enabled}
                    onMouseDown={() => {
                      handleSwitchMouseDown(section);
                    }}
                  />
                </Stack>
              </SortableItem>
              {section.section_name === ResumeKey.SUMMARY && (
                <Divider sx={{ marginY: 2, width: '99%' }} />
              )}
            </Fragment>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};

export default ReorderSections;
