import { Box, type BoxProps } from '@mui/material';

const Grid = ({ children, sx, ...restProps }: BoxProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: 2,
      ...sx,
    }}
    {...restProps}
  >
    {children}
  </Box>
);

export default Grid;
