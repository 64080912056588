import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import Grid from '../../../components/common/GridCustom/Grid';
import GridItem from '../../../components/common/GridCustom/GridItem';
import { useSnackbar } from '../../../contexts/snackbar';
import RoutePaths from '../../../routes/RoutePaths';
import { createNewJobMatchScore } from '../../../services/jobMatches';
import type { JobMatchScoreData } from '../interface';
import JobMatchScoreReport from './components/JobMatchScoreReport';
import Recommendations from './components/Recommendations';
import ScoreCard from './components/ScoreCard';
import TipsAndResources from './components/TipsAndResources';

interface JobMatchScoreProps {
  jobMatchScore: JobMatchScoreData | undefined;
}

const ResumeScore = ({ jobMatchScore }: JobMatchScoreProps) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const jobMatchScoreReportData = useMemo(
    () => ({
      job_title_match: jobMatchScore?.job_title_match,
      years_of_experience: jobMatchScore?.years_of_experience_match,
      skills: jobMatchScore?.skill_match,
      responsibilities: jobMatchScore?.responsibility_match,
    }),
    [jobMatchScore],
  );

  const resourceLinks = useMemo(
    () => [
      {
        label: 'What Is Job Match Score?',
        href: 'https://jobquest.ai/faq/#How-to-use-job-match-score?',
      },
      {
        label: 'My Job Match Score is too low, what should I do?',
        href: 'https://jobquest.ai/faq/#My-Job-Match-Score-is-too-low,-what-should-I-do?',
      },
      {
        label: 'What is AI Customize and when to use it?',
        href: 'https://jobquest.ai/faq/#When-to-use-AI-Customize?',
      },
      {
        label: 'How to best use JobQuest to maximize its benefits?',
        href: 'https://jobquest.ai/faq/#How-to-best-use-JobQuest-to-maximize-its-benefit?',
      },
    ],
    [],
  );

  const { mutate: createAiCustomizedResumeScore, isLoading: isAiCustomizeResumeLoading } =
    useMutation({
      mutationFn: createNewJobMatchScore,
      onSuccess: (res) => {
        navigate(RoutePaths.CREATE_JOB_MATCH, {
          state: { matchScoreId: res?.data.match_score_id },
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        showSnackbar('error', error.response?.data?.errors || 'Something went wrong');
      },
    });

  const handleCreateAiCustomizedResumeScore = useCallback(async () => {
    if (jobMatchScore?.job_description?.id && jobMatchScore?.resume?.id) {
      const jdResumeId = {
        job_description_id: jobMatchScore?.job_description?.id,
        resume_id: jobMatchScore?.resume?.id,
        should_ai_customize_resume: true,
      };
      createAiCustomizedResumeScore(jdResumeId);
    }
  }, [
    createAiCustomizedResumeScore,
    jobMatchScore?.job_description?.id,
    jobMatchScore?.resume?.id,
  ]);

  return (
    <>
      {jobMatchScore && (
        <Grid>
          <GridItem
            sx={{
              gridArea: {
                xs: '1 / 1 / span 1 / span 4',
                md: '1 / 1 / span 1 / span 3',
              },
            }}
          >
            <Recommendations
              isAiCustomized={
                jobMatchScore?.resume?.is_ai_customized || jobMatchScore?.resume?.is_ai_generated
              }
              recommendations={jobMatchScore?.overview}
              score={jobMatchScore?.overall_score}
              onAiCustomizeResume={handleCreateAiCustomizedResumeScore}
              isAiCustomizeResumeLoading={isAiCustomizeResumeLoading}
            />
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '2 / 1 / span 1 / span 4',
                md: '1 / 4 / span 2 / span 1',
              },
            }}
          >
            <ScoreCard
              score={jobMatchScore?.overall_score}
              result={jobMatchScore?.overall_result}
              cardProps={{ rootSx: { height: '100%' }, sx: { height: '100%' } }}
            />
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '3 / 1 / span 1 / span 4',
                md: '2 / 1 / span 8 / span 3',
              },
            }}
          >
            <JobMatchScoreReport data={jobMatchScoreReportData} />
          </GridItem>
          <GridItem
            sx={{
              gridArea: {
                xs: '4 / 1 / span 1 / span 4',
                md: '3 / 4 / span 1 / span 1',
              },
            }}
          >
            <TipsAndResources links={resourceLinks} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};
export default ResumeScore;
