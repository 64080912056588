import type { ReactNode } from 'react';
import { create } from 'zustand';

import ProfileMenu from '../components/Layout/components/ProfileMenu';
import Logo from '../components/common/Logo';

type AppBarState = {
  content: ReactNode;
  controls: ReactNode;
  setAppBar: (newContent: ReactNode, newControls: ReactNode) => void;
  setInitialState: (isMobile: boolean) => void;
  resetToDesktopAppBar: () => void;
  resetToMobileAppBar: () => void;
};

export const useAppBarStore = create<AppBarState>((set) => ({
  content: null,
  controls: null,
  resetToDesktopAppBar: () => set(() => ({ content: <Logo />, controls: <ProfileMenu /> })),
  resetToMobileAppBar: () => set(() => ({ content: <Logo />, controls: null })),
  setInitialState: (isMobile: boolean) =>
    set(() => ({ content: <Logo />, controls: isMobile ? null : <ProfileMenu /> })),
  setAppBar: (newContent, newControls) =>
    set(() => ({ content: newContent, controls: newControls })),
}));
