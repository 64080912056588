import { isNil, isNotNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import type { EducationInfo } from '../../../../interface';
import { ResumesKeys, deleteResumeEducation } from '../../../../services/resumes';
import Icon from '../../../common/Icon';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';
import EducationDetail from './components/EducationDetail';

interface DisplayEducationProps {
  education?: EducationInfo[];
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

// TODO: Rename this component (Education Section)
const DisplayEducation = ({
  education = [],
  editable,
  resumeId,
  hideEmptySection,
}: DisplayEducationProps) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const shouldhideEmptySection =
    hideEmptySection && (isNil(education) || (isNotNil(education) && education.length === 0));

  const resumeEditorState: ResumeEditorState = {
    action: '',
    resumeId: resumeId || '',
    elementId: '',
    elementType: 'education',
  };

  const { mutate: deleteEducation } = useMutation({
    mutationFn: deleteResumeEducation,
    onSuccess: () => {
      showSnackbar('success', 'Education deleted');
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries([ResumesKeys.RESUMES, resumeEditorState.resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const handleAddEducation = () => {
    resumeEditorState.action = 'add';
    // TODO: Navigate on /resumes/add/education
    navigate('/resumes/edit/education', {
      state: { resumeEditorState },
    });
  };

  const handleEditEducation = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    navigate('/resumes/edit/education', {
      state: { resumeEditorState },
    });
  };

  const handleDeleteEducation = useCallback(
    (id: string) => {
      deleteEducation(id);
    },
    [deleteEducation],
  );

  const handlerReorderEducation = () => {
    resumeEditorState.action = 'reorder';
    // TODO: use nested routes, use RoutePaths
    navigate('/resumes/reorder/educations', {
      state: { id: resumeId },
    });
  };

  const shouldShowReorderButton = useMemo(() => {
    if (isNotNil(education) && education?.length > 1) {
      return true;
    }
    return false;
  }, [education]);

  return shouldhideEmptySection ? null : (
    // TODO: Update grid component with grid2
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid item>
          <Typography variant={isMobile ? 'h3' : 'h2'}>Education</Typography>
        </Grid>
        {editable && (
          <Grid item>
            {shouldShowReorderButton && (
              <ResumeEditorButton onClick={handlerReorderEducation} startIcon="fi fi-rr-apps-sort">
                Reorder
              </ResumeEditorButton>
            )}
            <ResumeEditorButton
              sx={{ color: 'primary' }}
              onClick={handleAddEducation}
              startIcon={
                <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
              }
            >
              Add
            </ResumeEditorButton>
          </Grid>
        )}
      </Grid>
      {education && education.length !== 0 ? (
        education?.map((edu) => (
          <EducationDetail
            key={edu.degree}
            education={edu}
            isEditable={editable}
            onEdit={handleEditEducation}
            onDelete={handleDeleteEducation}
          />
        ))
      ) : (
        <EmptySection text="No Education Added" />
      )}
    </Stack>
  );
};

export default DisplayEducation;
