import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography, useTheme } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import Grid from '@mui/system/Unstable_Grid';
import type { GridColDef } from '@mui/x-data-grid';

import { DataGridWithEmptyState } from '../../../components/DataGrid';
import { ColumnCell } from '../../../components/DataGrid/ColumnCell';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import ProgressStatus from '../../../components/common/ProgressStatus';
import useWindowResize from '../../../hooks/useWindowResize';
import type { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { Resume, ResumeSource } from '../../../services/resumes';
import type { ScoreColumnProps } from '../../../utils';
import { sortScoreColumn } from '../../../utils';
import { compareDates, dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import Controls from './Control';

interface ResumesTableProps {
  resumes: Resume[] | undefined;
  isLoading?: boolean;
  onClick?: (id: string, rowStatus: ResponseStatus, resumeSource: ResumeSource) => void;
}

const ResumesTable = ({ resumes, isLoading, onClick }: ResumesTableProps) => {
  const navigate = useNavigate();
  const theme: Theme = useTheme();
  const [contentHeight, setContentHeight] = useState(0);
  const [contentHeaderHeight, setContentHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const appBarHeight = document.querySelector('.MuiToolbar-root')?.clientHeight || 0;
    const fullHeight = window.innerHeight;
    setContentHeight(fullHeight - appBarHeight);
    setContentHeaderHeight(headerRef.current?.clientHeight || 0);
  };

  useWindowResize(handleResize);

  const onRenderUsedForScroe = ({ progress, score: usedForScore, status }: ScoreColumnProps) => {
    const onSuccess = <Typography variant="body2">{usedForScore > 0 ? 'Yes' : 'No'}</Typography>;
    return <ProgressStatus progress={progress} status={status} onSuccess={onSuccess} />;
  };

  const tableHeaders: GridColDef[] = [
    {
      field: 'resume',
      headerName: 'Resume',
      flex: 1,
      renderCell: (params) => ColumnCell(params.value),
      sortComparator: (value1, value2) => value1.primaryText.localeCompare(value2.primaryText),
      disableColumnMenu: true,
    },
    {
      field: 'usedForScore',
      headerName: 'Used for Job Matches',
      flex: 0.6,
      renderCell: (params) => onRenderUsedForScroe(params.value),
      sortComparator: (value1, value2) => sortScoreColumn(value1, value2),
      disableColumnMenu: true,
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 0.6,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.value.status === 'in_progress'
          ? 'In Progress'
          : dateToTimeAgo(params.value.updated_at),
      sortComparator: (value1, value2) => compareDates(value1.updated_at, value2.updated_at),
    },
    {
      field: 'controls',
      headerName: '',
      flex: 0.65,
      renderCell: (params) => Controls(params.value),
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const resumeRows = useMemo(() => {
    const rows = resumes?.map((resume) => ({
      id: resume._id,
      source: resume?.source || '',
      resume: {
        primaryText: resume.is_ai_customized || resume.is_ai_generated ? resume.name || '' : 'Base',
        secondaryText: resume.filename,
        isAiCustomized: resume.is_ai_customized,
        isAiGenerated: resume.is_ai_generated,
      },
      usedForScore: {
        progress: resume.progress,
        score: resume.used_for_score,
        status: resume.status,
      },
      lastUpdated: { status: resume.status, updated_at: resume.updated_at },
      controls: {
        id: resume._id,
        status: resume.status,
        isAiCustomized: resume.is_ai_customized || resume.is_ai_generated,
        filename: resume.filename,
      },
    }));

    return rows || [];
  }, [resumes]);

  const onDataGridRowClick = (params: {
    row: { id: string; source: ResumeSource; usedForScore: { status: ResponseStatus } };
  }) => {
    const { id: rowId, source } = params.row;
    const rowStatus = params.row.usedForScore.status;
    if (onClick) {
      onClick(rowId, rowStatus, source);
    }
  };

  const CreateResumeButton = useMemo(
    () => (
      <Button sx={{ paddingRight: 2 }} onClick={() => navigate(RoutePaths.RESUME_CREATE)}>
        <Icon className="fi fi-rr-plus" style={{ fontSize: '13px', padding: '3.5px' }} />
        Create
      </Button>
    ),
    [navigate],
  );

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        backgroundColor: 'background.mediumLight',
        height: contentHeight,
        maxHeight: contentHeight,
        padding: '32px 32px 0px 32px',
      }}
    >
      <Grid xs={11.5}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            paddingBottom: 4,
          }}
          ref={headerRef}
        >
          <Stack>
            <Typography variant="h3">Resumes</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Manage all your resumes here. AI Customize, edit, or download an ATS-ready resume.
            </Typography>
          </Stack>

          {resumeRows.length > 0 && CreateResumeButton}
        </Stack>

        {/* Data Grid */}
        {/* TODO; Create a styled component for datagrid */}
        <DataGridWithEmptyState
          getRowHeight={() => 'auto'}
          hideFooterPagination
          rows={resumeRows}
          columns={tableHeaders}
          isLoading={isLoading}
          onRowClick={onDataGridRowClick}
          sortingOrder={['asc', 'desc']}
          emptyGridState={
            <CreateNewListing
              primaryText="Add your first Resume"
              secondaryText="Upload your first resume to JobQuest."
              icon="fi fi-br-document"
              action={CreateResumeButton}
            />
          }
          mainBoxSx={{
            flexGrow: resumeRows.length > 0 ? 0 : 1,
            height: `calc(${contentHeight}px - ${contentHeaderHeight}px - ${theme.spacing(
              resumeRows.length > 0 ? 4 : 9,
            )})`,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ResumesTable;
