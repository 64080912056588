export enum CreateResumeKey {
  IMPORT = 'import',
  SAMPLE_RESUME = 'sample-resume',
  BLANK_RESUME = 'blank-resume',
}

export enum CareerStageKey {
  INTERSHIP = 'internship',
  EARLY_CAREER = 'early_career',
  MID_CAREER = 'mid_career',
  SENIOR_CAREER = 'senior_career',
  PROJECT_BASED_CAREER = 'project_based',
  CAREER_TRANSITION = 'career_transition',
}

export const resumeCreateOptions = [
  {
    key: CreateResumeKey.IMPORT,
    icon: 'fi-rr-file-upload',
    heading: 'Import Resume',
    description: 'Upload your resume for scan and edit',
  },
  {
    key: CreateResumeKey.SAMPLE_RESUME,
    icon: 'fi-rr-magic-wand',
    heading: 'Generate Sample Resume',
    description: 'Generate a sample resume and edit',
  },
  {
    key: CreateResumeKey.BLANK_RESUME,
    icon: 'fi-rr-add-document',
    heading: 'Add Blank Resume',
    description: 'Build a resume from scratch',
  },
];

export const careerStageOptions = [
  {
    key: CareerStageKey.INTERSHIP,
    icon: 'fi-rr-graduation-cap',
    heading: 'Internship',
    description: 'No Experience',
  },
  {
    key: CareerStageKey.EARLY_CAREER,
    icon: 'fi-rr-rocket',
    heading: 'Early Career',
    description: 'Less than 3 years experience',
  },
  {
    key: CareerStageKey.MID_CAREER,
    icon: 'fi-rr-star',
    heading: 'Mid Career',
    description: '3 to 6 years experience',
  },
  {
    key: CareerStageKey.SENIOR_CAREER,
    icon: 'fi-rr-briefcase',
    heading: 'Senior Career',
    description: ' 7+ years experience',
  },
  {
    key: CareerStageKey.PROJECT_BASED_CAREER,
    icon: 'fi-rr-apps',
    heading: 'Project-based Career',
    description: 'Project based experience',
  },
  {
    key: CareerStageKey.CAREER_TRANSITION,
    icon: 'fi-rr-refresh',
    heading: 'Career Transition',
    description: 'Shifting to a new industry or role',
  },
];
