import { isNotNilOrEmpty } from '../../../utils';
import styles from '../styles/index';

export interface StringConcatenatorProps {
  value1?: string;
  value2?: string;
  value1Style?: React.CSSProperties | undefined;
  value2Style?: React.CSSProperties | undefined;
  separator?: string;
}

/**
 * Renders a component that concatenates two strings with an optional separator.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.value1 - The first string value.
 * @param {string} props.value2 - The second string value.
 * @param {string} props.value1Style - The css styles for the first string value.
 * @param {string} props.value2Style - The css styles for the second string value.
 * @param {string} props.separator - The separator to use between the two string values.
 * @returns {JSX.Element} The rendered component.
 */
const StringConcatenator = ({
  value1,
  value2,
  value1Style,
  value2Style,
  separator = ' ',
}: StringConcatenatorProps) => (
  <p className="string-concatenator" style={{ ...styles.body1, ...value1Style }}>
    {value1}
    {isNotNilOrEmpty(value2) && (
      <span style={{ ...styles.colorSecondary, ...value2Style }}>{`${separator}${value2}`}</span>
    )}
  </p>
);

export default StringConcatenator;
