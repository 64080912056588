import { Link } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

import jobQuestDesktopLogo from '../../assets/jobquest-logo.svg';

// Define a custom interface for breakpoints
interface Breakpoints {
  xs?: string | number | undefined;
  sm?: string | number | undefined;
  md?: string | number | undefined;
  lg?: string | number | undefined;
  xl?: string | number | undefined;
}

interface LogoProps {
  width?: string | Breakpoints;
  height?: string | Breakpoints;
  style?: React.CSSProperties;
}

const Logo = ({ width = '130px', height = '48px', style }: LogoProps) => {
  const theme = useTheme();
  // Define the breakpoints you want to check
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const getLogoWidth = () => {
    if (typeof width === 'string') {
      return width;
    }
    if (isXl) {
      return width.xl || width.lg || width.md || width.sm || width.xs;
    }
    if (isLg) {
      return width.lg || width.md || width.sm || width.xs;
    }
    if (isMd) {
      return width.md || width.sm || width.xs;
    }
    if (isSm) {
      return width.sm || width.xs;
    }
    if (isXs) {
      return width.xs;
    }
    return '';
  };

  const getLogoHeight = () => {
    if (typeof height === 'string') {
      return height;
    }
    if (isXl) {
      return height.xl || height.lg || height.md || height.sm || height.xs;
    }
    if (isLg) {
      return height.lg || height.md || height.sm || height.xs;
    }
    if (isMd) {
      return height.md || height.sm || height.xs;
    }
    if (isSm) {
      return height.sm || height.xs;
    }
    if (isXs) {
      return height.xs;
    }
    return '';
  };

  return (
    <Link to="/">
      <img
        src={jobQuestDesktopLogo}
        alt="job-quest-logo"
        height={getLogoHeight()}
        width={getLogoWidth()}
        style={style}
      />
    </Link>
  );
};

export default Logo;
