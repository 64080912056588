import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { PatentInfo } from '../../../../../interface';
import { isNotNilOrEmpty } from '../../../../../utils';
import Button from '../../../../common/Button';
import Icon from '../../../../common/Icon';
import Modal from '../../../../common/Modal/Modal';
import ModalActionsDeprecated from '../../../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../../common/Modal/ModalDetail';

interface PatentDetailProps {
  key: string;
  patent: PatentInfo;
  isEditable?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const PatentDetail = ({ key, patent, isEditable, onEdit, onDelete }: PatentDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditPatent = () => {
    onEdit(patent._id);
  };

  const handleDeletePatent = async () => {
    setOpen(false);
    onDelete(patent._id);
  };

  return (
    <Box
      key={key}
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* TODO: Explore modal manager */}
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Patent?"
            description={`Are you sure you want to delete ${patent.title} patent? This action cannot be undone.`}
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeletePatent}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Stack gap={1}>
            {isNotNilOrEmpty(patent.title) && (
              <Typography variant="label1">{patent.title}</Typography>
            )}
            {isNotNilOrEmpty(patent.number) && (
              <Typography variant="body2">{patent.number}</Typography>
            )}
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditPatent}>
              <Icon className="fi fi-rr-edit" color="text.secondary" />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <Icon className="fi fi-rr-trash" color="error.main" />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PatentDetail;
