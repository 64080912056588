import { Global } from '@emotion/react';
import { Box, SwipeableDrawer as MuiSwipeableDrawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useSwipeableDrawerStore } from '../../stores/SwipeableDrawerStore';

const drawerBleeding = 56;

export const DrawerContent = styled(Stack)({
  height: '100%',
  padding: 16,
  textAlign: 'center',
  alignItems: 'center',
});

export const Puller = styled(Box)(({ theme }) => ({
  width: 54,
  height: 4,
  backgroundColor: theme.palette.text.tertiary,
  borderRadius: 12,
}));

const SwipeableDrawer = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme();
  const { isOpen, set } = useSwipeableDrawerStore();

  const toggleDrawer = (openDrawer: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    set(openDrawer);
  };

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            overflow: 'visible',
            borderRadius: '12px 12px 0px 0px',
          },
        }}
      />
      <MuiSwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <DrawerContent>{children}</DrawerContent>
      </MuiSwipeableDrawer>
    </>
  );
};

export default SwipeableDrawer;
