import { isNil, isNotNil } from 'ramda';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import { type PublicationInfo, ResumeKey } from '../../../../interface';
import RoutePaths from '../../../../routes/RoutePaths';
import { ResumesKeys, deleteResumeSection } from '../../../../services/resumes';
import Icon from '../../../common/Icon';
import MobileTooltip from '../../../common/MobileTooltip';
import Tooltip from '../../../common/Tooltip';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';
import PublicationDetail from './components/PublicationDetail';

interface PublicationSectionProps {
  publications?: PublicationInfo[];
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
  tooltipContent?: string | string[];
  enableSectionReorder?: boolean;
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

const PublicationSection = ({
  publications,
  editable,
  resumeId,
  hideEmptySection,
  tooltipContent,
  enableSectionReorder = false,
}: PublicationSectionProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveDevice();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const shouldhideEmptySection =
    hideEmptySection &&
    (isNil(publications) || (isNotNil(publications) && publications.length === 0));
  const publicationEditPageUrl = RoutePaths.RESUME_EDIT.replace(':key', ResumeKey.PUBLICATIONS);

  const resumeEditorState: ResumeEditorState = {
    action: '',
    resumeId: resumeId || '',
    elementId: '',
    elementType: ResumeKey.PUBLICATIONS,
  };

  const { mutate: deletePublication } = useMutation({
    mutationFn: deleteResumeSection,
    onSuccess: () => {
      showSnackbar('success', 'Publication deleted');
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries([ResumesKeys.RESUMES, resumeEditorState.resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const handleAddPublication = () => {
    resumeEditorState.action = 'add';
    // TODO: Navigate on /resumes/add/patents
    navigate(publicationEditPageUrl, {
      state: { resumeEditorState },
    });
  };

  const handleEditPublication = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    navigate(publicationEditPageUrl, {
      state: { resumeEditorState },
    });
  };

  const handleDeletePublication = useCallback(
    (id: string) => {
      deletePublication({ elementId: id, sectionKey: ResumeKey.PUBLICATIONS });
    },
    [deletePublication],
  );

  const handleReorderClick = () => {
    resumeEditorState.action = 'reorder';
    navigate(`/resumes/reorder/${resumeEditorState?.elementType}`, {
      state: { id: resumeEditorState?.resumeId },
    });
  };

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid item>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant={isMobile ? 'h3' : 'h2'}>Publications</Typography>
            {tooltipContent &&
              (isMobile || isTablet ? (
                <MobileTooltip content={tooltipContent} />
              ) : (
                <Tooltip content={tooltipContent} />
              ))}
          </Stack>
        </Grid>
        {editable && (
          <Grid item>
            {enableSectionReorder && (
              <ResumeEditorButton onClick={handleReorderClick} startIcon="fi fi-rr-apps-sort">
                Reorder
              </ResumeEditorButton>
            )}
            <ResumeEditorButton
              sx={{ color: 'primary' }}
              onClick={handleAddPublication}
              startIcon={
                <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
              }
            >
              Add
            </ResumeEditorButton>
          </Grid>
        )}
      </Grid>
      {publications && publications.length !== 0 ? (
        publications?.map((publication) => (
          <PublicationDetail
            key={publication._id}
            publication={publication}
            isEditable={editable}
            onEdit={handleEditPublication}
            onDelete={handleDeletePublication}
          />
        ))
      ) : (
        <EmptySection text="No Publications Added" />
      )}
    </Stack>
  );
};

export default PublicationSection;
