import { Children } from 'react';

import type { Grid2Props } from '@mui/material/Unstable_Grid2';
import Grid2 from '@mui/material/Unstable_Grid2';

interface MultiColumnLayoutProps {
  children: JSX.Element | JSX.Element[] | undefined;
  rootProps?: Grid2Props;
  columnProps?: Grid2Props;
}

const MultiColumnLayout = ({ children, rootProps, columnProps }: MultiColumnLayoutProps) => {
  // Convert children into an array if it's not already an array
  const childrenArray = Children.toArray(children);

  return (
    <Grid2
      sx={{ justifyContent: 'center', maxWidth: '100%', boxSizing: 'border-box' }}
      {...rootProps}
      container
    >
      {childrenArray.map((item, index) => (
        <Grid2
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...columnProps}
          sx={{
            boxSizing: 'border-box',
            paddingRight: 0,
            paddingLeft: index !== 0 ? 3 : 0,
            ...columnProps?.sx,
          }}
        >
          {item}
        </Grid2>
      ))}
    </Grid2>
  );
};

export default MultiColumnLayout;
