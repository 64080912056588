import { Box, CardContent, Card as MUICard } from '@mui/material';
import type { CardContentProps, CardProps as MuiCardProps, SxProps } from '@mui/material';

export interface CardProps extends MuiCardProps {
  rootSx?: SxProps;
  cardContentProps?: CardContentProps;
}

const Card = ({ children, sx, rootSx, cardContentProps, ...restProps }: CardProps) => (
  <Box sx={{ ...rootSx }}>
    <MUICard
      sx={{ border: '1px solid', borderColor: 'border.light', boxShadow: 'none', ...sx }}
      {...restProps}
    >
      <CardContent {...cardContentProps}>{children}</CardContent>
    </MUICard>
  </Box>
);

export default Card;
