import type { SxProps } from '@mui/material';
import { Modal as MuiModal } from '@mui/material';
import type { ModalProps as MuiModalProps } from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

interface ModalProps extends MuiModalProps {
  sx?: SxProps;
  contentSx?: SxProps;
}

const Modal = ({ sx, contentSx, children, ...modalProps }: ModalProps) => (
  <MuiModal
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }}
    {...modalProps}
  >
    <Stack
      gap="40px"
      sx={{
        backgroundColor: 'common.white',
        borderRadius: '12px',
        padding: '20px',
        minWidth: '300px',
        width: '416px',
        maxHeight: '80vh',
        height: 'fit-content',
        ...contentSx,
      }}
    >
      {children}
    </Stack>
  </MuiModal>
);

export default Modal;
