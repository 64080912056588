import type { CSSProperties } from 'react';

// TODO: convert these styles into CSS classes
const standardBStyles: { [key: string]: CSSProperties } = {
  pageBody: {
    padding: '16px 24px',
  },
  contactContainer: {
    alignItems: 'center',
    textAlign: 'center',
    gap: 0,
    backgroundColor: '#F0EFEF',
    padding: '12px 15px 15px',
  },
  sectionHeading: {
    paddingBottom: 3,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  workExperienceContentHeader: {
    backgroundColor: '#F0EFEF',
    padding: 4,
  },
};

export default standardBStyles;
