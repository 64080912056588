import { useRef, useState } from 'react';

import { Box, IconButton, Tooltip as MuiTooltip, Popper, Typography } from '@mui/material';

import useClickOutside from '../../hooks/useClickOutside';
import Icon from './Icon';

interface TooltipProps {
  content: string | string[];
  hide?: boolean;
}

const Tooltip = ({ content, hide }: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  useClickOutside(anchorRef, () => setOpen(false));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <MuiTooltip
        title={undefined}
        open={!hide}
        PopperProps={{ open, anchorEl: anchorRef.current }}
        placement="right"
      >
        <IconButton ref={anchorRef} onClick={handleToggle}>
          <Icon className="fi fi-rr-info" fontSize="16px" />
        </IconButton>
      </MuiTooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="right" sx={{ zIndex: 2000 }}>
        <Box
          sx={{
            padding: 2,
            maxWidth: { md: '300px', lg: '400px' },
            zIndex: 2000,
            bgcolor: 'common.white',
            borderRadius: '8px',
            boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          {typeof content === 'string' ? (
            <Typography variant="body3">{content}</Typography>
          ) : (
            <ul
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
                paddingInlineStart: '26px',
              }}
            >
              {content.map((item, index) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{ paddingBottom: index !== content.length - 1 ? '8px' : '0' }}
                >
                  <Typography variant="body3">{item}</Typography>
                </li>
              ))}
            </ul>
          )}
        </Box>
      </Popper>
    </>
  );
};

export default Tooltip;
