import { create } from 'zustand';

type SwipeableDrawerState = {
  isOpen: boolean;
  toggle: () => void;
  set: (value: boolean) => void;
  open: () => void;
  close: () => void;
  reset: () => void;
};

export const useSwipeableDrawerStore = create<SwipeableDrawerState>((set) => ({
  isOpen: false,
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
  set: (value) => set(() => ({ isOpen: value })),
  open: () => set(() => ({ isOpen: true })),
  close: () => set(() => ({ isOpen: false })),
  reset: () => set(() => ({ isOpen: false })),
}));
