import styles from '../styles/index';

interface InlineBulletListProps {
  contentList?: string[];
  style?: React.CSSProperties | undefined;
}

const InlineBulletList = ({ contentList = [], style }: InlineBulletListProps) => (
  <p
    className="inline-bulleted-list"
    style={{ ...styles.body1, ...styles.lineHeight1_2, ...style }}
  >
    {contentList.map((content, index) => `${index !== 0 ? '  •  ' : ''}${content}`)}
  </p>
);

export default InlineBulletList;
