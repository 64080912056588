import { useState } from 'react';

import { Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';

import Button from './Button';
import Icon from './Icon';

interface MenuItemType {
  label?: string;
  icon?: string;
  action?: () => void;
}

interface DropdownMenuProps {
  menuItems: MenuItemType[];
  label?: string;
  icon?: string;
}

const DropdownMenu = ({ menuItems, label, icon }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="text" sx={{ color: 'primary' }} onClick={handleMenu}>
        {icon && <Icon className={icon} fontSize="16px" />}
        {label}
        {anchorEl ? (
          <Icon className="fi fi-rr-angle-small-up" fontSize="20px" />
        ) : (
          <Icon className="fi fi-rr-angle-small-down" fontSize="20px" />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{ paper: { sx: { borderRadius: '8px' } } }}
      >
        {menuItems.map((item) => (
          <MenuItem
            sx={{
              padding: 0,
              '&:hover': {
                borderRadius: '8px',
                backgroundColor: 'background.main',
              },
            }}
            onClick={item.action}
            key={item.label}
          >
            <Button
              variant="text"
              sx={{
                color: 'text.primary',
                padding: '8px 12px',
                justifyContent: 'flex-start',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Icon className={item.icon} />
              <Typography variant="body3">{item.label}</Typography>
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
