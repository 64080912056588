import type { ChipProps } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';

const Chip = ({ label, sx }: ChipProps) => (
  <MuiChip
    label={label}
    sx={{
      height: 'max-content',
      '& .MuiChip-label': {
        padding: '0px 4px',
        fontSize: '12px',
      },
      ...sx,
    }}
  />
);

export default Chip;
