import { isNil, isNotNil } from 'ramda';
import { useNavigate } from 'react-router';

import { Box, Grid, Stack, Typography } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import EmptySection from '../ResumeView/components/EmptySection';
import ResumeEditorButton from '../ResumeView/components/ResumeEditorButton';
import MobileTooltip from './MobileTooltip';
import Tooltip from './Tooltip';

// TODO: Extend from list prop
export interface InlineChipsListProps {
  dataList: string[];
  title: string;
  id?: string;
  editable?: boolean;
  resumeId?: string;
  type?: string;
  hideEmptySection?: boolean;
  tooltipContent?: string | string[];
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

const InlineChipsListRenderer = ({
  dataList = [],
  id = '',
  title,
  editable,
  resumeId = '',
  type = '',
  hideEmptySection,
  tooltipContent,
}: InlineChipsListProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveDevice();

  const shouldhideEmptySection =
    hideEmptySection && (isNil(dataList) || (isNotNil(dataList) && dataList.length === 0));

  // TODO: move handler function in parent component
  const handleEditItems = () => {
    const resumeEditorState: ResumeEditorState = {
      action: 'edit',
      resumeId,
      elementId: id,
      elementType: type,
    };
    navigate(RoutePaths.RESUME_EDIT.replace(':key', type), {
      state: { resumeEditorState },
    });
  };

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid item>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant={isMobile ? 'h3' : 'h2'}>{title}</Typography>
            {tooltipContent &&
              (isMobile || isTablet ? (
                <MobileTooltip content={tooltipContent} />
              ) : (
                <Tooltip content={tooltipContent} />
              ))}
          </Stack>
        </Grid>
        {editable && (
          <Grid item>
            <ResumeEditorButton onClick={handleEditItems} />
          </Grid>
        )}
      </Grid>
      {/* TODO: Add a section */}
      {dataList && dataList.length !== 0 ? (
        <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
          {dataList.map((item) => (
            <Typography
              component="span"
              key={item}
              variant="body2"
              // TODO: create utility classes
              sx={{
                padding: '12px 20px',
                border: '1px solid',
                borderColor: 'border.light',
                borderRadius: '8px',
              }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      ) : (
        <EmptySection text={`No ${title} Added`} />
      )}
    </Stack>
  );
};

export default InlineChipsListRenderer;
