import { isEmpty } from 'ramda';

import type { ResponseStatus } from '../interface';
import http from './http';

export enum JobMatchScoreKeys {
  JOBMATCHES = 'jobMatches',
}

export interface JobMatchScore {
  _id: string;
  job_description_id: string;
  resume_id: string;
  user_id: string;
  error?: string;
  total_score: number;
  title_score: number;
  skill_score: number;
  responsibility_score: number;
  years_of_experience_score: number;
  created_at: string;
  updated_at: string;
  job_company: string;
  job_title: string;
  progress: number;
  resume_name: string;
  resume_filename: string;
  customized_name: string;
  resume_is_ai_customized: boolean;
  resume_is_ai_generated: boolean;
  status: ResponseStatus;
}

export interface JobMatchesResponse {
  scores: JobMatchScore[];
  success: boolean;
}
interface CreateJobMatchScoreParams {
  job_description_id: string;
  resume_id: string;
  should_ai_customize_resume?: boolean;
}

export const getAllJobMatches = async (): Promise<{
  result: JobMatchScore[];
  status: {
    status: ResponseStatus;
    message: string;
  };
}> => {
  try {
    const res = await http.get('/match-score');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: remove this function to createNewJobMatchScore
// TODO: refactor, and get only one param
export const createNewJobMatchScore = async (data: CreateJobMatchScoreParams) => {
  if (!data || isEmpty(data)) {
    return Promise.reject(new Error('Job Description and Resume Ids are required'));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { job_description_id, resume_id, should_ai_customize_resume = false } = data;

  try {
    const params = {
      job_description_id,
      resume_id,
      ai_customize_resume: should_ai_customize_resume,
    };

    const res = await http.post('/match-score', params);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getJobMatchScore = async (jobMatchScoreId: string) => {
  try {
    const res = await http.get(`/match-score/${jobMatchScoreId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMatchScoreProgress = async (jobMatchScoreId: string) => {
  if (!jobMatchScoreId) {
    return Promise.reject(new Error('Job Match Score Id is required'));
  }

  try {
    const res = await http.get(`/match-score/${jobMatchScoreId}/status`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteJobMatchScore = async (jobMatchScoreId: string) => {
  if (!jobMatchScoreId) {
    return Promise.reject(new Error('Job Match Score Id is required'));
  }

  try {
    const res = await http.delete(`/match-score/delete/${jobMatchScoreId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
