import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import BootstrapTooltip from '../../../components/common/BootstrapTooltip';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import Modal from '../../../components/common/Modal/Modal';
import ModalActions from '../../../components/common/Modal/ModalActions';
import ModalActionsDeprecated from '../../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../components/common/Modal/ModalDetail';
import SwipeableDrawer, { Puller } from '../../../components/common/SwipeableDrawerDeprecated';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import { ResumesKeys, deleteResume, duplicateResume } from '../../../services/resumes';
import { useSwipeableDrawerStore } from '../../../stores/SwipeableDrawerStore';

export interface ControlsProps {
  id: string;
  isAiCustomized: boolean;
  status: ResponseStatus;
  filename: string;
}

const Controls = ({ id, isAiCustomized, status, filename }: ControlsProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { close: closeSwipeableDrawer } = useSwipeableDrawerStore();
  const deleteModalDescription = `Are you sure you want to delete resume ${filename}? Its associated job match score(s) will also be deleted. This action cannot be undone.`;
  const { isDesktop } = useResponsiveDevice();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();

  const onCloseSwipeableDrawer = useCallback(() => {
    closeSwipeableDrawer();
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  }, [closeSwipeableDrawer]);

  const { mutate: deleteResumeFile } = useMutation({
    mutationFn: deleteResume,
    onSuccess: () => {
      setOpen(false);
      showSnackbar('success', 'Resume deleted');
      queryClient.invalidateQueries([ResumesKeys.RESUMES]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setOpen(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const { mutate: duplicateResumeFile } = useMutation({
    mutationFn: duplicateResume,
    onSuccess: () => {
      setOpen(false);
      showSnackbar('success', 'Resume duplicated');
      queryClient.invalidateQueries([ResumesKeys.RESUMES]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setOpen(false);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const onDeleteClick = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleEditResume = useCallback(() => {
    navigate(id);
  }, [navigate, id]);

  const handleDuplicateResume = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      duplicateResumeFile(id);
      if (!isDesktop) {
        onCloseSwipeableDrawer();
      }
    },
    [duplicateResumeFile, id, isDesktop, onCloseSwipeableDrawer],
  );

  const handleDeleteResume = useCallback(() => {
    deleteResumeFile(id);
    if (!isDesktop) {
      onCloseSwipeableDrawer();
    }
  }, [deleteResumeFile, id, isDesktop, onCloseSwipeableDrawer]);

  const handleAiCustomizeResume = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      navigate(RoutePaths.AI_CUSTOMIZED.replace(':id', id), {
        state: { filename },
      });
    },
    [navigate, id, filename],
  );

  const controlActions = useMemo(
    () => [
      {
        enableAction: !isAiCustomized && status === ResponseStatus.DONE,
        title: 'AI Customize',
        ariaLabel: 'ai-customize icon',
        icon: 'fi fi-br-magic-wand',
        onClick: handleAiCustomizeResume,
      },
      {
        enableAction: status === ResponseStatus.DONE,
        title: 'Edit',
        ariaLabel: 'edit icon',
        icon: 'fi fi-br-pencil',
        onClick: handleEditResume,
      },
      {
        enableAction: status === ResponseStatus.DONE,
        title: 'Duplicate',
        ariaLabel: 'duplicate icon',
        icon: 'fi fi-br-duplicate',
        onClick: handleDuplicateResume,
      },
      {
        enableAction: true,
        title: 'Delete',
        ariaLabel: 'delete icon',
        icon: 'fi fi-br-trash',
        onClick: onDeleteClick,
      },
    ],
    [
      handleAiCustomizeResume,
      handleDuplicateResume,
      handleEditResume,
      onDeleteClick,
      isAiCustomized,
      status,
    ],
  );

  return (
    <>
      {isDesktop ? (
        <Stack
          className="column-controls"
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '12px',
            width: '100%',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            flexWrap: 'wrap',
          }}
        >
          {controlActions.map(
            (control) =>
              control.enableAction && (
                <BootstrapTooltip title={control.title} key={control.title}>
                  <IconButton
                    aria-label={control.ariaLabel}
                    size="small"
                    component="button"
                    onClick={control.onClick}
                  >
                    <Icon className={control.icon} color={theme.palette.text.secondary} />
                  </IconButton>
                </BootstrapTooltip>
              ),
          )}
          <Modal open={open}>
            <>
              <ModalDetail title="Delete Resume?" description={deleteModalDescription} />
              <ModalActionsDeprecated
                type="delete"
                onConfirm={handleDeleteResume}
                onClose={() => setOpen(false)}
              />
            </>
          </Modal>
        </Stack>
      ) : (
        <SwipeableDrawer>
          {open ? (
            <Stack sx={{ alignItems: 'flex-end', gap: 0.5 }}>
              <IconButton
                aria-label="Close icon"
                size="small"
                component="button"
                onClick={onCloseSwipeableDrawer}
                sx={{ padding: 1.25 }}
              >
                <Icon
                  className="fi fi-br-cross"
                  style={{ color: theme.palette.text.secondary, fontSize: '16px' }}
                />
              </IconButton>
              <Stack sx={{ alignItems: 'flex-start', gap: 1.5 }}>
                <Typography variant="h3">Delete Resume</Typography>
                <Typography variant="body3" sx={{ textAlign: 'left', wordBreak: 'break-word' }}>
                  {deleteModalDescription}
                </Typography>
                <ModalActions
                  type="delete"
                  onConfirm={handleDeleteResume}
                  onClose={onCloseSwipeableDrawer}
                  sx={{ paddingTop: 1.5 }}
                />
              </Stack>
            </Stack>
          ) : (
            <>
              <Puller sx={{ marginBottom: 3 }} />
              {controlActions.map(
                (control) =>
                  control.enableAction && (
                    <Button
                      variant="text"
                      key={control.title}
                      sx={{
                        gap: 1,
                        width: '100%',
                        padding: '16px 12px',
                        justifyContent: 'flex-start',
                      }}
                      onClick={control.onClick}
                    >
                      <Icon
                        className={control.icon}
                        style={{ color: theme.palette.text.secondary, fontSize: '16px' }}
                      />
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {control.title}
                      </Typography>
                    </Button>
                  ),
              )}
            </>
          )}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Controls;
