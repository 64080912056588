import axios from 'axios';

import { BASE_URL } from '../configs';
import RoutePaths from '../routes/RoutePaths';
import { Storage } from './storage';

// TODO: quick fix, figure out why BASE_URL is undefined in production
const localBaseUrl = BASE_URL || window.location.origin;
export interface HttpResponse<T = {}> {
  data: T;
  status: 'success' | 'error';
  errors: string;
}

const http = axios.create({
  baseURL: `${localBaseUrl}/api/backend`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// attach jwt token to every request
http.interceptors.request.use(
  (config) => {
    // add trailing slash to url if not present
    if (!config.url?.endsWith('/') && !config.url?.includes('?')) {
      config.url += '/';
    }

    const token = Storage.getJwtToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// interceptor for refresh token handling
http.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const originalRequest = error.config;
      if (
        (error.response.status === 401 || error.response.status === 403) &&
        !originalRequest.retry
      ) {
        //   TODO: refactor this, to use auth service & for api response
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
          Storage.clear();
          window.location.href = RoutePaths.SIGN_IN;
          return await Promise.reject(error);
        }

        originalRequest.retry = true;
        try {
          const res = await axios.post(`${localBaseUrl}/api/backend/users/refresh-token/`, null, {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          });

          if (
            res?.data.status === 'success' &&
            res?.data.data.access_token &&
            res?.data.data.refresh_token
          ) {
            Storage.setJwtToken(res?.data.data.access_token);
            Storage.setRefreshToken(res?.data.data.refresh_token);
            return await http(originalRequest);
          }

          // if refresh token is invalid, clear local storage
          // send user to '/' route
          Storage.clear();
          window.location.href = RoutePaths.SIGN_IN;
          return await Promise.reject(error);
        } catch (err) {
          Storage.clear();
          window.location.href = RoutePaths.SIGN_IN;
          return await Promise.reject(err);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return Promise.reject(error);
  },
);

export default http;
