export enum ResultType {
  Match = 'Match',
  NoMatch = 'No Match',
  PartialMatch = 'Partial Match',
}

export interface JobMatchScoreReportItem {
  _id: string;
  match_score_id: string;
  item: string;
  resume: string;
  requirement: string;
  result: ResultType;
  criteria?: string;
}

export interface JobMatchScoreData {
  overall_score: number;
  // TODO: verify if overall result is required after the job match UI 2.0 changes
  overall_result: string;
  resume: {
    id: string;
    name: string;
    is_ai_customized: boolean;
    is_ai_generated: boolean;
  };
  job_description: {
    id: string;
    job_company: string;
  };
  overview: string[];
  match_score_id: string;
  job_title_match: {
    overall_score: number;
    items: JobMatchScoreReportItem[];
  };
  skill_match: {
    overall_score: number;
    items: JobMatchScoreReportItem[];
  };
  responsibility_match: {
    overall_score: number;
    items: JobMatchScoreReportItem[];
  };
  years_of_experience_match: {
    overall_score: number;
    items: JobMatchScoreReportItem[];
  };
}

export interface JobXRayData {
  job_company: string;
  job_location: {
    city: string | null;
    state: string | null;
    country: string | null;
  };
  employment_type: string;
  work_arrangement: string | null;
  salary_range: string | null;
  job_title: {
    Job_Title: string;
    Occupation: string;
    Seniority: string;
  };
  summary: {
    job_company: string;
    job_title: string;
  };
  responsibilities: string[];
  skills: string[];
  years_of_experience: string[];
}
