import type { TypographyProps } from '@mui/material';
import { Link, Typography } from '@mui/material';

interface TextLinkButtonProps extends TypographyProps {
  text: {
    startingText: string;
    linkText: string;
    endingText: string;
  };
  onClick?: () => void;
}

const TextWrappedLinkButton = ({ text, onClick, ...restProps }: TextLinkButtonProps) => (
  <Typography variant="body2" {...restProps}>
    {text.startingText ? `${text.startingText} ` : ''}
    {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link component="button" sx={{ color: 'primary.main' }} onClick={onClick}>
      {text.linkText}
    </Link>
    {text.endingText ? ` ${text.endingText}` : ''}
  </Typography>
);

export default TextWrappedLinkButton;
