import { forwardRef } from 'react';

import type { TextFieldProps } from '@mui/material';
import { TextField as MUITextField } from '@mui/material';

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ variant = 'outlined', sx, FormHelperTextProps, ...restProps }, ref) => (
    <MUITextField
      variant={variant}
      sx={{
        '& .MuiOutlinedInput-root': {
          fieldset: {
            border: '1px solid',
            borderColor: 'border.light', // Default border color
            borderRadius: '8px',
          },
          '&:hover fieldset': {
            borderColor: 'border.main', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main', // Border color on focus
          },
          '&.Mui-error fieldset': {
            borderColor: 'error.main', // Border color on error
          },
        },
        '& .MuiInputBase-root': {
          padding: 0,
        },
        ...sx,
      }}
      FormHelperTextProps={{
        sx: {
          marginTop: 1,
          marginLeft: 0,
          // Typography variant assistive
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.125rem',
          letterSpacing: '-0.00375rem',
        },
        ...FormHelperTextProps,
      }}
      inputRef={ref}
      {...restProps}
    />
  ),
);

export default TextField;
