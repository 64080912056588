import { useEffect, useRef } from 'react';

/**
 * Custom hook that returns an AbortSignal object.
 * The AbortSignal object can be used to abort asynchronous operations.
 * The signal is aborted when the component unmounts.
 *
 * @returns {AbortSignal | undefined} The AbortSignal object or undefined if it is not available.
 */
export const useAbortSignal = () => {
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current?.abort();
      }
    };
  }, []);

  return {
    abortSignal: abortControllerRef.current?.signal,
  };
};
