import type { Control, FieldError } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { CircularProgress, useTheme } from '@mui/material';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Icon from './Icon';

interface DropdownProps {
  items: (string | number)[]; // TODO: update to accept objects which can include id and name
  placeholder: string;
  name?: string;
  defaultValue?: string | number | null;
  control?: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disableOptionfilter?: (option: any) => boolean;
  filterOptions?: (option: string | number) => boolean;
  freeSolo?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  blurOnSelect?: boolean | 'touch' | 'mouse';
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSelectionChange?: (value: string | number | null) => void;
}

const getOptionLabel = (option: string | number) => {
  if (typeof option === 'string') {
    return option; // Return the string as is
  }
  if (typeof option === 'number') {
    return option.toString(); // Convert numbers to strings
  }
  return '';
};

const Dropdown = ({
  items,
  placeholder,
  defaultValue,
  control,
  name,
  disableOptionfilter,
  filterOptions,
  freeSolo = false,
  autoFocus = false,
  loading = false,
  blurOnSelect = false,
  onFocus,
  onSelectionChange,
}: DropdownProps) => {
  const theme = useTheme();

  const filterValue = (option: string | number | null) => {
    if (option === null) {
      return null;
    }
    return items.find((item) => item === option);
  };
  const renderInput = (params: AutocompleteRenderInputParams, error: FieldError | undefined) => (
    <TextField
      {...params}
      placeholder={placeholder}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& .MuiAutocomplete-input': { padding: 0.5 },
          '& fieldset': {
            border: '1px solid',
            borderColor: 'border.light',
            borderRadius: '8px',
          },
        },
      }}
      autoFocus={autoFocus}
      onFocus={onFocus}
      variant="outlined"
      error={!!error?.message}
    />
  );

  return (
    <Controller
      control={control}
      name={name || ''}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;
        return (
          <>
            <Autocomplete
              value={freeSolo ? value : filterValue(value)}
              defaultValue={defaultValue}
              getOptionLabel={(option) => getOptionLabel(option)}
              loading={loading}
              blurOnSelect={blurOnSelect}
              onChange={(_event, newValue) => {
                onChange(newValue);
                if (onSelectionChange) {
                  onSelectionChange(newValue);
                }
              }}
              onInputChange={(_event, newInputValue) =>
                (newInputValue === '' || freeSolo) && onChange(freeSolo ? newInputValue : null)
              }
              options={items}
              // Filter the options based on the filterOptions function
              filterOptions={(options) =>
                filterOptions ? options.filter((option) => filterOptions(option)) : options
              }
              // Grey out the options that are disabled
              getOptionDisabled={(option) =>
                disableOptionfilter ? disableOptionfilter(option) : false
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body3">{option}</Typography>
                </li>
              )}
              sx={{ '& .MuiOutlinedInput-root': { padding: 1.25 } }}
              renderInput={(params) => renderInput(params, error)}
              freeSolo={freeSolo}
              fullWidth
              disableClearable
              forcePopupIcon
              popupIcon={
                loading ? (
                  <CircularProgress size={16} value={50} variant="indeterminate" color="inherit" />
                ) : (
                  <Icon
                    className="fi fi-rr-angle-small-down"
                    fontSize="16px"
                    color={theme.palette.text.tertiary}
                  />
                )
              }
            />
            {!!error?.message && (
              <Typography variant="assistive" color="error">
                {error?.message}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
};

export default Dropdown;
