import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import type { CardProps } from '../../../../components/common/Card';
import StyledCard from '../../../../components/common/StyledCard';
import type { JobMatchScoreReportItem } from '../../interface';
import Accordion from './Accordion';
import AccordionDetails from './Accordion/AccordionDetails';
import AccordionSummary from './Accordion/AccordionSummary';

interface JobMatchScoreReportProps {
  data?: {
    job_title_match?: {
      overall_score: number;
      items: JobMatchScoreReportItem[];
    };
    skill_match?: {
      overall_score: number;
      items: JobMatchScoreReportItem[];
    };
    responsibility_match?: {
      overall_score: number;
      items: JobMatchScoreReportItem[];
    };
    years_of_experience_match?: {
      overall_score: number;
      items: JobMatchScoreReportItem[];
    };
  };
  cardProps?: CardProps;
}

const JobMatchScoreReport = ({ data, cardProps }: JobMatchScoreReportProps) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (key: string) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (isExpanded) {
      setExpanded([...expanded, key]);
    } else {
      setExpanded(expanded.filter((item) => item !== key));
    }
  };

  return (
    <StyledCard {...cardProps}>
      <Typography variant="h3">Here’s how it works</Typography>
      {data && (
        <Box>
          {Object.entries(data).map(([key, value], index) => (
            <Accordion
              key={key}
              expanded={expanded.includes(key)}
              onChange={handleChange(key)}
              sx={{
                borderRadius: index === 0 ? '12px 12px 0px 0px' : '0px',
              }}
            >
              <AccordionSummary expanded={expanded.includes(key)} label={value.overall_score}>
                {key.replaceAll('_', ' ')}
              </AccordionSummary>
              <AccordionDetails jobMatchScoreReportItems={value.items} />
            </Accordion>
          ))}
        </Box>
      )}
    </StyledCard>
  );
};
export default JobMatchScoreReport;
