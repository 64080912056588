import { useState } from 'react';

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import Icon from '../../common/Icon';

interface ProgressStatusInfoProps {
  progressText: string;
  isError: boolean;
}

const ProgressStatusInfo = ({ progressText, isError }: ProgressStatusInfoProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  return (
    <>
      {isError ? (
        <Typography variant="body3" textAlign="center">
          Some task(s) failed to complete. If the error persists, let us know at{' '}
          <Stack
            sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
          >
            <Typography
              variant="label2"
              sx={{
                textDecoration: 'underline',
                color: 'text.secondary',
                '&:hover': { color: 'primary.main', cursor: 'pointer' },
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              support@jobquest.ai
            </Typography>
            <Icon
              className="fi fi-br-copy"
              style={{
                fontSize: '16px',
                color: theme.palette.primary.main,
                opacity: hover ? 1 : 0,
              }}
            />
          </Stack>
        </Typography>
      ) : (
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={20} sx={{ color: 'text.tertiary' }} />
          <Typography variant="body2" sx={{ color: 'text.primaryGray', textAlign: 'center' }}>
            {progressText}
          </Typography>
        </Stack>
      )}
    </>
  );
};
export default ProgressStatusInfo;
