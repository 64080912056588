import Button, { type ButtonProps } from './Button';
import Icon from './Icon';

const AiCustomizeButton = ({ onClick, sx, ...restProps }: ButtonProps) => (
  <Button
    sx={{ width: { sm: 'fit-content' }, padding: { xs: '16px 12px', sm: '8px 12px' }, ...sx }}
    onClick={onClick}
    {...restProps}
  >
    <Icon className="fi fi-rr-magic-wand" fontSize="20px" />
    AI Customize
  </Button>
);

export default AiCustomizeButton;
