import parse from 'html-react-parser';
import { useState } from 'react';

import type { SxProps } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import type { AchievementInfo } from '../../../interface';
import { hasTextInsideHtml, isNotNilOrEmpty } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Modal from '../../common/Modal/Modal';
import ModalActionsDeprecated from '../../common/Modal/ModalActions_deprecated';
import ModalDetail from '../../common/Modal/ModalDetail';

interface AchievementDetailProps {
  key?: string;
  achievement: AchievementInfo;
  isEditable?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  sx?: SxProps;
}

const AchievementDetail = ({
  key,
  achievement,
  isEditable,
  onEdit,
  onDelete,
  sx,
}: AchievementDetailProps) => {
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleEditAchievement = async () => {
    if (onEdit) {
      onEdit(achievement._id);
    }
  };

  const handleDeleteAchievement = async () => {
    setOpen(false);
    if (onDelete) {
      onDelete(achievement._id);
    }
  };

  //   TODO: create a generic component for this
  return (
    <Stack
      key={key}
      sx={{
        border: '1px solid',
        borderRadius: '12px',
        borderColor: 'border.light',
        padding: '16px',
        '&:hover': {
          backgroundColor: 'background.mediumLight',
        },
        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Modal open={open}>
        <>
          <ModalDetail
            title="Delete Achievement?"
            description="Are you sure you want to delete this achievement? This action cannot be undone."
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={handleDeleteAchievement}
            onClose={() => setOpen(false)}
          />
        </>
      </Modal>
      <Grid justifyContent="space-between" minHeight={36} container>
        <Grid item alignSelf="center">
          <Stack gap={1}>
            {isNotNilOrEmpty(achievement?.name) && (
              <Typography variant="label1">{achievement.name}</Typography>
            )}
            {isNotNilOrEmpty(achievement.organization) && (
              <Typography variant="body2">{achievement.organization}</Typography>
            )}
            {isNotNilOrEmpty(achievement.issued_year) && (
              <Typography variant="body2">
                {formatDate(null, null, achievement.issued_month, achievement.issued_year, false)}
              </Typography>
            )}
          </Stack>
        </Grid>
        {isHovered && isEditable && (
          <Grid item>
            <Button variant="text" sx={{ color: 'common.black' }} onClick={handleEditAchievement}>
              <Icon className="fi fi-rr-edit" />
              <Typography variant="label2">Edit</Typography>
            </Button>
            <Button variant="text" sx={{ color: 'error.main' }} onClick={() => setOpen(true)}>
              <Icon className="fi fi-rr-trash" />
              <Typography variant="label2">Delete</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      {achievement?.description && hasTextInsideHtml(achievement.description) && (
        <Typography variant="body2" sx={{ color: 'text.primaryGray' }}>
          {parse(achievement.description)}
        </Typography>
      )}
    </Stack>
  );
};

export default AchievementDetail;
