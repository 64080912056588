import { useCallback, useMemo } from 'react';

import { type ResumeData, ResumeKey } from '../../interface';
import { isNotNilOrEmpty } from '../../utils/index';
import BulletedList from './components/BulletedList';
import InlineBulletedList from './components/InlineBulletedList';
import SectionContent from './components/SectionContent';
import SectionTitle from './components/SectionTitle';
import TemplateAchievement from './components/TemplateAchievement';
import TemplateCertification from './components/TemplateCertification';
import TemplateEducation from './components/TemplateEducation';
import TemplateHeader from './components/TemplateHeader';
import TemplatePatent from './components/TemplatePatent';
import TemplateProject from './components/TemplateProject';
import TemplatePublication from './components/TemplatePublication';
import TemplateVolunteerExperience from './components/TemplateVolunteerExperience';
import TemplateWorkExperience from './components/TemplateWorkExperience';
import styles from './styles/index';
import standardBStyles from './styles/standardBStyle';

const StandardB = ({ resume }: { resume: ResumeData }) => {
  const isSectionEnabled = useCallback(
    (sectionName: ResumeKey) =>
      resume.section_order.some(
        (section) => section.section_name === sectionName && section.enabled,
      ),
    [resume.section_order],
  );

  const resumeComponents = useMemo(() => {
    if (!isNotNilOrEmpty(resume)) return null;

    return (
      <div style={{ ...styles.page, ...standardBStyles.pageBody }}>
        {resume?.[ResumeKey.SECTIONS_ORDER]?.map((section) => {
          if (!section.enabled) return null;

          switch (section.section_name) {
            case ResumeKey.SUMMARY:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <SectionContent content={resume?.professional_summary?.professional_summary} />
                </div>
              );
            case ResumeKey.OBJECTIVE:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <SectionContent content={resume?.objective?.subtext} />
                </div>
              );
            case ResumeKey.SKILLS:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <InlineBulletedList
                    contentList={resume?.skills?.skills}
                    style={{ ...styles.contentContainer, ...styles.lineHeightNormal }}
                  />
                </div>
              );
            case ResumeKey.EXPERIENCE:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateWorkExperience
                    workExperience={resume?.work_experience}
                    sectionContentProps={{
                      headingStyle: standardBStyles.workExperienceContentHeader,
                      subHeadingStyle: styles.paddingX4,
                      contentStyle: styles.paddingX4,
                    }}
                    sectionHeadingProps={{ value2Style: styles.colorTertiary }}
                  />
                </div>
              );
            case ResumeKey.PROJECT:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateProject projects={resume?.project} />
                </div>
              );
            case ResumeKey.EDUCATION:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateEducation education={resume?.education} />
                </div>
              );
            case ResumeKey.COURSEWORK:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <InlineBulletedList
                    contentList={resume?.coursework?.coursework}
                    style={{ ...styles.contentContainer, ...styles.lineHeightNormal }}
                  />
                </div>
              );
            case ResumeKey.CERTIFICATES:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateCertification certifications={resume?.certifications_and_licenses} />
                </div>
              );
            case ResumeKey.VOLUNTEER_EXPERIENCE:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateVolunteerExperience volunteerExperience={resume?.volunteer_experience} />
                </div>
              );
            case ResumeKey.ACHIEVEMENTS:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplateAchievement achievements={resume?.achievements} />
                </div>
              );
            case ResumeKey.PATENTS:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplatePatent patents={resume?.patents} />
                </div>
              );
            case ResumeKey.PUBLICATIONS:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <TemplatePublication publications={resume?.publications} />
                </div>
              );
            case ResumeKey.INTERESTS:
              return (
                <div>
                  <SectionTitle title={section.name} style={standardBStyles.sectionHeading} />
                  <BulletedList
                    contentList={resume?.interests?.interests}
                    style={styles.contentContainer}
                  />
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    );
  }, [resume]);

  return (
    <div className="resume-template page">
      {resume.section_order.some(
        (section) =>
          (section.section_name === ResumeKey.CONTACT && isSectionEnabled(ResumeKey.CONTACT)) ||
          (section.section_name === ResumeKey.HERO && isSectionEnabled(ResumeKey.HERO)),
      ) && (
        <TemplateHeader
          isSectionEnabled={isSectionEnabled}
          contact={resume.contact}
          heroMessage={resume.hero_message}
          sectionContentProps={{ style: standardBStyles.contactContainer }}
        />
      )}
      {resumeComponents}
    </div>
  );
};

export default StandardB;
