import type { RatingProps } from '@mui/material';
import { Rating as MuiRating } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as FilledStar } from '../../assets/icons/fi-sr-star.svg';
import Icon from './Icon';

const StyledRating = styled(MuiRating)({
  '& .MuiRating-icon': {
    color: '#FFA824',
  },
  gap: '4px',
});

const Rating = ({
  value,
  precision = 0.5,
  icon,
  emptyIcon,
  readOnly = true,
  ...restProps
}: RatingProps) => {
  const FilledIcon = icon || <FilledStar />;
  const EmptyIcon = emptyIcon || <Icon className="fi fi-rr-star" fontSize="16px" />;
  return (
    <StyledRating
      value={value}
      precision={precision}
      icon={FilledIcon}
      emptyIcon={EmptyIcon}
      readOnly={readOnly}
      {...restProps}
    />
  );
};

export default Rating;
