export const TabsHeaderContent = [
  {
    label: 'Edit',
    value: 'edit',
    icon: 'fi fi-rr-pencil',
  },
  {
    label: 'Sections',
    value: 'sections',
    icon: 'fi fi-rr-layers',
  },
  {
    label: 'Template',
    value: 'template',
    icon: 'fi fi-rr-document',
  },
];
