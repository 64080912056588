// auth context, stores isLoggedIn, user, login, logout, signup, verifyOtp via a useAuth hook
// Path: src/contexts/auth.ts
import { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { useQueryClient } from '@tanstack/react-query';

import RoutePaths from '../routes/RoutePaths';
import { login, resendOtp, signup, verifyOtp } from '../services/auth';
import { Storage } from '../services/storage';
import { useSideBarStore } from '../stores/SideBarStore';

export interface User {
  name?: string;
  email: string;
  id: string;
  onboard_user: boolean;
}

interface AuthContextProps {
  isLoggedIn: boolean;
  isOnboardingCompleted: boolean;
  user: User | null;
  login: (email: string) => Promise<void>;
  signup: (email: string, name?: string) => Promise<void>;
  verifyOtp: (otp: string) => Promise<User> | Promise<void>;
  resendOtp: () => Promise<void>;
  logout: () => Promise<void>;
}

// TODO: consider moving this to a zustand store

const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  isOnboardingCompleted: false,
  user: null,
  login: async () => {},
  signup: async () => {},
  verifyOtp: async () => {},
  resendOtp: async () => {},
  logout: async () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(
    user ? user?.onboard_user === false : false,
  );
  const { reset: resetSideBarStore } = useSideBarStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleLogin = async (email: string) => {
    try {
      const res = await login(email);
      if (res?.otp_token) {
        Storage.setOtpToken(res?.otp_token);
        return await Promise.resolve();
      }
      return await Promise.reject(new Error('There is an error while login. Please try again.'));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleSignup = async (email: string, name?: string) => {
    try {
      const res = await signup(email, name);
      if (res?.otp_token) {
        Storage.setOtpToken(res?.otp_token);
        return await Promise.resolve();
      }
      return await Promise.reject(new Error('There is an error while login. Please try again.'));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleVerifyOtp = useCallback(async (otp: string) => {
    // TODO: handle this scenario after otpToken & token change from backend
    //   if (user || isLoggedIn) {
    //     return Promise.reject(new Error('User is already logged in'));
    //   }

    try {
      const res = await verifyOtp(otp);

      if (res?.user?.onboard_user) {
        setIsOnboardingCompleted(res?.user?.onboard_user === false);
      }
      if (res?.access_token) {
        Storage.removeOtpToken();
        Storage.setJwtToken(res?.access_token);
        setUser(res?.user);
        Storage.setCurrentUser(res?.user);
        setIsLoggedIn(true);

        if (res?.refresh_token) {
          Storage.setRefreshToken(res?.refresh_token);
        }

        return await Promise.resolve(res?.user);
      }
      return await Promise.reject(new Error('Invalid OTP'));
    } catch (error) {
      return Promise.reject(error);
    }
  }, []);

  const handleResendOtp = useCallback(async () => resendOtp(), []);

  const handleLogout = useCallback(async () => {
    Storage.clear(); // clear all local storage
    resetSideBarStore();
    setUser(null);
    setIsLoggedIn(false);
    queryClient.clear();
    setTimeout(() => {
      navigate(RoutePaths.SIGN_IN);
    }, 500);
  }, [resetSideBarStore, navigate, queryClient]);

  useLayoutEffect(() => {
    const storedUser = Storage.getCurrentUser();
    if (storedUser) {
      setUser(storedUser);
    }

    if (storedUser?.onboard_user) {
      setIsOnboardingCompleted(!storedUser.onboard_user);
    }

    const token = Storage.getJwtToken();
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      isOnboardingCompleted,
      user,
      login: handleLogin,
      signup: handleSignup,
      verifyOtp: handleVerifyOtp,
      resendOtp: handleResendOtp,
      logout: handleLogout,
    }),
    [handleLogout, handleResendOtp, handleVerifyOtp, isLoggedIn, isOnboardingCompleted, user],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
