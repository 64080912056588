import parse from 'html-react-parser';
import { useMemo } from 'react';

import { hasTextInsideHtml, isHtmlString, isNotNilOrEmpty } from '../../../utils';
import styles from '../styles/index';

export interface SectionContentProps {
  heading?: string | JSX.Element;
  subHeading?: string | JSX.Element;
  style?: React.CSSProperties | undefined;
  headingStyle?: React.CSSProperties | undefined;
  subHeadingStyle?: React.CSSProperties | undefined;
  contentStyle?: React.CSSProperties | undefined;
  content?: string | JSX.Element;
  date?: string;
}

const SectionContent = ({
  heading,
  subHeading,
  content,
  date,
  style,
  headingStyle,
  subHeadingStyle,
  contentStyle,
}: SectionContentProps) => {
  const memoizedContent = useMemo(() => {
    if (typeof content === 'string') {
      if (isHtmlString(content)) {
        return hasTextInsideHtml(content) ? (
          <div className="html-content">{parse(content || '')}</div>
        ) : null;
      }

      return <p>{content}</p>;
    }
    return content;
  }, [content]);

  const memoizedSubHeading = useMemo(() => {
    if (typeof subHeading === 'string') {
      if (isHtmlString(subHeading)) {
        return hasTextInsideHtml(subHeading) ? (
          <div className="html-content">{parse(subHeading || '')}</div>
        ) : null;
      }

      return <p>{subHeading}</p>;
    }
    return subHeading;
  }, [subHeading]);

  const memoizedHeading = useMemo(() => {
    if (typeof heading === 'string') {
      if (isHtmlString(heading)) {
        return hasTextInsideHtml(heading) ? (
          <div className="html-content">{parse(heading || '')}</div>
        ) : null;
      }

      return <p>{heading}</p>;
    }
    return heading;
  }, [heading]);

  return (
    <div style={{ ...styles.contentContainer, ...style }}>
      {isNotNilOrEmpty(heading) && (
        <div
          className="section-content-heading"
          style={{ ...styles.body1, ...styles.contentHeading, ...headingStyle }}
        >
          {memoizedHeading}
          {isNotNilOrEmpty(date) && <p>{date}</p>}
        </div>
      )}
      {isNotNilOrEmpty(subHeading) && (
        <div
          className="section-content-sub-heading"
          style={{ ...styles.label1, ...subHeadingStyle }}
        >
          {memoizedSubHeading}
        </div>
      )}
      {isNotNilOrEmpty(content) && (
        <div
          className="section-content"
          style={{ ...styles.body1, ...styles.lineHeight1_4, ...contentStyle }}
        >
          {memoizedContent}
        </div>
      )}
    </div>
  );
};

export default SectionContent;
