import { isNotNil } from 'ramda';

import { Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';

import useIsMobile from '../../../hooks/useIsMobile';
import Button from '../Button';
import { ButtonWithDisableTimer } from '../ButtonWithDisableTimer';
import Icon from '../Icon';

interface ModalActionsProps {
  type?: 'confirm' | 'delete';
  disableConfirmButtonDuration?: number;
  isLoading?: boolean;
  actions?: React.ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
  sx?: SxProps;
}

// TODO: Make this component more generic, rework how actions has displayed - by default it should be a Row not a coloumn
const ModalActions = ({
  type,
  disableConfirmButtonDuration,
  isLoading,
  actions,
  onClose,
  onConfirm,
  sx,
}: ModalActionsProps) => {
  const isMobile = useIsMobile();
  return (
    <Grid container sx={{ justifyContent: 'flex-end', gap: 1.5, ...sx }}>
      {isNotNil(actions) ? (
        <Grid item className="actions-grid" sx={{ flexGrow: { xs: 1, sm: 0 } }}>
          {actions}
        </Grid>
      ) : (
        <>
          <Grid item sx={{ flexGrow: { xs: 1, sm: 0 } }}>
            <Button
              variant={type === 'delete' && !isMobile ? 'text' : 'outlined'}
              color={type === 'delete' ? 'inherit' : 'primary'}
              sx={{
                color: type === 'delete' ? 'text.primary' : 'primary.main',
                border: { xs: '1.5px solid', sm: type === 'delete' ? 'unset' : '1.5px solid' },
                borderColor: {
                  xs: 'border.light',
                  sm: type === 'delete' ? 'unset' : 'primary.main',
                },
                width: { xs: '100%', sm: 'fit-content' },
                padding: { xs: '16px 12px', sm: '8px 12px' },
              }}
              onClick={onClose}
            >
              <Typography variant="label2">Cancel</Typography>
            </Button>
          </Grid>
          <Grid item sx={{ flexGrow: { xs: 1, sm: 0 } }}>
            {/* TODO: remove ButtonWithDisableTimer and use Button - make generic */}
            {type === 'delete' ? (
              <ButtonWithDisableTimer
                color="error"
                onClick={onConfirm}
                disableDuration={disableConfirmButtonDuration || 0}
                loading={isLoading}
                sx={{
                  width: { xs: '100%', sm: 'fit-content' },
                  padding: { xs: '16px 12px', sm: '8px 12px' },
                }}
              >
                <Icon className="fi fi-rr-trash" />
                <Typography variant="label2">Delete</Typography>
              </ButtonWithDisableTimer>
            ) : (
              <ButtonWithDisableTimer
                color="primary"
                onClick={onConfirm}
                disableDuration={disableConfirmButtonDuration || 0}
                loading={isLoading}
                sx={{
                  width: { xs: '100%', sm: 'fit-content' },
                  padding: { xs: '16px 12px', sm: '8px 12px' },
                }}
              >
                <Typography variant="label2">Confirm </Typography>
              </ButtonWithDisableTimer>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ModalActions;
