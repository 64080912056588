import { useMemo } from 'react';

import { Stack, Typography } from '@mui/material';

import { addSeparator, isNilOrEmpty, isNotNilOrEmpty } from '../../../utils';
import Alert from '../../common/Alert';
import type { CardProps } from '../../common/Card';
import Icon from '../../common/Icon';
import StyledCard from '../../common/StyledCard';

interface JobSummaryProps {
  summary: {
    job_company: string;
    job_location: {
      city: string | null;
      state: string | null;
      country: string | null;
    };
    employment_type: string;
    work_arrangement: string;
    salary_range: string;
    job_title: string;
  };
  cardProps?: CardProps;
}

const JobSummary = ({ summary, cardProps }: JobSummaryProps) => {
  const missingInfoMessage = useMemo(() => {
    const missingInfoArray = [];
    if (isNilOrEmpty(summary.job_title)) {
      missingInfoArray.push('Job Title');
    }
    if (isNilOrEmpty(summary.job_company)) {
      missingInfoArray.push('Company');
    }
    if (
      [summary.job_location.city, summary.job_location.state, summary.job_location.country].every(
        isNilOrEmpty,
      )
    ) {
      missingInfoArray.push('Location');
    }
    if (isNilOrEmpty(summary.employment_type)) {
      missingInfoArray.push('Employment Type');
    }
    if (isNilOrEmpty(summary.work_arrangement)) {
      missingInfoArray.push('Work Arrangement');
    }
    if (isNilOrEmpty(summary.salary_range)) {
      missingInfoArray.push('Salary Range');
    }
    if (missingInfoArray.length >= 2) {
      const lastValue = missingInfoArray.pop();
      return `${missingInfoArray.join(', ')} and ${lastValue}`;
    }
    return missingInfoArray.join('');
  }, [summary]);

  return (
    <StyledCard
      {...cardProps}
      cardContentProps={{
        ...cardProps?.cardContentProps,
        sx: {
          padding: 3,
          gap: 1,
          '&:last-child': {
            paddingBottom: 3,
          },
          ...cardProps?.cardContentProps?.sx,
        },
      }}
    >
      {isNotNilOrEmpty(summary.job_title) && (
        <Typography variant="h2">{summary.job_title}</Typography>
      )}
      {isNotNilOrEmpty(summary.job_company) && (
        <Typography variant="label1">{summary.job_company}</Typography>
      )}
      {[summary.job_location.city, summary.job_location.state, summary.job_location.country].some(
        isNotNilOrEmpty,
      ) && (
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Icon className="fi fi-rr-location-alt" fontSize="14px" />
          <Typography variant="body3">
            {/* TODO: update separator function to accept more than 2 values */}
            {addSeparator(
              addSeparator(summary.job_location.city, summary.job_location.state, ', '),
              summary.job_location.country,
              ', ',
            )}
          </Typography>
        </Stack>
      )}
      {(isNotNilOrEmpty(summary.employment_type) || isNotNilOrEmpty(summary.work_arrangement)) && (
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Icon className="fi fi-rr-briefcase" fontSize="14px" />
          <Typography variant="body3">
            {summary.employment_type || ''}
            <Typography
              variant="assistiveBold"
              component="span"
              sx={{ color: 'text.tertiary', paddingX: 0.5 }}
            >
              {[summary.employment_type, summary.work_arrangement].every(isNotNilOrEmpty) && '|'}
            </Typography>
            {summary.work_arrangement || ''}
          </Typography>
        </Stack>
      )}
      {isNotNilOrEmpty(summary.salary_range) && (
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Icon className="fi fi-rr-coins" fontSize="14px" />
          <Typography variant="body3">{summary.salary_range}</Typography>
        </Stack>
      )}
      {isNotNilOrEmpty(missingInfoMessage) && (
        <Alert
          severity="info"
          muiAlertIconStyles={{ padding: '1px 0px', marginRight: '8px' }}
          sx={{ '& .MuiAlert-message': { padding: 0 } }}
        >
          <Typography component="span" variant="label2">
            {'Missing Information: '}
          </Typography>
          <Typography component="span" variant="body3">
            {missingInfoMessage}
          </Typography>
        </Alert>
      )}
    </StyledCard>
  );
};
export default JobSummary;
