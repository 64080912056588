import type { ResponseStatus } from '../interface';
import http from './http';

interface ProgressStatus {
  id: string;
  endpoint: string;
}

export interface MissingInfoPayload {
  job_description_id?: string;
  job_company?: string;
  job_title?: {
    Job_Title: string;
  };
}

interface MissingInfoFormData {
  endpoint: string;
  payload: MissingInfoPayload;
}

export interface JobXrayProgressStatus {
  _id: string;
  status: ResponseStatus;
  progress: number;
  error: string;
  missing_fields: string[];
}

export interface ResumeScanProgressStatus {
  _id: string;
  status: ResponseStatus;
  progress: number;
  error: string;
}

export interface JobMatchScoreProgressStatus {
  progress: number;
  resume: {
    status: ResponseStatus;
    progress: number;
    error: string;
  };
  job_description: {
    id: string;
    status: ResponseStatus;
    progress: number;
    error: string;
    missing_fields: string[];
  };
  match_score: {
    status: ResponseStatus;
    progress: number;
    error: string;
  };
}

export interface AiCustomizeResumeProgressStatus {
  progress: number;
  resume: {
    status: ResponseStatus;
    progress: number;
    error: string;
  };
  job_description: {
    id: string;
    status: ResponseStatus;
    progress: number;
    error: string;
    missing_fields: string[];
  };
  ai_customization: {
    status: ResponseStatus;
    progress: number;
    error: string;
  };
}

export const getProgressStatus = async ({ id, endpoint }: ProgressStatus) => {
  if (!id) {
    return Promise.reject(new Error('Id is required for progress status'));
  }

  try {
    const res = await http.get(`${endpoint}/${id}/status`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addMissingInfo = async ({ endpoint, payload }: MissingInfoFormData) => {
  try {
    const res = await http.patch(endpoint, payload);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// Create tasks: create job match score, create AI Customize resume, create job xray, create resume scan
export const continueCreateTask = async ({ endpoint, payload }: MissingInfoFormData) => {
  try {
    const res = await http.post(endpoint, payload);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
