import { Stack, Typography } from '@mui/material';

import type { CardProps } from '../../../components/common/Card';
import StyledCard from '../../../components/common/StyledCard';
import useIsMobile from '../../../hooks/useIsMobile';
import { isNotNilOrEmpty } from '../../../utils';
import type { RawJdData } from './interface';

interface RawJobDescriptionProps {
  rawJd: RawJdData | undefined;
  cardProps?: CardProps;
}
const RawJobDescription = ({ rawJd, cardProps }: RawJobDescriptionProps) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isNotNilOrEmpty(rawJd) && (
        <StyledCard
          {...cardProps}
          cardContentProps={{
            ...cardProps?.cardContentProps,
            sx: {
              padding: 3,
              gap: 2,
              '&:last-child': {
                paddingBottom: 3,
              },
              ...cardProps?.cardContentProps?.sx,
            },
          }}
        >
          <Stack gap={0.25}>
            <Typography variant={isMobile ? 'h3' : 'h2'}>{rawJd?.job_company}</Typography>
            <Typography variant="label1">{rawJd?.job_title}</Typography>
          </Stack>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {rawJd?.job_description}
          </Typography>
        </StyledCard>
      )}
    </>
  );
};
export default RawJobDescription;
