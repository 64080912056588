const DocumentBaseStyles = () => (
  <style>
    {`@import url('https://rsms.me/inter/inter.css');

      .template-preview .page {
        padding: 14px;
      }

      .resume-template p {
        margin: 0;
      }

      .resume-template .html-content .editor-listitem,
      .resume-template .html-content li {
        margin: 3px 32px 3px 20px;
        /* Body1 */
        font-size: 10pt;
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
        font-family: 'Inter', sans-serif;
      }

      .resume-template .html-content .editor-list-ul,
      .resume-template ul {
        margin: 0;
        padding: 0;
      }

      .resume-template .html-content .editor-paragraph {
        padding: 0;
        margin: 0;
        font-size: 10pt;
        font-weight: 400;
        font-style: normal;
        line-height: 1.4;
        font-family: 'Inter', sans-serif;
      }

      .resume-template .html-content .editor-nested-listitem {
        list-style: none;
      }

      .resume-template .bulleted-list li {
        margin: 0px 0px 0px 20px;
      }

      .resume-template .header-info .editor-paragraph {
        padding: 0;
        margin: 0;
        font-size: 12pt;
        font-weight: 600;
        font-style: normal;
        line-height: 1.4;
        font-family: 'Inter', sans-serif;
      }
    `}
  </style>
);

export default DocumentBaseStyles;
