import { isNotNil } from 'ramda';
import type { ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

import Chip from '../common/Chip';

// TODO: revisit Props
interface ColumnCellProps {
  primaryText?: string;
  secondaryText?: string;
  isAiCustomized?: boolean;
  isAiGenerated?: boolean;
}

export const ColumnCell = ({
  primaryText = 'Base',
  secondaryText,
  isAiCustomized,
  isAiGenerated,
}: ColumnCellProps): ReactNode => (
  <Stack>
    <Typography
      variant="body2"
      sx={{
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        wordBreak: 'break-word',
      }}
    >
      {primaryText}
      {isAiCustomized && <Chip label="AI Customized" />}
      {isAiGenerated && <Chip label="AI Sample" />}
    </Typography>
    {isNotNil(secondaryText) && (
      <Typography
        variant="body2"
        sx={{ wordWrap: 'wrap', wordBreak: 'break-all', color: 'text.secondary' }}
      >
        {secondaryText}
      </Typography>
    )}
  </Stack>
);
