import styles from '../styles/index';

/* eslint-disable react/no-array-index-key */
interface BulletedListProps {
  contentList?:
    | string[]
    | {
        bulletIcon: string;
        content: string | React.ReactElement;
      }[];
  style?: React.CSSProperties | undefined;
}

const BulletedList = ({ contentList = [], style }: BulletedListProps) => (
  <ul className="bulleted-list" style={{ ...styles.body1, ...style }}>
    {contentList.map((content, index) =>
      typeof content === 'string' ? (
        <li key={index}>{content}</li>
      ) : (
        <li
          key={index}
          className={content.bulletIcon}
          style={{
            ...styles.paddingBottom3,
            ...styles.wordBreakAll,
          }}
        >
          {content.content}
        </li>
      ),
    )}
  </ul>
);

export default BulletedList;
