import * as React from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { type SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import Icon from './Icon';

interface TabItem {
  label: string;
  value: string;
  icon?: string;
}

interface TabsProps {
  tabs: TabItem[];
  onChange?: (newValue: string) => void;
  defaultSelected?: string | number;
  children: JSX.Element | JSX.Element[];
  sx?: SxProps;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onChange, defaultSelected, children, sx }) => {
  const [value, setValue] = React.useState(defaultSelected || tabs[0].value);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', ...sx }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', height: 'fit-content' }}>
          <TabList
            onChange={handleChange}
            aria-label="tabs list"
            sx={{
              '& .MuiTabs-flexContainer': {
                gap: { xs: 0, sm: 2 },
              },
              minHeight: 'inherit',
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={
                  <Icon
                    className={tab.value === value ? tab.icon?.replace('rr', 'br') : tab.icon}
                    style={{
                      height: '20px',
                      width: 'auto',
                    }}
                  />
                }
                iconPosition="start"
                sx={{
                  flexGrow: { xs: 1, sm: 0 },
                  textTransform: 'none',
                  minWidth: 'auto',
                  minHeight: 'fit-content',
                  paddingY: '8px',
                  paddingX: { xs: '8px', sm: '12px' },
                  color: 'text.primary',
                  // Desktop variant Body 2
                  // Mobile variant Body 3
                  fontSize: { xs: '14px', sm: '16px' },
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: { xs: '20px', sm: '24px' },
                  // Label 1 and 2
                  '&.Mui-selected': {
                    fontWeight: 600,
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {React.Children.toArray(children).map((child, index) => (
          <TabPanel
            key={tabs[index].value}
            value={tabs[index].value}
            sx={{ padding: 0, paddingTop: 3 }}
          >
            {child}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default Tabs;
