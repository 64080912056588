import { Box, FormHelperText, useTheme } from '@mui/material';

import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import TextField from '../../common/TextField';
import TextWrappedLinkButton from '../../common/TextWrappedLinkButton';

interface ImportJobDescriptionContentProps {
  jobDescriptionText?: string;
  onShowExample: () => void;
  onJobDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImportJobDescriptionContent = ({
  jobDescriptionText,
  onShowExample,
  onJobDescriptionChange,
}: ImportJobDescriptionContentProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveDevice();

  return (
    <>
      <FormHelperText sx={{ margin: 0, marginBottom: 1 }}>
        <TextWrappedLinkButton
          variant="body3"
          text={{
            startingText:
              'Copy and paste the entire job description from the job posting, including the company name and job title. You don’t have to clean up the text. ',
            linkText: 'Example',
            endingText: '',
          }}
          sx={{ ...(isMobile && { color: 'text.secondary', fontWeight: 700 }) }}
          onClick={onShowExample}
        />
      </FormHelperText>
      {/* Job Description text input field*/}
      <Box sx={{ width: '100%', height: '100%' }}>
        <TextField
          type="textarea"
          multiline
          minRows={isMobile ? 13 : 10}
          maxRows={isMobile ? 13 : 16}
          sx={{
            '& .MuiOutlinedInput-root': {
              fieldset: {
                borderColor: 'border.light',
                borderRadius: theme.spacing(1),
              },
            },
            width: '100%',
          }}
          value={jobDescriptionText}
          onChange={onJobDescriptionChange}
        />
      </Box>
    </>
  );
};

export default ImportJobDescriptionContent;
