import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

// TODO: Verify & remove these, as we've convertd to pts in the styles
// Increasing font size for PDF generation by 34%
export enum PDFFontSizes {
  '9px' = '9pt',
  '10px' = '10pt',
  '12px' = '12pt',
  '16px' = '16pt',
}

const useHTMLRenderer = () => {
  const generateHtml = (
    children: JSX.Element,
    baseFontSize: PDFFontSizes = PDFFontSizes['10px'],
  ) => {
    let html = '';

    try {
      // Create a temporary div to render the React component into
      const tempDiv = document.createElement('div');
      tempDiv.style.display = 'none';

      // document.body.appendChild(tempDiv);

      // // Render the component into the temporary div
      const root = createRoot(tempDiv);

      flushSync(() => {
        root.render(children);
      });

      // Get the inner HTML content
      const renderedHTML = tempDiv.innerHTML;

      // Construct the HTML content with provided styles and rendered HTML
      html = `
      <!DOCTYPE html>
      <html>
      <style>
      .header-info .section-content-heading, 
      .header-info .section-content-heading .html-content .editor-paragraph {
        font-size: ${PDFFontSizes['16px']} !important;
      }

      .section-title, 
      .header-info .section-content-sub-heading, 
      .header-info .section-content-sub-heading .html-content .editor-paragraph {
        font-size: ${PDFFontSizes['12px']} !important;
      }
    
      .section-content-heading,
      .section-content-sub-heading, 
      .section-content, 
      .editor-paragraph, 
      .editor-listitem, 
      .editor-nested-listitem, 
      .html-content li, 
      .string-concatenator, 
      .string-concatenator span, 
      .bulleted-list li, 
      .inline-bulleted-list {
        font-size: ${baseFontSize} !important; 
      }

      .header-info  .section-content, 
      .header-info .section-content .html-content .editor-paragraph {
        font-size: ${PDFFontSizes['9px']} !important;
      }
      </style>
      <style>
  * {
    -webkit-print-color-adjust: exact !important;
    text-rendering: geometricprecision !important;
    -webkit-font-smoothing: antialiased !important;
}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
}
</style>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <head><link rel="stylesheet" href="index.css"></head>
      <body>${renderedHTML}</body>
      </html>`;

      // clean up the temporary div
      tempDiv.remove();
    } catch (error) {
      throw new Error('An error occurred:', error as ErrorOptions);
    }

    return html;
  };

  return {
    generateHtml,
  };
};

export default useHTMLRenderer;
