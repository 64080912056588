import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../../components/DragnDrop/SortableItem';
import FormActions from '../../../components/Form/FormActions';
import type { ProjectInfo, SortedResumeSectionKey } from '../../../interface';
import { isNotNilOrEmpty } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';

interface ProjectsReorderProps {
  projects: ProjectInfo[] | undefined;
  isLoading?: boolean;
  onSubmit?: (sectionsOrder: SortedResumeSectionKey[]) => void;
}

const ProjectsReorder = ({ projects = [], isLoading, onSubmit }: ProjectsReorderProps) => {
  const [projectsOrder, setProjectsOrder] = useState(projects);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = projectsOrder.findIndex((project) => project._id === active.id);
      const newIndex = projectsOrder.findIndex((project) => project._id === over.id);
      const movedArray = arrayMove(projectsOrder, oldIndex, newIndex);

      setProjectsOrder(movedArray);
    }
  };

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedProjectKeys = projectsOrder.map((project, index) => ({
        id: project._id,
        index,
      }));

      onSubmit(sortedProjectKeys);
    }
  };

  return (
    // TODO: add to common components
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={projectsOrder.map((project) => project._id)}
          strategy={verticalListSortingStrategy}
        >
          {projectsOrder.map((project) => (
            <SortableItem
              key={project._id}
              id={project._id}
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: '12px',
                borderColor: 'border.light',
                backgroundColor: 'background.light',
                '&:hover': {
                  backgroundColor: 'background.mediumLight',
                },
              }}
            >
              <Stack gap={0.5}>
                {isNotNilOrEmpty(project.project_name) && (
                  <Typography variant="label1">{project.project_name}</Typography>
                )}
                {isNotNilOrEmpty(project.organization) && (
                  <Typography variant="body2">{project.organization}</Typography>
                )}
                <Typography variant="body2">
                  {formatDate(
                    project.start_month,
                    project.start_year,
                    project.end_month,
                    project.end_year as number,
                    project.present,
                  )}
                </Typography>
              </Stack>
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <FormActions onSubmit={onSaveClick} submitButtonProps={{ loading: isLoading }} />
    </Stack>
  );
};

export default ProjectsReorder;
