import { isEmpty } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import TemplateDocument from '../../components/ResumeTemplate/TemplateDocument';
import TemplatePreview from '../../components/ResumeTemplate/TemplatePreview';
import ResumeView from '../../components/ResumeView';
import SelectLayout from '../../components/ResumeView/components/TemplatePreview/SelectLayout';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import Modal from '../../components/common/Modal/Modal';
import MultiColumnLayout from '../../components/common/MultiColumnLayout';
import Tabs from '../../components/common/Tabs';
import { useAuth } from '../../contexts/auth';
import { useSnackbar } from '../../contexts/snackbar';
import { useDownload } from '../../hooks/useDownload';
import useHTMLRenderer from '../../hooks/useHTMLRenderer';
import useIsMobile from '../../hooks/useIsMobile';
import useResponsiveAppBar from '../../hooks/useResponsiveAppBar';
import type {
  ResumeData,
  ResumeTemplateInfo,
  ResumeTemplateKey,
  SectionOrder,
  SelectedResumeTemplateInfo,
} from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import {
  ResumesKeys,
  getCareerStages,
  getResumeTemplate,
  getResumeXRay,
  manageReorderResumeSections,
  updateCareerStage,
  updateResumeSectionData,
} from '../../services/resumes';
import { useAppBarStore } from '../../stores/AppBarStore';
import { useSideBarStore } from '../../stores/SideBarStore';
import { getResumeTemplateBaseFontSize, isNotNilOrEmpty } from '../../utils';
import { TabsHeaderContent } from './TabsHeaderContent';
import ManageSections from './components/ManageSections';
import ResumePageHeader from './components/ResumePageHeader';

const ResumePage = () => {
  const { user } = useAuth();
  const match = useMatch('/resumes/:lastPart');
  const urlId = match?.params.lastPart || '';
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const theme = useTheme();

  const { downloadResume } = useDownload();
  const { showSnackbar } = useSnackbar();
  const { generateHtml } = useHTMLRenderer();
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | number | undefined>(0);
  const { setAppBar } = useAppBarStore();

  const BreadCrumbLinks = isMobile
    ? [
        {
          label: 'Back',
          href: RoutePaths.RESUMES,
        },
      ]
    : [
        {
          label: 'Resumes',
          href: RoutePaths.RESUMES,
        },
        {
          label: 'View',
          href: `/resumes/${urlId}`,
        },
      ];

  const { data: resume, isLoading } = useQuery<ResumeData>({
    queryKey: [ResumesKeys.RESUMES, urlId],
    queryFn: () => getResumeXRay(urlId),
    enabled: !!urlId,
  });

  const { data: templates } = useQuery<ResumeTemplateInfo[]>({
    queryKey: [ResumesKeys.TEMPLATES],
    queryFn: getResumeTemplate,
    staleTime: 15 * 60 * 1000 /* 15 minutes cache */,
    cacheTime: 15 * 60 * 1000 /* 15 minutes cache */,
  });

  const { data: careerStages, isLoading: isCareerStagesLoading } = useQuery({
    queryKey: [ResumesKeys.CAREER_STAGES],
    queryFn: getCareerStages,
    staleTime: 15 * 60 * 1000 /* 15 minutes cache */,
    cacheTime: 15 * 60 * 1000 /* 15 minutes cache */,
  });

  const careerStageTitles = useMemo(
    () => Object.values(careerStages || {})?.map((stage) => stage.title),
    [careerStages],
  );

  const selectedTemplate = useMemo(
    () => resume?.selected_template?.key as ResumeTemplateKey,
    [resume],
  );

  const shouldShowAiCustomize = useMemo(
    () => !resume?.is_ai_customized && !resume?.is_ai_generated && resume?.id,
    [resume],
  );

  const baseFontSize = useMemo(
    () => getResumeTemplateBaseFontSize(selectedTemplate),
    [selectedTemplate],
  );

  const ResumeDocument = useMemo(() => {
    if (resume) {
      return <TemplateDocument resume={resume} selectedTemplateKey={selectedTemplate} />;
    }
    return null;
  }, [resume, selectedTemplate]);

  // react query mutation to update the resume career stage
  const { mutate: updateResumeCareerStage } = useMutation({
    mutationFn: updateCareerStage,
    retry: 2,
    onMutate: async (careerStage) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });

      // Snapshot the previous value
      const previousResume = queryClient.getQueryData([ResumesKeys.RESUMES, urlId]);

      // Optimistically update to the new value
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], {
        ...resume,
        career_stage: careerStage,
      });

      // Return a context with the previous and new todo
      return { previousResume };
    },
    onSuccess: (_data) => {
      queryClient.invalidateQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Career stage updated');
      showSnackbar(
        'info',
        `Career stage updated to ${careerStages ? careerStages[_data?.career_stage]?.title : ''}`,
      );
    },
    onError: (_error, _variable, context) => {
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], context?.previousResume);
      showSnackbar('error', 'Failed to update career stage');
    },
  });

  const { mutate: reorderResumeSections } = useMutation({
    mutationFn: manageReorderResumeSections,
    onMutate: async (sectionOrder) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });

      // Snapshot the previous value
      const previousResume = queryClient.getQueryData([ResumesKeys.RESUMES, urlId]);

      // Optimistically update to the new value
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], {
        ...resume,
        career_stage: 'custom',
        section_order: sectionOrder.sections,
      });

      // Return a context with the previous and new todo
      return { previousResume };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Resume sections updated');
      showSnackbar('info', 'Sections updated');
    },
    onError: (_error, _variable, context) => {
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], context?.previousResume);
      showSnackbar('error', 'Failed to update reorder');
    },
  });

  const { mutate: updateSelectedResumeTemplate } = useMutation({
    mutationFn: updateResumeSectionData,
    onMutate: async (newTemplate) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });

      // Snapshot the previous value
      const previousResume = queryClient.getQueryData([ResumesKeys.RESUMES, urlId]);
      const { selected_template_key: id } = newTemplate.data as SelectedResumeTemplateInfo;

      // Optimistically update to the new value
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], {
        ...resume,
        selected_template: {
          ...resume?.selected_template,
          _id: id,
        },
        ...newTemplate.data,
      });

      // Return a context with the previous and new todo
      return { previousResume };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ResumesKeys.RESUMES, urlId] });
      logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'Resume template updated');
      showSnackbar('info', `Resume template updated`);
    },
    // If the mutation fails, use the context we returned above
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any, _data, context) => {
      queryClient.setQueryData([ResumesKeys.RESUMES, urlId], context?.previousResume);
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const onDownloadFile = useCallback(async () => {
    if (resume) {
      const html = generateHtml(ResumeDocument as JSX.Element, baseFontSize);
      const filename = `${user?.name ? `${user?.name.replace(/ /g, '_')}_resume` : 'resume'}.pdf`;
      downloadResume({ html, options: { filename } });
      if (isMobile) {
        setOpenPreview(false);
      }
    }
  }, [ResumeDocument, baseFontSize, downloadResume, generateHtml, isMobile, resume, user?.name]);

  const onAiCustomizeClick = useCallback(() => {
    navigate(`/resumes/ai-customized/${resume?.id}`, {
      state: { filename: resume?.filename },
    });
  }, [resume, navigate]);

  const onChangeSection = (sectionsOrders: SectionOrder[]) => {
    reorderResumeSections({ resumeId: urlId, sections: sectionsOrders });
  };

  const onChangeResumeTemplate = (template: ResumeTemplateInfo) => {
    updateSelectedResumeTemplate({
      resumeId: urlId,
      data: { selected_template_key: template._id },
    });
  };

  const onChangeCareerStage = (careerStage: string | number | null) => {
    if (careerStages) {
      const careerStageKey = Object.keys(careerStages).find(
        (key) => careerStages[key].title === careerStage,
      ) as string;

      updateResumeCareerStage({
        resumeId: urlId,
        careerStage: careerStageKey,
      });
    }
  };

  const MobileViewControls = useMemo(
    () => (
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        {!resume?.is_ai_customized && !resume?.is_ai_generated && !isLoading && (
          <Button
            variant="text"
            sx={{ padding: 1.5, minWidth: 'max-content' }}
            onClick={onAiCustomizeClick}
          >
            <Icon className="fi fi-rr-magic-wand" fontSize="20px" />
          </Button>
        )}
        <Button
          variant="text"
          sx={{ padding: 1.5, minWidth: 'max-content' }}
          onClick={() => setOpenPreview(true)}
        >
          <Icon className="fi fi-rr-download" fontSize="20px" />
        </Button>
      </Stack>
    ),
    [onAiCustomizeClick, resume?.is_ai_customized, resume?.is_ai_generated, isLoading],
  );

  useResponsiveAppBar('Resume', MobileViewControls);

  // TODO: quickfix -  temporary fix and needs to optimize setting in useResponsiveAppbar hook
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setAppBar('Resume', MobileViewControls);
      }, 500);
    }
  }, [MobileViewControls, isLoading, setAppBar]);

  return openPreview && isMobile ? (
    <Modal
      contentSx={{
        width: '100%',
        height: '100%',
        maxHeight: '100vh',
        gap: 1,
        boxSizing: 'border-box',
        borderRadius: '12px 12px 0px 0px',
      }}
      open={openPreview}
    >
      <>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '4px 12px',
            borderBottom: '1px solid',
            borderColor: 'border.light',
          }}
        >
          <Typography
            sx={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: '25px' }}
          >
            Preview
          </Typography>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <Button
              variant="text"
              sx={{ padding: 1.5, minWidth: 'max-content' }}
              onClick={onDownloadFile}
            >
              <Icon className="fi fi-rr-download" fontSize="20px" />
            </Button>
            <Button
              variant="text"
              sx={{ padding: 1.5, minWidth: 'max-content' }}
              onClick={() => setOpenPreview(false)}
            >
              <Icon
                className="fi fi-br-cross"
                style={{ fontSize: '20px', color: theme.palette.text.tertiary }}
              />
            </Button>
          </Stack>
        </Stack>
        {resume && (
          <Box sx={{ overflowY: 'auto' }}>
            <TemplatePreview resume={resume} selectedTemplateKey={selectedTemplate} />
          </Box>
        )}
      </>
    </Modal>
  ) : (
    <Stack
      sx={{
        backgroundColor: 'common.white',
        // TODO: quick fix for padding, we need to handle page layout margins and padding
        // eslint-disable-next-line no-nested-ternary
        paddingLeft: isMobile ? 1.5 : isOpen ? 4 : 7,
        paddingRight: isMobile ? 1.5 : 4,
        paddingY: isMobile ? 2.5 : 4,
        overflow: 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        {isLoading && <LoadingSpinner />}

        <ResumePageHeader
          resume={resume}
          BreadCrumbLinks={BreadCrumbLinks}
          shouldShowAiCustomize={shouldShowAiCustomize as boolean}
          onAiCustomizeClick={onAiCustomizeClick}
          onDownloadFile={onDownloadFile}
        />

        {resume && isNotNilOrEmpty(resume) && (
          <Grid xs={12} sx={{ paddingX: { xs: 0, sm: 3.5 } }}>
            <Tabs tabs={TabsHeaderContent} defaultSelected={selectedTab} onChange={setSelectedTab}>
              <ResumeView resume={resume} editable />
              <MultiColumnLayout columnProps={{ sm: 6 }}>
                <ManageSections
                  loading={isCareerStagesLoading}
                  sectionsOrder={resume?.section_order}
                  careerStages={careerStageTitles}
                  defaultCareerStage={
                    resume?.career_stage ? careerStages?.[resume.career_stage]?.title : ''
                  }
                  onSectionsReorder={onChangeSection}
                  onCareerStageChange={onChangeCareerStage}
                />
                <>
                  {!isMobile && (
                    <TemplatePreview resume={resume} selectedTemplateKey={selectedTemplate} />
                  )}
                </>
              </MultiColumnLayout>
              <MultiColumnLayout columnProps={{ sm: 6 }}>
                <SelectLayout
                  templates={templates}
                  selectedTemplateKey={resume?.selected_template?._id || templates?.[0]._id}
                  onChange={onChangeResumeTemplate}
                />
                <>
                  {!isMobile && (
                    <TemplatePreview resume={resume} selectedTemplateKey={selectedTemplate} />
                  )}
                </>
              </MultiColumnLayout>
            </Tabs>
          </Grid>
        )}

        {!isLoading && isEmpty(resume) && (
          <Typography
            sx={{
              marginTop: 4,
              textAlign: 'center',
            }}
            variant="body2"
          >
            Data is being process. Check back in some time.
          </Typography>
        )}
      </Grid>
    </Stack>
  );
};

export default ResumePage;
