import { isNotNil } from 'ramda';
import { useMemo } from 'react';

import type { AccordionDetailsProps as MUIAccordionDetailsProps } from '@mui/material';
import { AccordionDetails as MUIAccordionDetails, Stack, Typography } from '@mui/material';

import { ReactComponent as CheckIcon } from '../../../../../assets/icons/fi-br-check.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/fi-br-cross.svg';
import { ReactComponent as InterrogationIcon } from '../../../../../assets/icons/fi-br-interrogation.svg';
import Alert from '../../../../../components/common/Alert';
import type { JobMatchScoreReportItem } from '../../../interface';
import { ResultType } from '../../../interface.d';

interface AccordionDetailsProps extends MUIAccordionDetailsProps {
  jobMatchScoreReportItems: JobMatchScoreReportItem[];
}

const AccordionDetails = ({ jobMatchScoreReportItems, ...restProps }: AccordionDetailsProps) => {
  const iconMapping = useMemo(
    () => ({
      [ResultType.Match]: <CheckIcon style={{ width: '20px', height: '20px' }} />,
      [ResultType.NoMatch]: <CrossIcon style={{ width: '20px', height: '20px' }} />,
      [ResultType.PartialMatch]: <InterrogationIcon style={{ width: '20px', height: '20px' }} />,
    }),
    [],
  );

  return (
    <>
      {isNotNil(jobMatchScoreReportItems) && (
        <MUIAccordionDetails {...restProps} sx={{ padding: 0 }}>
          <Stack gap={2.5}>
            {jobMatchScoreReportItems.map((item) => (
              <Alert
                key={item._id}
                icon={iconMapping[item.result as ResultType]}
                sx={{
                  padding: 0,
                  paddingLeft: { xs: 0, sm: 5 },
                  backgroundColor: 'white',
                  '& .MuiAlert-icon': {
                    paddingTop: 0.6,
                    marginRight: 2.5,
                  },
                }}
              >
                <Stack gap={1}>
                  <Typography variant="label1">{item?.requirement}</Typography>
                  <Typography variant="body2">{item?.resume}</Typography>
                </Stack>
              </Alert>
            ))}
          </Stack>
        </MUIAccordionDetails>
      )}
    </>
  );
};

export default AccordionDetails;
