import { encode } from 'js-base64';
import { useState } from 'react';

import { EventAction, EventCategory, logEvent } from '../services/analytics';
import { generateResume } from '../services/resumes';

interface DownloadOptions {
  filename?: string;
  margins?: { top: string; right: string; bottom: string; left: string };
}
interface DownloadResumeProps {
  html: string;
  options?: DownloadOptions;
}

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const downloadFromBlob = (blob: BlobPart, filename = '') => {
    if (!blob) return;

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename || 'resume.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadResume = async ({ html, options }: DownloadResumeProps) => {
    if (!html) throw new Error('Html content is required to download resume');

    setIsLoading(true);
    setError(null);
    try {
      // send html as base64 string to backend for pdf generation
      const base64Html = encode(html); // using js-base64 library because btoa has issues with utf-8 characters
      const response = await generateResume(base64Html, {
        filename: options?.filename,
        margins: options?.margins || {
          top: '25',
          right: '25',
          bottom: '25',
          left: '25',
        },
      });

      // download the pdf from blob response
      downloadFromBlob(response, 'resume.pdf');
      logEvent(EventCategory.USER_INTERACTION, EventAction.DOWNLOAD, 'Resume downloaded');
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, downloadResume };
};
