/* eslint-disable react/no-array-index-key */
import type { PublicationInfo } from '../../../interface';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';
import type { StringConcatenatorProps } from './StringConcatenator';
import StringConcatenator from './StringConcatenator';

interface TemplatePublicationProps {
  publications: PublicationInfo[];
  sectionContentProps?: SectionContentProps;
  sectionHeadingProps?: StringConcatenatorProps;
}

const TemplatePublication = ({
  publications,
  sectionContentProps,
  sectionHeadingProps,
}: TemplatePublicationProps) => (
  <>
    {publications?.map((publication, index) => (
      <SectionContent
        key={index}
        heading={publication?.title}
        subHeading={
          <StringConcatenator
            value1={publication?.publisher}
            value2={getFormatedDate(publication)}
            value1Style={{
              ...styles.label2,
              ...styles.colorPrimary,
              ...sectionHeadingProps?.value1Style,
            }}
            value2Style={{ ...styles.body1, ...sectionHeadingProps?.value2Style }}
            separator=" • "
            {...sectionHeadingProps}
          />
        }
        subHeadingStyle={{ ...styles.colorSecondary, ...sectionContentProps?.subHeadingStyle }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplatePublication;
