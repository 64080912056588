// reference: https://dev.to/jackherizsmith/making-a-progress-circle-in-react-3o65
// modified for our use cases
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

// Tweak Dimensions to change size of circle
const Dimensions = {
  small: {
    svgHeight: 50,
    svgWidth: 50,
    circleR: 17.5,
    circleCX: 175,
    circleCY: 25,
    strokeWidth: 4,
  },
  large: {
    svgHeight: 220,
    svgWidth: 220,
    circleR: 100,
    circleCX: 90,
    circleCY: 110,
    strokeWidth: 16,
  },
};

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  // eslint-disable-next-line no-nested-ternary
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

interface CircleProps {
  colour: string;
  pct?: number;
  size?: 'small' | 'large';
  circleDirection?: 'clockwise' | 'anticlockwise';
}

const Circle = ({
  colour,
  pct = 0,
  size = 'small',
  circleDirection = 'clockwise',
}: CircleProps) => {
  const r = Dimensions[size].circleR;
  const circ = 2 * Math.PI * r;
  const strokePct =
    circleDirection === 'anticlockwise' ? ((100 - pct) * circ) / 100 : -((100 - pct) * circ) / 100;

  return (
    <circle
      r={r}
      cx={Dimensions[size].circleCX}
      cy={Dimensions[size].circleCY}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
      strokeWidth={Dimensions[size].strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? -strokePct : 0}
      strokeLinecap="round"
    />
  );
};

const Text = ({ percentage }: { percentage: number }) => (
  <text
    x="50%"
    y="50%"
    dominantBaseline="central"
    textAnchor="middle"
    fontSize="14px"
    fontWeight={600}
  >
    {percentage.toFixed(0)}
  </text>
);

interface ProgressCircleProps {
  percentage: number;
  colour: string;
  ascentStrokeColour?: string;
  size?: 'small' | 'large';
  content?: React.ReactNode;
}

const ProgressCircle = ({
  percentage,
  colour,
  ascentStrokeColour = '#EBEBEB',
  size = 'small',
  content,
}: ProgressCircleProps) => {
  const pct = cleanPercentage(percentage);

  return (
    <Stack
      sx={{
        position: 'relative',
        height: `${Dimensions[size].svgHeight}px`,
        width: `${Dimensions[size].svgWidth}px`,
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ position: 'absolute' }}>
        <svg width={Dimensions[size].svgWidth} height={Dimensions[size].svgHeight}>
          <g transform={`rotate(-90 ${'100 100'})`}>
            <Circle size={size} colour={ascentStrokeColour} pct={100} />
            <Circle size={size} colour={colour} pct={pct} />
          </g>
          {!content && <Text percentage={pct} />}
        </svg>
      </Box>
      {content && <Box sx={{ position: 'relative' }}>{content}</Box>}
    </Stack>
  );
};

export default ProgressCircle;
