import { isNil, isNotNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../../../contexts/snackbar';
import useResponsiveDevice from '../../../../hooks/useResponsiveDevice';
import type { ProjectInfo } from '../../../../interface';
import { ResumesKeys, deleteResumeProject } from '../../../../services/resumes';
import Icon from '../../../common/Icon';
import MobileTooltip from '../../../common/MobileTooltip';
import Tooltip from '../../../common/Tooltip';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';
import ProjectDetail from './components/ProjectDetail';

interface DisplayProjectProps {
  projects?: ProjectInfo[];
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
  tooltipContent?: string | string[];
}

interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

const DisplayProjects = ({
  projects,
  editable,
  resumeId,
  hideEmptySection,
  tooltipContent,
}: DisplayProjectProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveDevice();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const shouldhideEmptySection =
    hideEmptySection && (isNil(projects) || (isNotNil(projects) && projects.length === 0));

  const resumeEditorState: ResumeEditorState = {
    action: '',
    resumeId: resumeId || '',
    elementId: '',
    elementType: 'project',
  };

  const { mutate: deleteProject } = useMutation({
    mutationFn: deleteResumeProject,
    onSuccess: () => {
      showSnackbar('success', 'Resume project deleted');
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries([ResumesKeys.RESUMES, resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const handleAddWorkProject = () => {
    resumeEditorState.action = 'add';
    navigate('/resumes/edit/project', {
      state: { resumeEditorState },
    });
  };

  const handleEditProject = (id: string) => {
    resumeEditorState.action = 'edit';
    resumeEditorState.elementId = id;
    navigate('/resumes/edit/project', {
      state: { resumeEditorState },
    });
  };

  const handleDeleteProject = useCallback(
    (id: string) => {
      deleteProject(id);
    },
    [deleteProject],
  );

  const handlerReorderProjects = () => {
    resumeEditorState.action = 'reorder';
    // TODO: use nested routes, use RoutePaths
    navigate('/resumes/reorder/projects', {
      state: { id: resumeId },
    });
  };

  const shouldShowReorderButton = useMemo(() => {
    if (isNotNil(projects) && projects?.length > 1) {
      return true;
    }
    return false;
  }, [projects]);

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid item>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant={isMobile ? 'h3' : 'h2'}>Projects</Typography>
            {tooltipContent &&
              (isMobile || isTablet ? (
                <MobileTooltip content={tooltipContent} />
              ) : (
                <Tooltip content={tooltipContent} />
              ))}
          </Stack>
        </Grid>
        {editable && (
          <Grid item>
            {shouldShowReorderButton && (
              <ResumeEditorButton onClick={handlerReorderProjects} startIcon="fi fi-rr-apps-sort">
                Reorder
              </ResumeEditorButton>
            )}
            <ResumeEditorButton
              sx={{ color: 'primary' }}
              onClick={handleAddWorkProject}
              startIcon={
                <Icon className="fi fi-rr-add" style={{ fontSize: '16px', color: 'primary' }} />
              }
            >
              Add
            </ResumeEditorButton>
          </Grid>
        )}
      </Grid>
      {projects && projects.length !== 0 ? (
        projects.map((project) => (
          <ProjectDetail
            key={project._id + project.project_name}
            project={project}
            isEditable={editable}
            onEdit={handleEditProject}
            onDelete={handleDeleteProject}
          />
        ))
      ) : (
        <EmptySection text="No Projects Added" />
      )}
    </Stack>
  );
};

export default DisplayProjects;
