import type { AccordionProps } from '@mui/material';
import { Accordion as MUIAccordion } from '@mui/material';

const Accordion = ({ children, sx, ...restProps }: AccordionProps) => (
  <MUIAccordion
    disableGutters
    elevation={0}
    sx={{
      padding: 2.5,
      border: '1px solid',
      borderColor: 'border.light',
      // borderRadius: index === 0 ? '12px 12px 0px 0px' : '0px',
      // TODO: Check why first-child selector is not working
      '&:first-child': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-child': {
        borderRadius: '0px 0px 12px 12px',
      },
      '&::before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        paddingBottom: { sm: 4 },
      },
      ...sx,
    }}
    {...restProps}
  >
    {children}
  </MUIAccordion>
);

export default Accordion;
