import { useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack, Typography } from '@mui/material';

import SortableItem from '../../components/DragnDrop/SortableItem';
import FormActions from '../../components/Form/FormActions';
import type { PatentInfo, SortedResumeSectionKey } from '../../interface';
import { isNotNilOrEmpty } from '../../utils';

interface PatentsReorderProps {
  patents: PatentInfo[] | undefined;
  isLoading?: boolean;
  onSubmit?: (sortedExperinceKeys: SortedResumeSectionKey[]) => void;
}

const PatentsReorder = ({ patents = [], isLoading, onSubmit }: PatentsReorderProps) => {
  const [patentsOrder, setPatentsOrder] = useState(patents);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      // swap main elements
      const oldIndex = patentsOrder.findIndex((patent) => patent._id === active.id);
      const newIndex = patentsOrder.findIndex((patent) => patent._id === over.id);
      const movedArray = arrayMove(patentsOrder, oldIndex, newIndex);

      setPatentsOrder(movedArray);
    }
  };

  const onSaveClick = () => {
    if (onSubmit) {
      const sortedPatentReorderKeys = patentsOrder.map((patent, index) => ({
        id: patent._id,
        index,
      }));

      onSubmit(sortedPatentReorderKeys);
    }
  };

  return (
    // TODO: add to common components
    <Stack
      sx={{
        gap: 2,
        paddingLeft: 4,
        paddingRight: 12,
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={patentsOrder.map((patent) => patent._id)}
          strategy={verticalListSortingStrategy}
        >
          {patentsOrder.map((patent) => (
            <SortableItem
              key={patent._id}
              id={patent._id}
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: '12px',
                borderColor: 'border.light',
                backgroundColor: 'background.light',
                '&:hover': {
                  backgroundColor: 'background.mediumLight',
                },
              }}
            >
              <Stack gap={0.5}>
                {isNotNilOrEmpty(patent.title) && (
                  <Typography variant="label1">{patent.title}</Typography>
                )}
                {isNotNilOrEmpty(patent.number) && (
                  <Typography variant="body2">{patent.number}</Typography>
                )}
              </Stack>
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
      <FormActions onSubmit={onSaveClick} submitButtonProps={{ loading: isLoading }} />
    </Stack>
  );
};

export default PatentsReorder;
