import { useCallback, useMemo } from 'react';

import { type ResumeData, ResumeKey } from '../../interface';
import { addSeparator, isNotNilOrEmpty } from '../../utils/index';
import BulletedList from './components/BulletedList';
import LinkedIn from './components/LinkedIn';
import SectionContent from './components/SectionContent';
import SectionTitle from './components/SectionTitle';
import TemplateAchievement from './components/TemplateAchievement';
import TemplateCertification from './components/TemplateCertification';
import TemplateEducation from './components/TemplateEducation';
import TemplateHeader from './components/TemplateHeader';
import TemplatePatent from './components/TemplatePatent';
import TemplateProject from './components/TemplateProject';
import TemplatePublication from './components/TemplatePublication';
import TemplateVolunteerExperience from './components/TemplateVolunteerExperience';
import TemplateWorkExperience from './components/TemplateWorkExperience';
import ModernBaseStyles from './styles/ModernBaseStyles';
import styles from './styles/index';

const Modern = ({
  resume,
  templateKey = 'modern_layout_a',
}: {
  resume: ResumeData;
  templateKey?: string;
}) => {
  const contactInfo = useMemo(() => {
    if (resume.contact) {
      const { contact } = resume;
      const contactInfoArray = [];

      if (contact.city || contact.state) {
        contactInfoArray.push({
          bulletIcon: 'location',
          content: addSeparator(contact.city, contact.state, ', ') as string,
        });
      }
      if (contact.phone) {
        contactInfoArray.push({
          bulletIcon: 'phone',
          content: contact.phone as string,
        });
      }
      if (contact.email) {
        contactInfoArray.push({
          bulletIcon: 'email',
          content: contact.email as string,
        });
      }
      if (contact.linkedin) {
        contactInfoArray.push({
          bulletIcon: 'linkedin',
          content: <LinkedIn profileURL={contact.linkedin} />,
        });
      }
      return contactInfoArray;
    }

    return undefined;
  }, [resume]);

  const isSectionEnabled = useCallback(
    (sectionName: ResumeKey) =>
      resume.section_order.some(
        (section) => section.section_name === sectionName && section.enabled,
      ),
    [resume.section_order],
  );

  const resumeComponents = useMemo(() => {
    if (!isNotNilOrEmpty(resume)) return null;

    return (
      <div
        className="page-content-container"
        style={{
          flexDirection: templateKey === 'modern_layout_a' ? 'row' : 'row-reverse',
        }}
      >
        <div className="column1">
          {isNotNilOrEmpty(resume) &&
            resume[ResumeKey.SECTIONS_ORDER]?.map((section) => {
              if (!section.enabled) return null;

              switch (section.section_name) {
                case ResumeKey.SUMMARY:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <SectionContent
                        content={resume.professional_summary.professional_summary}
                        contentStyle={{ ...styles.body2, ...styles.lineHeight1_4 }}
                      />
                    </div>
                  );
                case ResumeKey.OBJECTIVE:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <SectionContent content={resume?.objective?.subtext} />
                    </div>
                  );
                case ResumeKey.EXPERIENCE:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplateWorkExperience
                        workExperience={resume?.work_experience}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.fontSize9,
                          contentStyle: { ...styles.body2, ...styles.lineHeight1_4 },
                        }}
                        sectionHeadingProps={{
                          value1Style: { ...styles.h2, ...styles.fontSize9 },
                          value2Style: styles.body2,
                        }}
                      />
                    </div>
                  );
                case ResumeKey.PROJECT:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplateProject
                        projects={resume?.project}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.fontSize9,
                          contentStyle: { ...styles.body2, ...styles.lineHeight1_4 },
                        }}
                      />
                    </div>
                  );
                case ResumeKey.COURSEWORK:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <BulletedList
                        contentList={resume.coursework.coursework}
                        style={{ ...styles.contentContainer, ...styles.body2 }}
                      />
                    </div>
                  );
                case ResumeKey.CERTIFICATES:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplateCertification
                        certifications={resume?.certifications_and_licenses}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.body2,
                        }}
                      />
                    </div>
                  );
                case ResumeKey.VOLUNTEER_EXPERIENCE:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplateVolunteerExperience
                        volunteerExperience={resume?.volunteer_experience}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.fontSize9,
                          contentStyle: { ...styles.body2, ...styles.lineHeight1_4 },
                        }}
                        sectionHeadingProps={{ value1Style: styles.body2 }}
                      />
                    </div>
                  );
                case ResumeKey.ACHIEVEMENTS:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplateAchievement
                        achievements={resume?.achievements}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.body2,
                          contentStyle: { ...styles.body2, ...styles.lineHeight1_4 },
                        }}
                      />
                    </div>
                  );
                case ResumeKey.PATENTS:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplatePatent
                        patents={resume?.patents}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.body2,
                        }}
                      />
                    </div>
                  );
                case ResumeKey.PUBLICATIONS:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <TemplatePublication
                        publications={resume?.publications}
                        sectionContentProps={{
                          headingStyle: styles.body2,
                          subHeadingStyle: styles.body2,
                        }}
                        sectionHeadingProps={{
                          value1Style: styles.fontSize9,
                          value2Style: styles.body2,
                        }}
                      />
                    </div>
                  );
                case ResumeKey.INTERESTS:
                  return (
                    <div>
                      <SectionTitle title={section.name} style={styles.textDecorationUnderline} />
                      <BulletedList
                        contentList={resume.interests.interests}
                        style={{ ...styles.contentContainer, ...styles.body2 }}
                      />
                    </div>
                  );

                default:
                  return null;
              }
            })}
        </div>
        <div className="column2">
          {isSectionEnabled(ResumeKey.CONTACT) && (
            <div className="contact-info">
              <BulletedList
                contentList={contactInfo}
                style={{ ...styles.listContainer, ...styles.body2, ...styles.lineHeight1_4 }}
              />
            </div>
          )}
          {isNotNilOrEmpty(resume) &&
            resume[ResumeKey.SECTIONS_ORDER]?.map((section) => {
              if (!section.enabled) return null;

              switch (section.section_name) {
                case ResumeKey.SKILLS:
                  return (
                    <div>
                      <hr />
                      <SectionTitle title={section.name} style={styles.textDecorationNone} />
                      <BulletedList
                        contentList={resume.skills.skills}
                        style={{
                          ...styles.contentContainer,
                          ...styles.body2,
                          ...styles.lineHeight1_4,
                        }}
                      />
                    </div>
                  );
                case ResumeKey.EDUCATION:
                  return (
                    <div>
                      <hr />
                      <SectionTitle title={section.name} style={styles.textDecorationNone} />
                      <TemplateEducation
                        education={resume?.education}
                        datePosition="content"
                        sectionContentProps={{
                          headingStyle: {
                            ...styles.body2,
                            ...styles.lineHeight1_4,
                          },
                          subHeadingStyle: {
                            ...styles.fontSize9,
                            ...styles.lineHeight1_4,
                          },
                          contentStyle: {
                            ...styles.contentContainer,
                            ...styles.body2,
                            ...styles.colorSecondary,
                            ...styles.lineHeight1_4,
                            ...styles.padding0,
                          },
                        }}
                      />
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    );
  }, [contactInfo, isSectionEnabled, resume, templateKey]);

  return (
    <div className="resume-template modern-template page">
      <ModernBaseStyles />
      {resume.section_order.some(
        (section) =>
          (section.section_name === ResumeKey.CONTACT && isSectionEnabled(ResumeKey.CONTACT)) ||
          (section.section_name === ResumeKey.HERO && isSectionEnabled(ResumeKey.HERO)),
      ) && (
        <TemplateHeader
          isSectionEnabled={isSectionEnabled}
          contact={resume.contact}
          heroMessage={resume.hero_message}
          sectionContentProps={{
            style: {
              ...styles.resumeHeaderContainer,
              ...styles.padding15,
              ...styles.backgroundColorSecondary,
              ...styles.colorWhite,
            },
            content: '',
          }}
        />
      )}
      {resumeComponents}
    </div>
  );
};

export default Modern;
