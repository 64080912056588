import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import BreadCrumbs from '../../components/common/BreadCrumbs';
import { useSnackbar } from '../../contexts/snackbar';
import { ResumeKey, type SortedResumeSectionKey } from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import {
  ResumesKeys,
  getResumeXRay,
  reorderEducation,
  reorderProjects,
  reorderResumeSection,
  reorderWorkExperiences,
} from '../../services/resumes';
// TODO: MUI also has a capitalize function. Test if it works and remove this one
import { capitalize } from '../../utils';
import AchievementsReorder from './AchievementsReorder';
import CertificateAndLicensesReorder from './CertificateAndLicensesReorder';
import EducationsReorder from './EducationsReorder';
import PatentsReorder from './PatentsReorder';
import ProjectsReorder from './ProjectsReorder';
import PublicationsReorder from './PublicationsReorder';
import VolunteerExperiencesReorder from './VolunteerExperiencesReorder';
import WorkExperiencesReorder from './WorkExperiencesReorder';

// create enum for resume section keys // TODO: standardize these keys and export, similar keys are defined in ResumeKeys interface
export enum ResumeSectionKeys {
  PROFESSIONAL_SUMMARY = 'professional-summary',
  WORK_EXPERIENCE = 'work-experience',
  EDUCATION = 'educations',
  SKILLS = 'skills',
  CERTIFICATIONS = 'certifications_and_licenses',
  PROJECTS = 'projects',
  AWARDS = 'awards',
  PUBLICATIONS = 'publications',
  LANGUAGES = 'languages',
  REFERENCES = 'references',
  ACHIEVEMENTS = 'achievements',
  PATENTS = 'patents',
}

// TODO: add to common enum
enum EditorPagesBreadcrumbs {
  'manage-sections' = 'Manage Sections',
  summary = 'Reorder Professional Summary',
  'contact-info' = 'Reorder Contact Information',
  'work-experience' = 'Reorder Work Experience',
  skills = 'Reorder Skills',
  educations = 'Reorder Education',
  project = 'Reorder Project',
}

// TODO: consolidate with ResumeSectionKeys && EditorPagesBreadcrumbs
export enum ResumeSectionTitles {
  'contact' = 'contact',
  'professional_summary' = 'professional_summary',
  'skills' = 'skills',
  'work_experience' = 'work_experience',
  'project' = 'project',
  'education' = 'education',
}

const ResumeReorder = () => {
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = location.state as { id: string };

  // get last element of the path as sectionKey
  const sectionKey = useMemo(() => {
    const path = location.pathname.split('/');
    return path[path.length - 1];
  }, [location.pathname]);

  const BreadCrumbLinks = [
    {
      label: 'Resumes',
      href: RoutePaths.RESUMES,
    },
    {
      label: 'View',
      href: `${RoutePaths.RESUMES}/${id}`,
    },
    { label: EditorPagesBreadcrumbs[sectionKey as keyof typeof EditorPagesBreadcrumbs], href: '' },
  ];

  //   load resume data
  const { data: resume, isLoading } = useQuery({
    queryKey: [ResumesKeys.RESUMES, id],
    queryFn: () => getResumeXRay(id),
    retry: 3,
    enabled: !!id,
  });

  const { work_experience: workExperiences } = useMemo(() => {
    if (resume) {
      return resume;
    }
    return { work_experience: [] };
  }, [resume]);

  const resuleFilename = useMemo(() => {
    if (resume) {
      return resume.filename;
    }
    return '';
  }, [resume]);

  const { mutate: reorderResumeWorkExperience, isLoading: isReorderingWorkExperience } =
    useMutation({
      mutationFn: reorderWorkExperiences,
      onSuccess: () => {
        showSnackbar('success', 'Work Experience reordered');
        queryClient.invalidateQueries([ResumesKeys.RESUMES, id]);
        navigate(-1);
      },
      onError: () => {
        showSnackbar('error', 'Failed to reorder Work Experience');
      },
    });

  const { mutate: reorderResumeProjects, isLoading: isReorderingProjects } = useMutation({
    mutationFn: reorderProjects,
    onSuccess: () => {
      showSnackbar('success', 'Projects reordered');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, id]);
      navigate(-1);
    },
    onError: () => {
      showSnackbar('error', 'Failed to reorder Projects');
    },
  });

  const { mutate: reorderResumeEducation, isLoading: isReorderingEducation } = useMutation({
    mutationFn: reorderEducation,
    onSuccess: () => {
      showSnackbar('success', 'Education reordered');
      queryClient.invalidateQueries([ResumesKeys.RESUMES, id]);
      navigate(-1);
    },
    onError: () => {
      showSnackbar('error', 'Failed to reorder Education');
    },
  });

  const { mutate: reorderSection, isLoading: isReorderingSection } = useMutation({
    mutationFn: reorderResumeSection,
    onSuccess: (_response, payload) => {
      showSnackbar('success', `${capitalize(payload.sectionKey.replaceAll('_', ' '))} reordered`);
      queryClient.invalidateQueries([ResumesKeys.RESUMES, id]);
      navigate(-1);
    },
    onError: (_error, payload) => {
      showSnackbar('error', `Failed to reorder ${payload.sectionKey.replaceAll('_', ' ')}`);
    },
  });

  const handleWorkExperienceReorder = (sortedExperinceKeys: SortedResumeSectionKey[]) => {
    reorderResumeWorkExperience({ resumeId: id, sortedWorkExperienceKeys: sortedExperinceKeys });
  };

  const handleProjectReorder = (sortedProjectKeys: SortedResumeSectionKey[]) => {
    reorderResumeProjects({ resumeId: id, sortedProjectKeys });
  };

  const handleEducationReorder = (sortedEducationKeys: SortedResumeSectionKey[]) => {
    reorderResumeEducation({ resumeId: id, sortedEducationKeys });
  };

  return (
    <Stack>
      <Grid
        container
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginLeft: '3rem',
          gap: 6,
          width: 'unset',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 1000,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'common.white',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Grid>
          <Stack gap="4px">
            <BreadCrumbs links={BreadCrumbLinks} />
            <Typography variant="body2" paddingLeft="30px">
              {resuleFilename}
            </Typography>
          </Stack>
        </Grid>

        {sectionKey === ResumeSectionKeys.WORK_EXPERIENCE && !isLoading && (
          <WorkExperiencesReorder
            experiences={workExperiences}
            isLoading={isReorderingWorkExperience}
            onSubmit={handleWorkExperienceReorder}
          />
        )}

        {sectionKey === ResumeSectionKeys.PROJECTS && !isLoading && (
          <ProjectsReorder
            projects={resume?.project}
            isLoading={isReorderingProjects}
            onSubmit={handleProjectReorder}
          />
        )}

        {sectionKey === ResumeSectionKeys.EDUCATION && !isLoading && (
          <EducationsReorder
            educations={resume?.education}
            isLoading={isReorderingEducation}
            onSubmit={handleEducationReorder}
          />
        )}

        {sectionKey === ResumeSectionKeys.CERTIFICATIONS && !isLoading && (
          <CertificateAndLicensesReorder
            certificateAndLicenses={resume?.certifications_and_licenses}
            isLoading={isReorderingSection}
            onSubmit={(sortedCertificateAndLicenseKeys) => {
              reorderSection({
                resumeId: id,
                sectionKey: ResumeKey.CERTIFICATES,
                sortedResumeSectionKeys: sortedCertificateAndLicenseKeys,
              });
            }}
          />
        )}

        {sectionKey === 'volunteer-experience' && !isLoading && (
          <VolunteerExperiencesReorder
            volunteerExperiences={resume?.volunteer_experience}
            isLoading={isReorderingSection}
            onSubmit={(sortedKeys) => {
              reorderSection({
                resumeId: id,
                sectionKey: ResumeKey.VOLUNTEER_EXPERIENCE,
                sortedResumeSectionKeys: sortedKeys,
              });
            }}
          />
        )}

        {sectionKey === ResumeSectionKeys.ACHIEVEMENTS && !isLoading && (
          <AchievementsReorder
            achievements={resume?.achievements}
            isLoading={isReorderingSection}
            onSubmit={(sortedKeys) => {
              reorderSection({
                resumeId: id,
                sectionKey: ResumeKey.ACHIEVEMENTS,
                sortedResumeSectionKeys: sortedKeys,
              });
            }}
          />
        )}

        {sectionKey === ResumeSectionKeys.PUBLICATIONS && !isLoading && (
          <PublicationsReorder
            publications={resume?.publications}
            isLoading={isReorderingSection}
            onSubmit={(sortedKeys) => {
              reorderSection({
                resumeId: id,
                sectionKey: ResumeKey.PUBLICATIONS,
                sortedResumeSectionKeys: sortedKeys,
              });
            }}
          />
        )}

        {sectionKey === ResumeSectionKeys.PATENTS && !isLoading && (
          <PatentsReorder
            patents={resume?.patents}
            isLoading={isReorderingSection}
            onSubmit={(sortedKeys) => {
              reorderSection({
                resumeId: id,
                sectionKey: ResumeKey.PATENTS,
                sortedResumeSectionKeys: sortedKeys,
              });
            }}
          />
        )}
      </Grid>
    </Stack>
  );
};

export default ResumeReorder;
