// Lexical editor plugin to handle editor state changes like append, replace, reset and get editor content.
import type { LexicalCommand } from 'lexical';
import { $createParagraphNode, $createTextNode, $getRoot, createCommand } from 'lexical';
import { useCallback, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';

export const AppendEditorCommand: LexicalCommand<string> = createCommand('AppendEditorCommand');
export const ReplaceEditorCommand: LexicalCommand<string> = createCommand('ReplaceEditorCommand');
export const ResetEditorCommand: LexicalCommand<void> = createCommand('ResetEditorCommand');

// register the append, replace, reset and get editor content commands
// add jsDocs for the plugin
/**
 * Lexical editor plugin to handle editor state changes like append, replace, reset and get editor content.
 * @returns null
 * @example
 * ```tsx
 * <EditorStatePlugin />
 *
 * // append the text to the editor content
 * editor.dispatchCommand(AppendEditorCommand, 'Hello, World!');
 *
 * // replace the editor content with the text
 * editor.dispatchCommand(ReplaceEditorCommand, 'Hello, World!');
 *
 * // reset the editor content
 * editor.dispatchCommand(ResetEditorCommand);
 */
const EditorStatePlugin = () => {
  const [editor] = useLexicalComposerContext();

  //   TODO: handle no editor content and HTML text scenarios
  // append the draft text to the editor content
  const append = useCallback(
    (text: string) => {
      // eslint-disable-next-line no-console
      console.log('appending', text);
      editor.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(text);
        paragraph.append(element);

        root.append(paragraph);
        root.selectEnd();
      });
    },
    [editor],
  );

  // replace the editor content with the draft text
  const replace = useCallback(
    (text: string) => {
      editor.update(() => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const element = $createTextNode(text);
        paragraph.append(element);

        root.clear();
        root.append(paragraph);
        root.selectEnd();
      });
    },
    [editor],
  );

  // reset the editor content
  const reset = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      root.selectEnd();
    });
  }, [editor]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        AppendEditorCommand,
        (text) => {
          // eslint-disable-next-line no-console
          console.log('append called', text);
          append(text);
          return true;
        },
        1,
      ),
      editor.registerCommand(
        ReplaceEditorCommand,
        (text) => {
          replace(text);
          return true;
        },
        1,
      ),
      editor.registerCommand(
        ResetEditorCommand,
        () => {
          reset();
          return true;
        },
        1,
      ),
    );
  }, [append, editor, replace, reset]);

  return null;
};

export default EditorStatePlugin;
