import { useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SelectCard from '../../../components/common/SelectCard';
import type { CareerStageKey } from '../constants';
import { careerStageOptions } from '../constants';

/**
 * This component renders a grid of selectable cards, each representing a different career stage.
 * It allows users to select their current career stage by clicking on a card. Upon selection,
 * the `onChange` callback is triggered, passing the selected career stage key to the parent component.
 *
 * @component
 *
 * @param {function} props.onChange - A callback function that is called with the selected career stage key when a card is clicked.
 *
 * @returns {JSX.Element} A grid of selectable career stage cards.
 *
 * @example
 * // Example usage
 * const handleCareerStageChange = (selectedKey: string) => {
 *   console.log('Selected Career Stage:', selectedKey);
 * };
 *
 * <ChooseCareerStage onChange={handleCareerStageChange} />
 */

interface ChooseCareerStageProps {
  onChange: (key: CareerStageKey) => void;
  defaultValue?: CareerStageKey;
}

const ChooseCareerStage = ({ onChange, defaultValue }: ChooseCareerStageProps) => {
  const [selectedKey, setSelectedKey] = useState(defaultValue);
  const theme = useTheme();

  const handleChooseCareerStage = (key: string) => {
    setSelectedKey(key as CareerStageKey);
    if (onChange) {
      onChange(key as CareerStageKey);
    }
  };

  return (
    <Grid spacing={2} container sx={{ justifyContent: 'center', width: '100%', margin: 0 }}>
      <Grid xs={12} sx={{ paddingTop: 0, paddingBottom: 3 }}>
        <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          Choose a Career Stage
        </Typography>
      </Grid>
      {careerStageOptions.map((item) => (
        <Grid
          key={item.key}
          xs={12}
          md={6}
          lg={4}
          sx={{ [theme.breakpoints.down('md')]: { paddingX: 0 } }}
        >
          <SelectCard
            cardContent={item}
            sx={{ borderColor: selectedKey === item.key ? 'primary.main' : 'border.light' }}
            onClick={handleChooseCareerStage}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChooseCareerStage;
