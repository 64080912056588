import type { ChangeEvent } from 'react';

import {
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { SelectJDSection } from '../constants';
import type { ImportJobDescriptionProps } from './ImportJobDescription';
import ImportJobDescription from './ImportJobDescription';
import type { SelectJobXrayProps } from './SelectJobXray';
import SelectJobXray from './SelectJobXray';

const Radio = styled(MuiRadio)(({ theme: themeConfigs }) => ({
  width: themeConfigs.spacing(2.25),
  height: themeConfigs.spacing(2.25),
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme: themeConfigs }) => ({
  border: `${themeConfigs.spacing(0.125)} solid ${
    (themeConfigs.palette.border as { light?: string })?.light
  }`,
  backgroundColor: (themeConfigs.palette.background as { mediumLight?: string })?.mediumLight || '',
  borderRadius: `${themeConfigs.spacing(2)} ${themeConfigs.spacing(2)} 
                 ${themeConfigs.spacing(0)} ${themeConfigs.spacing(0)}`,
  boxSizing: 'border-box',
  margin: 0,
  gap: themeConfigs.spacing(2),
  padding: themeConfigs.spacing(2),
}));

interface JobDescriptionDesktopSelectorProps {
  selectJobXrayProps: SelectJobXrayProps;
  importJobDescriptionProps: ImportJobDescriptionProps;
  selectedSection: SelectJDSection;
  setSelectedSection: (section: SelectJDSection) => void;
}

const JobDescriptionDesktopSelector = ({
  selectJobXrayProps,
  importJobDescriptionProps,
  selectedSection,
  setSelectedSection,
}: JobDescriptionDesktopSelectorProps) => {
  const theme = useTheme();

  const handleSelectedSectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSection(event.target.value as SelectJDSection);
  };

  return (
    <FormControl sx={{ height: '100%' }}>
      <RadioGroup
        defaultValue={selectedSection}
        name="select-jd-sections-group"
        onChange={handleSelectedSectionChange}
        sx={{ height: '100%' }}
      >
        <FormControlLabel
          value={SelectJDSection.SELECT_JD}
          control={<Radio />}
          label={<Typography variant="label2">Select a Job X-Ray</Typography>}
          sx={{
            backgroundColor:
              selectedSection === SelectJDSection.SELECT_JD
                ? 'background.mediumLight'
                : 'common.white',
          }}
        />
        {/* Select Jd section goes here */}
        <SelectJobXray {...selectJobXrayProps} />
        <FormControlLabel
          value={SelectJDSection.IMPORT_JD}
          control={<Radio />}
          label={<Typography variant="label2">Import a job</Typography>}
          sx={{
            ...(selectedSection === SelectJDSection.IMPORT_JD
              ? {
                  borderRadius: 0,
                  backgroundColor: 'background.mediumLight',
                }
              : {
                  borderRadius: `${theme.spacing(0)} ${theme.spacing(0)} 
                                   ${theme.spacing(2)} ${theme.spacing(2)}`,
                  backgroundColor: 'common.white',
                }),
          }}
        />
        {/* Import JD goes here */}
        <ImportJobDescription {...importJobDescriptionProps} />
      </RadioGroup>
    </FormControl>
  );
};

export default JobDescriptionDesktopSelector;
