export enum Months {
  Jan,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}

export const START_YEAR_1971 = 1971;
export const START_YEAR_1981 = 1981;
export const END_YEAR = new Date().getFullYear() + 3; // Add 3 years to the current year

export const monthList = Object.keys(Months).filter((key) => Number.isNaN(Number(key)));

export const yearsListFrom1971 = Array.from(
  { length: END_YEAR - START_YEAR_1971 + 1 },
  (_, index) => END_YEAR - index,
);

export const yearsListFrom1981 = Array.from(
  { length: END_YEAR - START_YEAR_1981 + 1 },
  (_, index) => END_YEAR - index,
);

export const degreeList = [
  'Associate of Arts',
  'Associate of Science',
  'Associate of Technology',
  'Associate of Engineering',
  'Associate of Business Administration',
  'Bachelor of Arts',
  'Bachelor of Science',
  'Bachelor of Technology',
  'Bachelor of Engineering',
  'Bachelor of Accounting',
  'Bachelor of Architecture',
  'Bachelor of Business Administration',
  'Bachelor of Education',
  'Bachelor of Law',
  'Master of Arts',
  'Master of Science',
  'Master of Engineering',
  'Master of Technology',
  'Master of Business Administration',
  'Master of Law',
  'Doctor of Philosophy',
];

export const careerBreakReasonsList = [
  'Caregiving',
  'Career transition',
  'Community building',
  'Entrepreneur in training',
  'Full-time parenting',
  'Gap year',
  'Health and wellbeing',
  'Personal development',
  'Professional development',
  'Relocation',
  'Sabbatical',
  'Travel',
  'Volunteer work',
];
