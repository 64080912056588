import { isNotNil } from 'ramda';

import { Grid, Typography } from '@mui/material';

import Button from '../Button';
import { ButtonWithDisableTimer } from '../ButtonWithDisableTimer';
import Icon from '../Icon';

interface ModalActionsDeprecatedProps {
  type?: 'confirm' | 'delete';
  disableConfirmButtonDuration?: number;
  actions?: React.ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
}

const ModalActionsDeprecated = ({
  type,
  disableConfirmButtonDuration,
  actions,
  onClose,
  onConfirm,
}: ModalActionsDeprecatedProps) => (
  <Grid container justifyContent="flex-end" spacing="12px">
    {isNotNil(actions) ? (
      <Grid item>{actions}</Grid>
    ) : (
      <>
        <Grid item>
          <Button
            variant={type === 'delete' ? 'text' : 'outlined'}
            color={type === 'delete' ? 'inherit' : 'primary'}
            sx={{
              color: type === 'delete' ? 'text.primary' : 'primary.main',
              border: type === 'delete' ? 'unset' : '1.5px solid',
            }}
            onClick={onClose}
          >
            <Typography variant="label2">Cancel</Typography>
          </Button>
        </Grid>
        <Grid item>
          {type === 'delete' ? (
            <ButtonWithDisableTimer
              color="error"
              onClick={onConfirm}
              disableDuration={disableConfirmButtonDuration || 0}
            >
              <Icon className="fi fi-rr-trash" />
              <Typography variant="label2">Delete</Typography>
            </ButtonWithDisableTimer>
          ) : (
            <ButtonWithDisableTimer
              color="primary"
              onClick={onConfirm}
              disableDuration={disableConfirmButtonDuration || 0}
            >
              <Typography variant="label2">Confirm </Typography>
            </ButtonWithDisableTimer>
          )}
        </Grid>
      </>
    )}
  </Grid>
);

export default ModalActionsDeprecated;
