import {
  format,
  formatDistanceToNowStrict,
  intervalToDuration,
  isBefore,
  isEqual,
  parse,
} from 'date-fns';
import { isNil, isNotNil } from 'ramda';

import { Months } from '../constants';
import { addSeparator, isNilOrEmpty, isNotNilOrEmpty } from './index';

export const dateToTimeAgo = (date: Date | string) => {
  const distance = formatDistanceToNowStrict(new Date(date));

  if (distance.includes('second')) {
    return 'Just now';
  }
  if (distance.includes('minute')) {
    return `${distance.replace('minute', 'min')} ago`;
  }
  return `${distance} ago`;
};

export const compareDates = (value1: Date, value2: Date) => {
  if (isBefore(value1, value2)) {
    return -1;
  }
  if (isEqual(value1, value2)) {
    return 0;
  }
  return 1;
};

export const getStringMonth = (month: number | null | undefined) => {
  if (isNil(month)) {
    return '';
  }
  return Months[month - 1];
};

export const getIntegerMonth = (month: string | number | null | undefined) => {
  if (typeof month === 'number') {
    return month;
  }
  const enumMonth: keyof typeof Months = month as keyof typeof Months;
  if (isNil(month) || month === '') {
    return null;
  }
  return Months[enumMonth] + 1;
};

export const isValidMonth = (month: string | null) => {
  const enumMonth: keyof typeof Months = month as keyof typeof Months;
  if (isNil(Months[enumMonth]) && month !== null) {
    return false;
  }
  return true;
};

/**
 * Filters a date range based on start and end months and years. Returns true if the provided start date is after the end date i-e invalid year,
 * The function takes start and end months as strings (e.g., "January") and start and end years as numbers.
 * Indicating an invalid year values.
 *
 * @param startMonth - The starting month of the date range.
 * @param startYear - The starting year of the date range.
 * @param endMonth - The ending month of the date range.
 * @param endYear - The ending year of the date range.
 * @param isPresent - A boolean indicating whether the end date is the present date.
 * @returns A boolean indicating whether the date range is invalid (start date after end date) or not.
 */
export const filterYearList = (
  startMonth: string | null,
  startYear: number | null | undefined,
  endMonth: string | null | undefined,
  endYear: number | null | undefined,
  isPresent: boolean,
) => {
  const endDate = new Date();
  const endYearValue = isPresent ? Number(format(endDate, 'yyyy')) : endYear;
  const endMonthValue = isPresent ? format(endDate, 'MMM') : endMonth;

  if (isNotNilOrEmpty(endYearValue) && isNotNilOrEmpty(startYear)) {
    if (startYear && endYearValue && startYear > endYearValue) {
      return true;
    }
    if (startYear === endYearValue) {
      const startMonthInt = getIntegerMonth(startMonth);
      const endMonthInt = getIntegerMonth(endMonthValue);
      if (isNotNil(startMonthInt) && isNotNil(endMonthInt) && startMonthInt > endMonthInt) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Filters a date range based on start and end months and years. Returns true if the provided month value is invalid,
 * The function takes start and end months as strings (e.g., "January") and start and end years as numbers.
 * Indicating that the start month is after the end month when the years are the same.
 *
 * @param startMonth - The starting month of the date range.
 * @param startYear - The starting year of the date range.
 * @param endMonth - The ending month of the date range.
 * @param endYear - The ending year of the date range.
 * @param isPresent - A boolean indicating whether the end date is the present date.
 * @returns A boolean indicating whether the date range is invalid (start month after end month for the same year) or not.
 */
export const filterMonthList = (
  startMonth: string | null,
  startYear: number | null | undefined,
  endMonth: string | null | undefined,
  endYear: number | null | undefined,
  isPresent: boolean,
) => {
  const endDate = new Date();
  const endYearValue = isPresent ? Number(format(endDate, 'yyyy')) : endYear;
  const endMonthValue = isPresent ? format(endDate, 'MMM') : endMonth;

  const startMonthInt = getIntegerMonth(startMonth) || 0;
  const endMonthInt = getIntegerMonth(endMonthValue) || 12;
  if (
    isNotNilOrEmpty(startYear) &&
    isNotNilOrEmpty(endYearValue) &&
    startYear === endYearValue &&
    startMonthInt > endMonthInt
  ) {
    return true;
  }
  return false;
};

/**
 * Validates the start year in a date range. Returns an error message if the start year is missing when the start month is provided,
 * or if the start year is greater than the end year. This function takes start month as a string, start year, and end year as numbers.
 *
 * @param startMonth - The starting month of the date range.
 * @param startYear - The starting year of the date range.
 * @param endMonth - The ending month of the date range.
 * @param endYear - The ending year of the date range.
 * @param isPresent - A boolean indicating whether the end date is the present date.
 * @returns A string error message if validation fails, otherwise returns true.
 */
export const validateStartYear = (
  startMonth: string | null,
  startYear: number | null | undefined,
  endMonth: string | null | undefined,
  endYear: number | null | undefined,
  isPresent: boolean,
) => {
  // Start month exist without end year
  if (isNotNilOrEmpty(startMonth) && isNilOrEmpty(startYear)) {
    return 'Please enter a start year';
  }
  const endDate = new Date();
  const endYearValue = isPresent ? Number(format(endDate, 'yyyy')) : endYear;
  const endMonthValue = isPresent ? format(endDate, 'MMM') : endMonth;
  const startMonthInt = getIntegerMonth(startMonth) || 0;
  const endMonthInt = getIntegerMonth(endMonthValue) || 12;
  // Start year exist and is greater than end year
  if (
    isNotNilOrEmpty(startYear) &&
    isNotNilOrEmpty(endYearValue) &&
    startYear &&
    endYearValue &&
    (startYear > endYearValue || (startYear === endYearValue && startMonthInt > endMonthInt))
  ) {
    return 'Start year must be earlier than or the same as the end year';
  }
  // TODO: Add month condition if required
  return true;
};

/**
 * Validates the end year in a date range. Returns an error message if the end year is missing when the end month is provided,
 * or if the start year is provided without the end year. This function takes start year, end month as a string, and end year as numbers.
 *
 * @param startYear - The starting year of the date range.
 * @param endMonth - The ending month of the date range.
 * @param endYear - The ending year of the date range.
 * @returns A string error message if validation fails, otherwise returns true.
 */
export const validateEndYear = (
  startYear: number | null | undefined,
  endMonth: string | null | undefined,
  endYear: number | null | undefined,
) => {
  // End month exist without end year
  if (isNotNilOrEmpty(endMonth) && isNilOrEmpty(endYear)) {
    return 'Please enter an end year, or check present if applicable';
  }
  // Start year exist without end year.
  if (isNotNilOrEmpty(startYear) && isNilOrEmpty(endMonth) && isNilOrEmpty(endYear)) {
    return 'Please enter an end year, or check present if applicable';
  }
  return true;
};

/**
 * Validates the issued year in a date. Returns false if the issued year is missing when the issued month is provided,
 * otherwise returns true. This function takes issued month as a string and issued year as a number.
 *
 * @param issuedMonth - The issued month of the date.
 * @param issuedYear - The issued year of the date.
 * @returns A boolean indicating whether the issued year is valid or not.
 */
export const validateIssuedYear = (issuedMonth: string | null, issuedYear: number | null) => {
  if (isNotNilOrEmpty(issuedMonth) && isNilOrEmpty(issuedYear)) {
    return false;
  }
  return true;
};

// TODO: Add month validation if required

/**
 * Formats a date range based on input parameters.
 *
 * @param startMonth - The starting month (1-12) or null.
 * @param startYear - The starting year or null.
 * @param endMonth - The ending month (1-12) or null.
 * @param endYear - The ending year.
 * @param isPresent - Indicates if the date range is ongoing or not.
 *
 * @returns A formatted date range as a string. When any value is null or invalid, an empty string is returned.
 *          When isPresent is false, the format is "startMonth startYear - endMonth endYear (durationYears durationMonths)".
 *          When isPresent is true, the format is "startMonth startYear - Present (durationYears durationMonths)".
 *          Return date without adding duration at the end when disableCalculateDuration flag is true.
 */
export const formatDate = (
  startMonth: number | null,
  startYear: number | null,
  endMonth: number | null,
  endYear: number | null,
  isPresent: boolean,
  disableCalculateDuration: boolean = false,
) => {
  // return value without duration if any of the passed value is null or undfined
  if (isNil(startYear) || (isNil(endYear) && !isPresent)) {
    const startDate = addSeparator(getStringMonth(startMonth), startYear, ' ');
    let endDate;
    if (isPresent) {
      endDate = 'Present';
    } else {
      endDate = addSeparator(getStringMonth(endMonth), endYear, ' ');
    }
    return addSeparator(startDate, endDate, ' - ');
  }

  // Setting default value of start month to Jan if start month is null.
  const startDate = parse(
    `${startYear}-${isNil(startMonth) ? 1 : startMonth}-01`,
    'yyyy-MM-dd',
    new Date(),
  );
  let endDate;
  if (isPresent) {
    endDate = new Date(); // Use the current date as the end date
  } else {
    // Setting default value of end month to Dec if end month is null.
    endDate = parse(`${endYear}-${isNil(endMonth) ? 12 : endMonth}-01`, 'yyyy-MM-dd', new Date());
  }
  // Format start and end dates
  const formattedStartDate = isNil(startMonth)
    ? format(startDate, 'yyyy')
    : format(startDate, 'MMM yyyy');
  const formattedEndDate = isNil(endMonth) ? format(endDate, 'yyyy') : format(endDate, 'MMM yyyy');

  if (!disableCalculateDuration) {
    const duration = intervalToDuration({ start: startDate, end: endDate });

    return `${formattedStartDate} - ${isPresent ? 'Present' : `${formattedEndDate}`} (${
      duration.years
    }y ${duration.months}m)`;
  }
  return `${formattedStartDate} - ${isPresent ? 'Present' : `${formattedEndDate}`}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormatedDate = (data: any) =>
  formatDate(
    data?.start_month || data?.issued_month,
    data?.start_year || data.issued_year,
    data?.end_month || null,
    data?.end_year || null,
    data?.present || false,
    true,
  );
