import Typography from '@mui/material/Typography';

import DottedLine from '../../../assets/images/dotted-line.svg';

export const PageBreakMarker = ({ key, breakPx }: { key?: string | number; breakPx: number }) => (
  <div
    key={key}
    style={{
      position: 'absolute',
      alignItems: 'center',
      gap: 4,
      display: 'flex',
      top: `${breakPx}px`,
      left: 0,
      width: '100%',
      zIndex: 100,
    }}
  >
    <img
      src={DottedLine}
      alt="page-break"
      style={{
        height: '4px',
        width: '96%',
      }}
    />
    <Typography
      variant="assistiveBold"
      color="text.tertiary"
      sx={{
        backgroundColor: 'white',
      }}
    >
      Break
    </Typography>
  </div>
);
