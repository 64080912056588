import { useEffect, useState } from 'react';

import type { ButtonProps } from './Button';
import Button from './Button';

interface ButtonWithDisableTimerProps extends ButtonProps {
  disableDuration: number;
}

export const ButtonWithDisableTimer = ({
  disableDuration,
  children,
  ...restProps
}: ButtonWithDisableTimerProps) => {
  const [countDown, setCountDown] = useState(disableDuration);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (countDown > 0) {
      timer = setInterval(() => setCountDown(countDown - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [countDown]);

  return (
    <Button {...restProps} disabled={countDown > 0}>
      {children}
      {countDown >= 1 && `(${countDown})`}
    </Button>
  );
};
