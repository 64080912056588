import { Link, Stack, Typography } from '@mui/material';

import type { CardProps } from '../../../../components/common/Card';
import Icon from '../../../../components/common/Icon';
import StyledCard from '../../../../components/common/StyledCard';

interface ResourceLink {
  label: string;
  href: string;
}

interface TipsResourcesProps {
  links: ResourceLink[];
  cardProps?: CardProps;
}

const TipsResources = ({ links, cardProps }: TipsResourcesProps) => (
  <StyledCard {...cardProps}>
    <Typography variant="h3">Tips & Resources</Typography>
    <Stack gap={1.5}>
      {links.map(({ label, href }) => (
        <Link
          key={label}
          href={href}
          target="_blank"
          rel="noreferrer"
          variant="label2"
          color="text.secondary"
          sx={{
            '&:hover': {
              color: 'primary.main',
            },
            '.hover-icon': {
              opacity: 0,
            },
            '&:hover .hover-icon': {
              opacity: 1,
              transform: 'translateX(5px)',
            },
          }}
        >
          {label}
          <Icon
            className="hover-icon fi fi-rr-arrow-up-right"
            fontSize="10px"
            color="primary.main"
            style={{ display: 'inline-flex' }}
          />
        </Link>
      ))}
    </Stack>
  </StyledCard>
);

export default TipsResources;
