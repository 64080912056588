import { $insertNodes } from 'lexical';
import { useEffect, useRef } from 'react';

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

interface Props {
  initialHtml?: string;
  onHtmlChange: (html: string) => void;
}

const HtmlPlugin = ({ initialHtml, onHtmlChange }: Props) => {
  const [editor] = useLexicalComposerContext();
  const initialNodesInserted = useRef(false);

  useEffect(() => {
    if (!initialHtml || initialNodesInserted.current) return;

    initialNodesInserted.current = true;

    editor.update(() => {
      // de-serialize html to editor state / nodes
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  }, [editor, initialHtml]);

  return (
    <OnChangePlugin
      onChange={(editorState) => {
        // serialize editor state to html and trigger callback
        editorState.read(() => {
          onHtmlChange($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
};

export default HtmlPlugin;
