import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';

import Card from '../../common/Card';

interface EmptySectionProps {
  text?: string;
  sx?: SxProps;
}

const EmptySection = ({ text = 'No Item(s) Added', sx }: EmptySectionProps) => (
  <Card
    sx={{
      display: 'flex',
      justifyContent: 'center',
      border: '1px dashed',
      borderColor: 'border.main',
      borderRadius: '8px',
      padding: '24px 16px',
      ...sx,
    }}
    cardContentProps={{
      sx: {
        padding: 0,
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    }}
  >
    <Typography variant="label2" color="text.secondary" padding={0}>
      {text}
    </Typography>
  </Card>
);

export default EmptySection;
