import { Global } from '@emotion/react';
import { Stack, SwipeableDrawer, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import Button from './Button';
import Modal from './Modal/Modal';
import ModalActions from './Modal/ModalActions';
import ModalDetail from './Modal/ModalDetail';
import { DrawerContent, Puller } from './SwipeableDrawer';

/**
 * This component renders a popup that adapts its presentation for mobile and desktop views.
 * On mobile devices, it uses a swipeable drawer that slides up from the bottom,
 * while on desktop, it uses a modal window.
 *
 * The content includes a title and a description, with an action button labeled "Got it" to close the popup.
 * The `onClose` callback is triggered when the button is clicked, or the drawer/modal is closed.
 *
 * @component
 *
 * @param {object} content - The content to display inside the popup, including the `title` and `description`.
 * @param {string} content.title - The title text displayed at the top of the popup.
 * @param {string} content.description - The description text displayed below the title.
 * @param {boolean} open - A boolean indicating whether the popup is open or closed.
 * @param {function} onClose - A callback function that is called when the popup is closed.
 * @param {function} onOpen - A callback function that is called when the popup is opened.
 *
 * @returns {JSX.Element} A swipeable drawer for mobile or modal for desktop with a title, description, and close action.
 *
 * @example
 * // Example usage
 * const handleOpen = () => {
 *   console.log('Popup opened');
 * };
 *
 * const handleClose = () => {
 *   console.log('Popup closed');
 * };
 *
 * const content = {
 *   title: 'Info Title',
 *   description: 'This is a description of the popup content.',
 * };
 *
 * <InfoPopup
 *   content={content}
 *   open={true}
 *   onClose={handleClose}
 *   onOpen={handleOpen}
 * />
 */

interface PopupProps {
  content: { title: string; description: string };
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  // TODO: Add actions prop
}

const InfoPopup = ({ content, open, onClose, onOpen }: PopupProps) => {
  const { isMobile } = useResponsiveDevice();
  const theme = useTheme();

  return isMobile ? (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            overflow: 'visible',
            borderRadius: '12px 12px 0px 0px',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <DrawerContent style={{ textAlign: 'left' }}>
          <Puller sx={{ marginBottom: 3 }} />
          <Stack sx={{ gap: 2, width: '100%' }}>
            <Typography variant="h3">{content.title}</Typography>
            <Typography variant="body3">{content.description}</Typography>
            <Button
              onClick={onClose}
              sx={{
                marginTop: 2,
                width: '100%',
                padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
              }}
            >
              Got it
            </Button>
          </Stack>
        </DrawerContent>
      </SwipeableDrawer>
    </>
  ) : (
    <Modal open={open} contentSx={{ width: '31.25rem' }}>
      <>
        <ModalDetail title={content.title} description={content.description} />
        <ModalActions actions={<Button onClick={onClose}>Got it</Button>} />
      </>
    </Modal>
  );
};

export default InfoPopup;
