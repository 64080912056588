import { ResumeKey } from '../../interface';

export const ResumeEditorMap = {
  // TODO: replace id fields with ResumeKey keys
  // TODO: handle for add/edit actions based on Resume Editor State
  //   TODO: add success, error snakcbar messages
  id: { breadCrumb: '', sectionKey: ResumeKey.RESUME_ID },
  filename: { breadCrumb: '', sectionKey: ResumeKey.FILENAME },
  contact: { breadCrumb: 'Edit Contact Information', sectionKey: ResumeKey.CONTACT },
  [ResumeKey.HERO]: { breadCrumb: 'Edit Hero Message', sectionKey: ResumeKey.HERO },
  [ResumeKey.OBJECTIVE]: { breadCrumb: 'Edit Objective', sectionKey: ResumeKey.OBJECTIVE },
  summary: { breadCrumb: 'Edit Professional Summary', sectionKey: ResumeKey.SUMMARY },
  'work-experience': { breadCrumb: 'Edit Work Experience', sectionKey: ResumeKey.EXPERIENCE },
  'career-break': { breadCrumb: 'Edit Career Break', sectionKey: ResumeKey.EXPERIENCE },
  skills: { breadCrumb: 'Edit Skills', sectionKey: ResumeKey.SKILLS },
  education: { breadCrumb: 'Edit Education', sectionKey: ResumeKey.EDUCATION },
  project: { breadCrumb: 'Edit Project', sectionKey: ResumeKey.PROJECT },
  coursework: { breadCrumb: 'Edit Coursework', sectionKey: ResumeKey.COURSEWORK },
  interests: { breadCrumb: 'Edit Interests', sectionKey: ResumeKey.INTERESTS },
  patents: { breadCrumb: 'Edit Patents', sectionKey: ResumeKey.PATENTS },
  publications: { breadCrumb: 'Edit Publications', sectionKey: ResumeKey.PUBLICATIONS },
  'volunteer-experience': {
    breadCrumb: 'Edit Volunteer Experience',
    sectionKey: ResumeKey.VOLUNTEER_EXPERIENCE,
  },
  [ResumeKey.ACHIEVEMENTS]: {
    breadCrumb: 'Edit Achievements',
    sectionKey: ResumeKey.ACHIEVEMENTS,
  },
  [ResumeKey.CERTIFICATES]: {
    breadCrumb: 'Edit Certifications and Licenses',
    sectionKey: ResumeKey.CERTIFICATES,
  },
};
