/* eslint-disable react/no-array-index-key */
import type { EducationInfo } from '../../../interface';
import { addSeparator } from '../../../utils';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import GPA from './GPA';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';

interface TemplateEducationProps {
  education: EducationInfo[];
  datePosition?: 'heading' | 'content';
  sectionContentProps?: SectionContentProps;
}

const TemplateEducation = ({
  education,
  datePosition = 'heading',
  sectionContentProps,
}: TemplateEducationProps) => (
  <>
    {education?.map((edu, index) => (
      <SectionContent
        key={index}
        heading={addSeparator(edu?.degree, edu?.major, ', ')}
        subHeading={edu?.school}
        content={
          <>
            {datePosition === 'content' && <p>{getFormatedDate(edu)}</p>}
            <GPA gpa={edu.gpa} totalGpa={edu.total_gpa} />
          </>
        }
        date={datePosition === 'heading' ? getFormatedDate(edu) : ''}
        subHeadingStyle={{ ...styles.label2, ...sectionContentProps?.subHeadingStyle }}
        contentStyle={{ ...styles.lineHeightNormal, ...sectionContentProps?.contentStyle }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplateEducation;
