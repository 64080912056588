import type { SxProps } from '@mui/material';
import { Stack, Typography, useTheme } from '@mui/material';

import Icon from '../../components/common/Icon';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';

interface CreateNewItemProps {
  icon?: string;
  primaryText?: string;
  secondaryText?: string;
  action?: React.ReactNode;
  sx?: SxProps;
}

const CreateNewListing = ({ icon, primaryText, secondaryText, action, sx }: CreateNewItemProps) => {
  const theme = useTheme();
  const { isDesktop } = useResponsiveDevice();

  return (
    <Stack
      gap={3}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        backgroundColor: 'common.white',
        border: '1px solid',
        borderRadius: 2,
        borderColor: 'border.light',
        padding: 2,
        ...sx,
      }}
    >
      <Icon className={icon} fontSize="64px" color={theme.palette.text.tertiary} />
      <Stack gap={0.5} sx={{ textAlign: 'center', paddingBottom: 3 }}>
        {primaryText && <Typography variant={isDesktop ? 'h1' : 'h3'}>{primaryText}</Typography>}
        {secondaryText && (
          <Typography variant={isDesktop ? 'label1' : 'body3'}>{secondaryText}</Typography>
        )}
      </Stack>
      {action}
    </Stack>
  );
};

export default CreateNewListing;
