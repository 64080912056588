import { Box, Stack, Typography, useTheme } from '@mui/material';

import Icon from '../Icon';

interface ModalDetailProps {
  title: string;
  description: string | React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
}

const ModalDetail = ({ title, description, children, onClose }: ModalDetailProps) => {
  const theme = useTheme();

  return (
    <Stack gap={3} sx={{ paddingX: 1, overflowY: 'auto' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography id="modal-modal-title" variant="h3">
          {title}
        </Typography>
        {onClose && (
          <Icon
            className="fi fi-rr-cross-small"
            style={{
              color: theme.palette.text.secondary,
            }}
            onClick={onClose}
          />
        )}
      </Stack>
      <Typography id="modal-modal-description" variant="body3">
        {description}
      </Typography>
      {children && <Box className="modal-children">{children}</Box>}
    </Stack>
  );
};

export default ModalDetail;
