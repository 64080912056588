import { Typography } from '@mui/material';

import useIsMobile from '../../../hooks/useIsMobile';
import List from '../../List';
import type { CardProps } from '../../common/Card';
import StyledCard from '../../common/StyledCard';

interface JobContentListProps {
  title: string;
  dataList: string[];
  cardProps?: CardProps;
}

const JobContentList = ({ title, dataList, cardProps }: JobContentListProps) => {
  const isMobile = useIsMobile();
  return (
    <StyledCard
      {...cardProps}
      cardContentProps={{
        ...cardProps?.cardContentProps,
        sx: {
          padding: 3,
          gap: 2,
          '&:last-child': {
            paddingBottom: 3,
          },
          ...cardProps?.cardContentProps?.sx,
        },
      }}
    >
      <Typography variant={isMobile ? 'h3' : 'h2'}>{title}</Typography>
      <List
        sx={{ padding: 0 }}
        dataList={dataList}
        listItemProps={{ sx: { padding: 0.5 } }}
        listItemTextProps={{ primaryTypographyProps: { variant: 'body2' } }}
      />
    </StyledCard>
  );
};
export default JobContentList;
