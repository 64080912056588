import type { CSSProperties } from 'react';

const styles: { [key: string]: CSSProperties } = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontFamily: "'Inter', sans-serif",
  },

  resumeHeaderContainer: {
    alignItems: 'center',
    textAlign: 'center',
    gap: 0,
  },

  contentContainer: {
    padding: '6px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
  },

  contentHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  sectionHeading: {
    borderBottom: '1px solid #828282',
    paddingBottom: '3px',
  },

  body1: {
    fontSize: '10pt',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  body2: {
    fontSize: '9pt',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  h1: {
    fontSize: '16pt',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  h2: {
    fontSize: '12pt',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  h3: {
    fontSize: '10pt',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  label1: {
    fontSize: '10pt',
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  label2: {
    fontSize: '10pt',
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 'normal',
    fontFamily: "'Inter', sans-serif",
  },

  wordBreakAll: {
    wordBreak: 'break-all',
  },

  textDecorationUnderline: {
    textDecoration: 'underline',
  },

  textDecorationNone: {
    textDecoration: 'none',
  },

  padding0: {
    padding: '0px',
  },

  paddingTop3: {
    paddingTop: '3px',
  },

  paddingBottom0: {
    paddingBottom: '0px',
  },

  paddingBottom2: {
    paddingBottom: '2px',
  },

  paddingBottom3: {
    paddingBottom: '3px',
  },

  paddingX4: {
    padding: '0px 4px',
  },

  padding15: {
    padding: '15px',
  },

  lineHeightNormal: {
    lineHeight: 'normal',
  },

  lineHeight1_2: {
    lineHeight: 1.2,
  },

  lineHeight1_4: {
    lineHeight: 1.4,
  },

  fontSize9: {
    fontSize: '9pt',
  },

  colorWhite: {
    color: '#FFFFFF',
  },

  colorPrimary: {
    color: '#000000',
  },

  colorSecondary: {
    color: '#828282',
  },

  colorTertiary: {
    color: 'rgba(0, 0, 0, 0.80)',
  },

  backgroundColorPrimary: {
    backgroundColor: '#F0EFEF',
  },

  backgroundColorSecondary: {
    backgroundColor: '#818181',
  },
};

export default styles;
