import { addSeparator, isNotNilOrEmpty } from '../../../utils';

interface GpaProps {
  gpa?: number | null;
  totalGpa?: number | null;
}

const GPA = ({ gpa, totalGpa }: GpaProps) => (
  <>
    {[gpa, totalGpa].some(isNotNilOrEmpty) && (
      <p>{`• GPA ${addSeparator(gpa, totalGpa, ' / ')}`}</p>
    )}
  </>
);

export default GPA;
