import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '../../contexts/snackbar';
import { type ResumeData, type ResumeEditorState, ResumeKey } from '../../interface';
import { deleteResumeSection } from '../../services/resumes';
import { capitalize, hasTextInsideHtml, isNotNilOrEmpty } from '../../utils';
import InlineChipsList from '../common/InlineChipsList';
import SectionBordered from '../common/Section/SectionBordered';
import AchievementDetail from './components/AchievementDetail';
import CertificateLicenseDetail from './components/CertificateLicenseDetail';
import DisplayEducation from './components/DisplayEducation';
import DisplayProjects from './components/DisplayProjects';
import DisplayUserInfo from './components/DisplayUserInfo';
import DisplayWorkExperience from './components/DisplayWorkExperience';
import PatentSection from './components/PatentSection';
import PublicationSection from './components/PublicationSection';
import ResumeSection from './components/ResumeSection';
import Summary from './components/Summary';
import VolunteerExperienceSection from './components/VolunteerExperienceSection';

interface ResumeProps {
  resume: ResumeData | undefined;
  editable?: boolean;
  hideEmptySection?: boolean;
}

// TODO: Create a HOC component that can be use for editable resumes
const ResumeView = ({ resume, editable, hideEmptySection = false }: ResumeProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const sectionsOrder = resume?.[ResumeKey.SECTIONS_ORDER];
  const resumeId = resume?.[ResumeKey.RESUME_ID];

  const { mutate: deleteSection } = useMutation({
    mutationFn: deleteResumeSection,
    onSuccess: (_data, { sectionKey }) => {
      showSnackbar('success', `${capitalize(sectionKey).replace(/_/g, ' ')} successfully deleted`);
      // Invalidate and refetch the data query when the mutation is successful
      queryClient.invalidateQueries(['resumes', resumeId]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const onEditSection = (key: ResumeKey, editorState: ResumeEditorState) => {
    navigate(`/resumes/edit/${key}`, { state: { resumeEditorState: editorState } });
  };

  const onDeleteSection = (id: string, sectionKey: ResumeKey) => {
    deleteSection({
      elementId: id || '',
      sectionKey: sectionKey || '',
    });
  };

  return (
    <Stack gap="48px">
      {/* display sections based on sections order */}
      {isNotNilOrEmpty(resume) &&
        sectionsOrder?.map((section) => {
          if (!section.enabled) return null;

          switch (section.section_name) {
            // TODO: add hero, objective, achievements, certificates, volunteer experience

            case ResumeKey.HERO:
              return (
                <ResumeSection
                  title={section.name}
                  content={
                    hasTextInsideHtml(resume?.[ResumeKey.HERO]?.message) ? (
                      <SectionBordered>
                        <Typography variant="body2">
                          {parse(resume?.[ResumeKey.HERO]?.message || '')}
                        </Typography>
                      </SectionBordered>
                    ) : null
                  }
                  noContentText="No Hero Message added."
                  tooltipContent={[
                    'Hero Message is the most important part of your resume',
                    'It is a headline grabber',
                    'It appears below your name in the resume',
                    'Customize it to the target position',
                  ]}
                  editable={editable}
                  hideEmptySection={hideEmptySection}
                  // TODO: create a function for createEditorState
                  editorState={{
                    action: 'edit',
                    resumeId: resume?.[ResumeKey.RESUME_ID] || '',
                    elementId: resume?.[ResumeKey.HERO]?._id || '',
                    elementType: ResumeKey.HERO,
                    redirectpath: '/resumes/edit/hero_message',
                  }}
                />
              );

            case ResumeKey.OBJECTIVE:
              return (
                <ResumeSection
                  title={section.name}
                  content={
                    hasTextInsideHtml(resume?.[ResumeKey.OBJECTIVE]?.subtext) ? (
                      <SectionBordered>
                        <Typography variant="body2">
                          {parse(resume?.[ResumeKey.OBJECTIVE]?.subtext || '')}
                        </Typography>
                      </SectionBordered>
                    ) : null
                  }
                  noContentText="No Objective added."
                  tooltipContent={[
                    'The Objective section is recommended if you have less than 2 years of work experience or try to transition to a new career',
                    'Do not include both Objective and Professional Summary in the resume. Choose one',
                    'Customize it to the target position',
                  ]}
                  editable={editable}
                  hideEmptySection={hideEmptySection}
                  editorState={{
                    action: 'edit',
                    resumeId: resume?.[ResumeKey.RESUME_ID] || '',
                    elementId: resume?.[ResumeKey.OBJECTIVE]?._id || '',
                    elementType: ResumeKey.OBJECTIVE,
                    redirectpath: '/resumes/edit/objective',
                  }}
                />
              );

            case ResumeKey.CONTACT:
              return (
                <DisplayUserInfo
                  contact={resume?.[ResumeKey.CONTACT]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                />
              );
            case ResumeKey.SUMMARY:
              return (
                <Summary
                  summary={resume?.[ResumeKey.SUMMARY]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                />
              );
            case ResumeKey.SKILLS:
              return (
                <InlineChipsList
                  id={resume?.[ResumeKey.SKILLS]?._id}
                  dataList={resume?.[ResumeKey.SKILLS]?.[ResumeKey.SKILLS] || []}
                  title="Skills"
                  type={ResumeKey.SKILLS}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                />
              );
            case ResumeKey.EXPERIENCE:
              return (
                <DisplayWorkExperience
                  experiences={resume?.[ResumeKey.EXPERIENCE]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                />
              );

            case ResumeKey.PROJECT:
              return (
                <DisplayProjects
                  projects={resume?.[ResumeKey.PROJECT]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent={[
                    'The Projects section is recommended if your work experience is less than 3 years or if your work is project-centric or involved clients.',
                    'Do not try to list every single projects you had in the past.',
                    'Instead, choose ones that support your achievements described in the Work Experience section or ones relevant to the target position.',
                  ]}
                />
              );
            case ResumeKey.EDUCATION:
              return (
                <DisplayEducation
                  education={resume?.[ResumeKey.EDUCATION]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                />
              );
            case ResumeKey.COURSEWORK:
              return (
                <InlineChipsList
                  id={resume?.[ResumeKey.COURSEWORK]?._id}
                  dataList={resume?.[ResumeKey.COURSEWORK]?.[ResumeKey.COURSEWORK] || []}
                  title="Relevant Coursework"
                  type={ResumeKey.COURSEWORK}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent="The Relevant Coursework section is recommended if you graduated less than 2 years ago and do NOT have relevant work experience"
                />
              );
            case ResumeKey.INTERESTS:
              return (
                <InlineChipsList
                  id={resume?.[ResumeKey.INTERESTS]?._id}
                  dataList={resume?.[ResumeKey.INTERESTS]?.[ResumeKey.INTERESTS] || []}
                  title="Interests"
                  type={ResumeKey.INTERESTS}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent={[
                    'The Interests section is recommended only if they are relevant to the target company or their culture.',
                    'Interests can include hobbies, personal interests, and extra-curricular activities.',
                  ]}
                />
              );
            case ResumeKey.PATENTS:
              return (
                <PatentSection
                  patents={resume?.[ResumeKey.PATENTS]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent={[
                    'The Patents section is recommended if you have multiple patents to highlight.',
                    'Otherwise, describe your patents in the Work Experience section.',
                  ]}
                  enableSectionReorder={
                    resume?.[ResumeKey.PATENTS] && resume?.[ResumeKey.PATENTS]?.length > 1
                  }
                />
              );
            case ResumeKey.PUBLICATIONS:
              return (
                <PublicationSection
                  publications={resume?.[ResumeKey.PUBLICATIONS]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent={[
                    'The Publications section is recommended if you have multiple publications to highlight.',
                    'Otherwise, describe them in the Work Experience section.',
                  ]}
                  enableSectionReorder={
                    resume?.[ResumeKey.PUBLICATIONS] && resume?.[ResumeKey.PUBLICATIONS]?.length > 1
                  }
                />
              );
            case ResumeKey.VOLUNTEER_EXPERIENCE:
              return (
                // TODO: Use ResumeSection component and pass the content to it, i.e. populate VolunteerExperienceDetail
                <VolunteerExperienceSection
                  experiences={resume?.[ResumeKey.VOLUNTEER_EXPERIENCE]}
                  editable={editable}
                  resumeId={resume?.[ResumeKey.RESUME_ID]}
                  hideEmptySection={hideEmptySection}
                  tooltipContent={[
                    'The Volunteer Experience section can be recommended when applying for non-profit or government jobs.',
                    'If the skills used for the volunteer work are relevant to your job or a target position, list them under the Projects section. This approach can be effective when you do not have enough professional work experience.',
                    'Similarly, you can list the volunteer work in the Work Experience section if the work was done during a gap period between your careers.',
                  ]}
                  enableSectionReorder={
                    resume?.[ResumeKey.VOLUNTEER_EXPERIENCE] &&
                    resume?.[ResumeKey.VOLUNTEER_EXPERIENCE]?.length > 1
                  }
                />
              );

            // TODO: need to rework on this section
            // case ResumeKey.VOLUNTEER_EXPERIENCE:
            //   // TODO: create a memoized function to return this
            //   return (
            //     <ResumeSection
            //       title={section.name}
            //       content={
            //         resume?.[ResumeKey.VOLUNTEER_EXPERIENCE] &&
            //         resume?.[ResumeKey.VOLUNTEER_EXPERIENCE]?.length > 0 ? (
            //           <Stack gap={2}>
            //             {resume?.[ResumeKey.VOLUNTEER_EXPERIENCE]?.map((experience) => (
            //               <VolunteerExperienceDetail
            //                 key={experience._id}
            //                 experience={experience}
            //                 isEditable={editable}
            //                 onDelete={(id) => {
            //                   onDeleteSection(id, ResumeKey.VOLUNTEER_EXPERIENCE);
            //                 }}
            //                 onEdit={(id) => {
            //                   onEditSection('volunteer-experience', {
            //                     action: 'edit',
            //                     resumeId: resumeId || '',
            //                     elementId: id,
            //                     elementType: ResumeKey.VOLUNTEER_EXPERIENCE,
            //                     redirectpath: 'volunteer-experience',
            //                   });
            //                 }}
            //               />
            //             ))}
            //           </Stack>
            //         ) : null
            //       }
            //       noContentText="No Volunteer Experience added."
            //       tooltipContent={[
            //         'The Volunteer Experience section can be recommended when applying for non-profit or government jobs.',
            //         'If the skills used for the volunteer work are relevant to your job or a target position, list them under the Projects section. This approach can be effective when you do not have enough professional work experience.',
            //         'Similarly, you can list the volunteer work in the Work Experience section if the work was done during a gap period between your careers.',
            //       ]}
            //       editable={editable}
            //       hideEmptySection={hideEmptySection}
            //       editorState={{
            //         action: 'add',
            //         resumeId: resume?.[ResumeKey.RESUME_ID] || '',
            //         elementId: '',
            //         elementType: ResumeKey.VOLUNTEER_EXPERIENCE,
            //         redirectpath: 'volunteer-experience',
            //       }}
            //       enableSectionReorder={
            //         resume?.[ResumeKey.VOLUNTEER_EXPERIENCE] &&
            //         resume?.[ResumeKey.VOLUNTEER_EXPERIENCE]?.length > 1
            //       }
            //     />
            //   );

            case ResumeKey.ACHIEVEMENTS:
              // TODO: create a memoized function to return this
              return (
                <ResumeSection
                  title={section.name}
                  content={
                    resume?.[ResumeKey.ACHIEVEMENTS] &&
                    resume?.[ResumeKey.ACHIEVEMENTS]?.length > 0 ? (
                      <Stack gap={2}>
                        {resume?.[ResumeKey.ACHIEVEMENTS]?.map((achievement) => (
                          <AchievementDetail
                            key={achievement._id}
                            achievement={achievement}
                            isEditable={editable}
                            onDelete={(id) => {
                              onDeleteSection(id, ResumeKey.ACHIEVEMENTS);
                            }}
                            onEdit={(id) => {
                              onEditSection(ResumeKey.ACHIEVEMENTS, {
                                action: 'edit',
                                resumeId: resumeId || '',
                                elementId: id,
                                elementType: ResumeKey.ACHIEVEMENTS,
                                redirectpath: '/resumes/edit/achievements',
                              });
                            }}
                          />
                        ))}
                      </Stack>
                    ) : null
                  }
                  noContentText="No Achievements added."
                  tooltipContent={[
                    'The Achievements section is recommended if you have multiple achievements recognized by others',
                    'Otherwise, you can describe them in the Work Experience section',
                    'List your awards, honors, and professional recognitions',
                  ]}
                  editable={editable}
                  hideEmptySection={hideEmptySection}
                  editorState={{
                    action: 'add',
                    resumeId: resume?.[ResumeKey.RESUME_ID] || '',
                    elementId: '',
                    elementType: ResumeKey.ACHIEVEMENTS,
                    redirectpath: '/resumes/edit/achievements',
                  }}
                  enableSectionReorder={
                    resume?.[ResumeKey.ACHIEVEMENTS] && resume?.[ResumeKey.ACHIEVEMENTS]?.length > 1
                  }
                />
              );

            case ResumeKey.CERTIFICATES:
              return (
                <ResumeSection
                  title={section.name}
                  content={
                    resume?.[ResumeKey.CERTIFICATES] &&
                    resume?.[ResumeKey.CERTIFICATES]?.length > 0 ? (
                      <Stack gap={2}>
                        {resume?.[ResumeKey.CERTIFICATES]?.map((certificate) => (
                          <CertificateLicenseDetail
                            key={certificate._id}
                            certificate={certificate}
                            isEditable={editable}
                            onDelete={(id) => {
                              onDeleteSection(id, ResumeKey.CERTIFICATES);
                            }}
                            onEdit={(id) => {
                              onEditSection(ResumeKey.CERTIFICATES, {
                                action: 'edit',
                                resumeId: resumeId || '',
                                elementId: id,
                                elementType: ResumeKey.CERTIFICATES,
                              });
                            }}
                          />
                        ))}
                      </Stack>
                    ) : null
                  }
                  noContentText="No Certificates added."
                  tooltipContent={[
                    'Certificates are a great way to showcase your skills and qualifications.',
                    'Include relevant certifications that are related to the target position.',
                    'Mention the issuing authority and the date of certification.',
                  ]}
                  editable={editable}
                  hideEmptySection={hideEmptySection}
                  editorState={{
                    action: 'add',
                    resumeId: resumeId || '',
                    elementId: '',
                    elementType: ResumeKey.CERTIFICATES,
                  }}
                  enableSectionReorder={
                    resume?.[ResumeKey.CERTIFICATES] && resume?.[ResumeKey.CERTIFICATES]?.length > 1
                  }
                />
              );

            default:
              return null;
          }
        })}
    </Stack>
  );
};

export default ResumeView;
