import type { ReactElement } from 'react';

import { Box, Icon, Stack, useTheme } from '@mui/material';

import { ResponseStatus } from '../../interface';
import { isNotNilOrEmpty } from '../../utils';
import { renderText } from '../../utils/htmlUtils';
import Card from './Card';
import type { ProgressStatusChipProps } from './ProgressStatusChip';
import ProgressStatusChip from './ProgressStatusChip';

interface ListingItem {
  id?: string;
  icon?: string;
  heading?: ReactElement | string;
  subHeading: ReactElement | string;
}

interface ListingCardProps extends ProgressStatusChipProps {
  items: ListingItem[];
  control?: ReactElement;
  description: ReactElement | string;
  enableStatus?: boolean;
  key: string;
  onClick: (id: string, status: ResponseStatus) => void;
}

const ListingCard = ({
  items,
  control,
  description,
  enableStatus = false,
  key,
  status = ResponseStatus.TODO,
  onClick,
  ...restProps
}: ListingCardProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{ borderRadius: '12px' }}
      cardContentProps={{ sx: { '&:last-child': { paddingBottom: 2 } } }}
      key={key}
      onClick={() => onClick(key, status)}
    >
      <Stack
        sx={{
          flexDirection: enableStatus ? 'column' : 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        {enableStatus && (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingY: 1,
              paddingRight: 0.25,
              width: '100%',
            }}
          >
            <ProgressStatusChip status={status} {...restProps} />
            {control}
          </Stack>
        )}
        <Stack sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <Stack key={item.id} sx={{ flexDirection: 'row', gap: 1, padding: '8px 2px' }}>
              {isNotNilOrEmpty(item?.icon) && (
                <Icon
                  className={item.icon}
                  sx={{ fontSize: '20px', color: theme.palette.text.secondary, paddingTop: 0.25 }}
                />
              )}
              <Stack sx={{ width: '90%' }}>
                {renderText(item.heading)}
                {renderText(item.subHeading, { sx: { color: 'text.secondary' } })}
              </Stack>
            </Stack>
          ))}
          {renderText(description, {
            sx: {
              color: 'text.secondary',
              paddingLeft: 3.75,
              paddingTop: 1,
            },
            variant: 'body3',
          })}
        </Stack>
        {!enableStatus && <Box sx={{ paddingY: 1, paddingRight: 0.25 }}>{control}</Box>}
      </Stack>
    </Card>
  );
};

export default ListingCard;
