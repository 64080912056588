import { useEffect, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import JobXRay from '../../components/JobXRay';
import TemplateDocument from '../../components/ResumeTemplate/TemplateDocument';
import ResumeView from '../../components/ResumeView';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import Modal from '../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../components/common/Modal/ModalDetail';
import Tabs from '../../components/common/Tabs';
import { useDownload } from '../../hooks/useDownload';
import useHTMLRenderer from '../../hooks/useHTMLRenderer';
import useIsMobile from '../../hooks/useIsMobile';
import type { ResumeData, ResumeTemplateKey } from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { getJobXRay } from '../../services/jobDescriptions';
import { getJobMatchScore } from '../../services/jobMatches';
import { getResumeXRay } from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import { getResumeTemplateBaseFontSize } from '../../utils';
import ResumeScore from './ResumeScore';
import type { JobMatchScoreData, JobXRayData } from './interface';

// TODO: rename component
const JobMatches = () => {
  const [selectedSection, setSelectedSection] = useState<string | null>('score');

  const [jobMatchScore, setJobMatchScore] = useState<JobMatchScoreData>();
  const [jobXRay, setJobXRay] = useState<JobXRayData>();
  const [resumeXRay, setResumeXRay] = useState<ResumeData>();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isOpen } = useSideBarStore();
  const isMobile = useIsMobile();

  const match = useMatch('/job-matches/:lastPart');
  const urlId = match?.params.lastPart;

  const location = useLocation();
  const navigate = useNavigate();

  const { downloadResume } = useDownload();
  const { generateHtml } = useHTMLRenderer();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (urlId) {
          if (selectedSection === 'score') {
            const res = await getJobMatchScore(urlId);
            setJobMatchScore(res);
          } else if (selectedSection === 'x-ray' && jobMatchScore?.job_description.id) {
            const res = await getJobXRay(jobMatchScore?.job_description.id);
            setJobXRay(res);
          } else if (selectedSection === 'resume' && jobMatchScore?.resume.id) {
            const res = await getResumeXRay(jobMatchScore?.resume.id);
            setResumeXRay(res);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [jobMatchScore?.job_description.id, jobMatchScore?.resume.id, selectedSection, urlId]);

  const TabsContent = [
    { value: 'score', label: 'Score', icon: 'fi fi-rr-tachometer' },
    {
      value: 'x-ray',
      label: 'Job X-Ray',
      icon: 'fi fi-rr-search-alt',
    },
    {
      value: 'resume',
      label: 'Resume',
      icon: 'fi fi-rr-document',
    },
  ];

  const BreadCrumbLinks = useMemo(
    () =>
      isMobile
        ? [{ label: 'View', href: location.pathname }]
        : [
            { label: 'Job Matches', href: RoutePaths.JOBMATCHES },
            { label: 'View', href: location.pathname },
          ],
    [isMobile, location.pathname],
  );

  const selectedTemplate = useMemo(
    () => resumeXRay?.selected_template?.key as ResumeTemplateKey,
    [resumeXRay],
  );

  const baseFontSize = useMemo(
    () => getResumeTemplateBaseFontSize(selectedTemplate),
    [selectedTemplate],
  );

  const ResumeDocument = useMemo(() => {
    if (resumeXRay) {
      return <TemplateDocument resume={resumeXRay} selectedTemplateKey={selectedTemplate} />;
    }
    return null;
  }, [resumeXRay, selectedTemplate]);

  const onEditResumeClick = () => {
    if (jobMatchScore?.resume.id) {
      navigate(`${RoutePaths.RESUMES}/${jobMatchScore.resume.id}`);
    }
  };

  const onDownloadClick = () => {
    if (resumeXRay) {
      const html = generateHtml(ResumeDocument as JSX.Element, baseFontSize);
      downloadResume({ html });
    }
  };

  return (
    <Stack
      sx={{
        backgroundColor: 'common.white',
        paddingY: isMobile ? 1.5 : 3,
        // TODO: quick fix for padding, we need to handle page layout margins and padding
        // eslint-disable-next-line no-nested-ternary
        paddingLeft: isMobile ? 1.5 : isOpen ? 4 : 7,
        paddingRight: isMobile ? 1.5 : 4,
        boxSizing: 'border-box',
      }}
    >
      {isLoading && <LoadingSpinner />}
      {/* Job score page */}
      {/* top grid */}
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Grid xs={12} sx={{ marginBottom: { xs: 1.5, sm: 4 } }}>
          <Grid container spacing={1} sx={{ justifyContent: 'space-between', paddingRight: 3.5 }}>
            <Grid>
              <BreadCrumbs links={BreadCrumbLinks} />
            </Grid>
            {selectedSection === 'resume' && (
              <Grid>
                <Stack sx={{ flexDirection: 'row', gap: 1.5 }}>
                  <Modal open={showModal}>
                    <>
                      <ModalDetail
                        title="Edit Resume?"
                        description="You will be sent to the Resume menu. Your existing score and report will not be affected."
                      />
                      <ModalActionsDeprecated
                        type="confirm"
                        onConfirm={onEditResumeClick}
                        onClose={() => setShowModal(false)}
                      />
                    </>
                  </Modal>
                  <Button
                    variant="outlined"
                    sx={{ color: 'primary.main', border: '1.5px solid' }}
                    onClick={() => setShowModal(true)}
                  >
                    <Icon className="fi fi-rr-edit" fontSize="20px" />
                    Edit Resume
                  </Button>
                  <Button onClick={onDownloadClick}>
                    <Icon className="fi fi-rr-file-download" fontSize="20px" />
                    Download
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* sections */}
        <Grid xs={12} sx={{ paddingX: { xs: 0, sm: 3.5 } }}>
          <Tabs tabs={TabsContent} onChange={setSelectedSection}>
            <ResumeScore jobMatchScore={jobMatchScore} />
            <JobXRay jobXRay={jobXRay} />
            <ResumeView resume={resumeXRay} hideEmptySection />
          </Tabs>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JobMatches;
