import { Divider } from '@mui/material';

import Card from '../../../../components/common/Card';
import { type SectionOrder } from '../../../../interface';
import CareerStageSelect from './CareerStageSelect';
import { DISABLED_SECTIONS_REORDER_KEYS } from './Contants';
import ReorderSections from './ReorderSections';

interface ManageSectionsProps {
  sectionsOrder: SectionOrder[] | undefined;
  careerStages?: string[];
  defaultCareerStage?: string;
  loading?: boolean;
  onSectionsReorder?: (sectionsOrder: SectionOrder[]) => void;
  onCareerStageChange?: (careerStage: string | number | null) => void;
}

// TODO: rethink props, might need context here
const ManageSections = ({
  sectionsOrder = [],
  careerStages = [],
  defaultCareerStage,
  loading = false,
  onSectionsReorder,
  onCareerStageChange,
}: ManageSectionsProps) => (
  <Card
    sx={{ borderRadius: '8px' }}
    cardContentProps={{
      sx: { display: 'flex', flexDirection: 'column', gap: 2, padding: 2.5 },
    }}
  >
    <CareerStageSelect
      careerStages={careerStages}
      defaultCareerStage={defaultCareerStage}
      loading={loading}
      onChange={onCareerStageChange}
    />
    <Divider sx={{ marginY: 2, width: '99%' }} />
    <ReorderSections
      sectionsOrder={sectionsOrder}
      DisabledSectionsReorderKeys={DISABLED_SECTIONS_REORDER_KEYS}
      onSectionsReorder={onSectionsReorder}
    />
  </Card>
);

export default ManageSections;
