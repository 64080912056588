import { isNil, isNotNil } from 'ramda';
import { useNavigate } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';

import { type ContactInfo, ResumeKey } from '../../../../interface';
import RoutePaths from '../../../../routes/RoutePaths';
import { addSeparator, isNotNilOrEmpty } from '../../../../utils/index';
import EmptySection from '../EmptySection';
import ResumeEditorButton from '../ResumeEditorButton';

interface DisplayUserInfoProps {
  contact?: ContactInfo;
  editable?: boolean;
  resumeId?: string;
  hideEmptySection?: boolean;
}

// TODO: move to common interface file
interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
}

const DisplayUserInfo = ({
  contact,
  editable,
  resumeId,
  hideEmptySection,
}: DisplayUserInfoProps) => {
  const navigate = useNavigate();
  const shouldhideEmptySection = hideEmptySection && isNil(contact);

  const handleEditContactInfo = () => {
    const resumeEditorState: ResumeEditorState = {
      action: 'edit',
      resumeId: resumeId || '',
      elementId: contact?._id || '',
      elementType: ResumeKey.CONTACT,
    };
    navigate(RoutePaths.RESUME_EDIT.replace(':key', ResumeKey.CONTACT), {
      state: { resumeEditorState },
    });
  };

  return shouldhideEmptySection ? null : (
    <Stack gap={2}>
      <Grid sx={{ justifyContent: 'space-between' }} container>
        <Grid item>
          {isNotNil(contact) && (
            <Stack gap={0.5}>
              {(isNotNilOrEmpty(contact.first_name) || isNotNilOrEmpty(contact.last_name)) && (
                <Typography variant="h1">
                  {addSeparator(contact.first_name, contact.last_name, ' ')}
                </Typography>
              )}
              {(isNotNilOrEmpty(contact.city) || isNotNilOrEmpty(contact.state)) && (
                <Typography variant="body2">
                  {addSeparator(contact.city, contact.state, ' , ')}
                </Typography>
              )}
              {contact.display_country && isNotNilOrEmpty(contact.country) && (
                <Typography variant="body2">{contact.country}</Typography>
              )}
              {contact.email && <Typography variant="body2">{contact.email}</Typography>}
              {contact.phone && <Typography variant="body2">{contact.phone}</Typography>}
              {contact.linkedin && <Typography variant="body2">{contact.linkedin}</Typography>}
            </Stack>
          )}
        </Grid>
        {editable && (
          <Grid item>
            <ResumeEditorButton onClick={handleEditContactInfo} />
          </Grid>
        )}
      </Grid>
      {isNil(contact) && <EmptySection text="No Contact Information Added" />}
    </Stack>
  );
};

export default DisplayUserInfo;
