import styles from '../styles';

interface SectionTitleProps {
  title: string;
  style?: React.CSSProperties | undefined;
}

const SectionTitle = ({ title, style }: SectionTitleProps) => (
  <p className="section-title" style={{ ...styles.h2, ...style }}>
    {title}
  </p>
);

export default SectionTitle;
