import { isNotNil } from 'ramda';

import type { ResponseStatus } from '../interface';
import http from './http';

export enum JobDescriptionTypes {
  JOBXRAYS = 'jobXrays',
  JOBDESCRIPTIONS = 'jobDescriptions',
}

export enum JobKeys {
  JOBS = '/jobs',
}

export enum JobProgressSource {
  JOB_XRAY = 'job_xray',
  AI_CUSTOMIZE = 'ai_customize',
  MATCH_SCORE = 'match_score',
  AI_GENERATE = 'ai_generate',
}

export interface JobDescription {
  _id: string;
  user_id: string;
  job_description: string;
  created_at: string;
  updated_at: string;
  job_company: string;
  job_title: string;
  progress: number;
  used_for_score: number;
  source?: JobProgressSource;
  source_id?: string;
  status: ResponseStatus;
}

// Function signature with 0 arguments
export const getAllJobXRays: {
  (): Promise<{
    job_description_list: JobDescription[];
    status: {
      status: ResponseStatus;
      message: string;
    };
  }>;
  // Function signature with 1 optional argument
  (status?: ResponseStatus): Promise<{
    job_description_list: JobDescription[];
    status: {
      status: ResponseStatus;
      message: string;
    };
  }>;
} = async (status?: ResponseStatus) => {
  try {
    const reqUrl = isNotNil(status) ? `/job-description?status=${status}` : '/job-description';
    const res = await http.get(reqUrl);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadJobDescription = async (jobDescription: string) => {
  if (!jobDescription) {
    return Promise.reject(new Error('Job Description is required'));
  }

  try {
    const res = await http.post('/job-description', { job_description: jobDescription });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get job xray
export const getJobXRay = async (jobDescId: string) => {
  try {
    const res = await http.get(`/job-description/xray/${jobDescId}`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data?.jd_x_ray);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get raw jd
export const getRawJobDescription = async (jobDescId: string) => {
  try {
    const res = await http.get(`/job-description/raw/${jobDescId}`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteJobDescription = async (jobDescId: string) => {
  try {
    const res = await http.delete(`/job-description/delete/${jobDescId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
