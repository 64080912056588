import { isNotNil } from 'ramda';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import { JobDescriptionTypes, uploadJobDescription } from '../../services/jobDescriptions';
import { createNewJobMatchScore } from '../../services/jobMatches';
import { ResumesKeys, uploadJdResume, uploadResumeFile } from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import SelectJobDescriptionDeprecated, {
  SelectJDSection,
} from './components/SelectJobDescriptionDeprecated';
import SelectResume, { SelectResumeSection } from './components/SelectResume';

export enum CreateJobMatchSections {
  SELECT_JD = 'select-jd',
  SELECT_RESUME = 'select-resume',
  JOB_SCORE_PROGRESS = '-score-projobgress',
}

interface CompletedSectionType {
  [CreateJobMatchSections.SELECT_JD]: {
    status: boolean;
    section: SelectJDSection;
    value: string;
  };
  [CreateJobMatchSections.SELECT_RESUME]: {
    status: boolean;
    section: SelectResumeSection;
    value: string | File;
  };
}

const CreateJobMatch = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();

  // query client & mutations
  const queryClient = useQueryClient();
  const location = useLocation();
  const jobMatchScoreId = location?.state?.matchScoreId;
  const theme = useTheme();

  const [shouldAiCustomizeResume, setShouldAiCustomizeResume] = useState(
    isNotNil(jobMatchScoreId) || false,
  );
  const [matchScoreId, setMatchScoreId] = useState<string>(jobMatchScoreId || '');
  const [shouldDisplayBackButton, setShouldDisplayBackButton] = useState<Boolean>(false);

  const [jobDescriptionId, setJobDescriptionId] = useState<string>('');
  const [resumeId, setResumeId] = useState<string>('');
  const [jdText, setJdText] = useState<string>('');
  const [resumeFile, setResumeFile] = useState<File>();

  const [selectedSection, setSelectedSection] = useState<CreateJobMatchSections>(
    isNotNil(jobMatchScoreId)
      ? CreateJobMatchSections.JOB_SCORE_PROGRESS
      : CreateJobMatchSections.SELECT_JD,
  );

  const [completedSections, setCompletedSections] = useState<CompletedSectionType>({
    [CreateJobMatchSections.SELECT_JD]: {
      status: false,
      section: SelectJDSection.SELECT_JD,
      value: jobDescriptionId,
    },
    [CreateJobMatchSections.SELECT_RESUME]: {
      status: false,
      section: SelectResumeSection.SELECT_RESUME,
      value: resumeId,
    },
  });

  const [alreadyProcessedSection, setAlreadyProcessedSection] = useState({
    jobDescription: false,
    resume: false,
  });

  const { isLoading: isCreateJobMatchLoading, mutate: createJobMatch } = useMutation({
    retry: 3,
    mutationFn: createNewJobMatchScore,
    onSuccess: (res) => {
      const jobMatchData = res?.data;
      setMatchScoreId(jobMatchData.match_score_id);
      setSelectedSection(CreateJobMatchSections.JOB_SCORE_PROGRESS);
    },
  });

  const { isLoading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jdUploadData) => {
      setJobDescriptionId(jdUploadData._id);
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated job xray are fetched
      setTimeout(() => {
        createJobMatch({
          job_description_id: jdUploadData._id,
          resume_id: resumeId,
          should_ai_customize_resume: shouldAiCustomizeResume,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  const { isLoading: isResumeLoading, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile,
    onSuccess: (resumeUploadData) => {
      setResumeId(resumeUploadData._id);
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      // TODO: need to verify setTimeout.
      setTimeout(() => {
        createJobMatch({
          job_description_id: jobDescriptionId,
          resume_id: resumeUploadData._id,
          should_ai_customize_resume: shouldAiCustomizeResume,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  const uploadJdResumeQueryFuntion = async ({
    jobDescription,
    resume,
  }: {
    jobDescription: string;
    resume: File;
  }) => {
    const res = await uploadJdResume(jobDescription, resume);
    return res;
  };

  const { mutate: uploadJdResumeData } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (jdResumeData: any) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated job xray are fetched
      setJobDescriptionId(jdResumeData.job_description_id);
      setResumeId(jdResumeData.resume_id);
      // TODO: need to verify setTimeout.
      setTimeout(() => {
        createJobMatch({
          job_description_id: jdResumeData.job_description_id,
          resume_id: jdResumeData.resume_id,
          should_ai_customize_resume: false,
        });
      }, 500);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  const BreadCrumbLinks = [
    {
      label: 'Job Matches',
      href: RoutePaths.JOBMATCHES,
    },
    {
      label: 'Create New',
      href: RoutePaths.CREATE_JOB_MATCH,
    },
  ];

  const onClickNext = () => {
    if (selectedSection === CreateJobMatchSections.SELECT_JD && (jobDescriptionId || jdText)) {
      setSelectedSection(CreateJobMatchSections.SELECT_RESUME);
      setShouldDisplayBackButton(true);
    }

    if (selectedSection === CreateJobMatchSections.SELECT_RESUME) {
      if (resumeId && jobDescriptionId) {
        createJobMatch({
          job_description_id: jobDescriptionId,
          resume_id: resumeId,
          should_ai_customize_resume: shouldAiCustomizeResume,
        });
      } else if (resumeId && jdText) {
        uploadJD(jdText);
      } else if (resumeFile && jobDescriptionId) {
        uploadResume(resumeFile);
      } else if (resumeFile && jdText) {
        uploadJdResumeData({ jobDescription: jdText, resume: resumeFile });
      }
    }
  };

  const onClickBack = () => {
    if (selectedSection === CreateJobMatchSections.SELECT_RESUME) {
      setSelectedSection(CreateJobMatchSections.SELECT_JD);
      setShouldDisplayBackButton(false);
    } else {
      navigate(-1);
    }
  };

  const shouldDisableNextButton = useMemo(
    () => () =>
      (selectedSection === CreateJobMatchSections.SELECT_JD &&
        !completedSections[CreateJobMatchSections.SELECT_JD].status) ||
      (selectedSection === CreateJobMatchSections.SELECT_RESUME &&
        !completedSections[CreateJobMatchSections.SELECT_RESUME].status) ||
      isLoading ||
      isResumeLoading ||
      isCreateJobMatchLoading,
    [completedSections, isCreateJobMatchLoading, isLoading, isResumeLoading, selectedSection],
  );

  // TODO: needs refactoring and respelling
  // TODO: rework required to handle scenarios in which the user removes anything especially resume file (bug)

  const handleOnSelected = (data: {
    jdId?: string;
    resumeId?: string;
    jDText?: string;
    resumeFile?: File;
  }) => {
    if (data.jdId) {
      setJobDescriptionId(data.jdId);
      setJdText('');
      setCompletedSections({
        ...completedSections,
        [CreateJobMatchSections.SELECT_JD]: {
          status: true,
          section: SelectJDSection.SELECT_JD,
          value: data.jdId,
        },
      });
      setAlreadyProcessedSection({
        ...alreadyProcessedSection,
        jobDescription: true,
      });
    }

    if (data.resumeId) {
      setResumeId(data.resumeId);
      setCompletedSections({
        ...completedSections,
        [CreateJobMatchSections.SELECT_RESUME]: {
          status: true,
          section: SelectResumeSection.SELECT_RESUME,
          value: data.resumeId,
        },
      });
      setAlreadyProcessedSection({
        ...alreadyProcessedSection,
        resume: true,
      });
    }

    if (data.jDText) {
      setJdText(data.jDText);
      setJobDescriptionId('');
      setCompletedSections({
        ...completedSections,
        [CreateJobMatchSections.SELECT_JD]: {
          status: true,
          section: SelectJDSection.IMPORT_JD,
          value: data.jDText,
        },
      });
      setAlreadyProcessedSection({
        ...alreadyProcessedSection,
        jobDescription: false,
      });
    }

    if (data.resumeFile) {
      setResumeId('');
      setResumeFile(data.resumeFile);
      setCompletedSections({
        ...completedSections,
        [CreateJobMatchSections.SELECT_RESUME]: {
          status: true,
          section: SelectResumeSection.IMPORT_RESUME,
          value: data.resumeFile,
        },
      });
      setAlreadyProcessedSection({
        ...alreadyProcessedSection,
        resume: false,
      });
    }
  };

  const onAICustomizeResumeChange = (value: boolean) => {
    setShouldAiCustomizeResume(value);
  };

  return (
    // quickfix: removing left margin added by layout component
    <Stack
      sx={{
        marginLeft:
          selectedSection === CreateJobMatchSections.JOB_SCORE_PROGRESS && !isOpen && !isMobile
            ? '20px'
            : '0px',
      }}
    >
      {/* create Job score page */}
      <Stack
        sx={{
          height: '100%',
          width:
            selectedSection === CreateJobMatchSections.JOB_SCORE_PROGRESS
              ? `calc(100% - ${isMobile ? '24px' : '48px'})`
              : '80vw',
          maxWidth: '1920px',
          margin: '32px auto',
        }}
      >
        {selectedSection !== CreateJobMatchSections.JOB_SCORE_PROGRESS && (
          <Stack gap="4px">
            <BreadCrumbs links={BreadCrumbLinks} onBack={onClickBack} />
            <Typography variant="body2" sx={{ paddingLeft: '30px' }}>
              Create a Job Match Score Report.
            </Typography>
          </Stack>
        )}
        <Stack sx={{ gap: 4 }}>
          {selectedSection !== CreateJobMatchSections.JOB_SCORE_PROGRESS && (
            <Box>
              <Stack
                sx={{
                  marginTop: 4,
                  marginBottom: '12px',
                  flexDirection: 'row',
                  gap: 1,
                }}
              >
                {/* TODO: refactor and make generic Chip component */}
                <Chip
                  label="Step 1"
                  icon={
                    <Icon
                      className={
                        completedSections[CreateJobMatchSections.SELECT_JD].status &&
                        selectedSection !== CreateJobMatchSections.SELECT_JD
                          ? 'fi fi-br-check'
                          : ''
                      }
                      fontSize="16px"
                      color={
                        completedSections[CreateJobMatchSections.SELECT_JD].status &&
                        selectedSection !== CreateJobMatchSections.SELECT_JD
                          ? theme.palette.primary.main
                          : 'inherit'
                      }
                    />
                  }
                  sx={{
                    padding: '8px',
                    backgroundColor:
                      selectedSection === CreateJobMatchSections.SELECT_JD
                        ? '#E6F7EE'
                        : 'transparent',
                    color:
                      selectedSection === CreateJobMatchSections.SELECT_JD
                        ? 'primary.main'
                        : 'inherit',
                    border: selectedSection !== CreateJobMatchSections.SELECT_JD ? 'none' : 'unset',
                    fontWeight: selectedSection === CreateJobMatchSections.SELECT_JD ? 600 : 400,
                  }}
                  variant={
                    selectedSection === CreateJobMatchSections.SELECT_JD ? 'filled' : 'outlined'
                  }
                />
                <Chip
                  label="Step 2"
                  icon={
                    <Icon
                      className={
                        completedSections[CreateJobMatchSections.SELECT_RESUME].status &&
                        selectedSection !== CreateJobMatchSections.SELECT_RESUME
                          ? 'fi fi-br-check'
                          : ''
                      }
                      fontSize="16px"
                      color={
                        completedSections[CreateJobMatchSections.SELECT_RESUME].status &&
                        selectedSection !== CreateJobMatchSections.SELECT_RESUME
                          ? theme.palette.primary.main
                          : 'inherit'
                      }
                    />
                  }
                  sx={{
                    padding: '8px',
                    backgroundColor:
                      selectedSection === CreateJobMatchSections.SELECT_RESUME
                        ? '#E6F7EE'
                        : 'transparent',
                    color:
                      selectedSection === CreateJobMatchSections.SELECT_RESUME
                        ? 'primary.main'
                        : 'inherit',
                    border:
                      selectedSection !== CreateJobMatchSections.SELECT_RESUME ? 'none' : 'unset',
                    fontWeight:
                      selectedSection === CreateJobMatchSections.SELECT_RESUME ? 600 : 400,
                  }}
                  variant={
                    selectedSection === CreateJobMatchSections.SELECT_RESUME ? 'filled' : 'outlined'
                  }
                />
              </Stack>

              <Divider />
            </Box>
          )}

          {/* sections */}
          {selectedSection === CreateJobMatchSections.SELECT_JD && (
            <SelectJobDescriptionDeprecated
              onSelected={handleOnSelected}
              defaultValue={{
                section: completedSections[CreateJobMatchSections.SELECT_JD].section,
                value: completedSections[CreateJobMatchSections.SELECT_JD].value,
              }}
            />
          )}
        </Stack>
        {selectedSection === CreateJobMatchSections.SELECT_RESUME && (
          <SelectResume
            onSelected={handleOnSelected}
            onAICustomizeResumeChange={onAICustomizeResumeChange}
            defaultValue={{
              section: completedSections[CreateJobMatchSections.SELECT_RESUME].section,
              value: completedSections[CreateJobMatchSections.SELECT_RESUME].value,
              shouldAiCustomizeResume,
            }}
          />
        )}

        {selectedSection === CreateJobMatchSections.JOB_SCORE_PROGRESS && (
          <ProgressManager id={matchScoreId} progressScreenKey={ProgressScreenType.JobMatchScore} />
        )}

        {/* Next button goes here */}
        {selectedSection !== CreateJobMatchSections.JOB_SCORE_PROGRESS && (
          <Stack
            sx={{
              flexDirection: 'row',
              marginTop: '32px',
              justifyContent: shouldDisplayBackButton ? 'space-between' : 'flex-end',
            }}
          >
            {shouldDisplayBackButton && (
              <Button variant="outlined" onClick={onClickBack} sx={{ color: 'primary.main' }}>
                Back
              </Button>
            )}
            <Button
              onClick={onClickNext}
              disabled={shouldDisableNextButton()}
              loading={isLoading || isResumeLoading}
            >
              Next
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CreateJobMatch;
