import { useEffect, useMemo, useState } from 'react';

import { IconButton, Stack, Typography, useTheme } from '@mui/material';

import BootstrapTooltip from '../../../../../components/common/BootstrapTooltip';
import Button from '../../../../../components/common/Button';
import type { CardProps } from '../../../../../components/common/Card';
import Icon from '../../../../../components/common/Icon';
import Modal from '../../../../../components/common/Modal/Modal';
import ModalActions from '../../../../../components/common/Modal/ModalActions';
import ModalDetail from '../../../../../components/common/Modal/ModalDetail';
import ProgressCircle from '../../../../../components/common/ProgressCircle';
import StyledCard from '../../../../../components/common/StyledCard';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { getScoreColor } from '../../../../../utils/colorUtils';

interface ScoreCardProps {
  score: number;
  result?: string;
  cardProps?: CardProps;
}

const ScoreCard = ({ score, result, cardProps }: ScoreCardProps) => {
  const progressCircleColor = getScoreColor(score);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const isMobile = useIsMobile();

  // Close the tooltip modal when switching from mobile to desktop view
  useEffect(() => {
    if (!isMobile) {
      setShowModal(false);
    }
  }, [isMobile]);

  const progressCircleContent = useMemo(
    () => (
      <Stack sx={{ gap: 2.5 }}>
        <Typography variant="display1">{Math.round(score)}</Typography>
        <Typography variant="label2" sx={{ color: 'text.secondary' }}>
          {result}
        </Typography>
      </Stack>
    ),
    [result, score],
  );

  return (
    <StyledCard {...cardProps}>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography variant="h3">Job Match Score</Typography>
        {isMobile ? (
          <>
            <IconButton aria-label="info" sx={{ padding: 0 }} onClick={() => setShowModal(true)}>
              <Icon
                className="fi fi-rr-info"
                fontSize="20px"
                style={{ color: theme.palette.text.secondary }}
              />
            </IconButton>
            <Modal open={showModal} contentSx={{ margin: 2, width: '80vw' }}>
              <>
                <ModalDetail
                  title="What is a Job Match Score?"
                  description="Ranging from 0 to 100, Job Match Score gauges how well a resume aligns with a job description, indicating its potential to pass ATS keyword screening and attract human reviewers."
                />
                <ModalActions
                  sx={{ justifyContent: 'center' }}
                  actions={
                    <Button
                      onClick={() => setShowModal(false)}
                      sx={{ width: '100%', padding: '16px 12px' }}
                    >
                      Got it
                    </Button>
                  }
                />
              </>
            </Modal>
          </>
        ) : (
          <BootstrapTooltip title="Ranging from 0 to 100, Job Match Score gauges how well a resume aligns with a job description, indicating its potential to pass ATS keyword screening and attract human reviewers.">
            <IconButton aria-label="info" sx={{ padding: 0 }}>
              <Icon
                className="fi fi-rr-info"
                fontSize="20px"
                style={{ color: theme.palette.text.secondary }}
              />
            </IconButton>
          </BootstrapTooltip>
        )}
      </Stack>
      <Stack sx={{ gap: 2.5, alignItems: 'center' }}>
        <ProgressCircle
          percentage={score}
          size="large"
          colour={progressCircleColor}
          content={progressCircleContent}
        />
        <Typography variant="assistiveBold" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          Score 75+ is recommended for job application
        </Typography>
      </Stack>
    </StyledCard>
  );
};
export default ScoreCard;
