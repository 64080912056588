import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, useTheme } from '@mui/material';

import BootstrapTooltip from '../../../components/common/BootstrapTooltip';
import Button from '../../../components/common/Button';
import Chip from '../../../components/common/Chip';
import Icon from '../../../components/common/Icon';
import ListingCard from '../../../components/common/ListingCard';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { Resume, ResumeSource } from '../../../services/resumes';
import { useSwipeableDrawerStore } from '../../../stores/SwipeableDrawerStore';
import { addSeparator, capitalize } from '../../../utils';
import { dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import type { ControlsProps } from './Control';
import Controls from './Control';

interface ResumesListProps {
  resumes: Resume[] | undefined;
  isLoading?: boolean;
  onClick?: (id: string, rowStatus: ResponseStatus, resumeSource: ResumeSource) => void;
}

const ResumesList = ({ resumes, isLoading, onClick }: ResumesListProps) => {
  const { isOpen, toggle: toggleSwipeableDrawer } = useSwipeableDrawerStore();
  const theme = useTheme();
  const navigate = useNavigate();
  const [control, setControl] = useState<ControlsProps>({
    id: '',
    status: ResponseStatus.TODO,
    isAiCustomized: false,
    filename: '',
  });

  const cardRows = useMemo(() => {
    const rows = resumes?.map((resume) => ({
      id: resume._id,
      status: resume.status,
      progress: resume.progress,
      lastUpdated: resume.updated_at,
      usedForScore: resume.used_for_score,
      source: resume.source,
      controls: {
        id: resume._id,
        status: resume.status,
        isAiCustomized: resume.is_ai_customized || resume.is_ai_generated,
        filename: resume.filename,
      },
      items: [
        {
          icon: 'fi fi-rr-document',
          heading: (
            <span>
              {resume.is_ai_customized || resume.is_ai_generated
                ? resume?.name || resume?.company_customized_for || ''
                : 'Base'}{' '}
              {resume.is_ai_customized && <Chip label="AI Customized" />}
              {resume.is_ai_generated && <Chip label="AI Sample" />}
            </span>
          ),
          subHeading: resume.filename,
        },
      ],
    }));

    return rows || [];
  }, [resumes]);

  const onMenuClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, rowControl: ControlsProps): void => {
      e.stopPropagation();
      setControl(rowControl);
      toggleSwipeableDrawer();
    },
    [toggleSwipeableDrawer],
  );

  useEffect(() => {
    // when swipeable drawer is closed, reset control
    if (!isOpen) {
      setControl({
        id: '',
        status: ResponseStatus.TODO,
        isAiCustomized: false,
        filename: '',
      });
    }
  }, [isOpen]);

  const CreateResumeButton = (
    <Button sx={{ paddingRight: 2 }} onClick={() => navigate(RoutePaths.RESUME_CREATE)}>
      <Icon className="fi fi-rr-plus" style={{ fontSize: '13px', padding: '3.5px' }} />
      Create
    </Button>
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {cardRows.length !== 0 ? (
        <>
          <Stack sx={{ gap: 2, padding: '24px 12px' }}>
            {cardRows.map((row) => (
              <ListingCard
                key={row.id}
                items={row.items}
                description={addSeparator(
                  row.status === ResponseStatus.IN_PROGRESS
                    ? 'In progress'
                    : dateToTimeAgo(row.lastUpdated),
                  capitalize(`${row.usedForScore ? '' : 'Not '}used for Job Matches`),
                  ' • ',
                )}
                control={
                  <BootstrapTooltip title="Menu">
                    <IconButton
                      aria-label="menu icon"
                      size="small"
                      component="button"
                      onClick={(e) => onMenuClick(e, row.controls)}
                    >
                      <Icon
                        className="fi fi-rr-menu-dots-vertical"
                        color={theme.palette.text.secondary}
                      />
                    </IconButton>
                  </BootstrapTooltip>
                }
                status={row.status}
                progress={row.progress}
                onClick={() => onClick?.(row.id, row.status, row.source)}
                enableStatus={row.status !== ResponseStatus.DONE}
              />
            ))}
          </Stack>
          <Controls {...control} />
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            height: '100%',
          }}
        >
          <CreateNewListing
            primaryText="Add your first Resume"
            secondaryText="Upload your first resume to JobQuest."
            icon="fi fi-br-document"
            action={CreateResumeButton}
            sx={{ border: 'none' }}
          />
        </Box>
      )}
    </>
  );
};

export default ResumesList;
