/* eslint-disable react/no-array-index-key */
import type { CertificatesLicensesInfo } from '../../../interface';
import { addSeparator } from '../../../utils';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';

interface TemplateCertificationProps {
  certifications: CertificatesLicensesInfo[];
  sectionContentProps?: SectionContentProps;
}

const TemplateCertification = ({
  certifications,
  sectionContentProps,
}: TemplateCertificationProps) => (
  <>
    {certifications?.map((certification, index) => (
      <SectionContent
        key={index}
        heading={certification?.name}
        subHeading={addSeparator(
          certification?.organization,
          getFormatedDate(certification),
          ' • ',
        )}
        subHeadingStyle={{
          ...styles.colorSecondary,
          ...styles.body1,
          ...sectionContentProps?.subHeadingStyle,
        }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplateCertification;
