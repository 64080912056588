/* eslint-disable react/no-array-index-key */
import type { AchievementInfo } from '../../../interface';
import { getFormatedDate } from '../../../utils/dateUtils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';

interface TemplatePatentProps {
  achievements: AchievementInfo[];
  sectionContentProps?: SectionContentProps;
}

const TemplatePatent = ({ achievements, sectionContentProps }: TemplatePatentProps) => (
  <>
    {achievements?.map((achievement, index) => (
      <SectionContent
        key={index}
        heading={achievement?.name}
        subHeading={achievement?.organization}
        content={achievement?.description}
        date={getFormatedDate(achievement)}
        subHeadingStyle={{
          ...styles.body1,
          ...styles.colorSecondary,
          ...sectionContentProps?.subHeadingStyle,
        }}
        {...sectionContentProps}
      />
    ))}
  </>
);

export default TemplatePatent;
